import React from "react";
import clsx from "clsx";
import { Card, Chip, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useNavigate } from "react-router-dom";
import DottedHorizontalMenu from "../../../components/dottedHorizontalMenu";

import Avatar from "src/components/Avatar";
import { RewardIcon, CalendarIcon } from "src/icons/common";
import { RECRUITER_SEARCH_RESULT_DOTTED_MENU_OPTION } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "250px",
    cursor: "pointer",
  },
  textBold: {
    fontSize: 16,
    fontWeight: "bold",
  },
  center: {
    display: "flex",
    textAlign: "center",
  },
  titleChip: {
    marginLeft: 20,
    border: "none",
    background: "#FFC0CB",
  },
  skillChip: {
    borderRadius: 5,
    fontWeight: 600,
    background: "#EFF3F8",
    marginRight: 8,
  },
  workTypeChip: {
    marginLeft: 20,
    height: 20,
  },
  avatar: {
    margin: 5,
    borderRadius: "50%",
  },
  moreAvatar: {
    margin: "auto 0",
  },
}));

const GeneralInfo = ({ jobPost, getCandidateProfile, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const workLocationPreference = jobPost.workLocationPreference;

  const handleMenuSelection = () => {};

  let topSkills = jobPost?.jobPostSkills?.filter((skill) => {
    return !skill.isAdditionalSkill;
  });

  let additionalSkills = jobPost?.jobPostSkills?.filter((skill) => {
    return skill.isAdditionalSkill;
  });

  let skills = topSkills.concat(additionalSkills);

  let skillsLeft = 0;

  let skillsToShow = skills;
  if (skills.length > 3) {
    skillsLeft = skills.length - 3;
    skillsToShow = skills.slice(0, 3);
  }
  const duration = jobPost?.duration;
  const durationUnit = jobPost?.timeUnit;

  const applicants = jobPost.applicantUserCodes || [];
  let applicantsToShow = applicants;
  let applicantsLeft = 0;
  if (applicantsToShow?.length > 4) {
    applicantsLeft = applicants.length - 3;
    applicantsToShow = skills.slice(0, 3);
  }

  const recCandidates = jobPost.recommendedCandidates || [];
  let recCandidatesToShow = recCandidates;
  let recCandidatesLeft = 0;
  if (recCandidatesToShow?.length > 4) {
    recCandidatesLeft = recCandidates.length - 4;
    recCandidatesToShow = recCandidates.slice(0, 3);
  }

  const handleClick = () => {
    navigate("/r/jobDetails/" + jobPost.id);
  };

  return (
    <Card className={classes.root}>
      <CardContent onClick={handleClick}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Typography variant="body1" className={classes.textBold}>
            {jobPost.title}
          </Typography>
          {workLocationPreference && (
            <Chip
              size="small"
              label={workLocationPreference}
              variant="outlined"
              className={clsx(classes.workTypeChip)}
            />
          )}
          <Chip
            size="small"
            className={clsx(classes.workTypeChip, classes.titleChip)}
            label={jobPost.timeCommitment}
            variant="outlined"
          />
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <RewardIcon style={{ marginRight: 10 }} />
          {skillsToShow.length > 0 &&
            skillsToShow.map((skill, key) => (
              <Chip
                key={key}
                className={classes.skillChip}
                variant="outlined"
                size="small"
                label={skill.skillName}
              />
            ))}
          {skillsLeft > 0 && (
            <Link to={"/r/jobDetails/" + jobPost.id}>
              <Chip
                className={classes.skillChip}
                variant="outlined"
                size="small"
                label={"+ " + skillsLeft + " more..."}
              />
            </Link>
          )}
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <CalendarIcon style={{ marginRight: 10 }} />
          {duration && (
            <Typography
              variant="body2"
              className={classes.center}
              display="block"
            >
              {duration + " " + durationUnit}
            </Typography>
          )}
        </div>
        {applicantsToShow.length > 0 && (
          <div
            container
            spacing={5}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <div>
              <Typography variant="body1" className={classes.textBold}>
                Applied Candidates
              </Typography>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                {applicantsToShow.map((applicant, key) => (
                  <div key={key} className={classes.avatar}>
                    <Avatar
                      URL={getCandidateProfile(applicant).profilePicUrl}
                      height={40}
                      width={40}
                      firstName={getCandidateProfile(applicant).firstName}
                      lastName={getCandidateProfile(applicant).lastName}
                    />
                  </div>
                ))}
                {applicantsLeft > 0 && (
                  <Link
                    to={"/r/jobDetails/" + jobPost.id}
                    className={classes.moreAvatar}
                  >
                    <Chip
                      variant="outlined"
                      size="small"
                      color="primary"
                      label={"+ " + applicantsLeft + " more..."}
                    />
                  </Link>
                )}
              </div>
            </div>
            <div style={{ alignSelf: "center" }}>
              <DottedHorizontalMenu
                options={RECRUITER_SEARCH_RESULT_DOTTED_MENU_OPTION}
                handleMenuSelection={handleMenuSelection}
                style={{ height: 30 }}
              />
            </div>
          </div>
        )}

        {(!applicantsToShow || applicantsToShow.length == 0) &&
          recCandidatesToShow.length > 0 && (
            <>
              <Typography variant="h6">Possible Matches</Typography>
              <span style={{ display: "flex", marginBottom: "10px" }}>
                {recCandidatesToShow.map((candidate, key) => (
                  <div key={key} className={classes.avatar}>
                    <Avatar
                      URL={
                        getCandidateProfile(candidate.userCode).profilePicUrl
                      }
                      height={40}
                      width={40}
                      firstName={
                        getCandidateProfile(candidate.userCode).firstName
                      }
                      lastName={
                        getCandidateProfile(candidate.userCode).lastName
                      }
                      borderRadius={"100px"}
                    />
                  </div>
                ))}
                {recCandidatesLeft > 0 && (
                  <Link
                    to={"/r/jobDetails/" + jobPost.id}
                    className={classes.moreAvatar}
                  >
                    <Chip
                      variant="outlined"
                      size="small"
                      color="primary"
                      label={"+ " + recCandidatesLeft + " more..."}
                    />
                  </Link>
                )}
              </span>
            </>
          )}

        {(!applicantsToShow || applicantsToShow.length == 0) &&
          (!recCandidatesToShow || recCandidatesToShow.length == 0) && (
            <Typography variant="h6">No Recommended Candidates..</Typography>
          )}
      </CardContent>
    </Card>
  );
};

const JobSummaryCard = ({ jobPost, getCandidateProfile, ...rest }) => {
  return (
    <GeneralInfo jobPost={jobPost} getCandidateProfile={getCandidateProfile} />
  );
};

export default JobSummaryCard;
