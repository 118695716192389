import { createContext, useState } from "react";
export const UpdateListenerContext = createContext({
    updateListeners: false,
    notifyUpdateListeners: () => { }
})

export const UpdateListenerContextProvider = (props) => {
    const [update, setUpdate] = useState(false)
    function togglerHandler() {
        setUpdate((update) => !update);
    }

    const context = {
        updateListeners: update,
        notifyUpdateListeners: togglerHandler
    }

    return (
        <UpdateListenerContext.Provider value={context}>
            {props.children}
        </UpdateListenerContext.Provider>
    )
}