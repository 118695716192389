import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  Typography,
  RadioGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import Button from "src/designsystem/Core/Button";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));

const RewardDialog = ({ open, setOpen, info, user, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      userType: "",
      gender: "male",
      policy: false,
      mobile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {}, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">Claim Reward</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid item sm={12} md={12} xs={12}>
            <div>
              <Typography variant="label" gutterBottom>
                Total Rewards Earned - 500
              </Typography>
            </div>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose Reward Type </FormLabel>
              <RadioGroup
                defaultValue="individual"
                aria-label="profileSelector"
                name="profile-selector"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      label=""
                    />
                    <Button variant="outlined" disabled>
                      Amazon Voucher
                    </Button>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value="agency"
                      control={<Radio />}
                      label=""
                    />
                  </div>
                  <Button variant="outlined" disabled>
                    Flipcart Voucher
                  </Button>
                </div>
              </RadioGroup>
            </FormControl>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Typography variant="body2">Enter Reward Point</Typography>
                <TextField size="small" variant="outlined" />
              </div>
              <Button>Submit</Button>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
};

export default RewardDialog;
