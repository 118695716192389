import React, { FC, ReactNode } from "react";
import styled from '@emotion/styled'
import { colors, typography, gutterBottom } from '../../theme'

const GvTypography = styled.p<GvTypography>`
font-family: Inter, sans-serif;
color: ${(props) => typography[props.variant].color};
font-size: ${(props) => typography[props.variant].size};
font-weight: ${(props) => typography[props.variant].weight};
margin: 0;
margin-bottom: ${(props) => props.gutterBottom ? gutterBottom : '0em'};
line-height: 1.15em;
`

export interface TypoProps {
  children: ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body' | 'label' | 'body2'
  gutterBottom?: boolean
  className?: string
}

interface GvTypography {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body' | 'label' | 'body2'
  gutterBottom?: boolean
}

const Typography: FC<TypoProps> = ({ children, variant = 'body', gutterBottom, className }) => {

  return (
    <GvTypography
      as={typography[variant].component}
      variant={variant}
      gutterBottom={gutterBottom}
      className={className}>
      {children}
    </GvTypography>
  )
}

export default Typography