import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
} from "@mui/material";
import VistasCardNew from "src/components/custom/VistasCardNew";

import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Page from "src/components/Page";
//import Notifications from './Notifications';
//import Password from './Password';
import PersonalInfo from "./PersonalInfo";
import VerifyEmail from "./VerifyEmail";
import DeactivateAccount from "./DeactivateAccount";
import DeleteAccount from "./DeleteAccount";
import NotificationsPreference from "./NotificationsPreferences";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  box: {
    backgroundColor: "white",
    margin: "20px 0 0 0",
    padding: "20px",
    borderRadius: "10px",
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("personal-info");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Typography variant="h5">My Account</Typography>
        <Box mt={3} className={classes.box}>
          <PersonalInfo />
        </Box>

        <Box mt={3} p={1} className={classes.box}>
          <VerifyEmail />
        </Box>

        <Box mt={3} p={1} className={classes.box}>
          <NotificationsPreference />
        </Box>

        <Box mt={3} className={classes.box}>
          <DeleteAccount />
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
