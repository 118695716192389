import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { GET_EMPLOYEE_CONTRACT_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "50px",
  },
}));

const Gigs = (props) => {
  const { gigs } = props;
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Candidate</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Deliverables</TableCell>
            <TableCell>Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gigs?.slice(page * limit, page * limit + limit).map((contract, i) => (
            <TableRow hover key={i}>
              <TableCell>
                <Box alignItems="center" display="flex">
                  <Typography color="textPrimary" variant="body1">
                    {"Name"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                {moment(contract.startDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                {moment(contract.endDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>{contract.deliverables}</TableCell>
              <TableCell>{contract.currency + " " + contract.fee}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={gigs.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default Gigs;
