import CandidateProfile from "../../../derivedComponents/CandidateProfile";
import WorkDetail from "src/derivedComponents/WorkExpDetail";
import EducationDetail from "src/derivedComponents/EducationDetail/index";
import CertificationDetail from "src/derivedComponents/CertificationDetail";
import HueCard from "src/designsystem/Core/HueCard";
import LoadingButton from "src/designsystem/Derivative/LoadingButton";
import ProjectCard from "../../../designsystem/Derivative/ProjectCard";
import FileCard from "../../../designsystem/Derivative/FilesCard";
import styled from "@emotion/styled";
import JobDetailEntity from "src/views/candidate/CandidateJobView/state/models";
import ContractDialog from "src/views/recruiter/RecruiterContract/ContractDialog";
import Page from "src/components/Page";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateProfileAction } from "./state/reducer";
import { useParams } from "react-router";
import { Resource } from "src/models/Resource";
import { CandidateProfileModel, Education } from "./state/models";
import UserContext from "src/context/user-context";
import InitiateContractForm from "./InitiateContractForm";
import { Certification } from "./state/models";
import axios from "axios";
import {
  CREATE_CANDIDATE_BOOKMARK,
  GET_CANDIDATE_BOOKMARKS,
  REMOVE_CANDIDATE_BOOKMARK,
} from "src/utils/constants";

const GvProfileCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
`;

const GvLeftCard = styled.div`
  flex: 49%;
  @media (max-width: 800px) {
    flex: 100%;
  }
`;

const GvRightCard = styled.div`
  flex: 49%;
  @media (max-width: 800px) {
    flex: 100%;
  }
`;

const ProfileDetails = (props: any) => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id?.toString();

  const userContext = useContext(UserContext);
  const userCode = userContext.user.get.userCode;

  const [certificationToShow, setCertificationToShow] = useState(1);

  const [experienceToShow, setExperienceToShow] = useState(1);

  const [educationToShow, setEducationToShow] = useState(1);
  const [showBookMark, setBookMark] = useState(false);

  const [initContract, setInitContract] = useState(false);

  useEffect(() => {
    dispatch(
      getCandidateProfileAction({
        candidate_code: !!id ? id : "",
        user_code: userContext.user.get.userCode,
        employerCode: userContext.user.get.userCode,
      })
    );
  }, []);

  const state: Resource<CandidateProfileModel> = useSelector<
    any,
    Resource<CandidateProfileModel>
  >((state) => {
    return state.candidateInfo;
  });

  if ("isPending" in state) {
    console.log("pending");
    return <div>Pending</div>;
  } else if ("resource" in state) {
    console.log("we have resource", state);
    return SuccessView(
      state.resource,
      userContext.user,
      certificationToShow,
      setCertificationToShow,
      experienceToShow,
      setExperienceToShow,
      educationToShow,
      setEducationToShow,
      initContract,
      setInitContract
    );
  } else if ("errorMessage" in state) {
    console.log("error");
    return errorView(state.errorMessage);
  } else {
    return <div>Fire! Fire! Fire!</div>;
  }
};

const errorView = (errorMessage: string) => {
  return <h1>{errorMessage}</h1>;
};

const SuccessView = (
  candidateProfile: CandidateProfileModel,
  user: any,
  certificationToShow: any,
  setCertificationToShow: any,
  experienceToShow: any,
  setExperienceToShow: any,
  educationToShow: any,
  setEducationToShow: any,
  initContract: any,
  setInitContract: any
) => {
  function showCertification(length: any) {
    setCertificationToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }
  function showExperience(length: any) {
    setExperienceToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  const showEducation = (length: any) => {
    setEducationToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  return (
    <Page title="Profile">
      <CandidateProfile
        editable={false}
        profileData={candidateProfile}
        onClick={() => {
          setInitContract(true);
        }}
        bookmark={candidateProfile.bookmarked}
      />
      <ContractDialog
        candidateUserCode={candidateProfile.userCode}
        name={`${candidateProfile.userProfile.firstName} ${candidateProfile.userProfile.lastName}`}
        profileUrl={candidateProfile.userProfile.profilePicUrl}
        title={candidateProfile.title}
        open={initContract}
        setOpen={setInitContract}
      />
      <GvProfileCard>
        {!!candidateProfile.candidateProjects &&
        candidateProfile.candidateProjects.length > 0 ? (
          <GvLeftCard>
            <HueCard
              title={"Projects"}
              hue={235}
              saturation={100}
              lightness={71}
            >
              <ProjectCard data={candidateProfile} />
            </HueCard>
          </GvLeftCard>
        ) : (
          <div></div>
        )}
        {candidateProfile.candidateFiles &&
        candidateProfile.candidateFiles.length > 0 ? (
          <GvRightCard>
            <HueCard
              title={"Portfolio and Files"}
              hue={235}
              saturation={100}
              lightness={71}
            >
              <FileCard
                data={candidateProfile.candidateFiles}
                deletable={false}
              />
            </HueCard>
          </GvRightCard>
        ) : (
          <GvRightCard>
            <HueCard
              title={"Portfolio and Files"}
              hue={235}
              saturation={100}
              lightness={71}
            >
              No Portfolio and Files Uploaded
            </HueCard>
          </GvRightCard>
        )}
      </GvProfileCard>
      {candidateProfile.certifications.length > 0 && (
        <HueCard
          hue={235}
          saturation={100}
          lightness={71}
          title="Certifications"
        >
          <>
            {candidateProfile.certifications
              .slice(0, certificationToShow)
              .map((certification: Certification) => (
                <CertificationDetail
                  data={certification}
                  key={certification.id}
                />
              ))}

            {candidateProfile &&
              candidateProfile.certifications &&
              candidateProfile.certifications.length > 1 &&
              (candidateProfile.certifications.length > 0 ? (
                certificationToShow === 1 ? (
                  <LoadingButton
                    onClick={() => {
                      showCertification(candidateProfile.certifications.length);
                    }}
                    fullWidth={true}
                  >
                    Show More
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      showCertification(1);
                    }}
                    fullWidth={true}
                  >
                    Show Less
                  </LoadingButton>
                )
              ) : (
                <div>No certification details provided...</div>
              ))}
          </>
        </HueCard>
      )}

      {candidateProfile.candidateExperiences.length > 0 && (
        <HueCard
          hue={235}
          saturation={100}
          lightness={71}
          title="Work Experience"
        >
          <>
            {candidateProfile.candidateExperiences
              .slice(0, experienceToShow)
              .map((candidateExperience) => (
                <WorkDetail data={candidateExperience} />
              ))}
            {candidateProfile &&
              candidateProfile.candidateExperiences &&
              candidateProfile.candidateExperiences.length > 1 &&
              (candidateProfile.candidateExperiences.length > 0 ? (
                experienceToShow === 1 ? (
                  <LoadingButton
                    onClick={() => {
                      showExperience(
                        candidateProfile.candidateExperiences.length
                      );
                    }}
                    fullWidth={true}
                  >
                    Show More
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      showExperience(1);
                    }}
                    fullWidth={true}
                  >
                    Show Less
                  </LoadingButton>
                )
              ) : (
                <div>No Work Experience details provided...</div>
              ))}
          </>
        </HueCard>
      )}

      {candidateProfile.educationList.length > 0 && (
        <HueCard hue={235} saturation={100} lightness={71} title="Education">
          <div>
            {candidateProfile.educationList
              .slice(0, educationToShow)
              .map((education: Education) => (
                <EducationDetail data={education} key={education.id} />
              ))}
            {candidateProfile &&
              candidateProfile.educationList &&
              candidateProfile.educationList.length > 1 &&
              (candidateProfile.educationList.length > 0 ? (
                educationToShow === 1 ? (
                  <LoadingButton
                    onClick={() => {
                      showEducation(candidateProfile.educationList.length);
                    }}
                    fullWidth={true}
                  >
                    Show More
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      showEducation(1);
                    }}
                    fullWidth={true}
                  >
                    Show Less
                  </LoadingButton>
                )
              ) : (
                <div>No Education details provided...</div>
              ))}
          </div>
        </HueCard>
      )}
    </Page>
  );
};

export default ProfileDetails;
