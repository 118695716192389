import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles({
  convMessageItem: {
    display: "flex",
    padding: "1rem 0 0 0",
    // opacity: 0,
    transition: "all 0.15s ease-in-out",
    animation: "fadeNewMessage 0.5s",
    animationFillMode: "forwards",
  },
  convMessageLeft: {
    justifyContent: "flex-start",
  },
  convMessageRight: {
    flexDirection: "row-reverse",
    justifyContent: "end",
  },
  convMessageAvatar: {
    width: 40,
    height: 40,
    borderRadius: 40,
    imageRendering: "-webkit-optimize-contrast",
    userSelect: "none",
  },
  convMessageValue: {
    position: "relative",
    maxWidth: "50%",
    minHeight: 40,
    padding: "0.75rem",
    borderRadius: 4,
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "bold",
    boxShadow: "0px 10px 10px -8px rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const MessageItem = (props) => {
  const classes = useStyles();
  /* message position formatting - right if I'm the author */
  let messagePosition =
    props.owner == props.sender
      ? classes.convMessageRight
      : classes.convMessageLeft;

  return (
    <div className={clsx(classes.convMessageItem, messagePosition)}>
      <img
        src={props.senderAvatar}
        alt={props.sender}
        className={classes.convMessageAvatar}
      />
      <div
        className={classes.convMessageValue}
        dangerouslySetInnerHTML={{ __html: props.message }}
      ></div>
    </div>
  );
};

export default MessageItem;
