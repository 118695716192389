import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import backgroundPattern from "src/assets/rectangle.png";

import UserContext from "src/context/user-context";
import axios from "axios";
import { GET_CANDIDATE_PROFILE_TRACKER } from "src/utils/constants";

const setupSteps = [
  {
    label: (
      <Link to="/c/candidate" style={{ color: "#fbfdff" }}>
        Add Profile Picture
      </Link>
    ),
    completed: true,
    key: "profileImage",
  },
  {
    label: (
      <Link to="/c/candidate" style={{ color: "#fbfdff" }}>
        Upload Resume / Portfolio
      </Link>
    ),
    completed: false,
    key: "hasAttachments",
  },
  {
    label: (
      <Link to="/c/candidate" style={{ color: "#fbfdff" }}>
        Add Work Experience & Education
      </Link>
    ),
    completed: false,
    key: "expEduCert",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    opacity: 0.83,
    backgroundColor: "#0071bd",
    backgroundImage: `url(${backgroundPattern})`,
    backgroundPosition: "center",
    color: "#fbfdff",
    borderRadius: 8,
    paddingTop: 50,
    margin: "0px 25px 10px 25px",
  },
  setupSteps: {
    fontSize: 14,
    fontWeight: 500,
  },
  step: {
    fontSize: "0.9rem",
    marginBottom: 8,
    height: 40,
    padding: 0,
  },
  stepIcon: {
    minWidth: 30,
  },
  completedStep: {
    color: "#28d497",
  },
  incompleteStep: {
    color: "#ffffff",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  actionButton: {
    color: "#fbfdff",
    borderRadius: 8,
    fontSize: 14,
    backgroundColor: "rgba(255, 255, 255, 0.49)",
  },
  title: {
    color: "#fbfdff",
    fontSize: "1.2rem",
    fontWeight: "450",
    marginBottom: "15px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#28d497",
  },
}))(LinearProgress);

const updateSetUpSteps = (stateResponse, setProgress, setProgressBarValue) => {
  let newSteps = [];
  let completedSteps = 0;
  setupSteps.forEach(function (step) {
    let stepNew = step;
    stepNew.completed = stateResponse[step.key];
    newSteps.push(stepNew);
    completedSteps += stepNew.completed ? 1 : 0;
  });
  setProgress((oldSteps) => {
    return newSteps;
  });

  let progBarValue = (100 / (setupSteps.length / completedSteps)).toFixed(2);

  setProgressBarValue((oldVal) => {
    return progBarValue;
  });
};

const SetupStatusCard = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [progress, setProgress] = useState(setupSteps);
  const [progressBarValue, setProgressBarValue] = useState(0);

  useEffect(() => {
    setProgress(setupSteps);

    axios({
      method: "GET",
      url:
        GET_CANDIDATE_PROFILE_TRACKER +
        "?userCode=" +
        userContext.user.get.userCode,
    })
      .then((response) => {
        updateSetUpSteps(
          response.data.userTracker,
          setProgress,
          setProgressBarValue
        );
        // updateSetUpSteps(
        //   profileSetupResponse,
        //   setProgress,
        //   setProgressBarValue
        // );
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  }, [setupSteps]);

  // const profileSetupResponse = {
  //   profilePic: true,
  //   hasAttachments: false,
  //   expEduCert: true,
  // };

  return (
    <Card className={classes.root}>
      <CardContent>
        <p className={classes.title}>Complete your Profile</p>
        <BorderLinearProgress
          variant="determinate"
          value={parseInt(progressBarValue)}
        />
        <List component="nav" className={classes.setupSteps}>
          {progress.map((step) => {
            return (
              <ListItem className={classes.step} key={step.label}>
                <ListItemIcon className={classes.stepIcon}>
                  {step.completed ? (
                    <CheckCircleIcon
                      color="primary"
                      className={classes.completedStep}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      color="primary"
                      className={classes.incompleteStep}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={step.label} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default SetupStatusCard;
