import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import UserContext from "src/context/user-context";
import { GET_CONTACT_URL } from "src/utils/constants";
import MessageService from "src/components/messaging/MessageService";
import webSocketUtil from "src/utils/WebSocketUtil";

const MessageContext = createContext({
  contacts: [],
  addContact: (userCode) => {},
  activeContact: null,
  addActiveContact: (userCode) => {},
  connectionWs: null,

  messageHandler: null,
  notificationHandler: null,
  addMessageHandler: (onmessage) => {},
  addNotificationHandler: (onnotification) => {},
});

const MessageProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const loggedInUserCode = userContext.user.get.userCode;
  const [contacts, setContact] = useState([]);
  const [activeContact, setActiveContact] = useState(null);
  const [connectionWs, setConnectionWs] = useState(null);

  const [messageHandler, setMessageHandler] = useState(null);
  const [notificationHandler, setNotificationHandler] = useState(null);

  const fetchContacts = async (cb) => {
    const response = await axios
      .get(GET_CONTACT_URL + `?userCode=` + loggedInUserCode)
      .catch((err) => console.log(err));

    let contactsData = [];
    if (response != undefined) {
      if (response.data && response.data.length > 0) {
        response.data.forEach((contact) => {
          contactsData.push(MessageService.getUserObject(contact));
        });
      }
    }
    setContact((prevContactsData) => {
      return contactsData;
    });
    cb && typeof cb === "function" && cb.apply(null, []);
  };

  useEffect(() => {
    fetchContacts();
    setConnectionWs((prevConnection) =>
      webSocketUtil.openConnection(userContext.user.get.userCode, onmessage)
    );

    return () => {
      setConnectionWs((prevConnection) => {
        prevConnection &&
          typeof prevConnection.close == "function" &&
          prevConnection.close();
        return null;
      });
    };
  }, [userContext]);

  const onmessage = (message) => {
    if (message.type == "MESSAGE") {
      setMessageHandler((messageHandler) => {
        if (messageHandler && typeof messageHandler === "function") {
          messageHandler.apply(null, [message]);
        }
        return messageHandler;
      });
    } else if (message.type == "NOTIFICATION") {
      setNotificationHandler((notificationHandler) => {
        if (notificationHandler && typeof notificationHandler === "function") {
          notificationHandler.apply(null, [message]);
        }
        return notificationHandler;
      });
    }
  };
  const addMessageHandler = (onmessage) => {
    setMessageHandler(() => onmessage);
  };
  const addNotificationHandler = (onnotification) => {
    setNotificationHandler(() => onnotification);
  };

  const addContact = (userCode, cb) => {
    const newContact = {
      firstUser: loggedInUserCode,
      secondUser: userCode,
    };
    axios
      .post(GET_CONTACT_URL, newContact)
      .then((res) => {
        console.log(res);
        fetchContacts(cb);
      })
      .catch((err) => console.log(err));
  };

  const addActiveContact = (userCode) => {
    setActiveContact(() => userCode);
  };

  return (
    <MessageContext.Provider
      value={{
        contacts,
        addContact,
        activeContact,
        addActiveContact,
        connectionWs,
        addNotificationHandler,
        addMessageHandler,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider, MessageContext };
