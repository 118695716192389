import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Collapse,
  IconButton,
  Link as MaterialLink,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import Page from "src/components/Page";
import UserContext from "src/context/user-context";
import FeedCard from "src/components/cards/feed-card";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { CourseLeft, CourseRight } from "./Courses";
import { m } from "framer-motion";
import { USER_PARTNER_PRODUCT_URL } from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  customAlert: {
    backgroundColor: "#FFE996",
  },
}));

const courseList = [
  {
    courseId: "1",
    courseTitle: "Smart Entrepreneur Foundation Program",
    partnerLogo:
      "https://gv-org-logo.s3.ap-south-1.amazonaws.com/beta/global-gyan/gg-logo.png",
    partnerName: "Global Gyan",
    partnerCode: "globalgyan",
    courseDescription:
      "Enroll into this exclusive GigVistas program and get access to 4 courses",
    courseType: "Digital Learning",
  },
];
const SkillsUpgrade = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [activeTab, setActiveTab] = useState("courses");

  const [alertOpen, setAlertOpen] = React.useState(true);

  useEffect(() => {
    axios({
      method: "GET",
      url:
        USER_PARTNER_PRODUCT_URL + "?userCode=" + userContext.user.get.userCode,
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
    setCourses(() => {
      return courseList;
    });
  }, [userContext]);

  const handleAvail = (courseId) => {
    let newCourses = [...courses];

    newCourses.forEach((c, i) => {
      if (c.courseId == courseId) {
        c.availed = true;
      }
    });
    setCourses(newCourses);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(() => newValue);
  };

  return (
    <Page className={classes.root} title="Skills Upgrade">
      <AppBar
        position="static"
        style={{
          boxShadow: "none",
          marginTop: "15px",
          backgroundColor: "#F4F6F8",
          marginBottom: "10px",
        }}
      >
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Skills Upgrade" value="courses" />
          {/* <Tab label="Skill Assessment" value="tests" /> */}
        </Tabs>
      </AppBar>
      {activeTab == "courses" ? (
        courses.map((course, key) => (
          <>
            <Collapse in={alertOpen}>
              <Alert
                icon={false}
                severity="success"
                className={classes.customAlert}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                To avail this <strong>free course</strong>, please click on
                “Avail Coupon Code”, copy the code and apply the code during
                payment checkout on{" "}
                <MaterialLink
                  href="https://globalgyan.in/product/gigvistas-smart-entrepreneur-foundation-program/"
                  target="_blank"
                  rel="noreferrer"
                >
                  GlobalGyan's Website
                </MaterialLink>
              </Alert>
            </Collapse>
            <FeedCard
              left={<CourseLeft entry={course} />}
              right={<CourseRight entry={course} onAvail={handleAvail} />}
              leftWidth="70vw"
              rightWidth="30vw"
              key={key}
            />
          </>
        ))
      ) : (
        <></>
      )}
    </Page>
  );
};

export default SkillsUpgrade;
