import React from "react";
import { Grid, IconButton, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles({
  convInput: {
    width: "100%",
  },
});

const MessageInput = (props) => {
  const classes = useStyles();
  const messageInput = React.useRef(null);
  const ownerInput = React.useRef(null);
  const ownerAvatarInput = React.useRef(null);

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (messageInput.current > 0) {
      console.log(messageInput.current);
      props.sendMessageLoading(
        ownerInput.current,
        ownerAvatarInput.current,
        messageInput.current
      );

      /* Reset input after send*/
      messageInput.value = "";
    }
  };

  const handleTyping = (event) => {
    const value = event.target.value;
    /* Tell users when another user has at least started to write */
    if (value > 0) {
      props.typing(value);
    } else {
      /* When there is no more character, the user no longer writes */
      props.resetTyping(value);
    }
  };

  return (
    <form onSubmit={handleSendMessage}>
      <input type="hidden" ref={ownerInput} value={props.owner} />
      <input type="hidden" ref={ownerAvatarInput} value={props.ownerAvatar} />

      <Grid container spacing={1} alignItems="flex-end">
        <Grid item md={10}>
          <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            onChange={handleTyping}
            placeholder="Text message"
            size="small"
            className={classes.convInput}
          />
        </Grid>
        <Grid item md={2}>
          <IconButton
            onClick={handleSendMessage}
            aria-label="delete"
            size="large"
          >
            <SendIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default MessageInput;
