import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Button from "src/designsystem/Core/Button";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import { BookmarkOne } from "@icon-park/react";
import { motion } from "framer-motion";
import HueButton from "src/designsystem/Core/HueButton";
import { CheckCorrect } from "@icon-park/react";
import Tooltip from "@mui/material/Tooltip";

const GvAddTask = styled(Button)<AddTaskProps>`
  padding: 0em;
`;
const GvHueAddTask = styled(HueButton)<AddTaskProps>`
  padding: 0em;
`;

interface AddTaskProps {
  toggle: boolean;
  onClick?: () => void;
  hue?: number;
  fullWidth?: boolean;
}

const AddTaskButton: FC<AddTaskProps> = ({
  toggle,
  fullWidth,
  onClick,
  hue,
}) => {
  if (hue) {
    return (
      <GvHueAddTask
        toggle={toggle}
        variant={toggle ? "contained" : "outlined"}
        onClick={onClick}
        hue={hue}
      >
        <Tooltip title="Shortlist" arrow>
          <motion.div
            whileTap={{ scale: 0.9 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            <CheckCorrect
              theme={toggle ? "filled" : "outline"}
              size="20"
              fill={toggle ? colors.white : `hsl(${hue}, 40%, 50%)`}
              style={{ display: "grid", placeItems: "center" }}
            />
          </motion.div>
        </Tooltip>
      </GvHueAddTask>
    );
  } else {
    return (
      <GvAddTask
        toggle={toggle}
        variant={toggle ? "contained" : "outlined"}
        onClick={onClick}
      >
        <Tooltip title="Shortlist" arrow>
          <motion.div
            whileTap={{ scale: 0.9 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            <CheckCorrect
              theme={toggle ? "filled" : "outline"}
              size="20"
              fill={toggle ? colors.white : colors.p40}
              style={{ display: "grid", placeItems: "center" }}
            />
          </motion.div>
        </Tooltip>
      </GvAddTask>
    );
  }
};

export default AddTaskButton;
