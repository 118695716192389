import React, { useState, useEffect, useContext, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import Autocomplete from "@mui/material/Autocomplete";
import { useFormik, Formik } from "formik";
import UserContext from "src/context/user-context";
import axios from "axios";
import * as yup from "yup";

import {
  JOB_CATEGORY_URL,
  JOB_CATEGORY_LIST,
  JOB_CATEGORY_EXPERTISE_LIST,
  JOB_CATEGORY_SKILL_LIST,
  POST_DRAFT_URL,
  CurrencyFormat,
  CurrencyIcon,
} from "src/utils/constants";
import ProficiencyInfo from "src/components/job/ProficiencyInfo.js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fontWeight, padding } from "@mui/system";
import { useNavigate } from "react-router";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";

const useStyles = makeStyles((theme) => ({
  helperTextLayout: {
    "& .css-1wxe1b7-MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  editorlayout: {
    minHeight: "100px",

    "& .ql-editor": {
      //<--- here
      margin: 0,
      minHeight: "100px",
    },
  },
  bottomBtn: {
    textAlign: "right",
  },
  errorText: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.75rem",
    padding: "5px 0px",
    margin: 0,
  },
  ipLabel: {
    margin: 0,
    padding: 0,
    zIndex: 10000,
  },
}));

// const category = JOB_CATEGORY_LIST;

const validationSchema = yup.object().shape({
  componentFunc: yup.string().required(),
  title: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Must be valid text")
      .max(160, "Must be less than 160 characters")
      .required("Title is required"),
    otherwise: yup.string("Must be valid text").required("Title is required"),
  }),
  description: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Must be valid text")
      .max(5000, "Must be less than 5000 characters")
      .required("Description is required"),
    otherwise: yup.string("Must be valid text"),
  }),
  category: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Must be valid category")
      .min(1, "Category is required")
      .required("Category is required"),
    otherwise: yup.string("Must be valid category"),
  }),
  expertise: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Must be valid expertise")
      .required("Expertise is required"),
    otherwise: yup.string("Must be valid expertise"),
  }),
  preferredSkills: yup.array().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .array()
      .min(1, "Atleast 1 Top Skill is required")
      .required("Top Skill is required"),
    otherwise: yup.array(),
  }),
  duration: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Duration must be valid number ")
      .matches(/^\d+$/, "The field should have digits only")
      .min(1, "Must atleast be 1 character")
      .typeError("Duration is required")
      .required("Duration is required"),
    otherwise: yup
      .string("Duration must be valid number ")
      .matches(/^\d+$/, "The field should have digits only")
      .nullable(true),
  }),
  // pincode: yup.string().when("componentFunc", {
  //   is: (componentFunc) => {
  //     return componentFunc !== "saveDraft";
  //   },
  //   then: yup
  //     .string("Must be of 6 digits")
  //     .matches(/^[0-9]{6}$/, "Must be exactly 6 digits"),
  //   otherwise: yup
  //     .string("Must be of 6 digits")
  //     .matches(/^[0-9]{6}$/, "Must be exactly 6 digits"),
  // }),
  jobFormattedLocations: yup.array().when(["componentFunc", "projectType"], {
    is: (componentFunc, projectType) => {
      return componentFunc !== "saveDraft" && projectType !== "remote";
    },
    then: yup
      .array()
      .min(1, "Atleast 1 Job Location is required")
      .required("Job Location is required"),
    otherwise: yup.array(),
  }),
});

const JobDetails = ({
  funcFlag,
  className,
  handleNext,
  jobpost,
  setJobPost,
  setToggler,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [address, setAddress] = React.useState("");
  // console.log(jobpost);
  const google = window.google;

  const searchOptions = {
    location: new google.maps.LatLng(-34, 151),
    // language:"fr",
    // radius: 2000,
    // types: ['address']
  };

  function handleCategoryChange(event, values, setValues) {
    let categorySelected = event.target.value;
    axios({
      method: "GET",
      url: JOB_CATEGORY_URL + categorySelected,
    })
      .then((response) => {
        setValues((values) => {
          let newVals = { ...values };
          newVals.categoryExpertiseList = response.data;
          newVals.category = categorySelected;
          newVals.expertise = response.data[0];
          return newVals;
        });
        axios({
          method: "GET",
          url: JOB_CATEGORY_URL + categorySelected + "%7C" + response.data[0],
        })
          .then((response) => {
            console.log(response);
            setValues((values) => {
              let newVals = { ...values };
              newVals.categorySkillsList = response.data;
              return newVals;
            });
          })
          .catch((error) => {
            //handleServerResponse(false, error.response.data.error);
          });
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  }

  function handleCategoryExpertiseChange(event, values, setValues) {
    let expertiseSelected = event.target.value;

    axios({
      method: "GET",
      url: JOB_CATEGORY_URL + values.category + "%7C" + expertiseSelected,
    })
      .then((response) => {
        setValues((values) => {
          let newVals = { ...values };
          newVals.categorySkillsList = response.data;
          newVals.expertise = expertiseSelected;
          return newVals;
        });
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  }

  function handleDescriptionChange(html, values, setValues) {
    // console.log("inside handleeDescriptionChange ", html);
    values.description = html;
    setValues(values);
  }

  function submitJobDetails(newJob) {
    console.log(newJob, "Inside submit job details");
    setJobPost(newJob);
    handleNext();
  }

  useEffect(() => {
    getSubCategory(formik.values.category, formik.values, formik.setValues);
  }, []);

  const getSubCategory = (category, values, setValues) => {
    axios({
      method: "GET",
      url: JOB_CATEGORY_URL + category,
    })
      .then((response) => {
        console.log(response);
        formik.values.categoryExpertiseList = response.data;
        formik.values.category = category;
        setValues(values);
        axios({
          method: "GET",
          url:
            JOB_CATEGORY_URL +
            values.category +
            "%7C" +
            values.categoryExpertiseList[0],
        })
          .then((response) => {
            console.log(response);
            formik.values.categorySkillsList = response.data;
            formik.values.categoryExpertise = values.categoryExpertiseList[0];
            setValues(values);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  let initialValues = {
    id: jobpost.id,
    componentFunc: "postJob",
    title: jobpost.title,
    description: jobpost.description,
    category: jobpost.category,
    expertise: jobpost.expertise,
    proficiencyLevel: jobpost.proficiencyLevel,
    currencyCode: jobpost.currencyCode,
    skills: jobpost.jobPostSkills
      ? jobpost.jobPostSkills
          .filter((skill) => skill.isAdditionalSkill)
          .map((skill) => skill.skillName)
      : [],
    preferredSkills: jobpost.jobPostSkills
      ? jobpost.jobPostSkills
          .filter((skill) => !skill.isAdditionalSkill)
          .map((skill) => skill.skillName)
      : [],

    projectType: jobpost.projectType,
    timeCommitment: jobpost.timeCommitment,
    duration: jobpost.duration,
    timeUnit: jobpost.timeUnit,
    budget: jobpost.budget,
    jobPostLocations: jobpost.jobPostLocations ? jobpost.jobPostLocations : [],
    addedLocations: [],
    // pincode: jobpost.jobPostLocations
    //   ? jobpost.jobPostLocations[0]?.pincode
    //   : "",
    //jobFormattedLocations: jobpost.JobFormattedLocations ? jobpost.JobFormattedLocations : [],
    jobFormattedLocations: jobpost.jobPostLocations
      ? jobpost.jobPostLocations.map(
          (jobPostLocation) => jobPostLocation.formattedAddress
        )
      : [],

    //jobFormattedLocations: jobpost.jobPostLocations ? jobpost.jobPostLocations.map((jobPostLocation)  => jobPostLocation.formattedAddress): jobpost.JobFormattedLocations?jobpost.JobFormattedLocations:[],
    categorySkillsList: JOB_CATEGORY_SKILL_LIST,
    categoryExpertiseList: JOB_CATEGORY_EXPERTISE_LIST,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      let newJob = { ...jobpost };

      let jobPostSkills = [];
      let jobPLocationsList = [];
      let addedLocations = [];

      values.jobFormattedLocations.forEach((jobFormattedLocation, i) => {
        geocodeByAddress(jobFormattedLocation)
          .then((results) => jobPLocationsList.push(results[0]))
          .catch((error) => console.error(error));
      });

      // if ( funcFlag === "editJob") {
      //   values.jobFormattedLocations.forEach((jobFormattedLocation, i) => {
      //     geocodeByAddress(jobFormattedLocation)
      //       .then((results) => jobPLocationsList.push(results[0]))
      //       .catch((error) => console.error(error));
      //   });
      // }

      values.preferredSkills.forEach((val, i) => {
        jobPostSkills.push({
          skillName: val,
          isAdditionalSkill: false,
        });
      });

      values.skills.forEach((val, i) => {
        jobPostSkills.push({
          skillName: val,
          isAdditionalSkill: true,
        });
      });

      values.jobFormattedLocations.forEach((jobFormattedLocation, i) => {
        addedLocations.push({
          formattedAddress: jobFormattedLocation,
        });
      });

      newJob.id = jobpost.id ?? null;
      newJob.title = values.title;
      newJob.description = values.description;
      newJob.category = values.category;
      newJob.expertise = values.expertise;
      newJob.proficiencyLevel = values.proficiencyLevel;
      newJob.jobPostSkills = jobPostSkills;
      newJob.projectType = values.projectType;
      newJob.timeCommitment = values.timeCommitment;
      newJob.duration = values.duration;
      newJob.timeUnit = values.timeUnit;
      newJob.budget = values.budget;
      newJob.currencyCode = values.currencyCode;
      if (values.componentFunc !== "saveDraft") {
        newJob.jobPostLocations = addedLocations;
        newJob.jobPostLocationList = jobPLocationsList;
        newJob.JobFormattedLocations = values.jobFormattedLocations;
      }

      if (funcFlag === "editJob" || funcFlag === "editDraft") {
        newJob.addedLocations = [];
      }

      if (values.componentFunc === "saveDraft") {
        newJob.jobPostLocations = jobPLocationsList;
      }
      if (values.componentFunc === "saveDraft" && funcFlag === "addNewJob") {
        setTimeout(() => {
          const { JobFormattedLocations, jobPostLocationList, ...rest } =
            newJob;
          axios
            .post(
              `${POST_DRAFT_URL}?userCode=${userContext.user.get.userCode}`,
              rest
            )
            .then((res) => {
              console.log(res, "post draft succcessfull");
              setToggler((prevVal) => !prevVal);
              navigate("/r/jobs");
            })
            .catch((err) => {
              console.log(err, "posting draft failed");
            });
        }, 500);

        console.log("saving draft");
        // onClose();
        return;
      }

      if (values.componentFunc === "saveDraft" && funcFlag === "editDraft") {
        setTimeout(() => {
          const { JobFormattedLocations, jobPostLocationList, ...rest } =
            newJob;
          axios
            .put(
              `${POST_DRAFT_URL}?userCode=${userContext.user.get.userCode}`,
              {
                ...rest,
                id: jobpost.id,
              }
            )
            .then((res) => {
              console.log(res, "updating draft succcessfull");
              setToggler((prevVal) => !prevVal);
              // onClose();
            })
            .catch((err) => {
              console.log(err, "updating draft failed");
            });
        }, 500);
      }

      if (values.componentFunc === "postJob" && funcFlag === "editJob") {
        console.log("editing an existing job ");
        submitJobDetails(newJob);
      }

      if (values.componentFunc === "postJob" && funcFlag === "addNewJob") {
        console.log("posting a new job ");
        submitJobDetails(newJob);
      }

      if (values.componentFunc === "postJob" && funcFlag === "editDraft") {
        console.log("converting a draft to job post ");
        const { JobFormattedLocations, jobPostLocationList, ...rest } = newJob;
        submitJobDetails(newJob);
      }

      console.log("Nothing happens");
      console.log(values.componentFunc, funcFlag);
    },
  });

  const handleDraftSave = async () => {
    await formik.setFieldValue("componentFunc", "saveDraft");
    console.log(formik.values);
    formik.handleSubmit();
  };

  const handlePostJob = async (event) => {
    event.preventDefault();
    await formik.setFieldValue("componentFunc", "postJob");
    formik.handleSubmit();
  };

  function handleLocationChange(event) {
    setAddress(event);
  }

  // Log error status and clear dropdown when Google Maps API returns an error.
  const onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handlePostJob}
      {...rest}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Title*"
                name="title"
                className={classes.helperTextLayout}
                onChange={formik.handleChange}
                variant="outlined"
                value={formik.values.title}
                size="small"
                placeholder="Eg: Software Developer, UI Designer, Helping clients to meet revenue goals"
                error={Boolean(formik.touched.title && formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <ReactQuill
                className={classes.editorlayout}
                // name="description"
                theme="snow"
                onChange={(e, _, __, editor) => {
                  // formik.handleChange(e);
                  const text = editor.getText(e).trim().split(" ").length;
                  // console.log(e);
                  // console.log("text", "ab" + "     " + text + "cd");
                  if (text === "") {
                    console.log("setting blank value for description");
                    formik.setFieldValue("description", "");
                  } else {
                    handleDescriptionChange(e, formik.values, formik.setValues);
                  }
                }}
                placeholder={"Description*"}
                value={formik.values.description}
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              {formik.touched.description && formik.errors.description && (
                <p className={classes.errorText}>{formik.errors.description}</p>
              )}
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={4}>
              <FormControl component="fieldset" fullWidth size="small">
                <InputLabel className={classes.ipLabel}>Category*</InputLabel>
                <Select
                  name="category"
                  value={formik.values.category}
                  label="Category*"
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    handleCategoryChange(e, formik.values, formik.setValues);
                  }}
                  error={Boolean(
                    formik.touched.category && formik.errors.category
                  )}
                  // helperText={formik.touched.category && formik.errors.category}
                >
                  {JOB_CATEGORY_LIST.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={classes.errorText}>
                  {formik.touched.category && formik.errors.category}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                className={classes.helperTextLayout}
                label="Expertise*"
                name="expertise"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleCategoryExpertiseChange(
                    e,
                    formik.values,
                    formik.setValues
                  );
                }}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.expertise}
                size="small"
                error={Boolean(
                  formik.touched.expertise && formik.errors.expertise
                )}
                helperText={formik.touched.expertise && formik.errors.expertise}
              >
                {formik.values.categoryExpertiseList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl component="fieldset">
                <ProficiencyInfo />
                <RadioGroup
                  row
                  aria-label="Proficiency Level"
                  name="proficiencyLevel"
                  value={formik.values.proficiencyLevel}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="basic"
                    control={<Radio />}
                    label="Basic"
                  />
                  <FormControlLabel
                    value="proficient"
                    control={<Radio />}
                    label="Proficient"
                  />
                  <FormControlLabel
                    value="expert"
                    control={<Radio />}
                    label="Expert"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                id="preferredSkills"
                className={classes.helperTextLayout}
                options={formik.values.categorySkillsList.map(
                  (option) => option
                )}
                freeSolo
                value={formik.values.preferredSkills}
                onChange={(e, val) => {
                  let otherSkillsValues = formik.values.preferredSkills;
                  if (
                    e.target.localName === "li" &&
                    val.length < otherSkillsValues.length
                  ) {
                    return;
                  }
                  if (val.length > 10) {
                    val.splice(10, 1);
                  }
                  formik.setValues((oldVal) => {
                    var temp = { ...oldVal };
                    temp.preferredSkills = val;
                    return temp;
                  });
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Top Skills*"
                    placeholder="Choose a skill OR type a new skill and press enter to add"
                    multiline
                    rows={2}
                    inputProps={{ ...params.inputProps, maxLength: 40 }}
                    size="small"
                    error={Boolean(
                      formik.touched.preferredSkills &&
                        formik.errors.preferredSkills
                    )}
                    helperText={
                      formik.touched.preferredSkills &&
                      formik.errors.preferredSkills
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                id="skills"
                options={formik.values.categorySkillsList.map(
                  (option) => option
                )}
                freeSolo
                value={formik.values.skills}
                onChange={(e, val) => {
                  let SkillsValues = formik.values.skills;
                  if (
                    e.target.localName === "li" &&
                    val.length < SkillsValues.length
                  ) {
                    return;
                  }
                  if (val.length > 10) {
                    val.splice(10, 1);
                  }
                  formik.setValues((oldVal) => {
                    var temp = { ...oldVal };
                    temp.skills = val;
                    console.log("TPS:", temp);
                    return temp;
                  });
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Additional Skills"
                    placeholder="Choose a skill OR type a new skill and press enter to add"
                    inputProps={{ ...params.inputProps, maxLength: 40 }}
                    multiline
                    rows={2}
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
                size="small"
                // error={Boolean(touched.projectType && errors.projectType)}
                // helperText={touched.projectType && errors.projectType}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Location Type
                </InputLabel>
                <Select
                  fullWidth
                  native
                  onChange={formik.handleChange}
                  label="Location Type"
                  inputProps={{
                    name: "projectType",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                  value={formik.values.projectType}
                >
                  <option value="remote">Remote</option>
                  <option value="onsite">Onsite</option>
                  <option value="hybrid">Hybrid</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
                size="small"
                // error={Boolean(touched.timeCommitment && errors.timeCommitment)}
                // helperText={touched.timeCommitment && errors.timeCommitment}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Time Commitment
                </InputLabel>
                <Select
                  fullWidth
                  native
                  onChange={formik.handleChange}
                  label="Time Commitment"
                  inputProps={{
                    name: "timeCommitment",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                  value={formik.values.timeCommitment}
                >
                  <option value="Flexible">Flexible</option>
                  <option value="Part-Time">Part-Time</option>
                  <option value="Full-Time">Full-Time</option>{" "}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <TextField
                fullWidth
                label="Duration*"
                className={classes.helperTextLayout}
                name="duration"
                onChange={formik.handleChange}
                variant="outlined"
                size="small"
                rows="number"
                value={formik.values.duration}
                error={Boolean(
                  formik.touched.duration && formik.errors.duration
                )}
                helperText={formik.touched.duration && formik.errors.duration}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
                size="small"
                // error={Boolean(touched.timeUnit && errors.timeUnit)}
                // helperText={touched.timeUnit && errors.timeUnit}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Time Unit
                </InputLabel>
                <Select
                  fullWidth
                  native
                  onChange={formik.handleChange}
                  label="Time Unit"
                  inputProps={{
                    name: "timeUnit",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                  value={formik.values.timeUnit}
                >
                  <option value={"Hours"}>Hours</option>
                  <option value={"Days"}>Days</option>
                  <option value={"Weeks"}>Weeks</option>
                  <option value={"Months"}>Months</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
              <TextField
                fullWidth
                label="Overall Budget"
                name="budget"
                onChange={formik.handleChange}
                variant="outlined"
                size="small"
                value={formik.values.budget}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
              {/* <InputLabel shrink className={classes.inputLabel}>
                    Currency*
                  </InputLabel> */}
              <TextField
                label="Currency*"
                fullWidth
                variant="outlined"
                name="currencyCode"
                size="small"
                onChange={(e) => {
                  e.preventDefault();
                  formik.handleChange(e);
                }}
                select
                SelectProps={{ native: true }}
                value={formik.values.currencyCode}
              >
                {CurrencyFormat.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            {formik.values.projectType !== "remote" ? (
              // <Grid item xs={12} sm={12} md={4} lg={4}>
              //   <TextField
              //     fullWidth
              //     label="Location Pincode"
              //     name="pincode"
              //     placeholder="Enter valid 6 digits pincode"
              //     onChange={formik.handleChange}
              //     variant="outlined"
              //     size="small"
              //     value={formik.values.pincode}
              //     error={Boolean(
              //       formik.touched.pincode && formik.errors.pincode
              //     )}
              //     helperText={formik.touched.pincode && formik.errors.pincode}
              //   />
              // </Grid>

              //GooglePlace https://www.npmjs.com/package/react-places-autocomplete

              <Grid item md={12} xs={12}>
                <PlacesAutocomplete
                  value={address}
                  onChange={(e) => {
                    handleLocationChange(e);
                  }}
                  onError={onError}
                  // searchOptions={searchOptions}
                  shouldFetchSuggestions={address.length > 3}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        options={suggestions.map(
                          (suggestion) => suggestion.description
                        )}
                        value={formik.values.jobFormattedLocations}
                        onChange={(e, val) => {
                          let jobFormattedLocations =
                            formik.values.jobFormattedLocations;
                          if (
                            e.target.localName === "li" &&
                            val.length < jobFormattedLocations.length
                          ) {
                            return;
                          }
                          if (val.length > 3) {
                            val.splice(3, 1);
                          }
                          formik.setValues((oldVal) => {
                            var temp = { ...oldVal };
                            temp.jobFormattedLocations = val;
                            console.log("TPS:", temp);
                            return temp;
                          });
                        }}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <div {...getSuggestionItemProps(option)}>
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            </div>
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...getInputProps()}
                            variant="outlined"
                            label="Job Location"
                            multiline
                            rows={2}
                            size="small"
                            error={Boolean(
                              formik.touched.jobFormattedLocations &&
                                formik.errors.jobFormattedLocations
                            )}
                            helperText={
                              formik.touched.jobFormattedLocations &&
                              formik.errors.jobFormattedLocations
                            }
                          />
                        )}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container>
            {(funcFlag === "addNewJob" || funcFlag === "editDraft") && (
              <>
                <Grid
                  item
                  justifyContent="flex-start"
                  lg={6}
                  md={6}
                  sm={8}
                  xs={8}
                >
                  <Button
                    strech
                    variant="outlined"
                    color="primary"
                    onClick={handleDraftSave}
                  >
                    Save as draft
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.bottomBtn}
                  lg={6}
                  md={6}
                  sm={4}
                  xs={4}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Next
                  </Button>
                </Grid>
              </>
            )}
            {funcFlag === "editJob" && (
              <Grid
                item
                className={classes.bottomBtn}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Button variant="contained" color="primary" type="submit">
                  Next
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {/* <Popover
              id="popover"
              open={openPopOver}
              anchorEl={anchorEl}
              onClose={handleAnchorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Typography className={classes.typography}>
                {popOverMessage}
              </Typography>
            </Popover> */}
      </Card>
    </form>
  );
};

JobDetails.propTypes = {
  className: PropTypes.string,
};

export default JobDetails;
