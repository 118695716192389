import axios from "axios";
import { GET_CANDIDATE_URL } from "src/utils/constants";

export const candidateProfile = async (user_code: string) => {
  const response = await axios({
    method: "GET",
    url: GET_CANDIDATE_URL + "?userCode=" + user_code,
  });

  return response.data;
};
