import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadRecruiteJobDetail,
  loadDraftDetailAction,
  getJobApplicantsAction,
  getShortListApplicantsAction,
} from "./state/RecuiterReducers";
import {
  RecruiterJobDetailEntity,
  RecruiterJobInfo,
  CandidateSummaryResponse,
} from "./state/models";
import { Resource } from "src/models/Resource";
import {
  IconButton,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import UserContext from "src/context/user-context";
import JobStepperEdit from "src/components/job-steps/JobStepperEdit";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import Card from "src/designsystem/Core/Card";
import JobCardContactMini from "src/derivedComponents/JobCardContactMini";
import JobCardContactSelection from "src/derivedComponents/JobCardContactSelection";
import AppliedCandidate from "src/derivedComponents/AppliedCandidate";
import Section from "src/designsystem/Core/Section";
import styled from "@emotion/styled";
import { breakpoints } from "src/designsystem/theme";
import JobEditCard from "src/derivedComponents/JobEditCard";

import Button from "src/designsystem/Core/Button";
import { useLocation } from "react-router";

import Locations from "src/designsystem/Derivative/Locations";
import Assessment from "./assessment";
const useStyles = makeStyles((theme) => ({}));

const CJDView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const TopCard = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Description = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const Suggestions = styled.div`
  display: grid;
  row-gap: 15px;
  margin-top: 25px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const CJDRadio = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    margin-left: 700px;
  }
`;

const JobDetailView = (param: { jobID: number }) => {
  const location = useLocation();
  let pageFunc = "";
  if (location.pathname.split("/").includes("jobDetails")) {
    pageFunc = "jobDetails";
  } else if (location.pathname.split("/").includes("jobDrafts")) {
    pageFunc = "jobDrafts";
  }

  const { jobID } = param;
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const userContext = useContext(UserContext);

  const handleOpen = () => {
    setOpen(true);
  };
  const [toggler, setToggler] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [toggler]);

  var user_code: any = null;
  if (userContext.user) {
    const user: any = userContext.user;
    user_code = user.get.userCode;
  }

  const loadData = () => {
    if (pageFunc === "jobDetails") {
      dispatch(loadRecruiteJobDetail({ jobID, user_code, page_Number: 0 }));
    } else if (pageFunc === "jobDrafts") {
      dispatch(loadDraftDetailAction({ jobID, user_code }));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const state: RecruiterJobDetailEntity = useSelector<
    any,
    RecruiterJobDetailEntity
  >((state) => state.recuiterJobInfo);

  console.log("State", state);

  return (
    <div>
      <div>
        {BuildInfo(
          state.jobInfo,
          classes,
          handleOpen,
          open,
          setOpen,
          isMobile,
          toggler,
          setToggler,
          handleClose,
          state.applicants,
          state.recommendations,
          state.shortListApplicants,
          user_code,
          jobID,
          pageFunc,
          dispatch
        )}
      </div>
    </div>
  );
};
// var itemsToShow = 2;

const BuildInfo = (
  info: Resource<RecruiterJobInfo>,
  classes: any,
  handleOpen: any,
  open: any,
  setOpen: any,
  isMobile: any,
  toggler: any,
  setToggler: any,
  handleClose: any,
  appliedCandidates: any,
  recommendedCandidates: any,
  shortListCandidates: any,
  user_code: any,
  jobID: any,
  pageFunc: string,
  dispatch: any
) => {
  const [recommendedCandidateToShow, setRecommendedCandidateToShow] =
    useState(2);
  
  const [shortlistCandidateToShow, setShortlistCandidateToShow] = useState(2);
  const [assessmentDialogOpen, setAssessmentDialogOpen] = useState(false);
  

  function showRecommendedCandidates(length: any) {
    setRecommendedCandidateToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  // function showAppliedCandidate(length: any) {
  //   setAppliedCandidateToShow(length);
  //   window.scroll({ top: 0, behavior: "smooth" });
  // }

  function showtShortlistCandidate(length: any) {
    setShortlistCandidateToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  if ("isPending" in info) {
    return <div>Pending</div>;
  } else if ("resource" in info) {
    return (
      <CJDView>
        <TopCard>
          <JobEditCard
            jobDetailEntity={info.resource}
            handleOpen={handleOpen}
            pageFunc={pageFunc}
          />
        </TopCard>
        <Description>
          <Card title={"Job Description"}>
            <div
              dangerouslySetInnerHTML={{
                __html: info.resource.jobPost.description,
              }}
            ></div>
          </Card>
        </Description>

        {info.resource.jobPost.jobPostLocations?.length > 0 ? (
          <Description>
            <Card title={"Job Location(s)"}>
              <Locations
                jobPostLocations={info.resource.jobPost.jobPostLocations}
              />
            </Card>
          </Description>
        ) : (
          <></>
        )}

      {/* <Suggestions>
      <Section children={"Applicant Assessment"} />
      <div>
        <Button>Create Assessment</Button>
      </div>

      </Suggestions> */}

        {pageFunc === "jobDetails" && (
          <>
            <Suggestions>
              <Section children={"Shortlisted Candidates"} />
              {shortListCandidates.resource &&
              shortListCandidates.resource.candidates.length > 0 ? (
                <div>
                  {shortListCandidates &&
                    shortListCandidates.resource.candidates
                      .slice(0, shortlistCandidateToShow)
                      .map((applicant: CandidateSummaryResponse) => (
                        <JobCardContactSelection
                          user_code={user_code}
                          applicantInfo={applicant}
                          jobID={jobID}
                          refetch={() => {
                            dispatch(
                              getJobApplicantsAction({
                                jobID,
                                user_code,
                                page_Number: 0,
                              })
                            );
                            dispatch(getShortListApplicantsAction({
                              jobID,
                              user_code,
                              page_Number: 0,
                            })
                          );
                          }}
                        />
                      ))}

                  {shortListCandidates &&
                  shortListCandidates.resource.candidates.length > 2 ? (
                    shortlistCandidateToShow === 2 ? (
                      <Button
                        fullWidth
                        onClick={() => {
                          showtShortlistCandidate(
                            shortListCandidates.resource.candidates.length
                          );
                        }}
                      >
                        Show More
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        onClick={() => {
                          showtShortlistCandidate(2);
                        }}
                      >
                        Show Less
                      </Button>
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div>No Shortlisted Candidates...</div>
              )}
            </Suggestions>

            <Suggestions>
              <div
              // style={{
              //   display: "grid",
              //   gridTemplate: "1fr/1fr 1fr",
              //   //   alignItems: "baseline",
              //   //   justifyContent: "space-between",
              // }}
              >
                {/* Filter Button */}
                {/* <CJDRadio>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                      <FormControlLabel
                        control={<Switch name="goodFit" />}
                        label="Good Fit"
                        checked={state.goodFit}
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        control={<Switch name="hasFiles" />}
                        label="Has Resume"
                        checked={state.hasFiles}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </FormControl>
                </CJDRadio> */}

                <Section children={"Applied Candidates"} />
              </div>
              {appliedCandidates.resource &&
              appliedCandidates.resource.candidates &&
              appliedCandidates.resource.candidates.length > 0 ? (
                <div style={{ display: "grid", rowGap: "10px" }}>
                  <AppliedCandidate
                    user_code={user_code}
                    applicantInfo={appliedCandidates.resource}
                    jobID={jobID}
                    refetch={() => {
                      //dispatch(loadRecruiteJobDetail({ jobID, user_code,page_Number:0}));
                      dispatch(
                        getJobApplicantsAction({
                          jobID,
                          user_code,
                          page_Number: 0,
                        })
                      );
                      dispatch(getShortListApplicantsAction({
                          jobID,
                          user_code,
                          page_Number: 0,
                        })
                      );
                    }}
                  />
                </div>
              ) : (
                <div>No Applied Candidates...</div>
              )}
            </Suggestions>
            <Suggestions>
              <Section children={"Recommended Candidates"} />
              {recommendedCandidates.resource &&
                recommendedCandidates.resource.candidates
                  .slice(0, recommendedCandidateToShow)
                  .map((recommendation: CandidateSummaryResponse) => (
                    <JobCardContactMini
                      user_code={user_code}
                      applicantInfo={recommendation}
                    />
                  ))}
              {recommendedCandidates.resource &&
              recommendedCandidates.resource.candidates &&
              recommendedCandidates.resource.candidates.length > 2 ? (
                recommendedCandidateToShow === 2 ? (
                  <Button
                    onClick={() => {
                      showRecommendedCandidates(
                        recommendedCandidates.resource.candidates.length
                      );
                    }}
                  >
                    Show More
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={() => {
                      showRecommendedCandidates(2);
                    }}
                  >
                    Show Less
                  </Button>
                )
              ) : (
                <div></div>
              )}
            </Suggestions>
          </>
        )}
        {
          <Dialog
            onClose={(event, reason) => {
              if (reason == "backdropClick") return;
              handleClose();
            }}
            aria-labelledby="customized-dialog-title"
            fullScreen={isMobile}
            open={open}
            maxWidth="lg"
            classes={{ paper: classes.dialogPaper }}
          >
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              className={classes.close}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <JobStepperEdit
                funcFlag={pageFunc === "jobDetails" ? "editJob" : "editDraft"}
                toggler={toggler}
                setToggler={setToggler}
                jobInfo={info.resource?.jobPost}
                getJobInfo={info.resource}
              />
            </DialogContent>
          </Dialog>
        }
        {
          <Dialog
            onClose={(event, reason) => {
              if (reason == "backdropClick") return;
              handleClose();
            }}
            aria-labelledby="customized-dialog-title"
            fullScreen
            open={assessmentDialogOpen}
            maxWidth="lg"
            classes={{ paper: classes.dialogPaper }}
          >
            <IconButton
              aria-label="close"
              onClick={() => setAssessmentDialogOpen(false)}
              className={classes.close}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Assessment/>
            </DialogContent>
          </Dialog>
        }
      </CJDView>
    );
  } else if ("errorMessage" in info) {
    return <div>Error: {info.errorMessage}</div>;
  }
};

export default JobDetailView;
