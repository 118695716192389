import React, { FC, useContext } from "react";
import UserContext from "src/context/user-context";
import styled from "@emotion/styled";
import { colors } from "../../designsystem/theme";
import HueButton from "../../designsystem/Core/HueButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

import { getCandidateProfileAction } from "src/views/candidate/CandidateProfile/state/reducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  CONTRACT_DOWNLOAD_ATTACHMENT_URL,
  CONTRACT_DELETE_ATTACHMENT_URL,
} from "src/utils/constants";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
// import { CurrentNotificationContext } from "src/context/notification-context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useMediaQuery } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import FullScreenDialog from "src/components/fullScreenDialog";

interface GvAttachment {
  hue: number;
  size: string;
  src: string | null;
  id?: any;
  // candidateFileId?: number;
}

const GvAttachmentDetails = styled.div<GvAttachment>`
  background-color: hsl(${(props) => props.hue}, 40%, 50%);
  background-image: url(${(props) => props.src});
  background-size: cover;
  border: 1px solid ${colors.nv80};
  font-family: Inter, sans-serif;
  border-radius: 8px;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  font-size: calc(${(props) => props.size} / 2.5);
  color: ${colors.white};
  display: grid;
  place-items: bottom;
  user-select: none;
  position: relative;
  &:hover > .hover {
    display: block;
  }

  & .actions {
    background-color: transparent;
    outline: none;
    border: none;
  }

  & > .hover {
    display: none;
  }

  & > .hidden {
    display: none;
  }
`;

const Hover = styled.div``;

// const DownloadButton = styled.div`
//     &:hover>: {
//     color: "green";
//     background-color: "green";
//    }
// `;
interface AttachmentProps {
  src: string | null;
  alt: string;
  size?: string;
  hue?: number;
  name: string;
  candidateFileId: number;
  deletable?: boolean;
  refetch?:any;
}

const Attachment: FC<AttachmentProps> = ({
  src,
  alt,
  size = "170px",
  name,
  candidateFileId,
  deletable = true,
  refetch
}) => {
  // const currentNotification = useContext(CurrentNotificationContext);
  const userContext = useContext<any>(UserContext);
  const user: any = userContext.user;
  const userCode = user.get.userCode;
  const dispatch = useDispatch();

  const file = CONTRACT_DOWNLOAD_ATTACHMENT_URL + candidateFileId;
  const type = "pdf" || "docx";
  const docs = [
    {
      fileType: alt.split(".")[1],
      uri: CONTRACT_DOWNLOAD_ATTACHMENT_URL + candidateFileId,
      fileData: alt,
    },
  ];

  const previewFile = (id: number, fileName: string) => {
    setOpenPreview(true);
  };

  const downloadFile = (id: number, fileName: string) => {
    axios({
      url: CONTRACT_DOWNLOAD_ATTACHMENT_URL + id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {  
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };

  const deleteFile = (id: number) => {
    axios
      .delete(CONTRACT_DELETE_ATTACHMENT_URL + id)
      .then((res) => {
        setOpenDelete(false);
        refetch();
      })
      .catch((err) => console.log(err));
  };
  let color: number = 235;
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClose = () => setOpenPreview(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("xs"));

  const handleClickPreview = () => {
    previewFile(candidateFileId, alt);
  };

  const handleClickDownload = () => {
    setOpenDownload(true);
    downloadFile(candidateFileId, alt);
  };

  const handleClickDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDownload = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDownload(false);
  };

  const handleCloseDelete = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDelete(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <GvAttachmentDetails hue={color} size={size} src={src}>
        <Hover
          className="hover"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            backgroundColor: "#00000066",
            textAlign: "center",
            // display: "flex",
            // gap: "5px",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "15%",
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 25px)",
                // left: "calc(50% - 16px)",
              }}
            >
              <IconButton
                className="actions"
                onClick={handleClickPreview}
                style={{ color: "white" }}
              >
                <Tooltip title="Preview" arrow>
                  <VisibilityIcon fontSize="large"></VisibilityIcon>
                </Tooltip>
              </IconButton>
              <Dialog
                fullWidth={true}
                maxWidth="lg"
                fullScreen={fullScreen}
                open={openPreview}
                onClose={handleClose}
              >
                <div>
                  <DialogTitle
                    id="responsive-dialog-title"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    {alt}
                    <IconButton
                      aria-label="close"
                      onClick={() => setOpenPreview(false)}
                      size="large"
                    >
                      <CloseIcon
                        style={{ display: "flex", alignItems: "flex-end" }}
                      />
                    </IconButton>
                  </DialogTitle>
                  <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    documents={docs}
                    style={{ width: "100%", height: 1100 }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: false,
                        retainURLParams: true,
                      },
                    }}
                  ></DocViewer>
                </div>
              </Dialog>

              <IconButton
                className="actions"
                onClick={handleClickDownload}
                style={{ color: "white" }}
              >
                <Tooltip title="Download" arrow>
                  <DownloadIcon fontSize="large" />
                </Tooltip>
              </IconButton>
              <Snackbar
                open={openDownload}
                autoHideDuration={6000}
                onClose={handleCloseDownload}
              >
                <Alert
                  onClose={handleCloseDownload}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Downloaded successfully!!
                </Alert>
              </Snackbar>
            </div>
            {deletable && (
              <div
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                }}
              >
                <IconButton
                  className="actions"
                  onClick={handleClickDelete}
                  style={{ color: "white" }}
                >
                  <Tooltip title="Delete" arrow>
                    <DeleteIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
                <Dialog
                  open={openDelete}
                  onClose={() => {
                    handleCloseDelete();
                  }}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Delete Uploaded File"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure, permanently want to <strong>DELETE</strong>{" "}
                      the file?
                      <br />
                      <strong> Note:</strong> Can't UNDO this change
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => deleteFile(candidateFileId)}
                    >
                      Yes
                    </Button>
                    <Button onClick={handleCloseDelete} autoFocus>
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
        </Hover>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {" "}
          <HueButton hue={color}>
            <div>{name}</div>
          </HueButton>
        </div>
      </GvAttachmentDetails>
    </div>
  );
};

export default Attachment;
