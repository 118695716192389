import React, { createContext} from "react";
import Expertise from "../views/onboarding/Candidate/Expertise";
import Preference from "../views/onboarding/Candidate/Preference";


import { RewardActiveIcon } from "src/icons/onboarding-icons";
import { Settings } from "@mui/icons-material";
export const STEPS = [
  {
    name: "My Expertise",
    key: "expertise",
    active: true,
    completed: false,
    icon: RewardActiveIcon,
  },
  {
    name: "Preferences",
    key: "preference",
    active: false,
    completed: false,
    icon: Settings,
  },
];

const OnBoardingContext = createContext({
  steps: [],
  currentStep: 0,
  expertise: {},
  addExpertise: (expertise) => { },
  hasExpertise: () => { },
  getCurrentStep: (index) => { },
  setCurrentStep:(index) => {},

});

export function OnBoardingContextProvider(props) {
  const [useExpertise, setExpertise] = React.useState({});
  const [steps, setSteps] = React.useState(STEPS);
  const [currentStep, setCurrentStep] = React.useState(0);

  function handleNext() {
    if (currentStep >= STEPS.length - 1) {
      return;
    }

    let nextStep = currentStep + 1;
    steps[currentStep].completed = true;
    steps[currentStep].active = false;
    steps[nextStep].active = true;

    setSteps(steps);
    setCurrentStep(nextStep);
  }

  function handleBack() {
    if (currentStep <= 0) {
      return;
    }
    let prevStep = currentStep - 1;
    steps[currentStep].completed = false;
    steps[currentStep].active = false;
    steps[prevStep].active = true;
    setCurrentStep(prevStep);
  }

  function getCurrentStep(index) {
    switch (index) {
      case 0:
        return <Expertise handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return <Preference handleNext={handleNext} handleBack={handleBack} />;
      default:
        return null;
    }
  }

  function addExpertiseHandler(expertise) {
    setExpertise(expertise);
    localStorage.setItem("Expertise", JSON.stringify(expertise));
  }
  

  function hasExpertiseData() {
    if (Object.values(useExpertise).length > 0) {
      return true;
    } else if (localStorage.getItem("Expertise")) {
      let expertise = JSON.parse(localStorage.getItem("Expertise")),
        checkExpertise = false;

      if (Object.values(expertise).length > 0) {
        // setExpertise(expertise);
        checkExpertise = true;
      }
      return checkExpertise;
    }
  }

  
  const context = {
    steps: steps,
    currentStep: currentStep,
    expertise: useExpertise,
    addExpertise: addExpertiseHandler,
    hasExpertise: hasExpertiseData,
    getCurrentStep: getCurrentStep,
    setCurrentStep:setCurrentStep
  };

  return (
    <OnBoardingContext.Provider value={context}>
      {props.children}
    </OnBoardingContext.Provider>
  );
}

export default OnBoardingContext;
