import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Chip from "../../Core/Chip";

const GvChipContainer = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
`;

const GvChipContainerFixed = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  white-space: nowrap;
  text-transform: capitalize;
`;

const GvTimeChip = styled(Chip)`
  background-color: ${colors.t80};
  color: ${colors.t0};
`;

const GvTimeChipFixed = styled(Chip)`
  background-color: ${colors.t80};
  color: ${colors.t0};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const GvLocationChip = styled(Chip)`
  background-color: ${colors.s80};
  color: ${colors.s0};
`;

const GvLocationChipFixed = styled(Chip)`
  background-color: ${colors.s80};
  color: ${colors.s0};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

interface LTChipsProps {
  location: string;
  timePref: string;
  fixedWidth?: boolean;
}

const LTChip: FC<LTChipsProps> = ({ location, timePref, fixedWidth }) => {
  return fixedWidth ? (
    <GvChipContainerFixed>
      {!!timePref && <GvTimeChipFixed>{timePref}</GvTimeChipFixed>}
      {!!location && <GvLocationChipFixed>{location}</GvLocationChipFixed>}
    </GvChipContainerFixed>
  ) : (
    <GvChipContainer>
      {!!timePref && <GvTimeChip>{timePref}</GvTimeChip>}
      {!!location && <GvLocationChip>{location}</GvLocationChip>}
    </GvChipContainer>
  );
};

export default LTChip;
