import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import JobDetailData from "src/models/JobDetailData";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import LTChip from "../../designsystem/Derivative/LTChip";
import Skills from "../../designsystem/Derivative/Skills";
import Button from "src/designsystem/Core/Button";
import Bookmark from "../../designsystem/Derivative/Bookmark";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Organization from "src/views/candidate/CandidateJobView/state/models";
import { Link } from "react-router-dom";
import JobDetailEntity from "src/views/candidate/CandidateJobView/state/models";
import {
  getJobDetailAction,
  applyToJobAction,
  bookmarkToJobAction,
  removeBookmarkAction,
} from "src/views/candidate/CandidateJobView/state/CandidateReducers";
import CityLocation from "src/designsystem/Derivative/CityLocation";
const GvJobCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const GvGigLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 90px 1fr;
  padding: 15px;
`;
const GvGigRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 200px;
    gap: 30px;
    padding: 15px;
  }
`;
const GvGigDetails = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (min-width: ${breakpoints["lg"]}) {
    gap: 30px;
    flex-direction: row;
  }
`;

interface JobCardMiniProps {
  jobDetails: JobDetailData;
  organization?: any;
  jobDetailEntity: JobDetailEntity;
  jobID: number;
  user_code: string;
  dispatch: any;
}

function getJobStatus(jobStatus: string) {
  if (jobStatus === "REVIEW") {
    return true;
  } else if (jobStatus === "ACTIVE") {
    return true;
  } else {
    return false;
  }
}

const JobCardMini: FC<JobCardMiniProps> = ({
  jobDetails,
  organization,
  jobDetailEntity,
  jobID,
  user_code,
  dispatch,
}) => {
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const jobDetail = "/c/jobDetails/" + jobDetails.id;

  const hasBookMarkedState =
    jobDetails && jobDetails.hasBookMarked ? true : false;
  const hasJobStatusState =
    jobDetails && getJobStatus(jobDetails.status[0]) ? true : false;
  const hasAppliedState = jobDetails && jobDetails.hasApplied ? true : false;

  const [hasBookMarked, setToggle] = useState(hasBookMarkedState);
  const [hasApplied, setAppliedToggle] = useState(hasAppliedState);
  return (
    <GvJobCard>
      <GvGigLeft>
        <Link to={jobDetail}>
          <Avatar
            src={organization?.logo}
            alt={organization?.name}
            size={"80px"}
          />
        </Link>
        <div>
          {" "}
          <Link to={jobDetail}>
            <Typography variant={matches ? "h4" : "h5"}>
              {jobDetails.title}
            </Typography>
          </Link>
          <div style={{ height: "5px" }} />
          <Typography variant="label">{jobDetails.category}</Typography>
          <div style={{ height: "10px" }} />
          {matches ? (
            <div style={{ display: "flex", columnGap: "5px" }}>
              <LTChip
                timePref={jobDetails.timeCommitment}
                location={jobDetails.projectType}
              />

              <div style={{ height: "5px" }} />
              <CityLocation cityLists={jobDetails?.cityList} />
            </div>
          ) : (
            <div>
              <LTChip
                timePref={jobDetails.timeCommitment}
                location={jobDetails.projectType}
              />

              <div style={{ height: "5px" }} />
              <CityLocation cityLists={jobDetails?.cityList} />
            </div>
          )}
        </div>
      </GvGigLeft>
      <GvGigRight>
        <GvGigDetails>
          <div>
            <Typography variant="label" gutterBottom>
              Budget
            </Typography>
            <Typography variant="h5">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <AddSubtract
                  theme="outline"
                  size="24"
                  strokeWidth={3}
                  style={{ display: "grid", placeItems: "center" }}
                />
                {jobDetails.budget ? "₹" + jobDetails.budget : ""}
              </div>
            </Typography>
          </div>
          <div>
            <Typography variant="label" gutterBottom>
              Duration
            </Typography>
            <Typography variant="h5">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <CalendarDot
                  theme="outline"
                  size="24"
                  strokeWidth={3}
                  style={{ display: "grid", placeItems: "center" }}
                />
                {!!jobDetails.duration || !!jobDetails.timeUnit
                  ? jobDetails.duration + " " + jobDetails.timeUnit
                  : ""}
              </div>
            </Typography>
          </div>
        </GvGigDetails>
        <div style={{ display: "flex", gap: "15px" }}>
          {!hasApplied && hasJobStatusState ? (
            <Button
              fullWidth
              onClick={() => {
                dispatch(applyToJobAction({ jobID, user_code }));
                setAppliedToggle(true);
              }}
            >
              Apply
            </Button>
          ) : (
            <Button disabled fullWidth>
              {hasJobStatusState ? "Applied" : jobDetails.status[0]}
            </Button>
          )}
          {!hasBookMarked ? (
            <Bookmark
              toggle={hasBookMarked}
              onClick={() => {
                dispatch(bookmarkToJobAction({ jobID, user_code }));
                setToggle(true);
              }}
            />
          ) : (
            <Bookmark
              toggle={true}
              onClick={() => {
                dispatch(removeBookmarkAction({ jobID, user_code }));
                setToggle(false);
              }}
            />
          )}
        </div>
      </GvGigRight>
    </GvJobCard>
  );
};

export default JobCardMini;
