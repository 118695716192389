import React, { FC } from "react";
import Page from "src/components/Page";
import { useParams } from "react-router-dom";

import ProfileDetails from "./ProfileDetails";

const JobIdProfile: FC = () => {

  let { jobId } = useParams();

  return (
    <Page title="Edit Profile">
        <ProfileDetails jobID={parseInt(jobId!, 10)} />
    </Page>
  );
};

export default JobIdProfile;