import React, { FC, ReactElement, ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import { motion } from "framer-motion";
import { useField } from "formik";
import { FormHelperText } from "@mui/material";
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import Typography from "src/designsystem/Core/Typography";
import Button from "src/designsystem/Core/Button";

const ButtonForm = styled.div`
  margin-bottom: 10px;
`;
const GvFormLabel = styled(Typography)<SubCompProps>`
  color: ${(props) => (props.dark ? colors.white : colors.text)};
  margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  font-family: Inter, sans-serif;
  font-size: 1rem;
`;

const GvButtonBase = styled(Button)<SubCompProps>`
  display: flex;
  font-weight: 500;
  background-color: ${(props) => (props.dark ? colors.p30 : colors.p40)};
  border: 2px solid ${(props) => (props.dark ? colors.p60 : colors.p40)};
  &:hover {
    background-color: ${(props) => (props.dark ? colors.p20 : colors.p30)};
  }
  ${(props) =>
    props.dark &&
    `
    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 ${colors.p60 + "77"}, 0 0 0 5px ${
      colors.p60 + "98"
    };
        outline: none;
      }
    `}
`;
const GvButtonSel = styled(GvButtonBase)`
  place-items: center;
  gap: 10px;
  height: 50px;
  padding: 0em 1em;
`;
const GvButtonUnsel = styled(GvButtonSel)`
  color: ${colors.p40};
  background-color: ${colors.white};
  border: 2px solid ${colors.p40};
  &:hover {
    background-color: ${colors.nv80};
  }
`;
const GvButtonSelLarge = styled(GvButtonBase)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 250px;
  font-weight: 500;
`;
const GvButtonUnSelLarge = styled(GvButtonSelLarge)`
  color: ${colors.p40};
  background-color: ${colors.white};
  border: 2px solid ${colors.p40};
  &:hover {
    background-color: ${colors.nv80};
  }
`;

const GvHelperText = styled(Typography)<SubCompProps>`
  font-size: 0.9rem;
  // color: ${(props) => (props.dark ? "#FFE2D1" : colors.e60)};
  color: #f3f808;
  font-size: 1rem;
  font-weight: 600;
  margin: 2px 0px 1.5px 1px;
`;

interface ButtonSelectProps {
  options: SelectOption[];
  large?: boolean;
  name: string;
  label: string;
  dark?: boolean;
}

interface SubCompProps {
  large?: boolean;
  dark?: boolean;
}

export interface SelectOption {
  iconSel: ReactNode;
  iconUnsel: ReactNode;
  value: string | boolean;
  title: string;
}

const ButtonSelect: FC<ButtonSelectProps> = ({
  options,
  large = false,
  name,
  label,
  dark = false,
}) => {
  const [field, meta, helpers] = useField(name);
  const { value, touched, error } = meta;
  const { setValue } = helpers;

  return (
    <ButtonForm>
      <GvFormLabel dark={dark}>{label}</GvFormLabel>
      {large ? (
        <ButtonContainer>
          {options.map((option, i) => {
            if (option.value === value) {
              return (
                <GvButtonSelLarge
                  onClick={() => setValue(option.value)}
                  dark={dark}
                  key={i}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    {option.iconSel}
                  </div>
                  <p style={{ padding: "0em 1em", marginTop: "10px" }}>
                    {option.title}
                  </p>
                </GvButtonSelLarge>
              );
            } else {
              return (
                <GvButtonUnSelLarge
                  onClick={() => setValue(option.value)}
                  dark={dark}
                  key={i}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    {option.iconUnsel}
                  </div>
                  <p style={{ padding: "0em 1em", marginTop: "10px" }}>
                    {option.title}
                  </p>
                </GvButtonUnSelLarge>
              );
            }
          })}
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          {options.map((option, i) => {
            if (option.value === value) {
              return (
                <GvButtonSel
                  onClick={() => setValue(option.value)}
                  dark={dark}
                  key={i}
                >
                  <div style={{ display: "grid", placeItems: "center" }}>
                    {option.iconSel}
                  </div>
                  <p>{option.title}</p>
                </GvButtonSel>
              );
            } else {
              return (
                <GvButtonUnsel
                  onClick={() => setValue(option.value)}
                  dark={dark}
                  key={i}
                >
                  <div style={{ display: "grid", placeItems: "center" }}>
                    {option.iconUnsel}
                  </div>
                  <p>{option.title}</p>
                </GvButtonUnsel>
              );
            }
          })}
        </ButtonContainer>
      )}
      {Boolean(touched && error) ? (
        <GvHelperText dark={dark}>{error}</GvHelperText>
      ) : (
        <GvHelperText dark={dark}> </GvHelperText>
      )}
    </ButtonForm>
  );
};

export default ButtonSelect;
