import React,  { forwardRef} from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonSelect from "src/designsystem/Forms/ButtonSelectSmall";
import { Ring, RadarTwo } from "@icon-park/react";
import VistasCardNew from "src/components/custom/VistasCardNew";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import Typography from "src/designsystem/Core/Typography";
import { Button } from "@mui/material";
import TokenService from "src/services/TokenService";
import TimeBased from "./TimeBased";

import Section from "src/designsystem/Core/Section";
import OutComeBased from "./OutComeBased";
const CSView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;
const CSCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 3fr 7fr;
  }
`;
const CSLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  padding: 15px;
`;
const CSRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 15px;
  }
`;

const CSBCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 7fr 3fr;
  }
`;
const CSBLeft = styled.div`
  display: grid;
  gap: 15px;

  grid-template-columns: 1fr;
  padding: 15px;
`;

const Header = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
`;
const CSBRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 200px 1fr;
    gap: 30px;
    padding: 15px;
  }
`;
const Terms = forwardRef((props, ref) => {
  const initialValues = {
    natureContract: 0,
  };



  const validationSchema = Yup.object().shape({});
  const [activeStep, setActiveStep] = React.useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TimeBased
           currentStep={props.currentStep}
           navigate={props.navigate}
            handleNext={props.handleNext}
            handleBack={props.handleBack}
            ref={ref}
          />
        );
      case 1:
        return (
          <OutComeBased
          currentStep={props.currentStep}
          navigate={props.navigate}
            handleNext={props.handleNext}
            handleBack={props.handleBack}
            ref={ref}
           
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
       // submitSaveTimeBased(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CSView>
          <Header variant={"label"} gutterBottom>
          Nature of Contract
            </Header>
         
            <ButtonSelect
              name="natureContract"

              dark
              large
              options={[
                {
                  iconSel: (
                    <Ring
                      theme="outline"
                      size="24"
                      fill={colors.white}
                    />
                  ),
                  iconUnsel: (
                    <Ring theme="outline" size="24" fill={colors.p40} />
                  ),
                  value: 1,
                  title: "Outcome Based",
                },
                {
                  iconSel: (
                    <RadarTwo
                      theme="outline"
                      size="24"
                      fill={colors.blue}
                    />
                  ),
                  iconUnsel: (
                    <RadarTwo theme="outline" size="24" fill={colors.p40} />
                  ),
                  value: 0,
                  title: "Time Based",
                },
              ]}
            />
          <>{getStepContent(values.natureContract)}</>

     
          </CSView>
        </form>
      )}
    </Formik>
  );
});

export default Terms;
