import axios from "axios";
import { CONTRACT_LIST_URL,CONTRACT_DETAIL_URL ,CONTRACT_REC_ACTIVELIST_URL} from "src/utils/constants";
import { ContractDetailEntity } from "./models";


const getContractDetails = async (
  userCode: string,
): Promise<ContractDetailEntity> => {
  const contractDetailResponse = await Promise.all([
    getContractList( userCode),
    getActiveContractList(userCode),
  ]);

  let contractDetails: ContractDetailEntity = {
    allContract: contractDetailResponse[0],
    activeContract: contractDetailResponse[1],

  };
  return Promise.resolve(contractDetails);
 
};

  export const getActiveContractList = async (user_code: string) => {
    const response = await axios({
      method: "GET",
      url: CONTRACT_REC_ACTIVELIST_URL + "?employerCode=" + user_code,
    });
  
    return response.data;
  };

export const getContractList = async (user_code: string) => {
  const response = await axios({
    method: "GET",
    url: CONTRACT_LIST_URL + "?employerCode=" + user_code,
  });

  return response.data;
};

export const getContractDetail = async (contractId?: string) => {
  const response = await axios({
    method: "GET",
    url: CONTRACT_DETAIL_URL + "?contractId=" + contractId,
  });

  return response.data;
};

export const submitForm =  async (methodType: any,endPoint: string,data: string,params: string,props:any) => {
  const response = await axios({
    method: methodType,
    url: endPoint,
    data: data,
    params: params,
  });
  return response.data;  
};

export default getContractDetails;
