import React, { useEffect, useState, useContext } from "react";
import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import UserContext from "src/context/user-context";
import Contracts from "src/views/candidate/CandidateGigs/Contracts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CandidateGigs = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  return (
    <Page className={classes.root} title="My Gigs">
      <Contracts />
    </Page>
  );
};

export default CandidateGigs;
