import React from "react";
import { Chip, Divider, Hidden, Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "src/components/Avatar";

import SearchView from "src/views/candidate/CandidateSearchView/SearchView";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

const GeneralInfo = (props) => {
  const classes = useStyles();

  const cp = props.candidateProfile;

  return <></>;
};
const ContractInfo = (props) => {
  const classes = useStyles();

  const cp = props.candidateProfile;
  return <></>;
};

const CandidateInfoCard = (props) => {
  return (
    <>
      {" "}
      {<GeneralInfo {...props} />}
      {<ContractInfo {...props} />}
    </>
  );
};

export default CandidateInfoCard;
