import React, { useState, useContext, useEffect} from "react";
import { Typography, IconButton, Button, TextField, 
  InputAdornment, Popper, ListItem, ListItemText, Paper, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TuneIcon from "@mui/icons-material/Tune";
import JobCardContactMini from "src/derivedComponents/JobCardContactMini";
import SearchFilter from "./SearchFilter";
import { CANDIDATE_SEARCH_URL } from "src/utils/constants";
import axios from "axios";
import UserContext from "src/context/user-context";
import { CANDIDATE_SEARCH_FILTERS } from "src/utils/searchConstants";
import { Formik } from "formik";
import { useTheme } from "@emotion/react";
import * as Yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MASTER_KEYWORDS } from "src/utils/searchConstants";
import Fuse from "fuse.js";
import PageviewIcon from "@mui/icons-material/Pageview";
import { SearchOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    padding: 0,
    width: "99.5%",
    display: "flex",
    border: "none",
    margin: "20px 0px",
  },
  searchWidget: {
    width: "100%",
  },
  resultsHeader: {
    color: "#1A2740",
    display: "flex",
    justifyContent: "space-between",
  },
  resultsTitle: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "27px",
    opacity: 0.6,
  },
  root: {
    display: "block",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
  },
}));

const SearchView = (props) => {
  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(false);

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [facetResults, setFacetResults] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [numFound, setNumFound] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});

  const userContext = useContext(UserContext);


  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));

  const placeHolderText = "Eg. web developer, python, customer service";

  const searchLabel = "Search Talent";

  const initialValues = {
    searchTerm: "",
  };
  const validationSchema = Yup.object().shape({});

  const fuse = new Fuse(MASTER_KEYWORDS, {
    keys: ["k", "t"],
    includeScore: true,
    threshold: 0.1,
    distance: 10,
    limit: 6
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [autoSuggestOptions, setAutoSuggestOptions] = React.useState([]);

  const [suggestWidth, setSuggestWidth] = React.useState(500);

  useEffect(() => {
    setSuggestWidth(document.getElementById("searchTerm").offsetWidth + 155);
  }, []);

  function getAutoSuggestOptions(e) {
    const term = e.target.value;
    console.log("getAutoSuggestOptions ", term);
    if(term.length > 2){
      const results = fuse.search(term).map((suggestion) => suggestion.item);
      if(results.length > 0){
        setAutoSuggestOptions(results);
        setAnchorEl(e.target.parentElement.parentElement);
      }else {
        setAutoSuggestOptions([]);
        setAnchorEl(null);
      }
    }
    else{
      setAutoSuggestOptions([]);
      setAnchorEl(null);
    }
  }

  function expandSuggestionType(t){
    if(t === "c"){
      return "in Categories";
    }
    else if(t === "e"){
      return "in Expertise";
    }
    else return "in Skills";
  }

  function prepareFacetFromAutoSuggest(term, type){
    let facet = {};
    if(type === "c"){
      facet = {"job_category":[term]};
    }
    else if(type === "e"){
      facet = {"job_sub_category":[term]};
    }
    else {
      facet = {"top_skills":[term]};
    };

    return JSON.stringify(facet);
  }

  function submitSuggestedSearch(term, type) {
    var params = {
      q: "",
      p: 0,
      sf: "",
      user_code: userContext.user.get.userCode,
      f: prepareFacetFromAutoSuggest(term, type)
    };

    window.location.search = "?"+ new URLSearchParams(params).toString()
  }

  const urlBasedSearchSubmit = (searchParams) => {
    axios({
      method: "get",
      url: CANDIDATE_SEARCH_URL+searchParams,
    })
      .then((response) => {
        let temp = searchResults;
        temp = temp.concat(response.data["candidates"]);
        setSearchResults(temp);
        setAndFormatFacetResults(response.data["fc"]);
        setNumFound(response.data.numFound);
        let nextPage = pageNum + 1;
        setPageNum(nextPage);
      })
      .catch(function (error) {
        console.log("POST CALL ERROR");
        console.log(error);
      });
  };

  useEffect(() => {
    let searchParams = window.location.search;
    if(searchParams){
      urlBasedSearchSubmit(searchParams)
    }
  },[]);


  function submitSearch(values, actions) {
    setSearchTerm(values.searchTerm);
    handlePopoverClose()
    var params = {
      q: values.searchTerm,
      p: 0,
      sf: "",
      user_code: userContext.user.get.userCode,
    };

    window.location.search = "?"+ new URLSearchParams(params).toString()

  };

  function setAndFormatFacetResults(facets) {
    let filters = [];

    for (const [k, label] of Object.entries(CANDIDATE_SEARCH_FILTERS)) {
      let temp = {};
      temp.name = k;
      temp.type = "radio";
      temp.label = label;
      let opt = [];
      let optVal = [];
      for (const [key, value] of Object.entries(facets[k])) {
        if (value !== 0) {
          opt.push(key);
          optVal.push(value);
        }
      }
      if (opt != []) {
        temp.options = opt;
        temp.optionsVal = optVal;
        filters.push(temp);
      }
    }
    setFacetResults(filters);
  }

  const getCandidateProfile = (userCode) => {
    const user = searchResults.find((r) => r.userCode === userCode);
    if (!user) {
      return {};
    }
    const name = user.name[0];
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    const userProfile = { ...user };
    userProfile.firstName = firstName;
    userProfile.lastName = lastName;
    return userProfile;
  };

  function handleBookmark(candidate) {
    setSearchResults((prevjobs) => {
      console.log("handleBookmark called");
      let newJobs = [...prevjobs];
      let match = newJobs.find((t) => t.id === candidate.id);
      if (match) {
        match.bookmarked = !match.bookmarked;
      }
      return newJobs;
    });
  }

  return (
    <>
      <div className={classes.widgetContainer}>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              submitSearch(values, actions);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                autoComplete="off"
                noValidate
                className={classes.root}
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              >
                <Typography
                  variant={matchesXS ? "h6" : "h3"}
                  gutterBottom
                  display="block"
                  className={classes.flexItem}
                >
                  {searchLabel}
                </Typography>
                <br />
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  value={values.searchTerm}
                  onChange={(e) =>{
                    handleChange(e)
                    getAutoSuggestOptions(e)
                  }}
                  className={classes.flexItem}
                  placeholder={placeHolderText}
                  display="block"
                  name="searchTerm"
                  id="searchTerm"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined edge="start" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {matchesXS ? (
                          <IconButton
                            color="primary"
                            onClick={handleSubmit}
                            type="submit"
                            aria-label="search"
                            component="span"
                            size="large"
                          >
                            <PageviewIcon
                              style={{
                                height: "50px",
                                width: "50px",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <Button variant="contained" color="primary" type="submit">
                            Search
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  id="auto-suggest-popover"
                  open={open}
                  anchorEl={anchorEl}
                  placement={"bottom-start"}

                >
                  <Paper>
                    <List style={{width:suggestWidth+"px"}} dense={true}>
                      {autoSuggestOptions.map((option) => (
                          <ListItem style={{cursor:"pointer"}} key={option.k + option.t}>
                          <ListItemText
                            onClick={() => {submitSuggestedSearch(option.k, option.t)}}
                            primary={option.k}
                            secondary={expandSuggestionType(option.t)}
                          />
                        </ListItem>
                        ))}
                    </List>
                  </Paper>
                </Popper>
              </form>
            )}
          </Formik>
      </div>
      { searchResults && searchResults.length > 0 ? (
        <>
          <div className={classes.resultsHeader}>
            <Typography variant="h5" className={classes.resultsTitle}>
              {numFound} Candidates Found
            </Typography>
            <IconButton color="default" onClick={handleFilter} size="large">
              <TuneIcon />
            </IconButton>
          </div>

          {searchResults.map((c, i) => {
            return (
                <JobCardContactMini
                  user_code={userContext.user.get.userCode}
                  applicantInfo={c}
                />
            );
          })}
          <div>
            {searchResults.length > 0 && pageNum <= numFound / pageNum && (
              <Button variant="outlined" color="primary" onClick={submitSearch}>
                More Results
              </Button>
            )}
          </div>

          <SearchFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            facetResults={facetResults}
            setFacetResults={setFacetResults}
            setSearchResults={setSearchResults}
            searchTerm={searchTerm}
            setNumFound={setNumFound}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </>
      ) : (
        <>
        { searchTerm.length >0 && 
          <Typography variant="h5" className={classes.resultsTitle}>
              Did not find who you were looking for? Try a different search or Post a Job.
          </Typography>
        } 
        </>
      )}
    </>
  );
};

export default SearchView;