import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import UserContext from "src/context/user-context";
import {
  UPDATE_CANDIDATE_EXPERTISE_URL,
  JOB_CATEGORY_URL,
  JOB_CATEGORY_LIST,
  JOB_CATEGORY_EXPERTISE_LIST,
  JOB_CATEGORY_SKILL_LIST,
} from "src/utils/constants";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Slider,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Chip,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const category = JOB_CATEGORY_LIST;

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const initialValues = {
  category: "",
  categoryExpertise: "",
  categoryExpertiseLevel: "",
  categoryExperience: "",
  top5Skills: [{ skillName: "", skillLevel: 4 }],
  otherSkills: [],
  categoryExpertiseList: JOB_CATEGORY_EXPERTISE_LIST,
  categorySkillsList: JOB_CATEGORY_SKILL_LIST,
  profileSummary: "",
  profileKeywords: [],
};

//const validationSchema = Yup.object().shape({});
const validationSchema = Yup.object().shape({
  category: Yup.string().max(255).required("Category is required"),
  categoryExpertise: Yup.string().max(255).required("Expertise is required"),
  categoryExperience: Yup.string()
    .max(255)
    .required("Overall Years of Experience is required"),
});

const ExpertiseForm = ({ className, handleNext, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  const isAgency = userContext.user.get.isAgency;

  console.log("is agency" + isAgency);
  const [popOverMessage, setPopOverMessage] = React.useState("");

  function valuetext(value) {
    return `${value}`;
  }

  function addNewSkill(values, setValues) {
    if (values.top5Skills.length < 5) {
      values.top5Skills.push({ skillName: "", skillLevel: 4 });
      setValues(values);
    }
  }

  function removeSkill(i, values, setValues) {
    console.log("i " + i);
    if (values.top5Skills.length > 1) {
      values.top5Skills.splice(i, 1);
      setValues(values);
    }
  }

  function handleCategoryChange(event, values, setValues) {
    axios({
      method: "GET",
      url: JOB_CATEGORY_URL + event.target.value,
    })
      .then((response) => {
        console.log(response);
        values.categoryExpertiseList = response.data;
        values.category = event.target.value;
        setValues(values);
        axios({
          method: "GET",
          url:
            JOB_CATEGORY_URL +
            values.category +
            "%7C" +
            values.categoryExpertiseList[0],
        })
          .then((response) => {
            console.log(response);
            values.categorySkillsList = response.data;
            values.categoryExpertise = values.categoryExpertiseList[0];
            setValues(values);
          })
          .catch((error) => {
            //handleServerResponse(false, error.response.data.error);
          });
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  }

  function handleCategoryExpertiseChange(event, values, setValues) {
    console.log("inside handleCategoryExpertiseChange " + event.target.value);
    values.categoryExpertise = event.target.value;
    setValues(values);
  }

  function handleCategoryExperienceChange(event, values, setValues) {
    console.log("inside handleCategoryExperienceChange " + event.target.value);
    values.categoryExperience = event.target.value;
    setValues(values);
  }

  function submitCandidateExpertise(values, actions) {
    var candidateSkills = [];
    values.top5Skills.forEach((val, i) => {
      candidateSkills.push({
        skillName: val.skillName,
        rating: val.skillLevel,
        isAdditionalSkill: false,
      });
    });
    values.otherSkills.forEach((val, i) => {
      candidateSkills.push({
        skillName: val,
        isAdditionalSkill: true,
      });
    });
    var candidateTags = [];
    values.profileKeywords.forEach((val, i) => {
      candidateTags.push({
        tag: val,
      });
    });
    var c = {
      userCode: userContext.user.get.userCode,
      summary: values.profileSummary,
      title: values.profileTitle,
      jobCategory: values.category,
      jobSubCategory: values.categoryExpertise,
      totalExperience: values.categoryExperience,
      expertiseLevel: values.categoryExpertiseLevel,
      candidateSkills: candidateSkills,
      candidateTags: candidateTags,
    };

    console.log(c);

    axios({
      method: "POST",
      url: UPDATE_CANDIDATE_EXPERTISE_URL,
      data: c,
    })
      .then((response) => {})
      .catch((error) => {
        actions.setSubmitting(false);
        //handleServerResponse(false, error.response.data.error);
      });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setPopOverMessage(message);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setPopOverMessage("");
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitCandidateExpertise(values, actions);
        handleNext();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="profileSummary"
                    label="Give us a summary of your offerings and what you are passionate about"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={values.profileSummary}
                    onChange={handleChange}
                    size="small"
                    name="profileSummary"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    id="profileTitle"
                    label="Tagline for your profile"
                    fullWidth
                    variant="outlined"
                    value={values.title}
                    onChange={handleChange}
                    size="small"
                    name="profileTitle"
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Category"
                    name="category"
                    onChange={(e) => {
                      handleChange(e);
                      handleCategoryChange(e, values, setValues);
                    }}
                    required
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    value={values.category}
                    size="small"
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                  >
                    {category.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Expertise"
                    name="categoryExpertise"
                    onChange={(e) => {
                      handleChange(e);
                      handleCategoryExpertiseChange(e, values, setValues);
                    }}
                    required
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    value={values.categoryExpertise}
                    size="small"
                    error={Boolean(
                      touched.categoryExpertise && errors.categoryExpertise
                    )}
                    helperText={
                      touched.categoryExpertise && errors.categoryExpertise
                    }
                  >
                    {values.categoryExpertiseList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {!isAgency ? (
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Overall Years of Experience"
                      name="categoryExperience"
                      onChange={(e) => {
                        handleChange(e);
                        handleCategoryExperienceChange(e, values, setValues);
                      }}
                      required
                      variant="outlined"
                      values={values.categoryExperience}
                      size="small"
                      error={Boolean(
                        touched.categoryExperience && errors.categoryExperience
                      )}
                      helperText={
                        touched.categoryExperience && errors.categoryExperience
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item md={6} sm={12} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Proficiency Level{" "}
                      <IconButton
                        onClick={(e) => {
                          handleAnchorOpen(
                            e,
                            <>
                              Basic - Relatively new to the field <br />
                              Proficient - Significant experience in the field{" "}
                              <br />
                              Expert - Deep Expertise in the field
                            </>
                          );
                        }}
                        size="large"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="Proficiency Level"
                      name="categoryExpertiseLevel"
                      value={values.categoryExpertiseLevel}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="basic"
                        control={<Radio />}
                        label="Basic"
                      />
                      <FormControlLabel
                        value="proficient"
                        control={<Radio />}
                        label="Proficient"
                      />
                      <FormControlLabel
                        value="expert"
                        control={<Radio />}
                        label="Expert"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <CardHeader subheader="Top 5 Skills" />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                {values.top5Skills.map((s, i) => (
                  <>
                    <Grid item md={6} sm={12} xs={12}>
                      <Autocomplete
                        freeSolo
                        id="categorySkill"
                        disableClearable
                        value={s.skillName}
                        options={values.categorySkillsList.map(
                          (option) => option
                        )}
                        onChange={(e, val) => {
                          values.top5Skills[i].skillName = val;
                          setValues(values);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Skill"
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            size="small"
                          />
                        )}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={5} sm={10} xs={10}>
                      <Typography id="discrete-slider" gutterBottom>
                        Rating{" "}
                        <IconButton
                          onClick={(e) => {
                            handleAnchorOpen(
                              e,
                              <>Slide right for giving a higher self rating</>
                            );
                          }}
                          size="large"
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Typography>
                      <Slider
                        defaultValue={initialValues.top5Skills[0].skillLevel}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        step={1}
                        marks
                        min={1}
                        max={5}
                        size="small"
                        value={values.top5Skills[i].skillLevel}
                        onChange={(e, val) => {
                          values.top5Skills[i].skillLevel = val;
                          setValues(values);
                        }}
                      />
                    </Grid>
                    <Grid item md={1} sm={2} xs={2}>
                      <IconButton aria-label="delete" size="large">
                        <CancelRoundedIcon
                          onClick={() => removeSkill(i, values, setValues)}
                        />
                      </IconButton>
                    </Grid>
                  </>
                ))}
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => addNewSkill(values, setValues)}
                  >
                    Add Skill
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    id="otherSkills"
                    options={values.categorySkillsList.map((option) => option)}
                    freeSolo
                    name="otherSkills"
                    value={values.otherSkills}
                    onChange={(e, val) => {
                      values.otherSkills = val;
                      setValues(values);
                    }}
                    limitTags={10}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Additional Skills"
                        placeholder="Add upto 10 other skills"
                        multiline
                        rows={2}
                        size="small"
                      />
                    )}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardHeader
              subheader={
                <>
                  Enter upto 10 keywords that summarize your experience best{" "}
                  <IconButton
                    onClick={(e) => {
                      handleAnchorOpen(
                        e,
                        "Example - M&A expert, Project Finance Specialist, Google Ad Campaign Planner etc"
                      );
                    }}
                    size="large"
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </>
              }
            ></CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Autocomplete
                    multiple
                    id="keywords"
                    options={[].map((option) => option)}
                    freeSolo
                    onChange={(e, val) => {
                      values.profileKeywords = val;
                      setValues(values);
                    }}
                    value={values.profileKeywords}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Enter the keyword and press enter to add."
                        multiline
                        rows={2}
                        size="small"
                      />
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}></Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit">
                  Next
                </Button>
              </Grid>
            </CardContent>

            <Popover
              id="popover"
              open={openPopOver}
              anchorEl={anchorEl}
              onClose={handleAnchorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className={classes.typography}>
                {popOverMessage}
              </Typography>
            </Popover>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ExpertiseForm.propTypes = {
  className: PropTypes.string,
};

export default ExpertiseForm;
