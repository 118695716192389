import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Link,
  Collapse,
  IconButton,
  InputBase,
} from "@mui/material";
import Page from "src/components/Page";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { RESET_PASSWORD_EMAIL_URL } from "src/utils/constants";
import { colors } from "src/designsystem/theme";
import Typography from "src/designsystem/Core/Typography";
import styled from "@emotion/styled";
import Textfield from "src/designsystem/Forms/Textfield";
import SubmitButton from "src/designsystem/Forms/SubmitButton";

const GvTypography = styled(Typography)`
    color: ${colors.white};
`
const GvTitle = styled(Typography)`
    color: ${colors.white};
    margin-left: -3px;
`

const initialValues = {
  email: "",
};

const ResetPasswordView = () => {
  const [success, setSuccess] = useState(false);

  const [globalError, setGlobalError] = React.useState({
    hasError: false,
    errorMessage: "",
  });

  const resetSubmit = (values: any, actions: any, setGlobalError: any) => {
    axios({
      method: "POST",
      url: RESET_PASSWORD_EMAIL_URL,
      params: {
        email: values.email,
      },
    })
      .then((response) => {
        actions.setSubmitting(false);
        setSuccess(true);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        let message = error.response.data || "Error Occured";
        if (error?.response?.status === 404) {
          message = "Account with the given email does not exist";
        }
        //handleServerResponse(false, error.response.data.error);
        setGlobalError({
          hasError: true,
          errorMessage: message,
        });
      });
  };

  return (
    <Page title="Reset Password">
      {!success ? (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
          })}
          onSubmit={(values, actions) => {
            resetSubmit(values, actions, setGlobalError);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <GvTitle variant='h1'>Reset Your Password</GvTitle>
              <GvTypography>
                Back to Login Page?{" "}
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="h6"
                  style={{ color: "#a3f3ff" }}
                >
                  Login
                </Link>
              </GvTypography>
              <div style={{ height: '20px' }} />
              <Textfield
                name="email"
                label="Email"
                type="email"
                dark
              />

              <Collapse in={globalError.hasError}>
                <Alert
                  style={{ marginBottom: "15px" }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setGlobalError({
                          hasError: false,
                          errorMessage: "",
                        });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                >
                  {globalError.errorMessage}
                </Alert>
              </Collapse>
              <div style={{ height: '20px' }} />
              <SubmitButton
                isSubmitting={isSubmitting}
                fullWidth
                type="submit"
                dark
              >
                Reset
              </SubmitButton>
              {/*<Grid item xs={6}>
                      <LinkedinAuth 
                        text="Sign in with Linkedin"
                        callback={callbackLinkedIn}
                        scope={["r_liteprofile","r_emailaddress"]}
                        clientId="772acmrlxsl42r"
                        />
            </Grid> */}
            </form>
          )}
        </Formik>
      ) : (
        <div>
          <GvTypography variant="h5">
            Link to reset your password is sent. Please check your email.
          </GvTypography>
          <GvTypography variant="body">
            Back to Login Page?{" "}
            <Link
              component={RouterLink}
              to="/login"
              variant="h6"
              style={{ color: "#a3f3ff" }}
            >
              Login
            </Link>
          </GvTypography>
        </div>
      )}
    </Page>
  );
};

export default ResetPasswordView;
