import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import userSlice from "src/redux/slice/userSlice";
import CandidateReducers from "src/views/candidate/CandidateJobView/state/CandidateReducers";
import RecuiterReducers from "src/views/recruiter/RecruiterJobView/state/RecuiterReducers";
import CandidateProfileReducers from "src/views/candidate/CandidateProfile/state/reducer";
import RewardReducers from "src/views/candidate/CandidateReward/state/RewardReducers";
import RecruiterReducers from 'src/views/recruiter/RecruiterDetails/state/reducer'
import ContractReducers from 'src/views/recruiter/RecruiterContract/state/reducer'
import contractDetailReducers from 'src/views/recruiter/RecruiterContract/state/contractDetailReducer'
import contractStepperReducers from 'src/views/recruiter/RecruiterContract/state/contractStepperReducers'
export const store = configureStore({
  reducer: {
    user: userSlice,
    jobInfo: CandidateReducers,
    recuiterJobInfo: RecuiterReducers,
    profileInfo: CandidateProfileReducers,
    candidateInfo: RecruiterReducers,
    reward: RewardReducers,
    contractinfo:ContractReducers,
    contractStepperinfo:contractStepperReducers,
    contractDetailInfo:contractDetailReducers
  },
});

setupListeners(store.dispatch);
