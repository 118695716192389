import React from "react";
import { Divider, Hidden, Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: "8px",
    border: "solid 1px" + theme.palette.accent.main,
    margin: "10px 0px",
    backgroundColor: "#fffefe",
  },
  gridItemLeft: {
    padding: "25px",
    [theme.breakpoints.down("lg")]: {
      padding: "25px 25px 0px 25px",
    },
  },
  gridItemRight: {
    padding: "25px",
    width: "30%",
    borderLeft: "solid 1px" + theme.palette.accent.main,
    [theme.breakpoints.down("lg")]: {
      borderLeft: "none",
      borderTop: "solid 1px" + theme.palette.accent.main,
      width: "100%",
    },
  },
}));

export default function FeedCard(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.gridItemLeft}>{props.left}</div>
      <div className={classes.gridItemRight}>{props.right}</div>
    </div>
  );
}
