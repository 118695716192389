import React, { useContext } from "react";
import { Grid, Typography, Button, Hidden, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SideBar from "../common/SideBar";
import OnBoardingContext from "../../../context/onboarding-context";
import UserContext from "src/context/user-context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formsContainer: {
    width: "75vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      paddingTop: "40px",
    },
    padding: "10px 10px ",
    paddingBottom: "40px",
    height: "100%",
    overflowY: "scroll",
  },

  title: {
    fontWeight: "bold",
    color: "#2B7ED8",
    marginTop: 30,
  },

  subtitle: {
    fontWeight: "fontWeightRegular",
    marginBottom: 10,
    paddingBottom: 10,
    color: "#27303A",
  },
  discText: {
    color: "grey",
    fontStyle: "italic",
    fontSize: "0.85rem",
  },
  paddingB: {
    marginBottom: 25,
  },
  discText: {
    color: "grey",
    fontStyle: "italic",
    fontSize: "0.85rem",
  },
  paddingB: {
    marginBottom: 25,
  },
}));

const CandidateOnboarding = (props) => {
  const classes = useStyles();
  const OnBoardingCtx = useContext(OnBoardingContext);
  const userContext = useContext(UserContext);

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Grid item md={4}>
          <SideBar steps={OnBoardingCtx.steps} />
        </Grid>
      </Hidden>

      <Grid container spacing={3} className={classes.formsContainer}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography variant="h5" color="primary" className={classes.title}>
            Welcome, {userContext.user.get.firstName}
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            Lets build your portfolio
          </Typography>
          <Typography className={classes.discText} variant="body">
            "At GigVistas we encourage you to provide as much detail about you
            as possible. This enables a more accurate match for a prospective
            employer. Additionally, the more complete your profile, the greater
            will be your “Completeness Score”"
          </Typography>
          <div className={classes.paddingB}></div>
          {OnBoardingCtx.getCurrentStep(OnBoardingCtx.currentStep)}
          <Typography className={classes.discText} variant="body">
            Fields marked with an asterisk(*) are mandatory
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default CandidateOnboarding;
