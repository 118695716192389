import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, AppBar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import Results from "./Results";
import UserContext from "src/context/user-context";
import { GET_EMPLOYER_JOBS_URL } from "src/utils/constants";
import axios from "axios";
import Drafts from "src/views/recruiter/RecruiterJobs/Drafts.js";
import { UpdateListenerContextProvider } from 'src/context/update-listener-context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const RecruiterJobs = () => {
  const classes = useStyles();

  const [jobs, setJobs] = useState([]);

  const [activeCredentials, setActiveCredentials] = useState("Open");
  const [credentials, setCredentials] = useState([]);

  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;

  const [toggler, setToggler] = useState(false);

  return (
    <UpdateListenerContextProvider>
      <Page className={classes.root} title="My Gigs">
        <AppBar position="static" color="default">
          <Tabs
            value={activeCredentials}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            <Tab label="Open Gigs" value="Open" />
          </Tabs>
        </AppBar>
        <div
          style={{
            display: "grid",
            rowGap: "10px",
            gridTemplate: "auto auto" / "1fr",
          }}
        >
          <Results toggler={toggler} setToggler={setToggler} />
          <Drafts toggler={toggler} />
        </div>
      </Page>
    </UpdateListenerContextProvider>
  );
};

export default RecruiterJobs;
