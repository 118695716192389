import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Slider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Cropper from "react-easy-crop";
import PublishIcon from "@mui/icons-material/Publish";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
  cropContainer: {
    position: "relative",
    width: "100%",
    height: 200,
    background: "#333",
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  sliderLabel: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 65,
    },
  },
  dialog: {
    width: "700px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 32,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      margin: "0 16px",
    },
  },
  uploadIcon: {
    borderRadius: "50%",
    color: "black",
    backgroundColor: "lightgrey",
    width: "75px",
    height: "75px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    margin: "5px auto 15px auto",
  },
  discText: {
    display: "flex",
    color: "grey",
    fontStyle: "italic",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
}));

export const UploadImageDialog = ({
  URL,
  open,
  setOpen,
  userCode,
  refetch,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImage(imageDataUrl);
    }
  };

  const submitImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      const imageFile = await getFileFromUrl(croppedImage, "profile.png");

      const formData = new FormData();
      formData.append("userCode", userCode);
      formData.append("file", imageFile);

      axios
        .post(URL, formData)
        .then((res) => {
          console.log("Response from upload image", res.data);
          refetch(res);
          setOpen(false);
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  async function getFileFromUrl(url, name, defaultType = "image/png") {
    const response = await fetch(url);

    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get("content-type") || defaultType,
    });
  }

  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">Upload Avatar</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers className={classes.dialog}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={onFileChange}
        />
        <label htmlFor="raised-button-file">
          <div className={classes.uploadIcon}>
            <PublishIcon />
          </div>
        </label>
        <div className={classes.cropContainer}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className={classes.controls}>
          <div className={classes.sliderContainer}>
            <Typography
              variant="overline"
              classes={{ root: classes.sliderLabel }}
            >
              Zoom
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              classes={{ root: classes.slider }}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
        </div>
        <Typography className={classes.discText} variant="body">
          Maximum size must be 1MB, dimension should be square(e.g. 100*100){" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => submitImage()}>
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });
}
