import React, { FC, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserContext from "src/context/user-context";
import profileCompletionTracker from "src/derivedComponents/ApplyChecker";
// import hasAttachments from "src/derivedComponents/ApplyChecker";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import JobDetailData from "src/models/JobDetailData";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import LTChip from "../../designsystem/Derivative/LTChip";
import Skills from "../../designsystem/Derivative/Skills";
import Button from "src/designsystem/Core/Button";
import ButtonMini from "@mui/material/Button";
import ApplyChecker from "src/derivedComponents/ApplyChecker";
import Bookmark from "../../designsystem/Derivative/Bookmark";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import JobDetailEntity from "src/views/candidate/CandidateJobView/state/models";
import {
  getJobDetailAction,
  applyToJobAction,
  bookmarkToJobAction,
  removeBookmarkAction,
} from "src/views/candidate/CandidateJobView/state/CandidateReducers";
import { GET_CANDIDATE_JOB_APPLICATION_CONFIRMATION } from "src/utils/constants";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { resolveProjectReferencePath } from "typescript";

const GvJobCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 350px;
  }
`;
const GvGigLeft = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 30px;
  }
`;
const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 110px 1fr;
`;
const GvGigRight = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  border-top: 1px solid ${colors.nv80};
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    border-top: 0px;
    border-left: 1px solid ${colors.nv80};
    padding: 30px;
  }
`;

function getJobStatus(jobStatus: string) {
  if (jobStatus === "REVIEW") {
    return true;
  } else if (jobStatus === "ACTIVE") {
    return true;
  } else {
    return false;
  }
}

function getTimeCommitment(timePref: string) {
  if (timePref === "Full-Time") {
    return "Full time";
  } else if (timePref === "Flexible") {
    return "Flexible";
  } else {
    return "Part time";
  }
}

function getLocation(location: string) {
  if (location === "remote") {
    return "Remote";
  } else if (location === "onsite") {
    return "Onsite";
  } else {
    return "Hybrid";
  }
}

interface JobCardProps {
  jobDetailEntity: JobDetailEntity;
  jobID: number;
  user_code: string;
  dispatch: any;
  profileCompletionTracker?: boolean;
}

const JobCard: FC<JobCardProps> = ({
  jobDetailEntity,
  jobID,
  user_code,
  dispatch,
  profileCompletionTracker,
}) => {
  // const [toggle, setToggle] = useState(false)
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const [profileCompletion, setProfileCompletion] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [categoryMismatch, setCategoryMismatch] = useState(false);
  const [locationMismatch, setLocationMismatch] = useState(false);
  const [locationTypeMismatch, setLocationTypeMismatch] = useState(false);

  const hasBookMarkedState =
    jobDetailEntity &&
    jobDetailEntity.jobInfo &&
    jobDetailEntity.jobInfo.jobPost.hasBookMarked
      ? true
      : false;
  const [hasBookMarked, setToggle] = useState(hasBookMarkedState);
  const hasJobStatusState =
    jobDetailEntity &&
    jobDetailEntity.jobInfo &&
    getJobStatus(jobDetailEntity.jobInfo.jobPost.status)
      ? true
      : false;
  const hasAppliedState =
    jobDetailEntity &&
    jobDetailEntity.jobInfo &&
    jobDetailEntity.jobInfo.jobPost.hasApplied
      ? true
      : false;
  const [hasApplied, setAppliedToggle] = useState(hasAppliedState);
  const userContext = useContext<any>(UserContext);
  const user: any = userContext.user;
  const [openApply, setOpenApply] = React.useState(false);

  const confirmApply = (id: number) => {
    axios
      .get(
        GET_CANDIDATE_JOB_APPLICATION_CONFIRMATION +
          "?userCode=" +
          user_code +
          "&jobId=" +
          id
      )
      .then((res) => {
        setOpenApply(true);
        setProfileCompletion(res.data.profileCompletion);
        setHasAttachments(res.data.profileCompletionTracker.hasAttachments);
        setCategoryMismatch(res.data.categoryMismatch);
        setLocationMismatch(res.data.locationMismatch);
        setLocationTypeMismatch(res.data.locationTypeMismatch);
      })
      .catch((err) => console.log(err));
  };

  const handleClickApply = () => {
    confirmApply(jobID);
  };
  const handleCloseApply = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenApply(false);
  };

  const month = jobDetailEntity.jobInfo.jobPost.createdDate
    ?.toString()
    .split("-")[1];

  const day = jobDetailEntity.jobInfo.jobPost.createdDate
    ?.toString()
    .split("-")[2];

  const year = jobDetailEntity.jobInfo.jobPost.createdDate
    ?.toString()
    .split("-")[0];

  return (
    <GvJobCard>
      <GvGigLeft>
        <GvGigDetails>
          <Avatar
            src={jobDetailEntity.jobInfo.organization.logo}
            alt={jobDetailEntity.jobInfo.organization.name}
            size={matches ? "110px" : "80px"}
          />
          <div style={{ marginTop: "-2px" }}>
            <Typography variant={matches ? "h3" : "h4"}>
              {jobDetailEntity.jobInfo.jobPost.title}
            </Typography>
            <div style={{ height: "5px" }} />
            <Typography variant="label">
              {jobDetailEntity.jobInfo.jobPost.category}
            </Typography>
            <div style={{ height: "15px" }} />
            <LTChip
              timePref={getTimeCommitment(
                jobDetailEntity.jobInfo.jobPost.timeCommitment
              )}
              location={getLocation(
                jobDetailEntity.jobInfo.jobPost.projectType
              )}
            />
          </div>
        </GvGigDetails>
        <Skills skills={jobDetailEntity.jobInfo.jobPost.jobPostSkills} />
      </GvGigLeft>
      <GvGigRight>
        <div>
          <Typography variant="label" gutterBottom>
            Budget
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <AddSubtract
                theme="outline"
                size="25"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              {jobDetailEntity.jobInfo.jobPost.budget
                ? "₹" + jobDetailEntity.jobInfo.jobPost.budget
                : ""}
            </div>
          </Typography>
        </div>
        <div>
          <Typography variant="label" gutterBottom>
            Duration
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarDot
                theme="outline"
                size="25"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              {!!jobDetailEntity.jobInfo.jobPost.duration
                ? jobDetailEntity.jobInfo.jobPost.duration +
                  " " +
                  jobDetailEntity.jobInfo.jobPost.timeUnit
                : ""}
            </div>
          </Typography>
        </div>
        <div>
          <Typography variant="label" gutterBottom>
            Posted on
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {" " + day?.substring(0, 2) + "-" + month + "-" + year}
            </div>
          </Typography>
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          {!hasApplied && hasJobStatusState ? (
            <div>
              {/*  <ApplyChecker user_code={user_code} jobID={jobID} /> */}
              <Button fullWidth onClick={handleClickApply}>
                Apply
              </Button>
              <Dialog
                open={openApply}
                onClose={(event, reason) => {
                  if (reason === "backdropClick") {
                    return;
                  }
                  handleCloseApply();
                }}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Job Application Confirmation"}
                </DialogTitle>
                <DialogContent>
                  {profileCompletion === false && hasAttachments === false ? (
                    <DialogContentText>
                      Please attach the resume,
                      <Link to="/c/candidate">Click here</Link> to complete the
                      profile. <br />
                      Are you sure, you want to apply to this job?
                    </DialogContentText>
                  ) : categoryMismatch === true ? (
                    <DialogContentText>
                      Your profile seems like a <strong>mismatch</strong>.{" "}
                      <br />
                      Are you sure, you want to apply to this job?
                    </DialogContentText>
                  ) : locationMismatch === true ||
                    locationTypeMismatch === true ? (
                    <DialogContentText>
                      Your <strong>location preferences</strong> do not match
                      with the job's required preferences. <br />
                      Are you sure, you want to apply to this job?
                    </DialogContentText>
                  ) : (
                    <DialogContentText>
                      Are you sure, you want to apply to this job?
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <ButtonMini
                    autoFocus
                    onClick={() => {
                      dispatch(applyToJobAction({ jobID, user_code }));
                      setAppliedToggle(true);
                    }}
                  >
                    Yes
                  </ButtonMini>
                  <ButtonMini onClick={handleCloseApply}>No</ButtonMini>
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            <Button disabled fullWidth>
              {hasJobStatusState
                ? "Applied"
                : jobDetailEntity.jobInfo.jobPost.status}
            </Button>
          )}

          {!hasBookMarked ? (
            <Bookmark
              toggle={hasBookMarked}
              onClick={() => {
                dispatch(bookmarkToJobAction({ jobID, user_code }));
                setToggle(true);
              }}
            />
          ) : (
            <Bookmark
              toggle={true}
              onClick={() => {
                dispatch(removeBookmarkAction({ jobID, user_code }));
                setToggle(false);
              }}
            />
          )}
        </div>
      </GvGigRight>
    </GvJobCard>
  );
};

type skills = {
  jobPostSkillId: number;
  skillName: string;
  isAdditionalSkill: boolean;
};

const dummySkills: skills[] = [
  {
    jobPostSkillId: 1,
    skillName: "JavaScript",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 2,
    skillName: "HTML",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 3,
    skillName: "React",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 4,
    skillName: "CSS",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 5,
    skillName: "NodeJS",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 6,
    skillName: "Docker",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 7,
    skillName: "Golang",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 8,
    skillName: "Java",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 9,
    skillName: "C#",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 10,
    skillName: "AWS",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 11,
    skillName: "NodeJS",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 12,
    skillName: "Docker",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 13,
    skillName: "Golang",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 14,
    skillName: "Java",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 15,
    skillName: "C#",
    isAdditionalSkill: true,
  },
  {
    jobPostSkillId: 16,
    skillName: "AWS",
    isAdditionalSkill: true,
  },
];

export default JobCard;
