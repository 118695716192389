import {MESSAGE_CHAT_URL} from "src/utils/constants";
import axios from "axios";

export const addChannel = async (userCode, contactUserCode) => {
    const response = await axios({
        method: "POST",
        url: MESSAGE_CHAT_URL + "channel",
        data: {"members":[userCode, contactUserCode]},
      });
    console.log(response);
}