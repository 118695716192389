import React from "react";
import moment from "moment";
import { Card, Grid, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import Avatar from "src/components/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  textBold: {
    fontSize: 16,
    fontWeight: "bold",
  },
  center: {
    display: "flex",
    textAlign: "center",
  },
  titleChip: {
    marginLeft: 20,
    border: "none",
    background: "#FFC0CB",
  },
  skillChip: {
    background: "#bcd6f1",
  },
  avatar: {
    margin: 5,
    width: "100%",
    height: 80,
  },
  name: {
    margin: "7px 0 12px 30px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "150px",
      marginLeft: "-60px",
      paddingTop: "0px",
    },
    fontSize: "1.8rem",
    color: "black",
    paddingTop: "20px",
  },
  subheading: {
    fontSize: "1.1rem",
    color: "#0858ec",
  },
  fee: {
    marginTop: "-50px",
    marginLeft: "0px",
    padding: "-50px",
    paddingTop: "3px",
    paddingLeft: "150px",
    paddingRight: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: "30px",
      padding: "0px",
      paddingTop: "60px",
    },
  },
  subheading1: {
    paddingLeft: "100px",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: "0px",
      padding: "0px",
      paddingLeft: "0px",
    },
    paddingRight: "0px",
    marginLeft: "50px",
  },
  moreAvatar: {
    margin: "auto 0",
  },
}));

const GeneralInfo = ({ gig, getCandidateProfile, ...rest }) => {
  const classes = useStyles();
  const user = getCandidateProfile(gig.candidate.userCode);
  let userProfileLink = "";
  if (user) {
    userProfileLink = "/r/candidate/" + user.userCode;
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2} md={2}>
            <Link to={userProfileLink}>
              <Avatar
                URL={user.profilePicUrl}
                height={100}
                width={100}
                firstName={user.firstName}
                lastName={user.lastName}
              />
            </Link>
            <Typography
              variant="body2"
              display="block"
              className={classes.textTitle}
            >
              {gig.candidate.title}
            </Typography>
          </Grid>
          <Grid item xs={2} md={8}>
            <Link to={userProfileLink}>
              <Typography variant="h4" className={classes.name}>
                {user && user.firstName + " " + user.lastName}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography className={classes.subheading}>Start Date</Typography>
            {moment(gig.startDate).format("DD/MM/YYYY")}
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography className={classes.subheading}>End Date</Typography>
            {moment(gig.endDate).format("DD/MM/YYYY")}
            <Typography className={classes.subheading + " " + classes.fee}>
              Fee
            </Typography>
            <Typography className={classes.subheading1}>
              {gig.currency + " " + gig.fee}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const GigSummaryCard = ({ gig, getCandidateProfile, ...rest }) => {
  return <GeneralInfo gig={gig} getCandidateProfile={getCandidateProfile} />;
};

export default GigSummaryCard;
