import React, { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import JobDetailData from "src/models/JobDetailData";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import LTChip from "../../designsystem/Derivative/LTChip";
import Skills from "../../designsystem/Derivative/Skills";
import CustomButton from "src/designsystem/Core/Button";
import Bookmark from "../../designsystem/Derivative/Bookmark";
import { AddSubtract, CalendarDot, EditName } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import RecruiterJobInfo from "src/views/recruiter/RecruiterJobView/state/models";
import DottedHorizontalMenu from "src/components/dottedHorizontalMenu";
import {
  RECRUITER_JOB_DOTTED_MENU_OPTION,
  SHARE_JOBDETAIL_URL,
} from "src/utils/constants";
import ShareIcon from "@mui/icons-material/Share";
import {
  closeToJobAction,
  deleteToJobAction,
} from "src/views/recruiter/RecruiterJobView/state/RecuiterReducers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { POST_DRAFT_URL, CurrencyIcon } from "src/utils/constants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from "@mui/material";

const GvJobCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 350px;
  }
`;
const GvGigLeft = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 30px;
  }
`;
const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 110px 1fr;
`;
const GvGigRight = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  border-top: 1px solid ${colors.nv80};
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    border-top: 0px;
    border-left: 1px solid ${colors.nv80};
    padding: 30px;
  }
`;
function getJobStatus(jobStatus: string) {
  if (jobStatus === "REVIEW") {
    return true;
  } else if (jobStatus === "ACTIVE") {
    return true;
  } else {
    return false;
  }
}

function getTimeCommitment(timePref: string) {
  if (timePref === "Full-Time") {
    return "Full-Time";
  } else if (timePref === "Flexible") {
    return "Flexible";
  } else {
    return "Part-Time";
  }
}

function getLocation(location: string) {
  if (location === "remote") {
    return "Remote";
  } else if (location === "onsite") {
    return "Onsite";
  } else {
    return "Hybrid";
  }
}
interface JobCardProps {
  jobDetailEntity: RecruiterJobInfo;
  handleOpen: any;
  pageFunc: string;
}

const JobEditCard: FC<JobCardProps> = ({
  jobDetailEntity,
  handleOpen,
  pageFunc,
}) => {
  const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const hasJobStatusState =
    jobDetailEntity &&
    jobDetailEntity.jobPost &&
    getJobStatus(jobDetailEntity.jobPost.status)
      ? true
      : false;
  const [hasJobStatus, setJobStatusToggle] = useState(hasJobStatusState);
  const jobID = jobDetailEntity.jobPost.id;

  const handleMenuSelection = (selectedOption: any) => {
    console.log(selectedOption);
    if (selectedOption === "Close Job") {
      dispatch(closeToJobAction({ jobID }));
    } else if (selectedOption === "Delete Job") {
      dispatch(deleteToJobAction({ jobID }));
      navigate("/r/jobs");
    }
  };
  const handleDraftDelete = () => {
    axios
      .request({
        method: "DELETE",
        url: POST_DRAFT_URL + "?id=" + jobDetailEntity.jobPost.id,
      })
      .then(() => {
        navigate("/r/jobs");
      });
  };

  let logo = "";
  try {
    logo = jobDetailEntity.organization.logo;
  } catch (e) {}

  let name = "";
  try {
    name = jobDetailEntity.organization.name;
  } catch (e) {}

  const handleShareClose = () => {
    setShareDialogOpen(false);
  };

  const copyJob = () => {
    navigator.clipboard.writeText(SHARE_JOBDETAIL_URL + jobID);
  };

  const handleShareOpen = () => {
    setShareDialogOpen(true);
  };

  const getCurrencyIcon = () => {
    return jobDetailEntity.jobPost.currencyCode === "INR"
      ? CurrencyIcon["INR"]
      : CurrencyIcon["USD"];
  };

  const month = jobDetailEntity.jobPost.createdDate?.toString().split("-")[1];

  const day = jobDetailEntity.jobPost.createdDate?.toString().split("-")[2];

  const year = jobDetailEntity.jobPost.createdDate?.toString().split("-")[0];

  //jobDetailEntity.jobPost.duration = null;

  return (
    <GvJobCard>
      <GvGigLeft>
        <GvGigDetails>
          <Avatar src={logo} alt={name} size={matches ? "110px" : "80px"} />
          <div style={{ marginTop: "-2px" }}>
            <Typography variant={matches ? "h3" : "h4"}>
              {jobDetailEntity.jobPost.title}
            </Typography>
            <div style={{ height: "5px" }} />
            <Typography variant="label">
              {jobDetailEntity.jobPost.category}
            </Typography>
            <div style={{ height: "15px" }} />
            <LTChip
              timePref={getTimeCommitment(
                jobDetailEntity.jobPost.timeCommitment
              )}
              location={getLocation(jobDetailEntity.jobPost.projectType)}
            />
          </div>
        </GvGigDetails>
        <Skills skills={jobDetailEntity.jobPost.jobPostSkills} />
      </GvGigLeft>
      <GvGigRight>
        <div>
          <Typography variant="label" gutterBottom>
            Budget
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <AddSubtract
                theme="outline"
                size="25"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              {!!jobDetailEntity.jobPost.budget
                ? getCurrencyIcon() + jobDetailEntity.jobPost.budget
                : ""}
            </div>
          </Typography>
        </div>
        <div>
          <Typography variant="label" gutterBottom>
            Duration
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarDot
                theme="outline"
                size="25"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              {!!jobDetailEntity.jobPost.duration
                ? jobDetailEntity.jobPost.duration +
                  " " +
                  jobDetailEntity.jobPost.timeUnit
                : ""}
            </div>
          </Typography>
        </div>
        <div>
          <Typography variant="label" gutterBottom>
            Posted on
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {" " + day?.substring(0, 2) + "-" + month + "-" + year}
            </div>
          </Typography>
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          {pageFunc === "jobDetails" &&
            (hasJobStatus ? (
              <CustomButton fullWidth onClick={handleOpen}>
                <div
                  style={{
                    display: "flex",
                    gap: "0px 5px",
                    justifyContent: "center",
                  }}
                >
                  {pageFunc === "jobDetails" ? "Edit" : "Edit Draft"}
                  <EditName style={{ display: "grid", placeItems: "center" }} />
                </div>
              </CustomButton>
            ) : (
              <CustomButton disabled fullWidth>
                {jobDetailEntity.jobPost.status}
              </CustomButton>
            ))}
          {!(pageFunc === "jobDetails") && (
            <CustomButton fullWidth onClick={handleOpen}>
              <div
                style={{
                  display: "flex",
                  gap: "0px 5px",
                  justifyContent: "center",
                }}
              >
                {pageFunc === "jobDetails" ? "Edit" : "Edit Draft"}
                <EditName style={{ display: "grid", placeItems: "center" }} />
              </div>
            </CustomButton>
          )}

          {pageFunc === "jobDetails" && (
            <Tooltip title="Share" arrow>
              <IconButton color="primary" onClick={handleShareOpen}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}

          {pageFunc === "jobDetails" && (
            <DottedHorizontalMenu
              options={RECRUITER_JOB_DOTTED_MENU_OPTION}
              handleMenuSelection={handleMenuSelection}
            />
          )}
          {!(pageFunc === "jobDetails") && (
            <Tooltip title="Delete" arrow>
              <CustomButton
                onClick={() => {
                  setOpenDelete(true);
                }}
              >
                <DeleteIcon fontSize="medium" />
              </CustomButton>
            </Tooltip>
          )}

          {/* {!jobDetailEntity.applied ? (
                <CustomButton fullWidth
              
                  onClick={() =>
                    dispatch(applyToJobAction({ jobID, user_code }))
                  }
                >
                  Apply
                </CustomButton>
              ) : (
                <CustomButton  disabled>
                  Applied
                </CustomButton>
              )}
             {!jobDetailEntity.bookmarked ? (
                 <Bookmark toggle={jobDetailEntity.bookmarked} onClick={() => { dispatch(bookmarkToJobAction({ jobID, user_code })); setToggle(true);}}  />
              ) : (
                <Bookmark toggle={true} onClick={() => { dispatch(removeBookmarkAction({ jobID, user_code })); setToggle(false);}} />
              )} */}
          <Dialog open={shareDialogOpen} onClose={handleShareClose}>
            <DialogTitle id="alert-dialog-title">{"Share"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {SHARE_JOBDETAIL_URL + jobID}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={copyJob}>Copy</CustomButton>
              <CustomButton onClick={handleShareClose}>Close</CustomButton>
            </DialogActions>
          </Dialog>

          <Dialog
            // onBackdropClick="false"
            open={openDelete}
            onClose={(event, reason) => {
              if (reason === "backdropClick") {
                return;
              }
              setOpenDelete(false);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Delete Drafted Job"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure, permanently want to <strong>DELETE</strong> the
                draft?
                <br />
                <strong> Note:</strong> Can't UNDO this change
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDraftDelete}>Yes</Button>
              <Button
                onClick={() => {
                  setOpenDelete(false);
                }}
                // autoFocus
                // style={{ color: "white", backgroundColor: "green" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </GvGigRight>
    </GvJobCard>
  );
};

export default JobEditCard;
