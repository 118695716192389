import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Resource } from "src/models/Resource";
import { ContractDetailModel, ContractDetailResponse } from "src/views/recruiter/RecruiterContract/state/models";
import { getContractList ,getContractDetail} from "src/views/recruiter/RecruiterContract/state/service";

export const getContractDetailAction = createAsyncThunk<
  any,
  { contractId?: string },
  any
>("ContractDetail/contractId", async ({ contractId }, { rejectWithValue }) => {
  try {
    const data =  await getContractDetail(contractId);
    console.log('====test', data);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  isPending: false,
} as Resource<ContractDetailResponse>;


export const contractDetailReducers = createSlice({
  name: "ContractDetail",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
   

    builder.addCase(getContractDetailAction.pending, (state) => {
      return {
        isPending: true,
      } as Resource<ContractDetailResponse>;
    });
    builder.addCase(
      getContractDetailAction.fulfilled,
      (state, { payload }) => {
        return {
          resource: { ...payload },
        } as Resource<ContractDetailResponse>;
      }
    );

    builder.addCase(getContractDetailAction.rejected, () => {
      return {
        errorMessage: "Some error 1",
      } as Resource<ContractDetailResponse>;
    });

  },
  

});


export default contractDetailReducers.reducer;