import React, { FC, useContext, useState } from "react";
import styled from "@emotion/styled";
import { colors } from "../../designsystem/theme";
import HueButton from "../../designsystem/Core/HueButton";
import ProjectEditDialog from "src/views/candidate/CandidateProfile/EditDialogs/ProjectEditDialog";
import UserContext from "src/context/user-context";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate } from "react-router";
import { getCandidateProfileAction } from "src/views/candidate/CandidateProfile/state/reducer";
import { useDispatch } from "react-redux";
import { PROJECT_URL } from "src/utils/constants";
import Tooltip from "@mui/material/Tooltip";

interface GvProject {
  hue: number;
  size: string;
  src: string | null;
}

const GvProjectDetails = styled.div<GvProject>`
  background-color: hsl(${(props) => props.hue}, 40%, 50%);
  background-image: url(${(props) => props.src});
  background-size: cover;
  border: 1px solid ${colors.nv80};
  font-family: Inter, sans-serif;
  border-radius: 8px;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  font-size: calc(${(props) => props.size} / 2.5);
  color: ${colors.white};
  display: grid;
  place-items: bottom;
  user-select: none;
  position: relative;

  &:hover > .hover {
    display: block;
  }

  & .actions {
    background-color: transparent;
    outline: none;
    border: none;
  }

  & > .hover {
    display: none;
  }

  & > .hidden {
    display: none;
  }
`;

interface ProjectProps {
  id: number;
  project: {};
  src: string | null;
  alt: string;
  size?: string;
  hue?: number;
  name: string;
  canEdit: boolean;
  onClick?: (props: { src: string | null; name: string; project: {} }) => void;
}
const Project: FC<ProjectProps> = ({
  id,
  src,
  alt,
  size = "170px",
  name,
  canEdit,
  onClick,
  project,
}) => {
  let color: number = 235;

  const [open, setOpen] = useState(false);
  const userContext = useContext<any>(UserContext);
  const user: any = userContext.user;
  const userCode = user.get.userCode;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteProject = () => {
    console.log("Trying to delete project");
    axios
      .delete(
        // DELETE_PROFILE_PROJECT_URL
        //POST_PROFILE_FILE_URL + info.id + "/?userCode=" + user.user.get.userCode
        ` ${PROJECT_URL}/${id}?userCode=${user.get.userCode}`
      )
      .then(function (response) {
        console.log(response);
        // window.location.reload();
        setOpen(false);
        dispatch(getCandidateProfileAction({ user_code: userCode }));
        // refetch();
      })
      .catch((err) => console.log(err));
  };

  let classNames = "";
  if (canEdit) {
    classNames = "hover";
  } else {
    classNames = "hidden";
  }

  return (
    <>
      <ProjectEditDialog
        id={id}
        open={open}
        setOpen={setOpen}
        info={project}
        addFlag={false}
        user={user}
        refetch={() => {
          // window.location.reload();
          dispatch(getCandidateProfileAction({ user_code: userCode }));
        }}
      />
      <GvProjectDetails
        hue={color}
        size={size}
        src={src}
        onClick={() => {
          if (canEdit) return;
          else {
            setOpen(true);
          }
        }}
      >
        <div
          className={classNames}
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            backgroundColor: "#00000066",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 25px)",
              left: "calc(50% - 16px)",
            }}
          >
            <button
              className="actions"
              style={{ color: "white" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Tooltip title="Edit" arrow>
                <EditIcon fontSize="large" />
              </Tooltip>
            </button>
          </div>
          <div
            style={{
              position: "absolute",
              right: "0px",
              top: "0px",
            }}
          >
            <button
              className="actions"
              onClick={deleteProject}
              style={{ color: "white" }}
            >
              <Tooltip title="Delete" arrow>
                <DeleteIcon fontSize="large" />
              </Tooltip>
            </button>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <HueButton hue={color} children={name} />
        </div>
      </GvProjectDetails>
    </>
  );
};

export default Project;
