import { useEffect, useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useFormik } from "formik";
import UserContext from "src/context/user-context";
import axios from "axios";
import * as yup from "yup";

import {
  POST_DRAFT_URL,
  GENERATE_JD_URL,
} from "src/utils/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router";
import Typography from "src/theme/typography";

const useStyles = makeStyles((theme) => ({
  helperTextLayout: {
    "& .css-1wxe1b7-MuiFormHelperText-root": {
      marginLeft: 0,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  editorlayout: {
    minHeight: "100px",

    "& .ql-container": {
      //<--- here
      fontSize: 14,
    },

    "& .ql-editor": {
      //<--- here
      margin: 0,
      minHeight: "400px",
      fontSize: 14,
    },
  },
  jdContainer : {
    minHeight: "400px",
    width: "800px"
  },

  bottomBtn: {
    textAlign: "right",
  },
  errorText: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.75rem",
    padding: "5px 0px",
    margin: 0,
  },
  ipLabel: {
    margin: 0,
    padding: 0,
    zIndex: 10000,
  },
}));

// const category = JOB_CATEGORY_LIST;

const validationSchema = yup.object().shape({
  description: yup.string().when("componentFunc", {
    is: (componentFunc) => {
      return componentFunc !== "saveDraft";
    },
    then: yup
      .string("Must be valid text")
      .max(5000, "Must be less than 5000 characters")
      .required("Description is required"),
    otherwise: yup.string("Must be valid text"),
  }),
});

const JobDetailsStep2 = ({
  funcFlag,
  className,
  handleNext,
  handleBack,
  jobpost,
  setJobPost,
  setToggler,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if(jobpost.description?.length === 0){
      generateJobDescription(jobpost);
    }
    else{
      setLoading(false)
    }
  }, []);


  function handleDescriptionChange(html, values, setValues) {
    // console.log("inside handleeDescriptionChange ", html);
    values.description = html;
    setValues(values);

    setJobPost((jobPost) => {
      let newJobPost = { ...jobPost };
      newJobPost.description = formik.values.description;
      return newJobPost;
    });
  }

  function submitJobDetails(newJob) {
    console.log(newJob, "Inside submit job details");
    handleNext();
  }



  const generateJobDescription = (jobPost) => {
    var params = {
      userCode: userContext.user.get.userCode,
    };

    axios({
      method: "POST",
      url: GENERATE_JD_URL,
      params: params,
      data: jobPost
    })
      .then((response) => {
        console.log(response);
        let generatedJd = respToHtml(response.data)
        formik.setFieldValue("description", generatedJd);
        setJobPost((jobPost) => {
          let newJobPost = { ...jobPost };
          newJobPost.description = generatedJd;
          return newJobPost;
        });
        setLoading(false)
        
      })
      .catch((error) => {
        setLoading(false)
      });
  };

  let initialValues = {
    description: jobpost.description,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log('inside step 2')
    },
  });

  const handleDraftSave = async () => {
    await formik.setFieldValue("componentFunc", "saveDraft");
    console.log(formik.values);
    formik.handleSubmit();
  };

  const handlePostJob = async (event) => {
    event.preventDefault();
    await formik.setFieldValue("componentFunc", "postJob");
    formik.handleSubmit();
    handleNext();
  };

  const respToHtml = (resp) => {

    let raw = (resp.indexOf("1.") > -1 ) ? resp.substring(resp.indexOf("1."), resp.length - 1) : resp
    
    return raw.split('\n').join('<br/>');
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handlePostJob}
      {...rest}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item md={12} xs={12} className={classes.jdContainer}>
              {loading ? 
                <Box sx={{ width: '100%', marginTop: '150px' }}>
                  <p style={{textAlign: "center", fontSize: "16px"}}> Generating Job Description </p>
                  <LinearProgress />
                </Box>
                :
                <ReactQuill
                  className={classes.editorlayout}
                  theme="snow"
                  onChange={(e, _, __, editor) => {
                    const text = editor.getText(e).trim().split(" ").length;
                    if (text === "") {
                      console.log("setting blank value for description");
                      formik.setFieldValue("description", "");
                    } else {
                      handleDescriptionChange(e, formik.values, formik.setValues);
                    }
                  }}
                  placeholder={"Description*"}
                  value={formik.values.description}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  
                />
                }
                {formik.touched.description && formik.errors.description && (
                  <p className={classes.errorText}>{formik.errors.description}</p>
                )}
            </Grid>
        </Grid>    
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container>
            {(funcFlag === "addNewJob" || funcFlag === "editDraft") && (
              <>
                <Grid
                  item
                  justifyContent="flex-start"
                  lg={6}
                  md={6}
                  sm={8}
                  xs={8}
                >
                  <Button
                    strech
                    variant="outlined"
                    color="primary"
                    onClick={handleDraftSave}
                  >
                    Save as draft
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.bottomBtn}
                  lg={6}
                  md={6}
                  sm={4}
                  xs={4}
                >
                  <Button variant="outlined" color="primary" onClick={function() {handleBack()}}>
                    Back
                  </Button>
                  &nbsp;
                  <Button variant="contained" color="primary" type="submit">
                    Next
                  </Button>
                </Grid>
              </>
            )}
            {funcFlag === "editJob" && (
              <Grid
                item
                className={classes.bottomBtn}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Button variant="contained" color="secondary" type="submit">
                  Back
                </Button>

                <Button variant="contained" color="primary" type="submit">
                  Next
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

JobDetailsStep2.propTypes = {
  className: PropTypes.string,
};

export default JobDetailsStep2;
