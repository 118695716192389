import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Resource } from "src/models/Resource";
import { CandidateProfileModel } from "./models";
import { candidateProfile } from "./service";

export const getCandidateProfileAction = createAsyncThunk<
  any,
  { user_code: string },
  any
>("candidateInfo/getById", async ({ user_code }, { rejectWithValue }) => {
  try {
    const data =  await candidateProfile(user_code);
    console.log('====test', data);

    try{
      data.candidateProjects = data.candidateProjects.map((project:any) => {
        project.canEdit = true;
        return project;
      })
    }catch(error){
      //do nothing
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  isPending: false,
} as Resource<CandidateProfileModel>;

export const CandidateReducers = createSlice({
  name: "CandidateProfile",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCandidateProfileAction.pending, (state) => {
      return {
        isPending: true,
      } as Resource<CandidateProfileModel>;
    });
    builder.addCase(
      getCandidateProfileAction.fulfilled,
      (state, { payload }) => {
        console.log(payload, "Payload");
        // add a random query string to profilePicUrl to avoid caching
        payload.userProfile.profilePicUrl = `${payload.userProfile.profilePicUrl}?q=${new Date().getTime()}`;
        return {
          resource: { ...payload },
        } as Resource<CandidateProfileModel>;
      }
    );

    builder.addCase(getCandidateProfileAction.rejected, () => {
      return {
        errorMessage: "Some error 1",
      } as Resource<CandidateProfileModel>;
    });
  },
});
export default CandidateReducers.reducer;
