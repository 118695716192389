export default {
  appendMessagesToContact: (response, contact, me) => {
    response.data.messages
      .map((msg) => {
        return {
          sent_by: msg.sent_by,
          text: msg.message.message,
          sent_to: msg.sent_to,
          time: Date.parse(msg.msg_time),
        };
      })
      .forEach((m) => {
        let currentSender = m.sent_by === me.id ? me : contact;
        contact.messages.push({
          id: contact.messages.length + 1,
          sender: currentSender.name,
          profilePicUrl: currentSender.profilePicUrl,
          senderFirstName: currentSender.firstName,
          senderLastName: currentSender.lastName,
          message: m.text,
          time: m.time,
        });
      });
    contact.messages = contact.messages.sort((m1, m2) => m1.time - m2.time);
    return contact;
  },
  appendUnreadMessagesToContacts: (response, newContacts, me) => {
    let contactsMap = {};
    newContacts.forEach((contact) => {
      contactsMap[contact.id] = contact;
    });
    contactsMap[me.id] = me;

    response.data.messages
      .map((msg) => {
        return {
          sent_by: msg.sent_by,
          text: msg.message.message,
          sent_to: msg.sent_to,
          time: Date.parse(msg.msg_time),
        };
      })
      .forEach((m) => {
        let currentContact =
          m.sent_by === me.id ? contactsMap[m.sent_to] : contactsMap[m.sent_by];
        let currentSender = contactsMap[m.sent_by];

        currentContact &&
          currentContact.messages.push({
            id: currentContact.messages.length + 1,
            sender: currentSender.name,
            profilePicUrl: currentSender.profilePicUrl,
            senderFirstName: currentSender.firstName,
            senderLastName: currentSender.lastName,
            message: m.text,
            time: m.time,
          });
      });

    newContacts.forEach((contact) => {
      if (contact.messages.length > 0) {
        contact.messages = contact.messages.sort((m1, m2) => m1.time - m2.time);
        let unreadMessage = contact.messages[contact.messages.length - 1];
        contact.unReadMessage = unreadMessage.message;
        contact.unReadMessageTime = unreadMessage.time;
      } else {
        contact.unReadMessage = "";
        contact.unReadMessageTime = -1;
      }
    });

    newContacts = newContacts.sort(
      (c1, c2) => c2.unReadMessageTime - c1.unReadMessageTime
    );

    return newContacts;
  },
  getUpdatedConversations: (message, contacts, updatedConversations, me) => {
    const addMessage = (currentContact, senderDetails) => {
      currentContact.messages.push({
        id: currentContact.messages.length + 1,
        sender: senderDetails.name,
        profilePicUrl: senderDetails.profilePicUrl,
        senderFirstName: senderDetails.firstName,
        senderLastName: senderDetails.lastName,
        message: message.message,
      });
    };
    const contactId =
      message.sender === me.id ? message.receiver : message.sender;

    let currentContact = updatedConversations.find(
      (conversation) => conversation.contact.id === contactId
    );
    if (currentContact) {
      const senderDetails =
        message.sender === me.id ? me : currentContact.contact;
      addMessage(currentContact.contact, senderDetails);
    } else {
      currentContact = contacts.find((contact) => contact.id === contactId);
      const senderDetails = message.sender === me.id ? me : currentContact;
      if (currentContact) {
        addMessage(currentContact, senderDetails);
        updatedConversations.push({
          contact: currentContact,
        });
      }
    }
    return updatedConversations;
  },
  getUserObject: (user) => {
    return {
      id: user.userCode,
      name: user.firstName + " " + user.lastName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profilePicUrl: user.profilePicUrl,
      status: "available",
      messages: [],
    };
  },
};
