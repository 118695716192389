import React, { useContext, useEffect } from "react";
import { Grid, Typography, Button, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UserContext from "src/context/user-context";
import Organization from "./Organization";
import SideBar from "../common/SideBar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BgImage from "src/assets/recruiter-onboarding-bg.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Box from "@mui/material/Box";

// Temporary data
const STEPS = [
  {
    name: "My Organization",
    key: "organization",
    active: true,
    completed: false,
    icon: AccountBalanceIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  palette: {
    text: {
      secondary: "#EEE",
    },
  },

  formsContainer: {
    height: "100%",
    width: "100%",
  },
  actionBar: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: "20vw",
    backgroundColor: "white",
    height: 60,
    borderTop: `1px solid ${theme.palette.accent.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 80px",
  },
  actionButton: {
    marginRight: 15,
  },
  "&.MuiFormControl-root": {
    width: "100%",
  },
  navigationContainer: {
    height: "100%",
    backgroundImage: `url(${BgImage})`,
  },

  listContainer: {
    width: "100%",
    maxWidth: 360,
  },

  navigationContainerItems: {
    paddingTop: "25vh",
    paddingLeft: "3vw",
  },

  listItem: {
    padding: "10px",
  },

  listItemText: {
    paddingLeft: "10px",
    "&& span": {
      color: "#FFF",
      fontWeight: "600",
    },
    "&& p": {
      color: "#FFF",
    },
  },

  selectedAvatar: {
    color: "#FFF",
    backgroundColor: "#000",
    opacity: "0.3",
    width: "60px",
    height: "60px",
  },

  unSelectedAvatar: {
    color: "#FFF",
    backgroundColor: "#00CAF6",
    width: "60px",
    height: "60px",
  },
}));

const RecruiterOnboarding = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  return (
    <Grid container spacing={2} className={classes.formsContainer}>
      <Hidden mdDown>
        <Grid item md={4}>
          <Box className={classes.navigationContainer}>
            <Box className={classes.navigationContainerItems}>
              <Typography variant="h4" style={{ color: "#FFF" }}>
                Welcome, {userContext.user.get.firstName}
              </Typography>
              <Typography variant="body1" style={{ color: "#FFF" }}>
                Lets set up your organization
              </Typography>
              <List className={classes.listContainer}>
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar className={classes.selectedAvatar}>
                      <BusinessIcon
                        fontSize="large"
                        style={{ color: "#FFF" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Add your Organization"
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar className={classes.unSelectedAvatar}>
                      <WorkIcon fontSize="large" style={{ color: "#FFF" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Post a Gig"
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemAvatar>
                    <Avatar className={classes.unSelectedAvatar}>
                      <PeopleAltIcon
                        fontSize="large"
                        style={{ color: "#FFF" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary="Find the right Talent"
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Hidden>
      <Grid item md={8} sm={12} xs={12}>
        <Organization />
      </Grid>
    </Grid>
  );
};

export default RecruiterOnboarding;
