import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TopBar from "./TopBar";
import { OnBoardingContextProvider } from "../../context/onboarding-context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  onboardingWrapper: {
    display: "flex",
    flex: "1 1 auto",
  },
}));

const OnboardingLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <OnBoardingContextProvider>
      <div className={classes.root}>
        <Hidden smUp>
          {" "}
          <TopBar />
        </Hidden>
        <div className={classes.onboardingWrapper}>
          <Outlet />
        </div>
      </div>
    </OnBoardingContextProvider>
  );
};

export default OnboardingLayout;
