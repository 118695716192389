import React, { FC, ReactElement } from "react";
import styled from '@emotion/styled'
import { breakpoints, colors, typography } from '../../theme'
import Typography from "../Typography";

const GvSection = styled.div`
border-bottom: 1px solid ${colors.nv70};
font-weight: 600;
`

const Title = styled(Typography)`
display: inline-block;
border-bottom: 2px solid ${colors.p40};
padding-bottom: 15px;
padding-right: 20px;
margin-bottom: -1.5px;
`

interface SectionProps {
    children: string
}


const Section: FC<SectionProps> = ({ children }) => {
    return (
        <GvSection>
            <Title variant='h5'>
                {children}
            </Title>
        </GvSection>
    )
}

export default Section