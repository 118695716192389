import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  Popover,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { POST_DRAFT_URL } from "src/utils/constants";
import axios from "axios";
import { Formik } from "formik";
import UserContext from "src/context/user-context";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    padding: theme.spacing(2),
  },
}));

const JobDetailsStep3 = ({
  funcFlag,
  className,
  handleNext,
  handleBack,
  jobpost,
  setJobPost,
  submitJob,
  setToggler,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  let isjobDraftsRoute = location.pathname.split("/").includes("jobDrafts");

  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const initialValues = {
    qualifications: jobpost.qualifications,
    certifications: jobpost.certifications,
  };

  const validationSchema = Yup.object().shape({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popOverMessage, setPopOverMessage] = React.useState("");

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.target);
    setPopOverMessage(message);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setPopOverMessage("");
  };
  const openPopOver = Boolean(anchorEl);

  function handleDraftSave(values) {
    var newJobPost = { ...jobpost };

    let backendJobPost = {};
    backendJobPost.id = newJobPost.id;
    backendJobPost.title = newJobPost.title;
    backendJobPost.description = newJobPost.description;
    backendJobPost.category = newJobPost.category;
    backendJobPost.expertise = newJobPost.expertise;
    backendJobPost.proficiencyLevel = newJobPost.proficiencyLevel;
    backendJobPost.projectType = newJobPost.projectType;
    backendJobPost.timeCommitment = newJobPost.timeCommitment;
    backendJobPost.duration = newJobPost.duration;
    backendJobPost.timeUnit = newJobPost.timeUnit;
    backendJobPost.budget = newJobPost.budget;
    backendJobPost.currencyCode = newJobPost.currencyCode;
    backendJobPost.qualifications = newJobPost.qualifications;
    backendJobPost.certifications = newJobPost.certifications;
    backendJobPost.jobPostSkills = newJobPost.jobPostSkills;
    backendJobPost.jobPostLocations = newJobPost.jobPostLocationList;
    backendJobPost.addedLocations = newJobPost.addedLocations;
    backendJobPost.updatedDate = newJobPost.updatedDate;
    backendJobPost.status = newJobPost.status;
    backendJobPost.modifiedBy = newJobPost.modifiedBy;
    backendJobPost.createdDate = newJobPost.createdDate;
    backendJobPost.fullTime = newJobPost.fullTime;

    backendJobPost.qualifications = values.qualifications;
    backendJobPost.certifications = values.certifications;

    if (funcFlag === "addNewJob") {
      axios
        .post(
          `${POST_DRAFT_URL}?userCode=${userContext.user.get.userCode}`,
          backendJobPost
        )
        .then((res) => {
          console.log(res, "post draft succcessfull");
          if (!isjobDraftsRoute) {
            navigate("/r/jobs");
          }
          setToggler((toggler) => !toggler);
        })
        .catch((err) => {
          console.log(err, "posting draft failed");
        });
      console.log("saving draft");
      // onClose();
      return;
    }

    if (funcFlag === "editDraft") {
      axios
        .put(
          `${POST_DRAFT_URL}?userCode=${userContext.user.get.userCode}`,
          backendJobPost
        )
        .then((res) => {
          console.log(res, "post draft succcessfull");
          if (!isjobDraftsRoute) {
            navigate("/r/jobs");
          }
          setToggler((toggler) => !toggler);
        })
        .catch((err) => {
          console.log(err, "posting draft failed");
        });
      console.log("saving draft");
      // onClose();
      return;
    }
  }

  function submitJobAdditionalDetails(values) {
    console.log(
      "submitJobAdditionalDetails has been called with:" +
        userContext.user.get.userCode
    );
    console.log("Values:", values);

    var temp = { ...jobpost };

    temp.qualifications = values.qualifications;
    temp.certifications = values.certifications;
    setJobPost(temp);
    handleBack();
  }

  const [openToast, setOpenToast] = React.useState(false);
  const handleClickToast = () => {
    setOpenToast(true);
    // ToastFile(candidateFileId, alt);
  };

  const handleCloseToast = () => {
    // const handleCloseToast = (event, reason) => {
    // if (reason === "clickaway") {
    //   return;
    // }

    setOpenToast(false);
  };
  //useEffect(() => {}, [jobpost]);

  function submitJobDetails(values, actions) {
    console.log(
      "submitJobAdditionalDetails has been called with:" +
        userContext.user.get.userCode
    );

    setJobPost((temp) => {
      let newpost = { ...temp };
      newpost.qualifications = values.qualifications;
      newpost.certifications = values.certifications;
      submitJob(newpost);
      return newpost;
    });
    // handleClickToast();
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitJobDetails(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Educational Qualifications "
                    name="qualifications"
                    value={values.qualifications}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    size="small"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Certifications"
                    name="certifications"
                    value={values.certifications}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    rows={4}
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Divider />
            <CardContent>
              <Grid container spacing={1} paddingBottom="10px">
                <Grid
                  item
                  justifyContent="flex-start"
                  lg={8}
                  md={8}
                  sm={9}
                  xs={9}
                >
                  {(funcFlag === "editDraft" || funcFlag === "addNewJob") && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        handleDraftSave(values);
                      }}
                    >
                      Save as Draft
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                // justifyContent="center"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item lg={2} md={2} sm={4} xs={4}>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      submitJobAdditionalDetails(values);
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => {
                      handleClickToast();
                    }}
                  >
                    Post
                  </Button>
                </Grid>
                <Snackbar
                  open={openToast}
                  autoHideDuration={600000}
                  onClose={handleCloseToast}
                >
                  <Alert
                    onClose={handleCloseToast}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    "The job has been successfully posted. You can checkout the{" "}
                    <Link to="/r/jobDetails/id/#recommendedcandidates">
                      recommended candidates
                    </Link>
                    "
                  </Alert>
                </Snackbar>
              </Grid>
            </CardContent>
            <Popover
              id="popover"
              open={openPopOver}
              anchorEl={anchorEl}
              onClose={handleAnchorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className={classes.typography}>
                {popOverMessage}
              </Typography>
            </Popover>
          </Card>
        </form>
      )}
    </Formik>
  );
};

JobDetailsStep3.propTypes = {
  className: PropTypes.string,
};

export default JobDetailsStep3;

const skills = [
  { title: "Java" },
  { title: "Javascript" },
  { title: "Python" },
  { title: "Linux" },
  { title: "SQL" },
  { title: "MySQL" },
  { title: "NoSQL" },
  { title: "MongoDB" },
  { title: "AWS" },
  { title: "Azure" },
  { title: "Docker" },
  { title: "Kubernetes" },
  { title: "GCP" },
];
