import React, { useContext } from "react";
import { IconButton, Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import {
  NotificationMenuContext,
  ReadNotificationContext,
} from "src/context/notification-context";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: "8px",
    padding: "0px 15px",
    color: "#2C80D5",
  },
  iconButtonActive: {
    color: "#2C80D5",
  },
}));

const NotificationButton = (props) => {
  const classes = useStyles();
  const notificationMenu = useContext(NotificationMenuContext);
  const readNotification = useContext(ReadNotificationContext);

  const handleClick = (event) => {
    notificationMenu.setAnchorEl(event.currentTarget);
    readNotification.setRead(true);
  };

  return (
    <IconButton
      color="inherit"
      onClick={handleClick}
      className={classes.iconButton}
      disableRipple
      size="large"
    >
      <Badge
        badgeContent={Number(!readNotification.hasRead)}
        color="secondary"
        variant="dot"
      >
        <NotificationIcon
          fill={notificationMenu.anchorEl === null ? "#223657" : "#2C80D5"}
          width={notificationMenu.anchorEl === null ? "1.5" : "2.5"}
        />
      </Badge>
    </IconButton>
  );
};

const NotificationIcon = ({ fill, width }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 35 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68379 15.3854C7.68014 12.7654 8.72257 10.2523 10.5797 8.40417C12.4367 6.556 14.9548 5.52567 17.5748 5.54194C22.9874 5.58218 27.3169 10.0812 27.3169 15.509V16.4792C27.3169 21.3758 28.3413 24.2173 29.2436 25.7703C29.4415 26.1081 29.4437 26.5258 29.2495 26.8657C29.0553 27.2055 28.6942 27.4157 28.3028 27.4167H6.69677C6.30529 27.4157 5.9442 27.2055 5.75 26.8655C5.5558 26.5256 5.55811 26.1078 5.75605 25.7701C6.65885 24.217 7.68379 21.3756 7.68379 16.4792L7.68379 15.3854Z"
        stroke={fill}
        strokeWidth={width}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 27.4166V28.5104C13.125 30.9266 15.0838 32.8854 17.5 32.8854C19.9162 32.8854 21.875 30.9266 21.875 28.5104V27.4166"
        stroke={fill}
        strokeWidth={width}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationButton;
