import React, { FC, ReactElement } from "react";
import styled from '@emotion/styled'
import { colors, typography } from "../../theme";

interface GvCoverProps{
  hue: number
  comp1: string
  comp2: string
  comp3: string
}

const GvCover = styled.div<GvCoverProps>`
height: 150px;
border-radius: 8px;
background-image: 
radial-gradient(at 100% 100%, ${props => props.comp2} 0, transparent 50%),
radial-gradient(at 30% 110%, ${props => props.comp3} 0, transparent 60%),
radial-gradient(at 30% 30%, ${props => props.comp1} 0, transparent 59%),
radial-gradient(at 10% 80%, ${props => props.comp1} 0, transparent 100%),
radial-gradient(at 25% 15%, ${props => props.comp1} 0, transparent 50%),
radial-gradient(at 85% 20%, ${props => props.comp3} 0, transparent 100%);

`

interface CoverProps {
  hue?: number
}

const Cover: FC<CoverProps> = ({ hue = 309 }) => {

  function parseHSL(str: string) {
    var hsl, h, s, l
    hsl = str.replace(/[^\d,]/g, '').split(',')
    h = Number(hsl[0])
    s = Number(hsl[1])
    l = Number(hsl[2])
    return [h, s, l]
  }
  function harmonize(color: string, start: number, end: number, interval: number) {
    const colors = [color]
    const [h, s, l] = parseHSL(color)

    for (let i = start; i <= end; i += interval) {
      const h1 = (h + i) % 360
      const c1 = `hsl(${h1}, ${s}%, ${l}%)`
      colors.push(c1)
    }

    return colors
  }

  const colors: string[] = harmonize(`hsl(${hue}, 100%, 82%)`, 150, 210, 60)
  return (
    <GvCover hue={hue} comp1={colors[0]} comp2={colors[1]} comp3={colors[2]}/>
  )
}

export default Cover
