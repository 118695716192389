import axios from "axios";
import { createAction, createAsyncThunk, current } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  getJobInfo,
  getCandidateRecommendations,
  getJobApplicants,
  bookmarkToCandidate,
  removeBookmarkCandidate,
  closeJob,
  deleteJob,
  shortlistCandidate,
  removeShortlistCandidate,
  getDraft,
  getJobShortListApplicants
} from "./service";

import {
  Resource,
  ResourceInitial,
  ResourcePending,
  ResourceSuccess,
} from "../../../../models/Resource";
import { RecruiterJobDetailEntity, ApplicantsInfo,RecommendationInfo } from "./models";

import { resourceUsage } from "process";

import { any, bool, number } from "prop-types";

export const loadRecruiteJobDetail = createAsyncThunk(
  "load",
  (params: { jobID: number; user_code: string;page_Number: number }, { dispatch }) => {
    dispatch(getJobInfoAction(params));
    dispatch(getJobApplicantsAction(params));
    dispatch(getShortListApplicantsAction(params));
    dispatch(getCandidateRecommendationsAction(params));
    return "";
  }
);

const getJobInfoAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>(
  "jobInfoDetail/getById",
  async ({ jobID, user_code }, { rejectWithValue }) => {
    try {
      return await getJobInfo(jobID);
      //return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getJobApplicantsAction = createAsyncThunk<
  ApplicantsInfo,
  { jobID: number; user_code: string;page_Number: number },
  any
>("jobApplicantDetail/getById", async ({ jobID,user_code,page_Number}, { rejectWithValue }) => {
  try {
    let response = await getJobApplicants(jobID,user_code,page_Number);
    //console.log(response);
    return response;
    //return rejectWithValue("error");
  } catch (error) {
    //console.log(error);
    return rejectWithValue(error);
  }
});


export const getShortListApplicantsAction = createAsyncThunk<
  ApplicantsInfo,
  { jobID: number; user_code: string;page_Number: number },
  any
>("jobShortListApplicantDetail/getById", async ({ jobID,user_code,page_Number}, { rejectWithValue }) => {
  try {
    let response = await getJobShortListApplicants(jobID,user_code,page_Number);
    //console.log(response);
    return response;
    //return rejectWithValue("error");
  } catch (error) {
    //console.log(error);
    return rejectWithValue(error);
  }
});

export const getCandidateRecommendationsAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>(
  "candidateRecommendationDetail/getById",
  async ({ jobID, user_code }, { rejectWithValue }) => {
    try {
      let response = await getCandidateRecommendations(jobID, user_code);
      //console.log(response);
      return response;
      //return rejectWithValue("error");
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const bookmarkToEmployerAction = createAsyncThunk<
  any,
  { candidateID: string; user_code: string },
  any
>(
  "bookmarkEmployer/jobId",
  async ({ candidateID, user_code }, { rejectWithValue }) => {
    try {
      return bookmarkToCandidate(user_code, candidateID);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeBookmarkEmployerAction = createAsyncThunk<
  any,
  { candidateID: string; user_code: string },
  any
>(
  "removeBookmarkEmployer/jobId",
  async ({ candidateID, user_code }, { rejectWithValue }) => {
    try {
      return await removeBookmarkCandidate(user_code, candidateID);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const closeToJobAction = createAsyncThunk<
  any,
  { jobID: number},
  any
>("close/jobId", async ({ jobID }, { rejectWithValue }) => {
  try {
    return closeJob(jobID);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteToJobAction = createAsyncThunk<
  any,
  { jobID: number},
  any
>("delete/jobId", async ({ jobID }, { rejectWithValue }) => {
  try {
    return deleteJob(jobID);
  } catch (error) {
    return rejectWithValue(error);
  }
});



export const shortlistCandidateAction = createAsyncThunk<
  any,
  { candidateID: string; jobID: number },
  any
>(
  "shortlistCandidateAction/jobId",
  async ({ candidateID, jobID }, { rejectWithValue }) => {
    try {
      return shortlistCandidate(candidateID, jobID);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeShortlistCandidateAction = createAsyncThunk<
  any,
  { candidateID: string; jobID: number },
  any
>(
  "removeShortlistCandidateAction/jobId",
  async ({ candidateID, jobID }, { rejectWithValue }) => {
    try {
      return await removeShortlistCandidate(candidateID, jobID);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadDraftDetailAction=createAsyncThunk<
any,
{jobID:number,user_code:string}
,any
>("recruiter/loadDraftDetail",
async ({jobID,user_code},{rejectWithValue})=>{
  try{
    //make service file API call
    return await getDraft(jobID,user_code)
  } catch(error){
    return rejectWithValue(error)
  }
})

const initialState = {
  jobInfo: { isPending: false } as ResourceInitial,
  applicants: { isPending: false } as ResourceInitial,
  shortListApplicants: { isPending: false } as ResourceInitial,
  recommendations: { isPending: false } as ResourceInitial,
} as RecruiterJobDetailEntity;



export const RecuiterReducers = createSlice({
  name: "jobInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(loadRecruiteJobDetail.fulfilled, (state) => {
      //console.log("All Pending");
      //console.log(state);
      return {
        jobInfo: { isPending: true } as ResourcePending,
        applicants: { isPending: true } as ResourcePending,
        shortListApplicants: { isPending: true } as ResourcePending,
        recommendations: { isPending: true } as ResourcePending,
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(getJobInfoAction.pending, (state) => {
      const currentState = current(state);
      return {
        ...currentState,
        jobInfo: { isPending: true },
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(getJobInfoAction.fulfilled, (state, { payload }) => {
      const currentState = current(state);

      return {
        ...currentState,
        jobInfo: { resource: { ...payload } },
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(
      getJobInfoAction.rejected,
      (state, { meta, payload, error }) => {
        const currentState = current(state);
        return {
          ...currentState,
          jobInfo: { errorMessage: "Cannot load job info" },
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(getJobApplicantsAction.pending, (state) => {
      const currentState = current(state);
      //console.log(currentState)
      return {
        ...currentState,
        applicants: { isPending: true },
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(getJobApplicantsAction.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      //console.log(currentState);
      return {
        ...currentState,
        applicants: { resource: { ...payload } },
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(
      getJobApplicantsAction.rejected,
      (state, { meta, payload, error }) => {
        const currentState = current(state);
        //console.log(currentState);
        return {
          ...currentState,
          applicants: { errorMessage: "Cannot load applicants info" },
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(getCandidateRecommendationsAction.pending, (state) => {
      const currentState = current(state);
      return {
        ...currentState,
        recommendations: { isPending: true },
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(
      getCandidateRecommendationsAction.fulfilled,
      (state, { payload }) => {
        const currentState = current(state);

        return {
          ...currentState,
          recommendations: { resource: { ...payload } },
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(
      getCandidateRecommendationsAction.rejected,
      (state, { meta, payload, error }) => {
        const currentState = current(state);
        return {
          ...currentState,
          recommendations: { errorMessage: "Cannot load job info" },
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(bookmarkToEmployerAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      //console.log(currentState);
      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(
      bookmarkToEmployerAction.fulfilled,
      (state, { payload }) => {
        //if (payload.status === 1) {
        //state.jobInfo.bookmarked = true;

        //}
        //console.log(current(state));

        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );
    builder.addCase(
      bookmarkToEmployerAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(removeBookmarkEmployerAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      //console.log(currentState);

      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(
      removeBookmarkEmployerAction.fulfilled,
      (state, { payload }) => {
        //if (payload.status === 1) {
        //state.jobInfo.bookmarked = true;

        //}
        //console.log(current(state));

        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );

    builder.addCase(
      removeBookmarkEmployerAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );
   
    builder.addCase(closeToJobAction.fulfilled, (state) => {
      // console.log("All Pending");
      //console.log(state);
      let currentState = current(state);
      return {
        jobInfo: { isPending: true } as ResourcePending,
        applicants: { isPending: true } as ResourcePending,
        recommendations: { isPending: true } as ResourcePending,
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(closeToJobAction.pending, (state) => {
      const currentState = current(state);
      return {
        ...currentState,
        jobInfo: { isPending: true },
      } as RecruiterJobDetailEntity;
    });
    



   
    builder.addCase(deleteToJobAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      //console.log(currentState);
      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(
      deleteToJobAction.fulfilled,
      (state, { payload }) => {
        //if (payload.status === 1) {
        //state.jobInfo.bookmarked = true;

        //}
        //console.log(current(state));

        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
       
      }
    );
    builder.addCase(
      deleteToJobAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );



    builder.addCase(shortlistCandidateAction.fulfilled, (state) => {
      let currentState = current(state);
      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });


    
    builder.addCase(shortlistCandidateAction.pending, (state) => {
      const currentState = current(state);
      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });


    builder.addCase(removeShortlistCandidateAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      //console.log(currentState);
      return {
        ...currentState,
      } as RecruiterJobDetailEntity;
    });
    builder.addCase(
      removeShortlistCandidateAction.fulfilled,
      (state, { payload }) => {
        //if (payload.status === 1) {
        //state.jobInfo.bookmarked = true;

        //}
        //console.log(current(state));

        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
       
      }
    );
    builder.addCase(
      removeShortlistCandidateAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        return {
          ...currentState,
        } as RecruiterJobDetailEntity;
      }
    );


    builder.addCase(loadDraftDetailAction.pending,(state)=>{
      return {
        jobInfo: { isPending: true } as ResourcePending,
        applicants: { isPending: true } as ResourcePending,
        recommendations: { isPending: true } as ResourcePending,
      } as RecruiterJobDetailEntity;
    })

    builder.addCase(loadDraftDetailAction.fulfilled,(state,{payload})=>{
      const currentState = current(state);

      return {
        applicants: { resource: { applicants: []} } as ResourceSuccess<ApplicantsInfo>,
        recommendations: {resource: { candidates: []} } as ResourceSuccess<RecommendationInfo>,
        jobInfo: { resource:  {...payload} }
      } as RecruiterJobDetailEntity;
    })

    builder.addCase(loadDraftDetailAction.rejected,(state)=>{
      const currentState = current(state);
      return {
        ...currentState,
        jobInfo: { errorMessage: "Cannot load applicants info" },
      } as RecruiterJobDetailEntity;
    })

    builder.addCase(getShortListApplicantsAction.pending, (state) => {
      const currentState = current(state);
      //console.log(currentState)
      return {
        ...currentState,
        shortListApplicants: { isPending: true },
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(getShortListApplicantsAction.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      //console.log(currentState);
      return {
        ...currentState,
        shortListApplicants: { resource: { ...payload } },
      } as RecruiterJobDetailEntity;
    });

    builder.addCase(
      getShortListApplicantsAction.rejected,
      (state, { meta, payload, error }) => {
        const currentState = current(state);
        //console.log(currentState);
        return {
          ...currentState,
          shortListApplicants: { errorMessage: "Cannot load applicants info" },
        } as RecruiterJobDetailEntity;
      }
    );

  },
});

export default RecuiterReducers.reducer;
