import React, { FC, ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Chip from "../../Core/Chip";
import { Down } from "@icon-park/react";
import Typography from "src/designsystem/Core/Typography";
import Button from "src/designsystem/Core/Button";
import { motion, AnimatePresence } from "framer-motion";

const GvSkillContainer = styled.div``;

const GvChipContainer = styled.div`
  display: flex;
  gap: 7px 10px;
  flex-wrap: wrap;
`;
const AdditionalToggle = styled(Button)`
  background-color: ${colors.p40};
  color: ${colors.white};
  font-weight: 500;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0.3em 1.2em;
  border-radius: 5px;
`;

const AdditionalSkills = styled(motion.div)`
  margin-top: 10px;
  padding: 10px 5px;
  background-color: ${colors.p90};
  display: flex;
  gap: 7px 10px;
  flex-wrap: wrap;
  border-radius: 5px;
`;

interface SkillsProps {
  skills: {
    jobPostSkillId: number;
    skillName: string;
    isAdditionalSkill: boolean;
  }[];
}

const Skills: FC<SkillsProps> = ({ skills }) => {
  const [toggle, setToggle] = useState(false);

  const toggleVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };

  return (
    <GvSkillContainer>
      <GvChipContainer>
        {skills.map((skill, i) => {
          if (!skill.isAdditionalSkill) {
            return <Chip key={i} children={skill.skillName} />;
          }
        })}

        {
          (skills.filter(skill => skill.isAdditionalSkill).length > 0)
            ? (
              <AdditionalToggle onClick={() => setToggle(!toggle)}>
                <motion.div
                  animate={toggle ? "open" : "closed"}
                  variants={toggleVariants}
                  transition={{ type: "tween" }}
                >
                  <Down
                    theme="outline"
                    size="19"
                    fill={colors.white}
                    style={{ display: "grid", placeItems: "center" }}
                  />
                </motion.div>
                Additional Skills
              </AdditionalToggle>
            )
            : (<></>)
        }
      </GvChipContainer>
      {
        (skills.filter(skill => skill.isAdditionalSkill).length > 0)
          ? (
            <AnimatePresence>
              {toggle && (
                <AdditionalSkills
                  transition={{ type: "tween" }}
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                >
                  {skills.map((skill, i) => {
                    if (skill.isAdditionalSkill) {
                      return <Chip key={i} children={skill.skillName} />;
                    }
                  })}
                </AdditionalSkills>
              )}
            </AnimatePresence>
          )
          : (<></>)
      }

    </GvSkillContainer>
  );
};

export default Skills;
