import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UserContext from "src/context/user-context";
import Page from "src/components/Page";
import DashboardCarousel from "./RecruiterLanding/DashboardCarousel";
import {
  GET_EMPLOYER_JOBS_URL,
  GET_EMPLOYEE_CONTRACT_URL,
  GET_CANDIDATE_RECOMMENDATIONS_MULTIPLE,
  GET_USER_PROFILES_URL,
  GET_CANDIDATE_BOOKMARKS,
} from "src/utils/constants";

import JobCardContactMini from "src/derivedComponents/JobCardContactMini";
import RecruiterWelcome from "src/views/recruiter/RecruiterDashboardView/RecruiterLanding/RecruiterWelcome";
import RecruiterLandingHeader from "./RecruiterLanding/RecruiterLandingHeader";
import InlineLoading from "src/components/Loading/InlineLoading";

import CloseIcon from "@mui/icons-material/Close";
import JobStepperAI from "src/components/job-steps/JobStepperAI";
import DashboardJobs from "./RecruiterLanding/DashboardJobs";
import Typography from "src/designsystem/Core/Typography";
import {colors} from "src/designsystem/theme";
import styled from "@emotion/styled";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  dialogPaper: {
    minHeight: "90vh",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
  carousel: {
    [theme.breakpoints.down("sm")]: {
      width: "342px",
    },
  },
}));


const SectionTitle = styled(Typography)`
    display: inline-block;
    border-bottom: 2px solid ${colors.p40};
    padding-bottom: 15px;
    padding-right: 20px;
    margin-bottom: 10px;
    color: ${colors.p40};
`


const RecruiterDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState({
    open: [],
    active: [],
    appliedCandidatesProfileMap: {},
    recommended: [],
    bookmarks: [],
  });

  const [toggler, setToggler] = useState(false);

  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;

  const [open, setOpen] = React.useState(false);
  const [waiting, setWaiting] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    console.log("CALLED CLOSE");
    setOpen(false);
  };

  useEffect(() => {
    getJobs();
    handleClose();
  }, [toggler]);

  useEffect(() => {
    getRecommendedCandidates();
  }, [jobs.open]);

  useEffect(() => {
    getCandidateProfiles();
  }, [jobs.open, jobs.recommended, jobs.active]);

  useEffect(() => {
    getBookmarks();
  }, []);

  const getRecommendedCandidates = async () => {
    if (jobs.open && jobs.open.length > 0) {
      const jobIds = jobs.open.map((j) => j.id);
      const recCandidates = await axios
        .get(
          GET_CANDIDATE_RECOMMENDATIONS_MULTIPLE +
            `?jobIds=` +
            jobIds.join(",") +
            `&pageNumber=0` +
            `&user_code=` +
            user_code
        )
        .catch((err) => console.log(err));
      if (recCandidates && recCandidates.data?.length > 0) {
        setJobs((prevJobs) => {
          let newJobs = { ...prevJobs };
          newJobs.recommended = [];
          recCandidates.data.forEach((rec, i) => {
            newJobs.recommended = newJobs.recommended.concat(rec.candidates);
            //TODO - change as per job id
            jobs.open[i]["recommendedCandidates"] = rec.candidates;
          });
          const uniqueObjects = [
            ...new Map(
              newJobs.recommended.map((item) => [item.id, item])
            ).values(),
          ];
          newJobs.recommended = uniqueObjects;
          return newJobs;
        });
      }
    }
  };
  const getJobs = async () => {
    const employerResponse = await axios
      .get(GET_EMPLOYER_JOBS_URL + `?userCode=` + user_code + `&status=ACTIVE`)
      .catch((err) => console.log(err));

    // Move Contracts also to /employer URL - need to change backend API
    const employerContracts = await axios
      .get(GET_EMPLOYEE_CONTRACT_URL + `?employerUserCode=` + user_code)
      .catch((err) => console.log(err));

    if (employerResponse && employerResponse.data) {
      console.log("logging ");
      console.log(employerResponse.data);
      setJobs((prevJobs) => {
        let newJobs = { ...prevJobs };
        newJobs.open = employerResponse.data;
        employerResponse.data.forEach((jobPost) => {
          if (jobPost.jobApplications?.length > 0) {
            jobPost["applicantUserCodes"] = jobPost.jobApplications.map(
              (jobApplication) => jobApplication.userCode
            );
          }
        });
        return newJobs;
      });
    }

    if (employerContracts && employerContracts.data) {
      setJobs((prevJobs) => {
        let newJobs = { ...prevJobs };
        newJobs.active = employerContracts.data;
        return newJobs;
      });
    }
    setWaiting(false);
  };

  const getCandidateProfiles = async () => {
    let candidateUserCodes = jobs?.open
      ?.map((jobPost) => jobPost.jobApplications)
      .flat()
      .map((application) => application.userCode);
    candidateUserCodes = candidateUserCodes.concat(
      jobs.recommended.map((rec) => rec.userCode)
    );
    candidateUserCodes = candidateUserCodes.concat(
      jobs.active.map((gig) => gig.candidate.userCode)
    );

    const uniqueCodes = [];
    candidateUserCodes.forEach((code) => {
      if (
        uniqueCodes.indexOf(code) === -1 &&
        !jobs.appliedCandidatesProfileMap[code]
      ) {
        uniqueCodes.push(code);
      }
    });
    if (uniqueCodes.length > 0) {
      const candidateProfiles = await axios
        .get(GET_USER_PROFILES_URL + `?userCodes=` + uniqueCodes.join(","))
        .catch((err) => console.log(err));

      if (
        candidateProfiles &&
        candidateProfiles.data &&
        candidateProfiles.data.length > 0
      ) {
        setJobs((prevJobs) => {
          let newJobs = { ...prevJobs };
          candidateProfiles.data.forEach((profile) => {
            newJobs.appliedCandidatesProfileMap[profile.userCode] = profile;
          });
          return newJobs;
        });
      }
    }
  };

  const getCandidateProfile = (userCode) => {
    return jobs.appliedCandidatesProfileMap[userCode] || {};
  };

  const hasContent =
    (jobs.open && jobs.open.length > 0) ||
    (jobs.active && jobs.active.length > 0);

  function handleBookmark(candidate) {
    setJobs((prevjobs) => {
      console.log("handleBookmark called with candidate id:", candidate.id);
      let newJobs = { ...prevjobs };
      let newRecs = [...prevjobs.recommended];
      //let newBookmarks = [...newJobs.bookmarks];

      let matchRecs = newRecs.find(
        (t) => Number(t.id) === Number(candidate.id)
      );
      if (matchRecs) {
        matchRecs.bookmarked = !matchRecs.bookmarked;
        console.log("WE FOUND A MATCH");
      }

      /*let matchBM = newBookmarks.find(t => t.id === candidate.id);
      if (matchBM) {
        matchBM.bookmarked = !matchBM.bookmarked;
      }*/
      newJobs.recommended = newRecs;
      //newJobs.bookmarks = newBookmarks;
      return newJobs;
    });
    getBookmarks();
  }

  const getBookmarks = () => {
    axios({
      method: "GET",
      url: GET_CANDIDATE_BOOKMARKS,
      params: {
        user_code: user_code,
      },
    })
      .then((response) => {
        console.log("WE GOT THE BOOKMARKS", user_code, "::", response.data);

        let bookmarks = [];
        response.data.forEach(function (bm) {
          let newBM = { ...bm };
          newBM.bookmarked = true;
          newBM.name = bm.userProfile.firstName + " " + bm.userProfile.lastName;

          let skills = [];
          bm.candidateSkills.forEach(function (cs) {
            skills.push(cs.skillName);
          });

          newBM.topSkills = skills;
          bookmarks.push(newBM);
        });

        setJobs((prevJobs) => {
          let newJobs = { ...prevJobs };
          newJobs.bookmarks = bookmarks; //response.data;
          return newJobs;
        });
      })
      .catch((error) => {
        console.log("WE DONT GOT THE BOOKMARKS:", user_code);
      });
  };

  return (
    <Page className={classes.root} title="Dashboard">
      {!waiting && (
        <RecruiterLandingHeader
          hasContent={hasContent}
          user={userContext.user}
          handleOpen={handleOpen}
        />
      )}
      <Box mt={3}>
        {waiting ? (
          <InlineLoading />
        ) : hasContent ? (
          <DashboardJobs 
            jobs={jobs.open}
            getCandidateProfile={getCandidateProfile}>
          </DashboardJobs>
        ) : (
          <RecruiterWelcome
            handleJobPostOpen={handleOpen}
            user={userContext.user}
          />
        )}
      </Box>

      {jobs.open && jobs.open.length > 0 && (
        <Box mt={3}>
          <SectionTitle variant="h4">Recommended Candidates</SectionTitle>
          {jobs.recommended && jobs.recommended.length > 0 ? (
            jobs.recommended.map((c, i) => {
              return (
                <JobCardContactMini
                      user_code={user_code}
                      applicantInfo={c}
                    />
              );
            })
          ) : (
            <div>No Recommended Candidate...</div>
          )}
        </Box>
      )}
      <Dialog
        onBackdropClick="false"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={true}
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          className={classes.close}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <JobStepperAI toggler={toggler} setToggler={setToggler} />
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default RecruiterDashboard;
