import React, { forwardRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import GigTextField from "src/components/custom/GigTextField";
import styled from "@emotion/styled";
import Avatar from "src/designsystem/Core/Avatar";
import { CONTRACT_SUMMARY_URL, JOB_LIST } from "src/utils/constants";
import Typography from "src/designsystem/Core/Typography";
import { breakpoints, colors } from "src/designsystem/theme";
import { GET_EMPLOYER_JOBS_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";
import { UpdateListenerContext } from "src/context/update-listener-context";
import { useDispatch, useSelector } from "react-redux";
import { submitFormInfo } from "src/views/recruiter/RecruiterContract/state/contractStepperReducers";
import ReactQuill from "react-quill";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  editorlayout: {
    "& .ql-editor": {
      //<--- here
      margin: 0,
      minHeight: "100px",
    },
  },
 
  errorText: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.75rem",
    padding: "5px 0px",
    margin: 0,
  }
}));

const CSView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
 
`;
const Descriptive = styled(Typography)`
  font-weight: 600;
  font-size: 0.8rem;
  color: gray;
`;

const Header = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
`;

const CSTopView = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 90px 1fr;
  background-color: ${colors.white};
  padding: 10px;
  border-right: none;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid ${colors.nv80};
  border-bottom: 1px solid ${colors.nv80};
  @media (min-width: ${breakpoints["lg"]}) {
    border: 1px solid ${colors.nv80};
    border-bottom: 1px solid ${colors.nv80};
  }
`;

const initialValues = {
  contractId:"",
  jobId: "",
  title: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  title: Yup.string().max(255).required("Title is required"),
  description: Yup.string().min(1).required("Description is required"),
});

const Summary = forwardRef((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState(JOB_LIST);
  const [initVal, setInitVal] = useState(initialValues);
  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;
  const { forwardRef, useRef, useImperativeHandle } = React;
  
  const extraContext = useContext(UpdateListenerContext);
  let methodType="POST";
  let params={
    employerCode: userContext.user.get.userCode,
  };
  const contractId= initVal.contractId;

  if (contractId && contractId != null) {
    methodType="PUT"
     params = {
      employerCode: userContext.user.get.userCode,
      contractId: contractId ,
    };
  } else {
    methodType="POST"
    params={
      employerCode: userContext.user.get.userCode,
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit,
    }),
    []
  );

  const handleSubmit = (values, actions) => {
    submitSaveSummary(values, actions);
  };

  const submitSaveSummary = (values, actions) => {

    var summaryData = {
      candidateCode: props.cprops.ccode,
      jobId: values.jobId,
      title: values.title,
      description: values.description,
    };
    dispatch(submitFormInfo({ methodType, endPoint:CONTRACT_SUMMARY_URL,formData:summaryData,params,props}));
   
  };


  const state = useSelector(state=>state.contractStepperinfo)


  function handleDescriptionChange(html, values, setValues) {
    values.description = html;
    setValues(values);
  }

  useEffect(() => {
    if (state && state.resource ) {
      setInitVal(
        {
          contractId:state.resource.contractId,
          jobId: state.resource.jobId,
          title: state.resource.title,
          description: state.resource.description,
        }
      )
    }
  }, ["resource" in state]);

  useEffect(() => {
    getJobs();
  }, [extraContext.updateListeners]);



  const getJobs = () => {
    axios({
      method: "GET",
      url: GET_EMPLOYER_JOBS_URL,
      params: {
        userCode: user_code,
      },
    })
      .then((response) => {
        if (response.data) {
          var activeJobs = response.data.filter((activeJob) => {
            if (activeJob.status === "ACTIVE") return activeJob;
          });
          setJobs(activeJobs);
        }
      })
      .catch((error) => {
        console.log("WE DONT GOT THE JOBS");
      });
  };

  const handleJob = (event, values, setValues) => {
    axios({
      method: "GET",
      url: GET_EMPLOYER_JOBS_URL,
      params: {
        userCode: user_code,
      },
    })
      .then((response) => {
        if (response.data) {
          var activeJobs = response.data.filter((activeJob) => {
            if (activeJob.status === "ACTIVE") return activeJob;
          });
          var selectedJob = activeJobs.find((activeJob) => {
            if (activeJob.id.toString() === event.target.value)
              return activeJob;
          });
          values.title = selectedJob.title;
          values.description = selectedJob.description;
          values.jobId = selectedJob.id;
          setJobs(activeJobs);
        }

        setValues(values);
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      validationSchema={validationSchema}
      innerRef={ref}
      onSubmit={(values, actions) => {
        console.log(values);
        submitSaveSummary(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <CSView>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Header variant={"label"} gutterBottom>
              Selected Candidate
            </Header>
            <CSTopView>
              <Avatar src={props.cprops.curl} alt="Certificate" size="60px" />
              <div>
                <Typography variant="body">{props.cprops.cname}</Typography>
                {/* <div style={{ height: "5px" }} /> */}
                <div style={{ height: "3px" }} />
                <Descriptive variant="body2">{props.cprops.ctitle}</Descriptive>
              </div>
            </CSTopView>
            <Header   variant={"label"}>
              Link to Job Post (Optional)
            </Header>
            <GigTextField
              inputLabelText=""
              name="jobList"
              onChange={(e) => {
                handleJob(e, values, setValues);
              }}
              variant="outlined"
              select
              id="jobList"
              SelectProps={{ native: true }}
              value={values.jobList}
              size="small"
              fullwidth={true}
              error={Boolean(touched.jobList && errors.jobList)}
              helperText={touched.jobList && errors.jobList}
            >
              {jobs.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </GigTextField>
            <Header variant={"label"}>Title*</Header>
            <GigTextField
              inputLabelText=""
              name="title"
              id="title"
              value={values.title}
              onChange={handleChange}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              fullwidth={true}
            />
            <Header variant={"label"}>Description*</Header>
            <ReactQuill
                //className={classes.editorlayout}
                //name="description"
                value={values.description}
               // id="description"
                placeholder={"Description*"}
                theme="snow"
                onChange={(e, _, __, editor) => {
                  const text = editor.getText(e).trim().split(" ").length;
                  if (text === "") {
                    values.description="";
                  } else {
                    handleDescriptionChange(e, values, setValues);
                  }
                }}
                error={Boolean(
                 touched.description && errors.description
                )}
                helperText={
                  touched.description && errors.description
                }
               
              />
               {touched.description && errors.description && (
                  <p className={classes.errorText}>{errors.description}</p>
                )}
          </form>
        </CSView>
      )}
    </Formik>
  );
});

export default Summary;

