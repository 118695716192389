import React, { FC, ReactElement, useState } from "react";
import styled from "@emotion/styled";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

import { colors } from "../../theme";
import { Down } from "@icon-park/react";
import Button from "src/designsystem/Core/Button";
import { motion, AnimatePresence } from "framer-motion";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationChip from "src/designsystem/Core/LocationChip";
const GvSkillContainer = styled.div``;

const GvChipContainer = styled.div`
  display: flex;
  gap: 7px 10px;
  flex-wrap: wrap;
  "& .MuiChip-root": {
    borderRadius: 3px,
    fontWeight: 600,
    marginRight: 8px
  }
`;

const useStyles = makeStyles(() => ({
  locationChip: {
    borderRadius: "3px",
    // fontWeight: 600,
    marginRight: 8,
  },
}));

interface CityLocationProps {
  // jobCityLocations: {
  cityLists: string;
  //   }[];
}

const CityLocation: FC<CityLocationProps> = ({ cityLists }) => {
  const classes = useStyles();
  const [location, setLocation] = useState(false);

  const [toggle, setToggle] = useState(false);

  const toggleVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };
  let moreCities = 0;

  let city = cityLists || [];

  let citiesToShow = city;

  if (city.length > 0) {
    moreCities = city.length - 1;
    citiesToShow = city.slice(0, 1);
  }

  return (
    <GvSkillContainer>
      <GvChipContainer>
        {citiesToShow.length > 0 && !!moreCities && moreCities > 0 && (
          <Chip
            label={
              cityLists[0] ? cityLists[0] + " + " + moreCities + " more" : ""
            }
            className={classes.locationChip}
            icon={<LocationOnIcon />}
            sx={{ background: "#EFF3F8" }}
          />
        )}
      </GvChipContainer>
    </GvSkillContainer>
  );
};

export default CityLocation;
