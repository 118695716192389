import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip,
  IconButton,
  Chip,
  Switch
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import UserContext from "src/context/user-context";
import VistasCardNew from "src/components/custom/VistasCardNew";

import axios from "axios";
import Footer from "./Footer";
import { UPDATE_CANDIDATE_PREFERENCE_URL } from "src/utils/constants";
import Autocomplete from "@mui/material/Autocomplete";
import {
  LocationPreference,
  TimingPreference,
  CurrencyFormat,
} from "src/utils/constants";
import TokenService from "src/services/TokenService";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
const useStyles = makeStyles((theme) => ({
  location: {
    width: "50%",
  },
  cardContent: {
    display: "flex",

    padding: 10,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  inputLabel: {
    fontSize: "16px",
    color: "#212830",
    fontWeight: "500",
  },

  preference: {
    backgroundColor: "white",
    margin: "5px 10px 0 0px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      // paddingLeft: 10,
    },
  },

  expectedFees: {
    backgroundColor: "white",
    margin: "5px 10px 0 0px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "220px",
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      // paddingLeft: 10,
    },
  },

  pincode: {
    backgroundColor: "white",
    margin: " 5px 0",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      // paddingLeft: 10,
    },
  },
  distanceInput: {
    backgroundColor: "white",
    margin: "0 0 0 0",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      paddingLeft: 10,
    },
  },
  field: {
    width: "200px",
    margin: "25px",
  },
  workDistPref: {
    marginRight: 0,
    paddingRight: 0,
  },
  workTimePref: {
    backgroundColor: "white",
    margin: "5px 20px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",

      // "&  .MuiInputBase-fullWidth": { //<--- here
      //   width: "330px"
      // }
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      paddingLeft: 10,
    },
  },
  field: {
    width: "200px",
    margin: "25px",
  },
  workDistPref: {
    marginRight: 0,
    paddingRight: 0,
  },
  field: {
    width: "200px",
    margin: "25px",
  },
  workTimePref: {
    backgroundColor: "white",
    margin: "5px 20px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",

      // "&  .MuiInputBase-fullWidth": { //<--- here
      //   width: "330px"
      // }
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      paddingLeft: 10,
    },
  },
}));

const Preference = ({ className, handleBack, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const isAgency = userContext.user.get.isAgency;
  const [popOverMessage, setPopOverMessage] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [address, setAddress] = React.useState("");

  var initialValues = {
    LocationPreference: "Remote",
    hourlyFees: "",
    distanceFromCurrentLocation: 10,
    distancePref: "Doesn't matter",
    availabilityPref: "Flexible",
    currencyCode: "INR",
    jobFormattedLocations: "",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validationSchema = Yup.object().shape({

    jobFormattedLocations: Yup.string()
      .required("Please enter your current location"),

    LocationPreference: Yup.string().required("Please enter your current location"),

    hourlyFees: Yup.number()
      .positive("A positive value is required")
      .min(1, "Please enter a value greater than 0")
      .max(100000, "The maximum allowed hourly rate is 100000")
      .required("Hourly Fees is required"),
    distanceFromCurrentLocation: Yup.number().when("distancePref", {
      is: (distancePref) => {
        return distancePref === "certainDistance";
      },
      then: Yup.number()
        .required("A value is required")
        .min(1, "Must be in range 1 -1000")
        .max(1000),
      otherwise: Yup.number().nullable(true),
    }),
    distancePref: Yup.string().when("LocationPreference", {
      is: (LocationPreference) => {
        return (
          LocationPreference === "Onsite" || LocationPreference === "Hybrid"
        );
      },
      then: Yup.string().required("Distance Preference is required"),
      otherwise: Yup.string().nullable(true),
    }),
    currencyCode: Yup.string().required(),
  });

  async function submitPreferenceDetails(values, actions) {
  
    if (values.distancePref === "Doesn't matter") {
      values.distanceFromCurrentLocation = null;
    }
    
    const mapResults = await geocodeByAddress(values.jobFormattedLocations);

    var candidate = {
      userCode: userContext.user.get.userCode,
      // pinCode: values.pincode,
      expectedFees: values.hourlyFees,
      distancePref: values.distanceFromCurrentLocation ?? null,
      availabilityPref: values.availabilityPref,
      gigProjectType: values.LocationPreference,
      currencyCode: values.currencyCode,
      candidateLocationDetail: mapResults[0],
    };
    axios({
      method: "POST",
      url: UPDATE_CANDIDATE_PREFERENCE_URL,
      data: candidate,
    })
      .then((response) => {
        // var userObj = JSON.parse(localStorage.getItem("auth"));
        // userObj.firstTimeLogin = false;
        //localStorage.setItem("auth", JSON.stringify(userObj));
        localStorage.removeItem("Expertise");
        localStorage.removeItem("Experience");
        localStorage.removeItem("Education");
        //userContext.user.set(userObj);
        TokenService.getRefreshToken()
          .then((response) => {
            const userObj = TokenService.getUser();
            const redirectPath = TokenService.getRedirectPath();
            userContext.user.set(userObj);
            TokenService.removeRedirectPath();
            if (!redirectPath) {
              navigate("/c/dashboard", { replace: true });
            }
            navigate(redirectPath);
          })
          .catch((e) => {
            actions.setSubmitting(false);
          });
      })
      .catch((error) => {
        actions.setSubmitting(false);
        //handleServerResponse(false, error.response.data.error);
      });
  };

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setPopOverMessage(message);
  };

  function handleProjectTypeChange(e, values, setValues) {
    values.gigPrefs.projectPrefs = e.target.value;
    setValues(values);
  }

  function handleHourlyFees(e, values, setValues) {
    values.hourlyFees = e.target.value;
    setValues(values);
  }

  function handleCanWorkFullTimeGigs(e, values, setValues) {
    values.gigPrefs.canWorkFullTimeGigs = e.target.checked ? true : false;
    setValues(values);
  }

  // function handlePinCodeChange(e, values, setValues) {
  //   if (e.target.value <= 8) {
  //     values.currentLocation.pincode = e.target.value;
  //     setValues(values);
  //   }
  // }

  function handleLocationPrefChange(e, values, setValues) {
    e.preventDefault();
    values.LocationPreference = e.target.value;
    setValues(values);
  }

  function handleLocationChange(event) {
    setAddress(event);
  }

  // Log error status and clear dropdown when Google Maps API returns an error.
  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  const fteLabel = { inputProps: { 'aria-label': 'I am open to Full Time Positions' } };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitPreferenceDetails(values, actions);
      }}
    >
      {(props) => {
        let {
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          isSubmitting,
          touched,
          values,
        } = props;
        return (
          <form autoComplete="off" noValidate className={clsx(classes.root)}>
            <VistasCardNew label="Current Location">
              <Grid container spacing={2}>
              <Grid item md={6} sm={6}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Current Location*
                  </InputLabel>
                  <PlacesAutocomplete
                    value={address}
                    onChange={(e) => {
                      handleLocationChange(e);
                    }}
                    onError={onError}
                    // searchOptions={searchOptions}
                    shouldFetchSuggestions={address.length > 3}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <Autocomplete
                          id="tags-filled"
                          options={suggestions.map(
                            (suggestion) => suggestion.description
                          )}
                          value={values.jobFormattedLocations}
                          onChange={(e, val) => {
                            setValues((oldVal) => {
                              var temp = { ...oldVal };
                              temp.jobFormattedLocations = val;
                              return temp;
                            });
                          }}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <div {...getSuggestionItemProps(option)}>
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              </div>
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...getInputProps()}
                              className={classes.pincode}
                              variant="outlined"
                              rows={2}
                              size="small"
                              error={Boolean(
                                touched.jobFormattedLocations &&
                                  errors.jobFormattedLocations
                              )}
                              helperText={
                                touched.jobFormattedLocations &&
                                errors.jobFormattedLocations
                              }
                              placeholder="Please type the name of the area or city"
                            />
                          )}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
              
              </Grid>

            </VistasCardNew>
            <VistasCardNew label="Work Location Preferences">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Work Location Preference*
                  </InputLabel>
                  <TextField
                    className={classes.preference}
                    fullWidth
                    name="LocationPreference"
                    onChange={(e) => {
                      e.preventDefault();
                      handleLocationPrefChange(e, values, setValues);
                    }}
                    required
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    value={values.LocationPreference}
                    size="small"
                  >
                    {LocationPreference.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {(values.LocationPreference === "Onsite" ||
                  values.LocationPreference === "Hybrid") && (
                  <Grid item sm={6} md={6} xs={12}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Work Distance Preference*
                    </InputLabel>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="distancePref"
                        value={values.distancePref}
                        onChange={handleChange}
                      >
                        <div style={{ display: "block", width: "100%" }}>
                          <FormControlLabel
                            value="Doesn't matter"
                            control={<Radio />}
                            label="Doesn't matter"
                          />
                        </div>
                        <div style={{ display: "block", width: "100%" }}>
                          {" "}
                          <FormControlLabel
                            value="certainDistance"
                            control={<Radio />}
                            label=" Within _ kms of my location"
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {values.distancePref === "certainDistance" &&
                  (values.LocationPreference === "Onsite" ||
                    values.LocationPreference === "Hybrid") && (
                    <Grid item sm={6} md={6} xs={12}>
                      <InputLabel shrink className={classes.inputLabel}>
                        Distance*
                      </InputLabel>
                      <TextField
                        fullWidth
                        className={classes.distanceInput}
                        name="distanceFromCurrentLocation"
                        size="small"
                        onChange={handleChange}
                        value={values.distanceFromCurrentLocation}
                        InputProps={{
                          endAdornment: <InputAdornment>Kms</InputAdornment>,
                        }}
                        error={Boolean(
                          // (touched && touched.workExperience &&touched.workExperience[index].companyName) || (errors.workExperience && errors.workExperience[index].companyName)
                          (values.distanceFromCurrentLocation < 1 &&
                            errors.distanceFromCurrentLocation) ||
                            !values.distanceFromCurrentLocation
                        )}
                        helperText={
                          (values.distanceFromCurrentLocation < 1 &&
                            errors.distanceFromCurrentLocation) ||
                          !values.distanceFromCurrentLocation
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            </VistasCardNew>

            <VistasCardNew label="Availability Preferences">
              <Grid container spacing={1}>
                <Grid item md={6} sm={6}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Work Timings Preference*
                    <Tooltip
                      title={
                        <ul>
                          <li>Full Time: Work for atleast 8 hours per day</li>
                          <li>Part Time: Work for 4 hours per day</li>
                          <li>Flexible: Work in any mode</li>
                        </ul>
                      }
                      // placement="top-start"
                    >
                      <IconButton
                        onClick={(e) => {
                          handleAnchorOpen(e, <></>);
                        }}
                        size="large"
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    className={classes.preference}
                    fullWidth
                    name="availabilityPref"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                    value={values.availabilityPref}
                    size="small"
                  >
                    {TimingPreference.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={6} sm={6}>
                  <br/>
                  <FormControlLabel
                    control={
                    <Switch defaultChecked onChange={handleChange} name="openToFTE" />
                    }
                    label="I am open to Full Time Employment"
                  />
                </Grid>
              </Grid>
            </VistasCardNew>
            <VistasCardNew label="Expected Fees">
              <Grid container spacing={0}>
              <Grid item md={1} sm={1}>
                  <InputLabel shrink className={classes.inputLabel} style={{width:"100px"}}>
                    Currency*
                  </InputLabel>
                  <TextField
                    className={classes.preference}
                    fullwidth
                    variant="outlined"
                    name="currencyCode"
                    size="small"
                    onChange={(e) => {
                      e.preventDefault();
                      handleChange(e);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values.currencyCode}
                    style={{width:"80px"}}
                  >
                    {CurrencyFormat.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={5} sm={5} xs={5}>
                  <InputLabel shrink className={classes.inputLabel}>
                    Hourly Fees*
                  </InputLabel>
                  <TextField
                    type="number"
                    className={classes.expectedFees}
                    name="hourlyFees"
                    variant="outlined"
                    required
                    value={values.hourlyFees}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      handleHourlyFees(e, values, setValues);
                    }}
                    size="small"
                    error={Boolean(
                      touched.hourlyFees && errors.hourlyFees
                    )}
                    helperText={
                      touched.hourlyFees && errors.hourlyFees
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">per hour</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </VistasCardNew>

            <Footer
              handleBack={handleBack}
              handleSubmit={handleSubmit}
            ></Footer>
          </form>
        );
      }}
    </Formik>
  );
};

export default Preference;
