import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
import { colors, breakpoints } from "../../designsystem/theme";
import { EditName } from "@icon-park/react";
import Cover from "src/designsystem/Core/Cover";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import LTChip from "../../designsystem/Derivative/LTChip";
import Skills from "../../designsystem/Derivative/Skills";
import HueButton from "src/designsystem/Core/HueButton";
import Bookmark from "../../designsystem/Derivative/Bookmark";
import MessageButton from "../../designsystem/Derivative/MessageButton";
import { CandidateProfileModel } from "src/views/candidate/CandidateProfile/state/models";
import { UploadImageDialog } from "src/components/dialogs/UploadImageDialog";
import UserContext from "src/context/user-context";
import EditIcon from "@mui/icons-material/Edit";
import { MessageContext } from "src/context/message-context";
import profileCompletionBadge from "src/assets/profileCompletionBadge.svg";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import axios from "axios";

import { UPLOAD_AVATAR_URL } from "src/utils/constants";
import { useDispatch } from "react-redux";
import { getCandidateProfileAction } from "src/views/candidate/CandidateProfile/state/reducer";
import {
  bookmarkCandidateAction,
  removeBookmarkCandidateAction,
} from "src/views/recruiter/RecruiterDetails/state/reducer";
import Tooltip from "@mui/material/Tooltip";
import { CurrencyIcon } from "src/utils/constants";
import { addChannel} from "src/utils/messagingUtils";
import { useNavigate
 } from "react-router";
const ProfileCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  margin-top: 10px;
`;
const ProfileLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 5px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 30px;
    grid-template-columns: 200px 1fr 350px;
  }
`;
const ProfileCardLeft = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  margin-top: -85px;
  @media (min-width: ${breakpoints["lg"]}) {
    margin-top: -100px;
  }
`;
const ProfileCardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const ProfileCardRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: ${breakpoints["lg"]}) {
    flex-direction: column;
  }
  gap: 15px;
`;
const ProfileCardSalary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (min-width: ${breakpoints["lg"]}) {
    margin-top: 15px;
  }
`;

const ProfileBadge = styled.div`
  height: 36px;
  // width: 100%;
  paddingleft: 10px;
  paddingright: 10px;
  display: flex;
  row-gap: 10px;
  margin-right: auto;
  margin-top: 15px;

  [theme.breakpoints.down("sm")]: {
    position: "relative";
    top: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  .hoverAvatar {
    display: none;
  }
  &:hover .hoverAvatar {
    display: inline;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    border-radius: 10px;
    background: #6e6e6e7e;
  }

  .hoverAvatar button {
    color: white;
    background: transparent;
    border: 0px;
    position: relative;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
`;

interface CandidateProfileProps {
  candidateProfile?: CandidateProfileModel;
  editable?: boolean;
  profileData: any;
  onClick?: () => void;
  bookmark?: any;
  // addPhone?: boolean;
}

const CandidateProfile: FC<CandidateProfileProps> = ({
  candidateProfile,
  editable,
  profileData,
  onClick,
  bookmark,
  // addPhone,
}) => {
  const dummyHue = 235;
  const [imageOpener, setimageOpener] = useState(false);
  const userContext = useContext(UserContext);
  const messageContext = useContext(MessageContext);
  const userCode = userContext.user.get.userCode;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("toggle in candidateProfile:", bookmark);

  function onBookmarkClick() {
    if (!bookmark) {
      dispatch(
        bookmarkCandidateAction({
          user_code: userCode,
          candidate_id: profileData.id,
        })
      );
    } else {
      dispatch(
        removeBookmarkCandidateAction({
          user_code: userCode,
          candidate_id: profileData.id,
        })
      );
    }
  }

  const findContact = (arr: any, searchKey: any) => {
    return arr.find((a: any) => a.id === searchKey);
  };
  const cb = () => {
    messageContext.addActiveContact(userCode);
  };
  const addContact = (cUserCode: any) => {
    const recUserCode = userContext.user.get.userCode;
    const channel = addChannel(recUserCode, cUserCode);
    navigate("/r/messages/");
  };

  return (
    <ProfileCard>
      <UploadImageDialog
        URL={UPLOAD_AVATAR_URL}
        open={imageOpener}
        setOpen={setimageOpener}
        userCode={userCode}
        refetch={() => {
          // window.location.reload();
          dispatch(getCandidateProfileAction({ user_code: userCode }));
        }}
      />
      <Cover hue={dummyHue} />
      <ProfileLayout>
        <ProfileCardLeft>
          <Wrapper>
            <div style={{ position: "relative" }}>
              <Avatar
                alt={
                  profileData.userProfile.firstName +
                  profileData.userProfile.lastName
                }
                src={profileData.userProfile.profilePicUrl}
                size="125px"
              />

              {editable ? (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                  }}
                >
                  <div className="hoverAvatar">
                    <button
                      onClick={() => {
                        setimageOpener(true);
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <EditIcon />
                      </Tooltip>
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Wrapper>

          <div>
            <Typography variant="h4" gutterBottom>
              {profileData.userProfile.firstName +
                " " +
                profileData.userProfile.lastName}
            </Typography>
            <Typography variant="body">
              <div style={{ display: "flex", alignItems: "center" }}>
                {!!profileData.userProfile.email && (
                  <MailOutlineIcon
                    style={{ color: colors.p60, fontSize: "large" }}
                  />
                )}
                {profileData.userProfile.email}
              </div>
            </Typography>{" "}
            {/* {addPhone ? ( */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {!!profileData.userProfile.mobile && (
                <PhoneOutlinedIcon
                  style={{ color: colors.p60, fontSize: "large" }}
                />
              )}
              {profileData.userProfile.mobile}
            </div>
            {/* ) : (
              <></>
            )} */}
            {/* alpha api doesnt return any such field as userName */}
            <div style={{ marginTop: "15px" }}>
              <LTChip
                location={profileData.gigProjectType}
                timePref={profileData.availabilityPref}
              />
            </div>
            {profileData.profileComplete && (
              <ProfileBadge>
                <img src={profileCompletionBadge} alt={"star badge"} />
              </ProfileBadge>
            )}
          </div>
        </ProfileCardLeft>
        <ProfileCardMiddle>
          <Typography variant="h4">{profileData.title}</Typography>
          <div>
            <Typography variant="label" gutterBottom>
              About Me
            </Typography>
            <Typography variant="body2">{profileData.summary}</Typography>
          </div>
          <Skills skills={profileData.candidateSkills} />
        </ProfileCardMiddle>
        <ProfileCardRight>
          {editable ? (
            <HueButton hue={dummyHue} fullWidth onClick={onClick}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <EditName style={{ display: "grid", placeItems: "center" }} />
                Edit
              </div>
            </HueButton>
          ) : (
            <div style={{ display: "flex", gap: "10px" }}>
              <HueButton hue={dummyHue} fullWidth onClick={onClick}>
                <div
                  style={{
                    width: "120px",
                  }}
                >
                  Hire Me
                </div>
              </HueButton>
              {/* </div> */}
              <Bookmark
                hue={dummyHue}
                toggle={bookmark}
                onClick={onBookmarkClick}
              />
              <MessageButton
                hue={dummyHue}
                onClick={() => {
                  addContact(profileData.userCode);
                }}
              />
            </div>
          )}
          <ProfileCardSalary>
            <Typography variant="h4">
              {profileData.currencyCode === "USD"
                ? CurrencyIcon.USD
                : CurrencyIcon.INR}
              {profileData.expectedFees}/hr
            </Typography>
            <Typography>
              {profileData.totalExperience} years of experience
            </Typography>
          </ProfileCardSalary>
        </ProfileCardRight>
      </ProfileLayout>
    </ProfileCard>
  );
};

type skills = {
  jobPostSkillId: number;
  skillName: string;
  isAdditionalSkill: boolean;
};

const dummySkills: skills[] = [
  {
    jobPostSkillId: 1,
    skillName: "JavaScript",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 2,
    skillName: "HTML",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 3,
    skillName: "React",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 4,
    skillName: "CSS",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 5,
    skillName: "NodeJS",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 6,
    skillName: "Docker",
    isAdditionalSkill: false,
  },
  {
    jobPostSkillId: 7,
    skillName: "Golang",
    isAdditionalSkill: false,
  },
  // {
  //   jobPostSkillId: 8,
  //   skillName: "Java",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 9,
  //   skillName: "C#",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 10,
  //   skillName: "AWS",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 11,
  //   skillName: "NodeJS",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 12,
  //   skillName: "Docker",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 13,
  //   skillName: "Golang",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 14,
  //   skillName: "Java",
  //   isAdditionalSkill: true,
  // },
  // {
  //   jobPostSkillId: 15,
  //   skillName: "C#",
  //   isAdditionalSkill: true,
  // },
];

export default CandidateProfile;
