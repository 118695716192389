import React, { FC } from "react";
import { Link, NavLink as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import styled from "@emotion/styled";


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    marginLeft: "8px",
  },
  arrow: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[0.5],
    "&::before": {
      border: "1px solid #E6E8ED",
    },
  },
}))(Tooltip);

interface NavItemProps {
  href: string
  Icon: FC
  IconActive: FC
  title: string
}

const NavItem: FC<NavItemProps> = ({ href, Icon, IconActive, title }) => {
  const location = useLocation();

  const GvIcon = styled(Icon)`
    margin: 5px;
    cursor: pointer;
    border-radius: 13.5px;
    &:hover {
      box-shadow: rgba(33, 35, 38, 0.2) 0px 15px 15px -10px;
      transform: scale(1.1);
    }
    transition: all 0.3s ease-in-out;
  `
  const GvIconActive = styled(IconActive)`
  margin: 5px;
  cursor: pointer;
  border-radius: 13.5px;
  transform: scale(1.1);
  box-shadow: rgba(33, 35, 38, 0.2) 0px 15px 15px -10px;
  transition: all 0.3s ease-in-out;
`

  return (
    <Link to={href} key={title}>
      <LightTooltip title={title} arrow placement="right">
        {location.pathname == href ? (
          <GvIconActive />
        ) : (
          <GvIcon />
        )}
      </LightTooltip>
    </Link>
  );
};

export default NavItem;
