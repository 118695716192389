import React from "react";
import Carousel from "react-material-ui-carousel";
import { Link as RouterLink } from "react-router-dom";
import JobSummaryCard from "src/views/recruiter/RecruiterJobView/JobSummaryCard";
import GigSummaryCard from "src/views/recruiter/RecruiterGigs/GigSummaryCard";

import { Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  floatRight: {
    float: "right",
  },
}));

const JobSummaryPair = (props) => {
  const { jobPosts, getCandidateProfile } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs>
        <JobSummaryCard
          jobPost={jobPosts[0]}
          getCandidateProfile={getCandidateProfile}
        />
      </Grid>
      <Grid item xs>
        {jobPosts[1] ? (
          <JobSummaryCard
            jobPost={jobPosts[1]}
            getCandidateProfile={getCandidateProfile}
          />
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
};

const GigSummaryPair = (props) => {
  const { gigs, getCandidateProfile } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs>
        <GigSummaryCard
          gig={gigs[0]}
          getCandidateProfile={getCandidateProfile}
        />
      </Grid>
      <Grid item xs>
        {gigs[1] ? (
          <GigSummaryCard
            gig={gigs[1]}
            getCandidateProfile={getCandidateProfile}
          />
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
};

function DashboardCarousel(props) {
  const { jobs, gigs, getCandidateProfile, type } = props;
  let items = [];
  const classes = useStyles();
  let showAllLink = "";

  if (type === "jobs") {
    for (let i = 0; i < jobs.length; ) {
      const jobOne = jobs[i++];
      const jobTwo = jobs[i++];
      items.push(
        <JobSummaryPair
          key={i}
          jobPosts={[jobOne, jobTwo]}
          getCandidateProfile={getCandidateProfile}
        />
      );
    }
    showAllLink = "/r/jobs";
  } else if (type === "gigs") {
    for (let i = 0; i < gigs.length; ) {
      const gigOne = gigs[i++];
      const gigTwo = gigs[i++];
      items.push(
        <GigSummaryPair
          key={i}
          gigs={[gigOne, gigTwo]}
          getCandidateProfile={getCandidateProfile}
        />
      );
    }
    showAllLink = "/r/gigs";
  }

  return (
    <>
      <Carousel interval={4000}>{items}</Carousel>
      <RouterLink to={showAllLink}>
        <Button color="primary" className={classes.floatRight}>
          Show All
          <PlayArrowIcon color="primary"></PlayArrowIcon>
        </Button>
      </RouterLink>
    </>
  );
}

export default DashboardCarousel;
