export const colors: { [key: string]: string } = {
  //Primary
  p100: "#fcfcff",
  p90: "#e8f1ff",
  p80: "#d0e4ff",
  p70: "#9ccaff",
  p60: "#64b0ff",
  p50: "#4695e2",
  p40: "#207ac6",
  p30: "#0061a6",
  p20: "#00497f",
  p10: "#00325a",
  p0: "#001d36",

  //Secondary
  s100: "#f4fff1",
  s99: "#F4FFF1",
  s90: "#c4ffcd",
  s80: "#7efc9d",
  s70: "#60de83",
  s60: "#42c26b",
  s50: "#1ba653",
  s40: "#008a3e",
  s30: "#006d2f",
  s20: "#005322",
  s10: "#003915",
  s0: "#002109",

  //Tertiary
  t100: "#fffbfe",
  t90: "#f6edff",
  t80: "#eaddff",
  t70: "#d1bcff",
  t60: "#b99bff",
  t50: "#a078ff",
  t40: "#855ce8",
  t30: "#6b40cd",
  t20: "#5321b5",
  t10: "#3a0094",
  t0: "#21005d",

  //Error
  e100: "#fcfcfc",
  e90: "#ffede9",
  e80: "#ffdad4",
  e70: "#ffb4a9",
  e60: "#ff897a",
  e50: "#ff5449",
  e40: "#dd3730",
  e30: "#ba1b1b",
  e20: "#930006",
  e10: "#680003",
  e0: "#410001",

  //Neutral Variant
  nv100: "#fcfcff",
  nv90: "#f7faff",
  nv80: "#DADFEB",
  nv70: "#c3c7d0",
  nv60: "#a7abb3",
  nv50: "#8d9199",
  nv40: "#73777f",
  nv30: "#5a5f66",
  nv20: "#43474e",
  nv10: "#2c3137",
  nv0: "#171c22",

  //Neutral
  n100: "#F9F9F9",
  n90: "#f3f3f3",
  n80: "#e5e5e5",
  n70: "#c7c6ca",
  n60: "#acabaf",
  n50: "#919094",
  n40: "#77777b",
  n30: "#5e5e61",
  n20: "#464649",
  n10: "#2f3033",
  n0: "#1b1b1d",

  white: "#ffffff",
  black: "#000000",
  text: "#1A2740",
};

export const breakpoints: { [key: string]: string } = {
  sm: "600px",
  lg: "1240px",
  md: "1024px",
};

interface Typography {
  component: React.ElementType;
  size: string;
  weight: number;
  color: string;
}

export const typography: { [key: string]: Typography } = {
  h1: {
    component: "h1",
    size: "2.566rem",
    weight: 400,
    color: colors.text,
  },
  h2: {
    component: "h2",
    size: "2.027rem",
    weight: 400,
    color: colors.text,
  },
  h3: {
    component: "h3",
    size: "1.602rem",
    weight: 400,
    color: colors.text,
  },
  h4: {
    component: "h4",
    size: "1.266rem",
    weight: 500,
    color: colors.text,
  },
  h5: {
    component: "h5",
    size: "1.125rem",
    weight: 600,
    color: colors.text,
  },
  label: {
    component: "p",
    size: "1rem",
    weight: 500,
    color: colors.n40,
  },
  body: {
    component: "p",
    size: "1rem",
    weight: 400,
    color: colors.text,
  },
  body2: {
    component: "p",
    size: "0.889rem",
    weight: 400,
    color: colors.text,
  },
  button: {
    component: "p",
    size: "1rem",
    weight: 600,
    color: colors.white,
  },
};

export const gutterBottom: string = "0.35em";
