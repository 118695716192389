import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "src/components/Avatar";

const useStyles = makeStyles({
  contact: {
    display: "flex",
    height: "65px",
    justifyContent: "space-between",
    borderBottom: "1px solid #e7ebee",
    position: "relative",
    alignItems: "center",
    padding: "10px 20px",
    "&:hover": {
      background: "#f1f4f6",
      cursor: "pointer",
    },
    "& .floatingImg": {
      width: "40px",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      left: "12px",
      border: "3px solid #fff",
    },
    "& p": {
      paddingLeft: "10px",
      textTransform: "capitalize",
      fontSize: "0.92rem",
    },
    "& p strong": {
      display: "block",
      fontWeight: 600,
      fontSize: "14px",
      color: "#597a96",
    },
    "& .status": {
      background: "#26c281",
      borderRadius: "50%",
      width: 9,
      height: 9,
    },
    "& .status.away": { background: "#ffce54" },
    "& .status.inactive": { background: "#eaeef0" },
  },
  unread: {
    color: "#000",
    fontWeight: "bold",
  },
  email: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#aab8c2",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
});

const ContactsListItem = (props) => {
  const classes = useStyles();
  const { contact, onContactSelection } = props;

  return (
    <div
      className={classes.contact}
      onClick={(e) => onContactSelection(contact)}
    >
      <div className={classes.flex}>
        <Avatar
          URL={contact.profilePicUrl}
          height={40}
          width={40}
          firstName={contact.firstName}
          lastName={contact.lastName}
        />
        <p>
          {contact.name}
          {/*<span className={classes.email}>{contact.email}</span>*/}
          <span className={classes.unread}>{contact.unReadMessage}</span>
        </p>
      </div>
      <div className={`status ${contact.status}`}></div>
    </div>
  );
};

export default ContactsListItem;
