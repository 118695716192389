import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import ExpertiseForm from "./ExpertiseForm";
import WorkExperienceForm from "./WorkExperienceForm";
import EducationForm from "./EducationForm";
import PreferencesForm from "./PreferencesForm";
import UserContext from "src/context/user-context";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(1) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const indiSteps = [
  "My Expertise",
  "Work Experience",
  "Education",
  "Preferences",
];

const agencySteps = ["My Expertise", "Work Experience", "Preferences"];

export default function PortfolioStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const userContext = useContext(UserContext);
  const isAgency = userContext.user.get.isAgency;

  var steps = isAgency ? agencySteps : indiSteps;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    if (!isAgency) {
      switch (step) {
        case 0:
          return <ExpertiseForm handleNext={handleNext} />;
        case 1:
          return (
            <WorkExperienceForm
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case 2:
          return (
            <EducationForm handleNext={handleNext} handleBack={handleBack} />
          );
        case 3:
          return <PreferencesForm handleBack={handleBack} />;
        default:
          throw new Error("Unknown step");
      }
    } else {
      switch (step) {
        case 0:
          return <ExpertiseForm handleNext={handleNext} />;
        case 1:
          return (
            <WorkExperienceForm
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case 2:
          return <PreferencesForm handleBack={handleBack} />;
        default:
          throw new Error("Unknown step");
      }
    }
  };

  return (
    <>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={0}>
          <Typography component="h1" variant="h4" align="center">
            Welcome to GigVistas. Lets build your portfolio
          </Typography>
          {!isMobile ? (
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Stepper activeStep={activeStep} className={classes.stepper}>
              <Step key={steps[activeStep]}>
                <StepLabel>{steps[activeStep]}</StepLabel>
              </Step>
            </Stepper>
          )}
        </Paper>
        <>{getStepContent(activeStep)}</>
      </main>
    </>
  );
}
