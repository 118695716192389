import React, { forwardRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Typography from "src/designsystem/Core/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import { UpdateListenerContext } from "src/context/update-listener-context";
import { CONTRACT_MILESTONE_URL } from "src/utils/constants";
import HueButton from "src/designsystem/Core/HueButton";
import { Plus } from "@icon-park/react";
import AddMilestoneDialog from "./AddMilestoneDialog";
import { useDispatch, useSelector } from "react-redux";
import ContractMileStoneEditCard from "src/derivedComponents/ContractMileStoneEditCard";

const CSView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;
const CSCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 3fr 7fr;
  }
`;
const CSLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  padding: 15px;
`;
const CSRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 15px;
  }
`;

const CSBCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  border: 1px solid ${colors.nv80};
  align-items: center;
  grid-template-columns: 1fr;
`;
const CSBLeft = styled.div`
  display: grid;
  gap: 15px;

  grid-template-columns: 1fr;
  padding: 15px;
`;
const Header = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
`;

const CSBRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 200px 1fr;
    gap: 30px;
    padding: 15px;
  }
`;
const initialValues = {
  contractId: "",
  rateUnit: "",
  currencyCode: "",
  fees: "",
  duration: "",
  timeUnit: "",
};

const OutComeBased = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useImperativeHandle } = React;
  const [milestone, setMilestone] = useState([]);
  const [initVal, setInitVal] = useState(initialValues);
  const [limit, setLimit] = useState(10);
  const extraContext = useContext(UpdateListenerContext);
  const contractId = initVal.contractId;

  useEffect(() => {
    getMilestone();
  }, [contractId]);

  const getMilestone = () => {
    if (contractId) {
      axios({
        method: "GET",
        url: CONTRACT_MILESTONE_URL,
        params: {
          contractId: contractId,
        },
      })
        .then((response) => {
          setMilestone(response.data);
        })
        .catch((error) => {
          console.log("WE DONT GOT THE getMilestone");
        });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit,
    }),
    []
  );

  const handleSubmit = (values, actions) => {
    submitSaveMileStone(values, actions);
  };

  const submitSaveMileStone = (values, actions) => {
    navigate("/r/contract", { replace: true });
    window.location.reload();
  };

  const state = useSelector((state) => state.contractStepperinfo);

  useEffect(() => {
    if (state && state.resource) {
      setInitVal({
        contractId: state.resource.contractId,
      });
    }
  }, ["resource" in state]);

  const validationSchema = Yup.object().shape({});
  const dummyHue = 222;
  const [open, setOpen] = useState(false);
  
  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitSaveMileStone(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CSView>
           
            <CSBCard>
              {milestone.map((ContractMilestones,index) => (
                <ContractMileStoneEditCard
                  contractMilestoneData={ContractMilestones}
                  index={index}
                  contractId={contractId}
                  refetch={() => {
                    dispatch(getMilestone());
                  }}
                />
              ))}
            </CSBCard>

            <HueButton
              hue={dummyHue}
              fullWidth
              onClick={() => {
                setOpen(true);
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Plus style={{ display: "grid", placeItems: "center" }} />
                Add Milestone
              </div>
            </HueButton>
            <AddMilestoneDialog
              // id={id}
              open={open}
              setOpen={setOpen}
              addFlag={true}
              refetch={() => {
                dispatch(getMilestone());
              }}
            />
          </CSView>
        </form>
      )}
    </Formik>
  );
});

export default OutComeBased;
