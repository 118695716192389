import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ConversationWindow from "./ConversationWindow";

const useStyles = makeStyles({
  chatConversation: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
});

const ConversationPane = (props) => {
  const classes = useStyles();
  const {
    activeConversations,
    closeConversation,
    sendMessage,
    readMessage,
    me,
  } = props;

  return (
    <React.Fragment>
      {activeConversations.map(function (conversation, index) {
        return (
          <div
            key={conversation.contact.id}
            className={classes.chatConversation}
            style={{
              position: "fixed",
              bottom: 0,
              right: (index + 1) * 300,
            }}
          >
            <ConversationWindow
              me={me}
              key={conversation.contact.id}
              conversation={conversation}
              closeConversation={() =>
                closeConversation(conversation.contact.id)
              }
              contact={conversation.contact}
              sendMessage={sendMessage}
              readMessage={readMessage}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ConversationPane;
