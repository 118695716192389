import styled from "@emotion/styled";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Typography from "src/designsystem/Core/Typography";
import { breakpoints } from "src/designsystem/theme";

const Container = styled.div`
    display: grid;
    min-height: 100vh;
    margin: 0px;
    grid-template-columns: 1fr;
    @media (min-width: ${breakpoints['lg']}) {
      grid-template-columns: 60% 40%;
    }
`
const Hero = styled.div`
    background-image: url(/static/images/loginBG.svg);
    background-repeat: no-repeat;
    background-size: 125vh;
    background-position: right 20% top 25%;
    padding: 30px 30px 0px 30px;
    @media (min-width: ${breakpoints['lg']}) {
      background-position: left top;
      padding: 30px;
    }
`
const HeroText = styled(Typography)`
    margin: 35px 0px;
    font-size: 1.8rem;
    font-weight: 400;
    @media (min-width: ${breakpoints['lg']}) {
      font-size: 3rem;
      margin-top: 30vh;
    }
`
const Content = styled.div`
    display: grid;
    align-items: center;
    background: linear-gradient(90deg, rgba(23,141,219,1) 0%, rgba(4,119,195,1) 100%);
    color: white;
    padding: 30px;
    @media (min-width: ${breakpoints['lg']}) {
      padding: 80px;
    }
`

const AuthLayout: FC = () => {
  let message = "Say hello to the new way of work";

  return (
    <Container>
      <Hero>
        <img src="/static/logo-full.svg" />
        <HeroText variant="h1">{message}</HeroText>
      </Hero>

      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default AuthLayout;
