import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Typography, Link, Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ContactsList from "./Contacts/ContactsList";
import ConversationPane from "./ActiveConversations/ConversationPane";

import UserContext from "src/context/user-context";
import webSocketUtil from "src/utils/WebSocketUtil";
import MessageService from "../MessageService";

import { MESSAGES_URL, UNREAD_MESSAGES_URL } from "src/utils/constants";
import { MessageContext } from "src/context/message-context";

const useStyles = makeStyles({
  chatWidget: {},
  chatHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 5px",
  },
  contactsListContainer: {
    position: "relative",
    background: "#fff",
    marginTop: "10px",
    borderRadius: 6,
  },
  conversationPaneContainer: {
    background: "#fff",
    borderRadius: 6,
    width: 370,
  },
});

const ChatWidget = (props) => {
  const classes = useStyles();

  const preventDefault = (event) => event.preventDefault();
  const userContext = useContext(UserContext);
  const messageContext = useContext(MessageContext);

  const [contacts, setContacts] = React.useState([]);
  const [connection, setConnection] = React.useState(null);
  //const [messages, setMessages] = React.useState([]);
  const [activeConversations, setActiveConversations] = React.useState([]);
  const [me, setMe] = React.useState(
    MessageService.getUserObject(userContext.user.get)
  );

  useEffect(() => {
    setMe((prevMe) => {
      return MessageService.getUserObject(userContext.user.get);
    });
    setConnection((prevConnection) => messageContext.connectionWs);
    const contactsData = messageContext.contacts;
    if (contactsData.length > 0) {
      axios({
        method: "GET",
        url: UNREAD_MESSAGES_URL + "?user=" + me.id,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.messages &&
            response.data.messages.length > 0
          ) {
            setContacts((prevContacts) => {
              let newContacts = MessageService.appendUnreadMessagesToContacts(
                response,
                [...contactsData],
                me
              );
              return newContacts;
            });
          } else {
            setContacts((prevContacts) => contactsData);
          }
        })
        .catch((error) => {
          console.log(error);
          //hacky fix till the API is implemented
          setContacts((prevContacts) => {
            return contactsData;
          });
        });
    }
    messageContext.addMessageHandler(onmessage);
    return () => {
      messageContext.addMessageHandler(null);
    };
  }, [messageContext.contacts]);

  useEffect(() => {
    const findContact = (arr, searchKey) => {
      return arr.find((a) => a.id === searchKey);
    };
    const activeContact = messageContext.activeContact;
    const updatedContacts = messageContext.contacts;
    if (activeContact != null) {
      const foundContact = findContact(updatedContacts, activeContact);
      handleContactSelection(foundContact);
    }
  }, [messageContext.activeContact]);

  const handleContactSelection = (contact) => {
    let alreadyOpened = activeConversations.some(
      (conversation) => conversation.contact.id === contact.id
    );

    if (
      contact &&
      contact.id &&
      activeConversations.length < 3 &&
      !alreadyOpened
    ) {
      axios({
        method: "GET",
        url: MESSAGES_URL + "?user=" + me.id + "&contact=" + contact.id,
      })
        .then((response) => {
          contact.messages = [];
          if (
            response &&
            response.data &&
            response.data.messages &&
            response.data.messages.length > 0
          ) {
            contact = MessageService.appendMessagesToContact(
              response,
              contact,
              me
            );
          }
          setActiveConversations([
            ...activeConversations,
            {
              contact: contact,
            },
          ]);
        })
        .catch((err) => {
          contact.messages = [];
          //contact = MessageService.appendMessagesToContact(response, contact, me)
          console.log(contact);
          setActiveConversations([
            ...activeConversations,
            {
              contact: contact,
            },
          ]);
        });
    }
  };

  const closeConversation = (conversationId) => {
    let currentActiveConversation = [...activeConversations];
    messageContext.addActiveContact(null);

    currentActiveConversation.splice(
      currentActiveConversation.findIndex(
        (conversation) => conversation.contact.id === conversationId
      ),
      1
    );
    setActiveConversations(currentActiveConversation);
  };

  const onmessage = (message) => {
    if (message.sender === me.id || message.receiver === me.id) {
      setActiveConversations((prevActiveConversations) => {
        let updatedConversations = [...prevActiveConversations];
        setContacts((prevContacts) => {
          updatedConversations = MessageService.getUpdatedConversations(
            message,
            prevContacts,
            updatedConversations,
            me
          );
          return prevContacts;
        });
        return updatedConversations;
      });
    }
  };

  const handleMessageNotification = (message) => {
    console.log(
      "IN Progress - Handling of notification on message from contact. Message is " +
        message
    );
  };
  const sendMessage = (contactID, message) => {
    webSocketUtil.sendMessage(me.id, contactID, message, connection);
  };
  const readMessage = (contactID) => {
    let currentContact = contacts.find((contact) => contact.id == contactID);
    if (currentContact.unReadMessage && currentContact.unReadMessageTime > 0) {
      currentContact.unReadMessage = "";
      currentContact.unReadMessageTime = -1;
      webSocketUtil.readMessage(contactID, me.id, connection);
    }
  };

  return (
    <div className={classes.chatWidget}>
      <div className={classes.chatHeader}>
        {/* <Typography variant="body1" style={{ paddingLeft: "10px" }}>
          Messages
        </Typography> */}
      </div>
      <div className={classes.conversationPaneContainer}>
        <ConversationPane
          activeConversations={activeConversations}
          me={me}
          closeConversation={closeConversation}
          sendMessage={sendMessage}
          readMessage={readMessage}
        />
      </div>
      <div className={classes.contactsListContainer}>
        <ContactsList
          contacts={contacts}
          onContactSelection={handleContactSelection}
          me={me}
        />
      </div>
    </div>
  );
};

export default ChatWidget;
