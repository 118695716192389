import React, { useState, useContext } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../designsystem/theme";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import AcademicIcon from "src/assets/newProfilePlaceholderIcons/educationIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import UserContext from "src/context/user-context";
import { Education } from "src/views/candidate/CandidateProfile/state/models";
import EducationEditDialog from "src/views/candidate/CandidateProfile/EditDialogs/EducationEditDialog";
import { useDispatch } from "react-redux";
import { getCandidateProfileAction } from "src/views/candidate/CandidateProfile/state/reducer";

const GvEducationCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 0.7fr;
  }
`;
const GvGigLeft = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 90px 1fr;
  padding: 20px;
  border-bottom: 1px solid ${colors.nv80};
  border-right: none;
  @media (min-width: ${breakpoints["lg"]}) {
    border-right: 1px solid ${colors.nv80};
    border-bottom: none;
  }
`;
const GvGigRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 15px 15px 5px 15px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 200px;
    gap: 5px;
    padding: 15px 10px 15px 15px;
  }
`;

const Degree = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
`;

const Major = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 500;
  color: #8d9199;
`;

interface EducationProps {
  data: Education;
  editable?: boolean;
}

const EducationDetail: React.FC<EducationProps> = ({ data, editable }) => {
  const [open, setOpen] = useState(false);
  const userContext = useContext<any>(UserContext);
  const user: any = userContext.user;
  const dispatch = useDispatch();
  return (
    <GvEducationCard>
      <GvGigLeft>
        <Avatar src={AcademicIcon} alt="Education" size="60px" />
        <div>
          <Typography variant="body">{data.college}</Typography>
          <div style={{ height: "5px" }} />
          <Degree variant="label">{data.degree}</Degree>
          <div style={{ height: "3px" }} />
          <Major variant="body2">{data.major}</Major>
        </div>
      </GvGigLeft>
      <GvGigRight>
        <div style={{ minWidth: "185px" }}>
          <Typography variant="body">
            Completed in year {data.yearOfCompletion}
          </Typography>
        </div>
        {editable ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <EducationEditDialog
              open={open}
              setOpen={setOpen}
              info={data}
              addFlag={false}
              user={user}
              refetch={() => {
                // window.location.reload();
                dispatch(
                  getCandidateProfileAction({ user_code: user.get.userCode })
                );
              }}
            />

            <IconButton
              aria-label="edit"
              onClick={() => {
                setOpen(true);
              }}
              size="small"
              style={{
                width: "30px",
                borderRadius: "100px",
              }}
            >
              <Tooltip title="Edit" arrow>
                <EditIcon />
              </Tooltip>
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </GvGigRight>
    </GvEducationCard>
  );
};

export default EducationDetail;
