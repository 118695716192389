import React, { FC } from "react";
import Page from "src/components/Page";
import { useParams } from "react-router-dom";

import JobDetailView from "./JobDetailView";

const JobIdDetails: FC<{pageTitle:string}> = (props) => {

  let { jobId } = useParams();

  return (
    <Page title={props.pageTitle}>
        <JobDetailView jobID={parseInt(jobId!, 10)} />
    </Page>
  );
};

export default JobIdDetails;
