import React, { FC, useState, useEffect, useContext } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import MessageButton from "../../designsystem/Derivative/MessageButton";
import Bookmark from "../../designsystem/Derivative/Bookmark";

import {
  bookmarkToEmployerAction,
  removeBookmarkEmployerAction,
  shortlistCandidateAction,
  removeShortlistCandidateAction,
  getJobApplicantsAction
} from "src/views/recruiter/RecruiterJobView/state/RecuiterReducers";
import { Link } from "react-router-dom";
import AddTaskButton from "../../designsystem/Derivative/AddTaskButton";
import profileCompletionBadge from "src/assets/profileCompletionBadgeMini.svg";
import Chip from "src/designsystem/Core/Chip";
import { MessageContext } from "src/context/message-context";
import { useDispatch, useSelector } from "react-redux";
import { CandidateSummaryResponse } from "src/views/recruiter/RecruiterJobView/state/models";

interface CandidateProps {
  applicantInfo: any;
  user_code: string;
  jobID: number;
  refetch: any;
}

const AppliedCandidate: FC<CandidateProps> = ({
  applicantInfo,
  jobID,
  user_code,
  refetch
}) => {
  const dispatch = useDispatch();
  // const [filterAppliedCandidates, setAppliedCandidatesToggle] = React.useState([]);
  const candidateDetail = "/r/candidate/" + user_code;
//   useEffect(() => {
      
//     if (applicantInfo && applicantInfo.candidates) {
//       var filterNonShortlistedAppliedCandidates =
//         applicantInfo.candidates.filter(
//           (
//             filterNonShortlistedAppliedCandidate: CandidateSummaryResponse
//           ) => {
//             if (!filterNonShortlistedAppliedCandidate.shortListed)
//               return filterNonShortlistedAppliedCandidate;
//           }
//         );
//       setAppliedCandidatesToggle(filterNonShortlistedAppliedCandidates);
//     }
  
// }, [applicantInfo.candidates]);

   const [pageState, setPageState] = React.useState(
     {
      isLoading:false,
      data:applicantInfo.candidates,
      total:applicantInfo.numTotalRecordsFound,
      page:applicantInfo.currentPage,
      pageSize:50
     });

     useEffect(() => {
       
      setPageState(old=>({
        isLoading:false,
        data:applicantInfo.candidates,
        total:applicantInfo.numTotalRecordsFound,
        page:applicantInfo.currentPage,
        pageSize:50
      }))
    }, [applicantInfo.candidates,pageState.page,pageState.pageSize]);

   

  function reload() {
    setTimeout(() => {
      refetch();
    }, 500);
  }

  const handlePageChange = (page_Number:number) => {
    
    dispatch(getJobApplicantsAction({ jobID, user_code ,page_Number:page_Number}));
  
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 160,

      renderCell: (params) => {
        return (
          <div>
            <Link to={"/r/candidate/" + params.row.userCode}>
              <u>{params.row.name}</u>
         
            </Link>
          </div>
        );
      },
    },
    {
      field: "availabilityPref",
      headerName: "Time Preference",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            <Chip hue={"#eaddff"} children={params.row.availabilityPref} />
          </div>
        );
      },
    },
    {
      field: "gigProjectType",
      headerName: "Location Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div>
            <Chip hue={"#7efc9d"} children={params.row.gigProjectType} />
          </div>
        );
      },
    },
    {
      field: "hasFiles",
      headerName: "Has Attachments",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div >
          {params.row && params.row.hasFiles ? "Yes" : "No"}  
          </div>
        );
      },
    },
    {
      field: "goodFit",
      headerName: "Good Fit",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <div >
          {params.row && params.row.goodFit ? "Yes" : "No"}  
          </div>
        );
      },
    },

    {
      field: "expectedFees",
      headerName: "Expected Fees",
      
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <div >
          {params.row && params.row.expectedFees !== "null" ? params.row.expectedFees : "-" }  
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      maxWidth: 300, 
      renderCell: (params) => {
        // const [hasBookMarked, setToggle] = useState(false);
        const hasBookMarkedState =
          params.row && params.row.bookmarked ? true : false;
        const hasSelectedCandidateState =
          params.row && params.row.shortListed ? true : false;

        const candidateID: string = params.row.id;
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              cursor: "pointer",
              display: "grid",
              gridTemplate: "1fr/1fr 1fr 1fr",
              gap: "10px",
              
            }}
          >
             <div
              style={{    alignItems: "center",
              justifyContent: "center",
              display: "inline-grid"
             }}
              
            >
            <MessageButton
            fullWidth={true}
              onClick={() => {
                addContact(params.row.userCode);
              }}
            />
            </div>
            <div
              style={{    alignItems: "center",
              justifyContent: "center",
              display: "inline-grid",
              padding: '0.4em'
             }}
              
            >
            {!hasBookMarkedState ? (
              <Bookmark
              fullWidth={true}
                toggle={hasBookMarkedState}
                onClick={() => {
                  dispatch(
                    bookmarkToEmployerAction({ candidateID, user_code })
                  );
                  reload();
                }}
              />
            ) : (
              <Bookmark
              fullWidth={true}
                toggle={true}
                onClick={() => {
                  dispatch(
                    removeBookmarkEmployerAction({ candidateID, user_code })
                  );
                  reload();
                }}
              />
            )}
            </div>
            <div
              style={{    alignItems: "center",
              justifyContent: "center",
              display: "inline-grid"
             }}
              
            >
            {!hasSelectedCandidateState ? (
              <AddTaskButton
              fullWidth={true}
                toggle={hasSelectedCandidateState}
                onClick={() => {
                  dispatch(
                    shortlistCandidateAction({ candidateID, jobID })
                  );
                  reload();
                }}
              />
            ) : (
            
              <AddTaskButton
                toggle={true}
                fullWidth={true}
                onClick={() => {
                  dispatch(
                    removeShortlistCandidateAction({ candidateID, jobID })
                  );
                  reload();
                }}
              />
             
            )}
             </div>
          </div>
        );
      },
    },
  ];

  const messageContext = useContext(MessageContext);

  const addContact = (userCode: string) => {
    const contactsCopy = messageContext.contacts;
    const foundContact = findContact(contactsCopy, userCode);

    if (!foundContact) {
      messageContext.addContact(userCode);
      messageContext.addActiveContact(userCode);
    } else {
      messageContext.addActiveContact(userCode);
    }
  };

  const findContact = (arr: Array<any>, searchKey: string): string => {
    return arr.find((a) => a.id === searchKey);
  };
   //https://github.com/AdeleD/react-paginate/issues/152
  return (
<div style={{ display: 'flex', height: '100%' }}>
  <div style={{ flexGrow: 1 }}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rows={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        rowsPerPageOptions={[50]}
        pagination
        page={pageState.page}
        pageSize={pageState.pageSize}
        paginationMode="server"
        onPageChange={(e) => {
           handlePageChange(e);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageState(old=>({
            ...old,  
            pageSize:newPageSize
          }))
       }}
        columns={columns}
      />
    </div>
    </div>
  );
};

export default AppliedCandidate;
