import React, { FC, ReactElement } from "react";
import styled from '@emotion/styled'
import { breakpoints, colors, typography } from '../../theme'
import Typography from "../Typography";

const GvCard = styled.div`
background-color: ${colors.white};
border: 1px solid ${colors.nv80};
font-family: Inter, sans-serif;
border-radius: 8px;
padding: 20px 15px 15px 15px;
@media (min-width: ${breakpoints['lg']}) {
    padding: 25px 30px 30px 30px;
}
`

const GvCardTitle = styled.div`
border-bottom: 1px solid ${colors.nv70};
font-weight: 600;
`

const GvCardBody = styled.div`
padding-top: 15px;
font-weight: ${typography['body2'].weight};
font-size: ${typography['body2'].size};
color: ${typography['body2'].color};
line-height: 1.35em;
`

const Title = styled(Typography)`
display: inline-block;
border-bottom: 2px solid ${colors.p40};
padding-bottom: 15px;
padding-right: 20px;
margin-bottom: -1.5px;
`

interface CardProps {
    children: string | ReactElement
    title: string
}


const Card: FC<CardProps> = ({ children, title }) => {
    return (
        <GvCard>
            <GvCardTitle>
                <Title variant='h5'>
                    {title}
                </Title>
            </GvCardTitle>
            <GvCardBody>
                {children}
            </GvCardBody>
        </GvCard>
    )
}

export default Card