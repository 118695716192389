import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Button,
  Typography,
  Chip,
  Link as MaterialLink,
  Card,
  CardContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { Link } from "react-router-dom";
import axios from "axios";
import UserContext from "src/context/user-context";
import {
  JOB_APPLICATION_URL,
  CREATE_JOB_BOOKMARK,
  REMOVE_JOB_BOOKMARK,
} from "src/utils/constants";
import IconButton from "@mui/material/IconButton";
import Avatar from "src/components/Avatar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FullscreenExit } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#1a2740",
  },
  logo: {
    height: "100px",
    width: "100%",
    marginTop: "10px",
  },
  name: {
    margin: "7px 0 12px -2px",
    fontSize: "1.8rem",
    color: "black",
  },
  textBold: {
    fontSize: 16,
    fontWeight: "bold",
  },
  textMedium: {
    fontSize: 16,
  },
  textSmall: {
    fontSize: 14,
  },
  textSummary: {
    width: "50%",
  },
  textLabelMedium: {
    fontSize: 16,
    opacity: 0.78,
  },
  textLabelSmall: {
    fontSize: 12,
    opacity: 0.78,
    fontWeight: 500,
  },
  // center: {
  //   display: "flex",
  //   textAlign: "center",
  //   [theme.breakpoints.down("xs")]: {
  //     display: "flex",
  //     textAlign: "flex-start",
  //   }

  // },
  left: {
    display: "flex",
    textAlign: "left",
  },
  workTypeChip: {
    marginLeft: 20,
    border: "none",
  },
  workTypeContract: {
    color: "#F08080",
    background: "#FFC0CB",
  },
  workTypeSubCategory: {
    background: "#FFC0CB",
  },
  workTypeFT: {
    color: "#9400D3",
    background: "lightblue",
  },
  workTypePT: {
    color: "darkgreen",
    background: "lightgreen",
  },
  padTop: {
    paddingTop: 3,
  },
  footerLeft: {
    marginTop: 25,
    marginBottom: 15,
  },
  skillChip: {
    borderRadius: 3,
    fontWeight: 600,
    background: "#F8F8F8",
    marginRight: 8,
  },
  infoButton: {
    fontSize: 12,
    color: "grey",
    display: "flex",
    justifyContent: "flex-end",
  },
  infoContent: {
    marginTop: 4,
  },
  footerRight: {
    marginTop: 39,
  },
}));

export const CourseLeft = ({ entry, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      {/* <Grid container spacing={2}> */}
      <Grid item xs={12} md={12}>
        <Avatar
          URL={entry.partnerLogo}
          firstName={entry.partnerName}
          height={115}
          width={115}
        />
        <MaterialLink
          href="https://globalgyan.in/product/gigvistas-smart-entrepreneur-foundation-program/"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="h4" className={classes.name}>
            {entry.courseTitle}

            <OpenInNewIcon style={{ marginLeft: "10px" }} />
          </Typography>
        </MaterialLink>

        <span style={{ display: "flex", marginBottom: "10px" }}>
          <Typography
            variant="body2"
            className={`${classes.textBold} ${classes.left}`}
            display="block"
          >
            {"In partnership with " + entry.partnerName}
          </Typography>
        </span>

        <span style={{ display: "flex" }}>
          <Typography
            variant="body2"
            className={`${classes.center}`}
            display="block"
          >
            {entry.courseDescription}
          </Typography>
        </span>
      </Grid>
      {/* </Grid> */}
    </>
  );
};

export const CourseRight = ({ entry, onAvail, ...rest }) => {
  const classes = useStyles();

  const userContext = useContext(UserContext);

  const availCoupon = (courseId) => {
    onAvail(courseId);

    // var params = {
    //   userCode: userContext.user.get.userCode,
    //   courseId: entry.courseId,
    //   partnerCode: entry.partnerCode
    // };

    // axios({
    //   method: "post",
    //   url: JOB_APPLICATION_URL,
    //   params
    // })
    //   .then(response => {
    //     console.log("POST JOB APPLICATION SUCCESSFUL");
    //     //set searchResults here
    //     handleAvail(entry);
    //   })
    //   .catch(function(error) {
    //     console.log("POST JOB APPLICATION ERROR");
    //     console.log(error);
    //   });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          {entry.courseType && (
            <Chip
              label={entry.courseType}
              color="primary"
              size="small"
              className={classes.chip}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.footerRight}>
        <Grid item xs={10} md={10}>
          {!entry.availed ? (
            <Button
              onClick={() => {
                availCoupon(entry.courseId);
              }}
              variant="outlined"
              color="primary"
              size="large"
              fullWidth={true}
            >
              Avail Coupon Code
            </Button>
          ) : (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Use Coupon Code
              </Typography>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">zipx-xb7o-u37q</Typography>
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
