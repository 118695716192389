import React, { FC, useContext } from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import Logo from "src/components/Logo";
import NotificationButton from "src/components/notifications/notificationButton";
import ProfileDropdown from "src/components/navbar/ProfileDropdown";
import { Link, useLocation } from "react-router-dom";
import styled from '@emotion/styled'
import { colors, breakpoints } from "src/designsystem/theme";

import UserContext from "src/context/user-context";
import { Message } from "@icon-park/react";

const GvTopBar = styled.div`
display: flex;
justify-content: space-between;
padding-left: 23px;
border-bottom: 1px solid ${colors.nv80};
height: 65px;
background-color: ${colors.white};
@media (min-width: ${breakpoints["lg"]}) {
  background-color: transparent;
} 
`
const ActionBar = styled.div`
display: flex;
width: 300px;
background-color: ${colors.white};
justify-content: flex-end;
padding-right: 12px;
@media (min-width: ${breakpoints["lg"]}) {
  border-left: 1px solid ${colors.nv80};
} 
`
const GvIconButton = styled(IconButton)`
border-radius: 0px;
height: 65px;
width: 65px;
display: grid;
place-items: center;
padding: 0;
`


const TopBar: FC = () => {
  const userContext = useContext(UserContext);
  const isCandidate = userContext.user.get.userType === "individual_candidate" ? true : false;
  const dashboard= isCandidate ? "/c/dashboard" : "/r/home";
  const path = useLocation().pathname;
  const fill = path === ('/r/messages' || '/c/messages') ? '#2C80D5': '#223657';
  const messagesPath = isCandidate ? "/c/messages" : "/r/messages";
  const isMobile = useMediaQuery(`(max-width:${breakpoints["lg"]})`);
  return (
    <GvTopBar>
    <Link to={dashboard}>
        <Logo style={{ padding: "2px 0px" }} />
      </Link>
      <ActionBar>
        <Link to={messagesPath}>
          <GvIconButton disableRipple>
              <Message theme="outline" size="24" fill={fill} strokeWidth={3} style={{display: 'grid', placeItems: 'center'}}/>
          </GvIconButton>
        </Link>
        <NotificationButton />
        <ProfileDropdown />
      </ActionBar>
    </GvTopBar >
  );
};

export default TopBar;
