import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import {
  POST_DRAFT_URL,
  DELETE_DRAFT_JOB_URL,
  GET_DRAFT_JOB_URL,
} from "src/utils/constants";
import UserContext from "src/context/user-context";
import JobStepperEdit from "src/components/job-steps/JobStepperEdit";

import MoreDetails from "./MoreDetails";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "50px",
  },
  close: {
    // display: "flex",
    alignContent: "right",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  actions: {
    display: "grid",
    gridTemplate: "1fr 1fr/ 1fr",
  },
}));

const Drafts = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [drafts, setDrafts] = useState([]);
  const [draftEdit, setDraftEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState();

  const userCode = userContext.user.get.userCode;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getDraftJobs();
  }, [draftEdit, props.toggler, openDelete]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickDelete = (draft) => {
    setOpenDelete(true);
    setSelectedDraft(draft);
  };

  const handleDraftClose = () => {
    setDraftEdit(false);
  };

  const handleCloseDelete = (Event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenDelete(false);
  };

  const getDraftJobs = () => {
    axios({
      method: "GET",
      url: GET_DRAFT_JOB_URL,
      params: {
        userCode: userCode,
      },
    })
      .then((response) => {
        setDrafts(response.data);
      })
      .catch((error) => {
        console.log("WE DIDN'T GET THE JOBS");
      });
  };

  const handleDraftOpen = async (draft) => {
    setSelectedDraft(draft);
    setDraftEdit(true);
  };

  const deleteDraft = (id) => {
    axios({
      method: "DELETE",
      url: DELETE_DRAFT_JOB_URL,
      params: {
        id: id,
      },
    }).then((response) => {
      setOpenDelete(!response.data.status);
    });
  };

  return (
    <Card className={classes.container}>
      <CardHeader title="Drafts" />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="65%">Job Title</TableCell>
            <TableCell width="20%">Date Modified</TableCell>
            <TableCell width="15%">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drafts
            ?.sort((draft1, draft2) =>
              draft1.updatedDate < draft2.updatedDate ? 1 : -1
            )
            .slice(page * limit, page * limit + limit)
            .map((draft) => {
              const jobDetailLink = "/r/jobDrafts/" + draft.id;
              return (
                <TableRow hover key={draft.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <RouterLink to={jobDetailLink}>
                        <Typography className={classes.title}>
                          <Link>
                            {draft.title.length > 25
                              ? draft.title.slice(0, 25) + "..."
                              : draft.title}
                          </Link>
                        </Typography>
                      </RouterLink>
                      <MoreDetails
                        title={draft.title}
                        // dangerouslySetInnerHTML={{ __html: desc }}
                        desc={draft.description}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {moment(draft.updatedDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className="actions"
                      onClick={() => {
                        handleDraftOpen(draft);
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <EditIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      className="actions"
                      onClick={() => {
                        handleClickDelete(draft);
                      }}
                    >
                      <Tooltip title="Delete" arrow>
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={drafts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog
        onBackdropClick="false"
        fullScreen={isMobile}
        onClose={handleDraftClose}
        aria-labelledby="customized-dialog-title"
        open={draftEdit}
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setDraftEdit(false)}
          className={classes.close}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <JobStepperEdit
            funcFlag="editDraft"
            toggler={draftEdit}
            setToggler={setDraftEdit}
            jobInfo={selectedDraft}
            getJobInfo={selectedDraft}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        onBackdropClick="false"
        open={openDelete}
        onClose={() => {
          handleCloseDelete();
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Drafted Job"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, permanently want to <strong>DELETE</strong> the draft?
            <br />
            <strong> Note:</strong> Can't UNDO this change
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => deleteDraft(selectedDraft.id)}>
            Yes
          </Button>
          <Button onClick={handleCloseDelete} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
export default Drafts;
