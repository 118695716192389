import React, { FC, ReactElement, ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Button from "src/designsystem/Core/Button";
import { CircularProgress } from "@mui/material";
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import Typography from "src/designsystem/Core/Typography";

const GvLoadingButton = styled(Button)<LBProps>`
  border: 0px;
  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 ${colors.nv40 + "24"},
      0 0 0 5px ${colors.nv40 + "57"};
    outline: none;
  }
  background-color: ${colors.n80};
  color: ${colors.n20};
  &:hover {
    background-color: ${colors.n70};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${colors.n60};
  }
  ${(props) => props.toggle && "cursor: not-allowed;"}
`;
const GvSpinner = styled(CircularProgress)`
  color: ${colors.nv40};
`;

interface LBProps {
  children?: ReactNode;
  toggle?: boolean;
  fullWidth?: boolean;
  thick?: boolean;
  onClick?: any;
}

const LoadingButton: FC<LBProps> = ({
  toggle,
  fullWidth,
  thick,
  children,
  onClick,
}) => {
  return (
    <GvLoadingButton
      toggle={toggle}
      fullWidth={fullWidth}
      thick={thick}
      variant="contained"
      onClick={onClick}
    >
      {toggle ? <GvSpinner size="18px" /> : <Typography>{children}</Typography>}
    </GvLoadingButton>
  );
};

export default LoadingButton;
