import { createTheme, colors, adaptV4Theme } from "@mui/material";
import shadows from "./shadows";

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: `"Inter", sans-serif`,
      text: {
        small: 12,
        medium: 14,
        large: 18,
      },
    },
    palette: {
      background: {
        dark: "#F5F6F8",
        default: "#F5F6F8",
        paper: "#F5F6F8",
      },
      primary: {
        main: "#006fba",
      },
      secondary: {
        main: "#00538b",
      },
      text: {
        primary: "#002944",
        secondary: colors.blueGrey[600],
        tertiary: "#002944",
        primaryBlue: "#0071BD",
      },
      accent: {
        main: "#DADFEB",
      },
      light: {
        main: "#F4F6F8",
      },
    },
    shadows,
  })
);

export default theme;
