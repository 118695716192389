import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Button from "src/designsystem/Core/Button";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import { BookmarkOne } from "@icon-park/react";
import { motion } from "framer-motion";
import HueButton from "src/designsystem/Core/HueButton";
import Tooltip from "@mui/material/Tooltip";

const GvBookmark = styled(Button)<BookmarkProps>`
  padding: 0em;
`;
const GvHueBookmark = styled(HueButton)<BookmarkProps>`
  padding: 0em;
`;

interface BookmarkProps {
  toggle: boolean;
  onClick?: () => void;
  hue?: number;
  fullWidth?: boolean;
}

const Bookmark: FC<BookmarkProps> = ({ toggle, fullWidth, onClick, hue }) => {
  console.log("toggle:", toggle);
  if (hue) {
    return (
      <GvHueBookmark
        toggle={toggle}
        variant={toggle ? "contained" : "outlined"}
        onClick={onClick}
        hue={hue}
      >
        <Tooltip title="Bookmark" arrow>
          <motion.div
            whileTap={{ scale: 0.9 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            <BookmarkOne
              theme={toggle ? "filled" : "outline"}
              size="20"
              fill={toggle ? colors.white : `hsl(${hue}, 40%, 50%)`}
              style={{ display: "grid", placeItems: "center" }}
            />
          </motion.div>
        </Tooltip>
      </GvHueBookmark>
    );
  } else {
    return (
      <GvBookmark
        toggle={toggle}
        variant={toggle ? "contained" : "outlined"}
        onClick={onClick}
      >
        <Tooltip title="Bookmark" arrow>
          <motion.div
            whileTap={{ scale: 0.9 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            <BookmarkOne
              theme={toggle ? "filled" : "outline"}
              size="20"
              fill={toggle ? colors.white : colors.p40}
              style={{ display: "grid", placeItems: "center" }}
            />
          </motion.div>
        </Tooltip>
      </GvBookmark>
    );
  }
};

export default Bookmark;
