import React from "react";
import { Typography, Slider } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  filterTitle: {
    fontSize: 21,
    lineHeight: "25px",
    fontWeight: 600,
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const RangeSlider = (props) => {
  const classes = useStyles();
  const { min, max, start, end, title } = props;
  const [value, setValue] = React.useState([start, end]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography
        id="range-slider"
        className={classes.filterTitle}
        gutterBottom
      >
        {title}
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        min={min}
        max={max}
      />
    </div>
  );
};

export default RangeSlider;
