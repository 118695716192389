import { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import SockJS from "sockjs-client";
import Page from "src/components/Page";
import ActiveChat from "src/components/messages/activechat";
import ChannelList from "src/components/messages/channellist";
import UserContext from "src/context/user-context";
import { breakpoints } from "src/designsystem/theme";
import {
    MESSAGES_URL,
    MESSAGE_CHANNEL_LIST_URL
} from "src/utils/constants";
import { over } from "stompjs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  padding: 5px;
  @media (min-width: ${breakpoints["md"]}) {
    grid-template-columns: 0.3fr 0.7fr;
  }
`;
var stompClient = null;
const CandidateMessagesView = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [channelList, setChannelList] = useState([]);
  const [activeChannel, setActiveChannel] = useState({});
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  var userCode = userContext.user.get.userCode;

  useEffect(() => {
    userChannels();
    connect();
  }, [userCode]);

  useEffect(() => {
    userChat();
  }, [activeChannel, message]);

  const connect = () => {
    // let Sock = new SockJS("https://alpha.gigvistas/mpn-service/mpn-ws");
    let Sock = new SockJS("https://app.gigvistas.com/mpn-service/mpn-ws");
    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    stompClient.subscribe("/topic/" + userCode, onMessageReceived);
  };
  const onError = (err) => {
    console.log(err);
  };
  const onMessageReceived = (payload) => {
   // if (payload.body.type == "message") {
      let msg = {
        channel: payload.body.channel,
        sender: payload.body.sender,
        content: payload.body.message,
        seq: payload.body.seq,
      };
      messages.push(msg);
      setMessage(msg.content);
      setMessages(messages);
 //   }
  };

  const userChannels = () => {
    axios({
      method: "GET",
      url: MESSAGE_CHANNEL_LIST_URL + userCode,
    })
      .then((response) => {
        setChannelList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userChat = () => {
    axios({
      method: "GET",
      url: MESSAGES_URL + activeChannel.channelCode + "/" + userCode,
    })
      .then((response) => {
        const msg = response.data;
        setMessages(msg.messages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendPrivateMessage = (sendMessageData) => {
    if (stompClient) {
      setMessage(sendMessageData.message);
      stompClient.send("/app/message", {}, JSON.stringify(sendMessageData));
    }
  };

  return (
    <Page className={classes.root} title="Messages">
      <MessageContainer>
        <ChannelList
          channelList={channelList}
          activeChannel={activeChannel}
          setActiveChannel={setActiveChannel}
        />
        {Object.keys(activeChannel).length !== 0 && activeChannel && (
          <ActiveChat
            activeChannel={activeChannel}
            messages={messages}
            sendPrivateMessage={sendPrivateMessage}
          />
        )}
      </MessageContainer>
    </Page>
  );
};

export default CandidateMessagesView;
