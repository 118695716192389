import { createAsyncThunk,current, createSlice } from "@reduxjs/toolkit";
import { Resource } from "src/models/Resource";
import { ContractReduxModel } from "src/views/recruiter/RecruiterContract/state/models";
import { submitForm } from "src/views/recruiter/RecruiterContract/state/service";

export const submitFormInfo = createAsyncThunk<
  any,
  { methodType: any,endPoint: string,formData: string,params: string,props:any },
  any
>("ContractStepper", async ({ methodType,endPoint,formData ,params,props}, { rejectWithValue }) => {
  try {
   
    const data =  await submitForm( methodType,endPoint,formData ,params,props);
   if(props.currentStep === 0 ||props.currentStep === 1 ){ 
    props.handleNext();
    }
    else
    {
      
      props.navigate("/r/contract", { replace: true });
      window.location.reload();
    }
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});



const initialState = {
  isPending: false,
} as Resource<ContractReduxModel>;


export const contractStepperReducers = createSlice({
  name: "ContractStepper",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
   

    builder.addCase(submitFormInfo.pending, (state) => {

      let currentState = current(state);
      console.log(currentState);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
          }
        } as Resource<ContractReduxModel>;
      }
      return currentState;
      
    });
    builder.addCase(submitFormInfo.fulfilled,(state, { payload }) => {
      console.log(current(state));

      let currentState = current(state);
      if ("resource" in currentState) {
        return {
          resource: { ...currentState.resource, ...payload}
        } as Resource<ContractReduxModel>;
      }
      else
      {
        return {
          resource: { ...payload }
        } as Resource<ContractReduxModel>; 
      }
      }
    );

    builder.addCase(submitFormInfo.rejected, () => {
      return {
        errorMessage: "Some error 1",
      } as Resource<ContractReduxModel>;
    });

  },
  

});


export default contractStepperReducers.reducer;