import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab, AppBar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import Gigs from "./Gigs";
import UserContext from "src/context/user-context";
import { GET_EMPLOYEE_CONTRACT_URL } from "src/utils/constants";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const RecruiterGigs = () => {
  const classes = useStyles();

  const [jobs, setJobs] = useState([]);

  const [activeCredentials, setActiveCredentials] = useState("Open");
  const [gigs, setGigs] = useState([]);

  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;

  useEffect(() => {
    // code to run on component mount
    getContracts();
  }, [userContext]);

  const getContracts = async () => {
    // Move Contracts also to /employer URL - need to change backend API
    const employerContracts = await axios
      .get(GET_EMPLOYEE_CONTRACT_URL + `?employerUserCode=` + user_code)
      .catch((err) => console.log(err));

    if (employerContracts && employerContracts.data) {
      setGigs(() => employerContracts.data);
    }
  };

  return (
    <Page className={classes.root} title="My Gigs">
      <AppBar position="static" color="default">
        <Tabs
          value={activeCredentials}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
        >
          <Tab label="Active Gigs" value="Open" />
        </Tabs>
      </AppBar>
      <Gigs gigs={gigs} />
    </Page>
  );
};

export default RecruiterGigs;
