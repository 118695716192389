import React, { FC, ReactElement, ReactNode } from "react";
import styled from '@emotion/styled'

import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { colors, typography } from "../../theme";
import Button, { ButtonProps } from "../../Core/Button";
import { CircularProgress } from "@mui/material";


const GvButton = styled(Button) <SubmitButtonProps>`
    background-color: ${(props) => props.dark ? colors.p30 : colors.p40};
    border: 2px solid ${(props) => props.dark ? colors.p30 : colors.p40};
    &:hover {
        background-color: ${(props) => props.dark ? colors.p20 : colors.p30};
    }
    ${(props) => props.dark &&
    `
    &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 ${colors.p60 + '77'}, 0 0 0 5px ${colors.p60 + '98'};
        outline: none;
      }
    `
  }

`

interface SubmitButtonProps extends ButtonProps {
  isSubmitting?: boolean
  type?: string
  dark?: boolean
}

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  return (
    <GvButton {...props}>
      {props.isSubmitting ?
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <CircularProgress style={{ color: 'white', height: '20px', width: '20px' }} />
        </div>
        :
        props.children
      }
    </GvButton>
  )
}

export default SubmitButton