import React, { FC, useContext, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Link,
  Collapse,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import axios from "axios";
import UserContext from "src/context/user-context";
import { REGISTER_URL, LOGIN_URL, GLOGIN_URL } from "src/utils/constants";
import { parseJwt, getSterilizeUtmParams } from "src/utils/generic";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { termsAndCondtions } from "./TermsAndConditions";
import LogoFull from "../../components/LogoFull";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { setAccessToken } from "src/redux/slice/userSlice";
import {
  trackGoogleAdsRegisterEvent,
  trackFBCandidateLead,
  trackFBEmployerLead,
} from "src/utils/analytics";
import ButtonSelect from "src/designsystem/Forms/ButtonSelect";
import { colors } from "src/designsystem/theme";
import { BuildingOne, UserBusiness } from "@icon-park/react";
import FormCheckbox from "src/designsystem/Forms/Checkbox";
import Typography from "src/designsystem/Core/Typography";
import Textfield from "src/designsystem/Forms/Textfield";
import Button from "src/designsystem/Core/Button";
import styled from "@emotion/styled";
import SubmitButton from "src/designsystem/Forms/SubmitButton";
import { login } from "./state/auth";
import TokenService from "src/services/TokenService";
import authService from "src/views/auth/state/authService";
import {getCookieValueByKey, deleteCookie} from "src/utils/cookieUtils";

const GvTypography = styled(Typography)`
  color: ${colors.white};
`;
const GvTitle = styled(Typography)`
  color: ${colors.white};
  margin-left: -3px;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  firstName: Yup.string().max(255).required("First name is required"),
  lastName: Yup.string().max(255).required("Last name is required"),
  password: Yup.string()
    .min(8, "Password should be atleast 8 characters long")
    .max(255)
    .matches(
      new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$"),
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
    )
    .required("Password is required"),
  policy: Yup.boolean().oneOf(
    [true],
    "You must agree to the terms and conditions"
  ),
  mobile: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]+$/, "Must contain only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
});

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  gender: "male",
  policy: false,
  mobile: "",
};

const registerSubmit = ( 
    values: any,
    actions: any,
    navigate: any,
    userContext: any,
    setGlobalError: any,
    dispatch: any
    ) => {
   
   const paramsFromCookies = getCookieValueByKey("gigvistas-user");
   
   axios({
     method: "POST",
     url: REGISTER_URL + getSterilizeUtmParams(paramsFromCookies),
     data: values,
   })
     .then((response) => {
       trackGoogleAdsRegisterEvent();
       actions.setSubmitting(false);
       actions.resetForm();
      const email = values.email;
      const password = values.password;
      deleteCookie("gigvistas-user");
      authService
        .login(email, password)
        .then((response) => {
          const userObj = TokenService.getUser();
          userObj.firstTimeLogin = true;
          const redirectPath = TokenService.getRedirectPath();
          const isRedirectPathAvaiable = redirectPath ? true : false;
          userContext.user.set(userObj);
          dispatch(setAccessToken(response));
          // if (userObj.userType === "individual_candidate") {
          //   trackFBCandidateLead();
          //   if (userObj.showOnboarding) {
          //     navigate("/o/candidate", { replace: true });
          //   } else if (isRedirectPathAvaiable) {
          //     TokenService.removeRedirectPath();
          //     return navigate(redirectPath, { replace: true });
          //   } else {
          //     navigate("/c/dashboard", { replace: true });
          //   }
          // } else {
          //   trackFBEmployerLead();
          //   if (userObj.showOnboarding) {
          //     navigate("/o/recruiter", { replace: true });
          //   } else {
          //     navigate("/r/home", { replace: true });
          //   }
          // }
          navigate("/u/type", { replace: true });
        })
        .catch((error) => {
          actions.setSubmitting(false);
        });
    })
    .catch((error) => {
      actions.setSubmitting(false);
      setGlobalError({
        hasError: true,
        errorMessage: error.response.data,
      });
      //handleServerResponse(false, error.response.data.error);
    });
};



const RegisterView: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const [globalError, setGlobalError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const [open, setOpen] = React.useState(false);
  type ScrollType = "body" | "paper" | undefined;
  const [scroll, setScroll] = React.useState<ScrollType>("paper");
  const handleClickOpen = (scrollType: ScrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<any>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Page title="Register">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          registerSubmit(
            values,
            actions,
            navigate,
            userContext,
            setGlobalError,
            dispatch
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <GvTitle variant="h1">Create a Free Account</GvTitle>
            <GvTypography>
              Have an account?{" "}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
                style={{ color: "#a3f3ff" }}
              >
                Sign in
              </Link>
            </GvTypography>
            <div style={{ height: "20px" }} />
            {/*<ButtonSelect
              name="isCandidate"
              dark
              large
              options={[
                {
                  iconSel: (
                    <UserBusiness
                      theme="outline"
                      size="24"
                      fill={colors.white}
                    />
                  ),
                  iconUnsel: (
                    <UserBusiness theme="outline" size="24" fill={colors.p40} />
                  ),
                  value: true,
                  title: "I'm looking for work",
                },
                {
                  iconSel: (
                    <BuildingOne
                      theme="outline"
                      size="24"
                      fill={colors.white}
                    />
                  ),
                  iconUnsel: (
                    <BuildingOne theme="outline" size="24" fill={colors.p40} />
                  ),
                  value: false,
                  title: "I'm looking to hire talent",
                },
              ]}
            /> */}
            <Textfield name="firstName" label="First Name" dark />
            <Textfield name="lastName" label="Last Name" dark />
            <Textfield name="mobile" label="Mobile Number" type="tel" dark />
            <Textfield name="email" label="Email" type="email" dark />
            <Textfield name="password" label="Password" type="password" dark />
            <FormCheckbox
              name="policy"
              dark
              label={
                <div>
                  I have read the{" "}
                  <Link
                    style={{ color: "#a3f3ff", fontSize: "1.1rem" }}
                    component={RouterLink}
                    to="#"
                    underline="always"
                    variant="h6"
                    onClick={handleClickOpen("paper")}
                  >
                    Terms and Conditions
                  </Link>
                </div>
              }
            />
            <Dialog
              maxWidth="md"
              open={open}
              onClose={handleClose}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">
                Terms and Conditions
              </DialogTitle>
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                  dangerouslySetInnerHTML={termsAndCondtions}
                ></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
            <div style={{ height: "20px" }} />
            <Collapse in={globalError.hasError}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setGlobalError({
                        hasError: false,
                        errorMessage: "",
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity="error"
              >
                {globalError.errorMessage}
              </Alert>
            </Collapse>
            <SubmitButton
              isSubmitting={isSubmitting}
              fullWidth
              type="submit"
              dark
            >
              Sign up
            </SubmitButton>
            {/* <Box my={2}>
                    <GoogleLogin
                        clientId="320721361621-hn7nbnnblf2r36pol6njriidgt8v4sr3.apps.googleusercontent.com"
                        buttonText="Sign Up with Google"
                        onSuccess={handleGoogleLoginSuccess}
                        onFailure={handleGoogleLoginFailure}
                        cookiePolicy={'single_host_origin'}
                    />
                  </Box> */}
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default RegisterView;
