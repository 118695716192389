import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import axios from "axios";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import "date-fns";
import {
  DatePicker,
} from "@mui/lab";

import { UPDATE_CANDIDATE_ED_AND_CERT_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormControl-marginDense": {
      "margin-top": "0px",
      "margin-bottom": "0px",
    },
  },
}));

const initialValues = {
  educationList: [
    {
      college: "",
      degree: "Bachelors",
      major: "",
      yearOfCompletion: "",
    },
  ],
  certifications: [
    {
      title: "",
      description: "",
      certifiedDate: null,
      validUpto: null,
    },
  ],
};

const validationSchema = Yup.object().shape({});

const EducationForm = ({ className, handleNext, handleBack, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        var candidateObj = {
          userCode: userContext.user.get.userCode,
          educationList: values.educationList,
          certifications: values.certifications,
        };
        axios({
          method: "POST",
          url: UPDATE_CANDIDATE_ED_AND_CERT_URL,
          data: candidateObj,
        })
          .then((response) => {})
          .catch((error) => {
            actions.setSubmitting(false);
            //handleServerResponse(false, error.response.data.error);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader subheader="Qualifications" />
            <CardContent>
              <FieldArray
                name="educationList"
                render={(arrayHelpers) => (
                  <>
                    {values.educationList.map((c, index) => (
                      <>
                        <Divider />
                        <CardContent>
                          <Grid key={index} container spacing={2}>
                            <Grid item md={5} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Name of the Institution"
                                name={`educationList.${index}.college`}
                                onChange={handleChange}
                                required
                                value={c.college}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={2} sm={12} xs={12}>
                              <FormControl
                                fullWidth
                                variant="outlined"
                                className={classes.formControl}
                                size="small"
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">
                                  Degree
                                </InputLabel>
                                <Select
                                  fullWidth
                                  native
                                  onChange={handleChange}
                                  label="Degree"
                                  inputProps={{
                                    name: "projectType",
                                    id: "outlined-age-native-simple",
                                  }}
                                  size="small"
                                  id="degree"
                                  values={c.degree}
                                  name={`educationList.${index}.degree`}
                                >
                                  <option value="bachelors">Bachelors</option>
                                  <option value="masters">Masters</option>
                                  <option value="doctoral">Doctoral</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Specialization"
                                name={`educationList.${index}.major`}
                                onChange={handleChange}
                                required
                                value={c.major}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={1} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Year"
                                name={`educationList.${index}.yearOfCompletion`}
                                onChange={handleChange}
                                required
                                value={c.yearOfCompletion}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    ))}

                    <Grid container spacing={2}>
                      <Grid container justifyContent="flex-end">
                        <Button
                          variant="contained"
                          onClick={() =>
                            arrayHelpers.push({
                              college: "",
                              degree: "Bachelors",
                              major: "",
                              yearOfCompletion: "",
                            })
                          }
                          size="small"
                        >
                          Add Education
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </CardContent>
            <Divider />
            <CardHeader subheader="Certifications" />
            <CardContent>
                <FieldArray
                  name="certifications"
                  render={(arrayHelpers) => (
                    <>
                      {values.certifications.map((c, index) => (
                        <>
                          <Divider />
                          <CardContent>
                            <Grid key={index} container spacing={2}>
                              <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  name={`certifications.${index}.title`}
                                  onChange={handleChange}
                                  required
                                  value={c.title}
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                              <Grid item md={3} sm={12} xs={12}>
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  margin="dense"
                                  id="certified-date"
                                  renderInput={(props) => (
                                    <TextField {...props} label="Certified Date" />
                                  )}
                                  name={`certifications.${index}.certifiedDate`}
                                  value={c.certifiedDate}
                                  onChange={(date) => {
                                    values.certifications[index].certifiedDate =
                                      date;
                                    setValues(values);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  size="small"
                                  fullWidth
                                  disableFuture
                                  className={classes.root}
                                />
                              </Grid>
                              <Grid item md={3} sm={12} xs={12}>
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  margin="dense"
                                  id="valid-upto"
                                  renderInput={(props) => (
                                    <TextField {...props} label="Valid Upto" />
                                  )}
                                  name={`certifications.${index}.validUpto`}
                                  value={c.validUpto}
                                  onChange={(date) => {
                                    values.certifications[index].validUpto =
                                      date;
                                    setValues(values);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  size="small"
                                  fullWidth
                                  className={classes.root}
                                />
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  name={`certifications.${index}.description`}
                                  onChange={handleChange}
                                  value={c.description}
                                  variant="outlined"
                                  multiline
                                  rows={3}
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </>
                      ))}

                      <Grid container spacing={2}>
                        <Grid container justifyContent="flex-end">
                          <Button
                            variant="contained"
                            onClick={() =>
                              arrayHelpers.push({
                                title: "",
                                description: "",
                                certifiedDate: "",
                                validUpto: "",
                              })
                            }
                            size="small"
                          >
                            Add Certification
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
            </CardContent>
            <CardContent>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      handleBack();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      handleNext();
                      handleSubmit(e);
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

EducationForm.propTypes = {
  className: PropTypes.string,
};

export default EducationForm;
