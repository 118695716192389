import React, { FC, useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import JobDetailData from "src/models/JobDetailData";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import LTChip from "../../designsystem/Derivative/LTChip";
import Skills from "../../designsystem/Derivative/Skills";
import Button from "src/designsystem/Core/Button";
import Bookmark from "../../designsystem/Derivative/Bookmark";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CandidateSummaryResponse } from "src/views/recruiter/RecruiterJobView/state/models";
import { MessageContext } from "src/context/message-context";
import {
  bookmarkToEmployerAction,
  removeBookmarkEmployerAction,
} from "src/views/recruiter/RecruiterJobView/state/RecuiterReducers";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AddTaskButton from "../../designsystem/Derivative/AddTaskButton";

// import { GET_CANDIDATE_PROFILE_TRACKER } from "src/utils/constants";
import axios from "axios";

import profileCompletionBadge from "src/assets/profileCompletionBadgeMini.svg";

const GvJobCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const GvGigLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 90px 1fr;
  padding: 15px;
`;
const GvGigRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 200px 1fr;
    gap: 30px;
    padding: 15px;
  }
`;
const GvGigDetails = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media (min-width: ${breakpoints["lg"]}) {
    gap: 30px;
    flex-direction: row;
  }
`;
const ProfileBadge = styled.span`
  height:  1.266rem;
  display: flex;
  align-items: space-between;
  justify-content
  margin-left: 10px;
  // margin-right: auto;
  margin-top: 15px;
  [theme.breakpoints.down("sm")]: {
    // position: "relative";
    width: 100px;
    top: 0;
    right: 0;
    // margin-left: 0;
    // margin-right: 0;
    // margin-top: 0;
    row-gap: 5px;
  }
`;
interface JobCardContactMiniProps {
  applicantInfo: CandidateSummaryResponse;
  user_code: string;
}

const JobCardContactMini: FC<JobCardContactMiniProps> = ({
  applicantInfo,
  user_code,
}) => {
  // const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const hasBookMarkedState =
    applicantInfo && applicantInfo.bookmarked ? true : false;
  const hasSelectedCandidateState = false;
  const [hasBookMarked, setToggle] = useState(hasBookMarkedState);
  const [hasSelectedCandidate, setSelectedCandidateToggle] = useState(
    hasSelectedCandidateState
  );
  const candidateID: string = applicantInfo.id;
  const candidateUserCode: string = applicantInfo.userCode;
  const candidateDetail = "/r/candidate/" + candidateUserCode;
  const addContact = (userCode: string) => {
    const contactsCopy = messageContext.contacts;
    const foundContact = findContact(contactsCopy, userCode);
    console.log("Contact Copy", foundContact);
    if (!foundContact) {
      messageContext.addContact(userCode);
      messageContext.addActiveContact(userCode);
    } else {
      messageContext.addActiveContact(userCode);
    }
  };

  // const [showBadge, setShowBadge] = useState(false);

  // useEffect(() => {
  //   getProfileTracker();
  // }, []);

  // const getProfileTracker = () => {
  //   var params = {
  //     userCode: user_code,
  //   };

  //   axios({
  //     method: "GET",
  //     url: GET_CANDIDATE_PROFILE_TRACKER,
  //     params,
  //   })
  //     .then((response) => {
  //       setShowBadge(response.data.activity.profileComplete);
  //     })
  //     .catch(function (error) {
  //       console.log("GET Profile Tracker ERROR");
  //       console.log(error);
  //     });
  // };

  const findContact = (arr: Array<any>, searchKey: string): string => {
    return arr.find((a) => a.id === searchKey);
  };

  return (
    <GvJobCard>
      <GvGigLeft>
        <Link to={candidateDetail}>
          <Avatar src={applicantInfo.profilePicUrl} alt={applicantInfo.name} />
        </Link>
        <div style={{ marginLeft: "15px", display: "grid", columnGap: "10px" }}>
          <Link to={candidateDetail}>
            <Typography variant="h4">
              {/* <div style={{ paddingRight: "10px" }}>{applicantInfo.name}</div> */}
              <ProfileBadge>
                {applicantInfo.name}
                {applicantInfo.profileComplete && (
                  // {showBadge && (
                  <img src={profileCompletionBadge} alt={"star badge"} />
                )}
              </ProfileBadge>{" "}
            </Typography>
          </Link>  
          <div style={{ height: "5px" }} />
          <Typography variant="label">
            {applicantInfo.jobCategory} | {applicantInfo.jobSubCategory?.join(",")}
          </Typography>
          <div style={{ height: "10px" }} />
          <LTChip
            timePref={applicantInfo.isOpenToFullTime ? "Full time" : "Flexible"}
            location={applicantInfo.gigProjectType}
          />
        </div>
      </GvGigLeft>
      <GvGigRight>
        <GvGigDetails>
          <div>
            <Typography variant="label" gutterBottom>
              Expected Fees
            </Typography>
            <Typography variant="body">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <AddSubtract
                  theme="outline"
                  size="25"
                  strokeWidth={3}
                  style={{ display: "grid", placeItems: "center" }}
                />
                ₹ {applicantInfo.expectedFees} per hour
              </div>
            </Typography>
          </div>
        </GvGigDetails>
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            onClick={() => {
              addContact(applicantInfo.userCode);
            }}
            fullWidth
          >
            Contact
          </Button>
          {!hasBookMarked ? (
            <Bookmark
              toggle={hasBookMarked}
              onClick={() => {
                dispatch(bookmarkToEmployerAction({ candidateID, user_code }));
                setToggle(true);
              }}
            />
          ) : (
            <Bookmark
              toggle={true}
              onClick={() => {
                dispatch(
                  removeBookmarkEmployerAction({ candidateID, user_code })
                );
                setToggle(false);
              }}
            />
          )}

          {/* <div style={{display: 'flex', gap: '0px 5px', justifyContent: 'center'}}>
         <AddTaskButton toggle={false} onClick={() => setSelectedCandidateToggle(true)}  />
         </div> */}
        </div>
      </GvGigRight>
    </GvJobCard>
  );
};

export default JobCardContactMini;
