import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MessageItem from "./MessageItem";

const useStyles = makeStyles({
  convTimeline: {
    display: "flex",
    height: "100%",
    padding: "1rem",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: "#fff",
    flexDirection: "column-reverse",
    overflowY: "auto",
    width: "800px"
  },
});

const MessageList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.convTimeline}>
      {props.messages
        .slice(0)
        .reverse()
        .map((messageItem) => (
          <MessageItem
            key={messageItem.id}
            owner={props.owner}
            sender={messageItem.sender}
            senderAvatar={messageItem.senderAvatar}
            message={messageItem.message}
          />
        ))}
    </div>
  );
};

export default MessageList;
