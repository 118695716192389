import { SetStateAction, useEffect, useState, useContext } from "react";

import CandidateProfile from "../../../derivedComponents/CandidateProfile";
import WorkDetail from "src/derivedComponents/WorkExpDetail";
import EducationDetail from "src/derivedComponents/EducationDetail/index";
import CertificationDetail from "src/derivedComponents/CertificationDetail";
import HueCard from "src/designsystem/Core/HueCard";
import LoadingButton from "src/designsystem/Derivative/LoadingButton";
import ProjectCard from "../../../designsystem/Derivative/ProjectCard";
import FileCard from "../../../designsystem/Derivative/FilesCard";
import styled from "@emotion/styled";
import CertificateEditDialog from "./EditDialogs/CertificateEditDialog";
import userContext from "src/context/user-context";
import { useDispatch, useSelector } from "react-redux";
import { Resource } from "src/models/Resource";
import {
  CandidateProfileModel,
  Certification,
  Education,
} from "./state/models";
import { getCandidateProfileAction } from "./state/reducer";
import UserContext from "src/context/user-context";
import ProfileEditDialog from "src/views/candidate/CandidateProfile/EditDialogs/ProfileEditDialog";
import ProjectEditDialog from "./EditDialogs/ProjectEditDialog";
import PortfolioAddDialog from "./EditDialogs/PortfolioAddDialog";
import EducationEditDialog from "./EditDialogs/EducationEditDialog";
import WorkExEditDialog from "./EditDialogs/WorkExEditDialog";
import { candidateProfile } from "./state/service";

const GvProfileCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
`;

const GvLeftCard = styled.div`
  flex: 49%;
  @media (max-width: 800px) {
    flex: 100%;
  }
`;

const GvRightCard = styled.div`
  flex: 49%;
  @media (max-width: 800px) {
    flex: 100%;
  }
`;

const ProfileDetails = (props: any) => {
  const state: Resource<CandidateProfileModel> = useSelector<
    any,
    Resource<CandidateProfileModel>
  >((state) => {
    return state.profileInfo;
  });

  const userContext = useContext(UserContext);

  const [editProfile, setEditProfile] = useState(false);

  const [editProject, setEditProject] = useState(false);

  const [editFile, setEditFile] = useState(false);

  //Certificate Edit
  const [editCertification, setEditCertification] = useState(false);

  //Work Ex Edit
  const [editExperience, setEditExperience] = useState(false);

  //Education Edit
  const [editEducation, setEditEducation] = useState(false);

  const [certificationToShow, setCertificationToShow] = useState(1);

  const [experienceToShow, setExperienceToShow] = useState(1);

  const [educationToShow, setEducationToShow] = useState(1);

  const dispatch = useDispatch();

  var user_code: any = null;
  if (userContext.user) {
    const user: any = userContext.user;
    user_code = user.get.userCode;
  }

  useEffect(() => {
    dispatch(getCandidateProfileAction({ user_code }));
  }, []);
  console.log(state);
  if ("isPending" in state) {
    console.log("pending");
    return <div>Pending</div>;
  } else if ("resource" in state) {
    console.log("we have resource", state);
    return SuccessView(
      dispatch,
      state.resource,
      userContext.user,
      editProfile,
      setEditProfile,
      certificationToShow,
      setCertificationToShow,
      experienceToShow,
      setExperienceToShow,
      educationToShow,
      setEducationToShow,
      editProject,
      setEditProject,
      editFile,
      setEditFile,
      editCertification,
      setEditCertification,
      editExperience,
      setEditExperience,
      editEducation,
      setEditEducation
    );
  } else if ("errorMessage" in state) {
    console.log("error");
    return errorView(state.errorMessage);
  } else {
    return <div>Fire! Fire! Fire!</div>;
  }
};

const errorView = (errorMessage: string) => {
  return <h1>{errorMessage}</h1>;
};

const SuccessView = (
  dispatch: any,
  candidateProfile: CandidateProfileModel,
  user: any,
  editProfile: any,
  setEditProfile: any,
  certificationToShow: any,
  setCertificationToShow: any,
  experienceToShow: any,
  setExperienceToShow: any,
  educationToShow: any,
  setEducationToShow: any,
  editProject: any,
  setEditProject: any,
  editFile: any,
  setEditFile: any,
  editCertification: any,
  setEditCertification: any,
  editExperience: any,
  setEditExperience: any,
  editEducation: any,
  setEditEducation: any
) => {
  function showCertification(length: any) {
    setCertificationToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }
  function showExperience(length: any) {
    setExperienceToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  const showEducation = (length: any) => {
    setEducationToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <CandidateProfile
        // addPhone={false}
        editable={true}
        profileData={candidateProfile}
        onClick={() => {
          setEditProfile(true);
        }}
      />
      <ProfileEditDialog
        open={editProfile}
        setOpen={setEditProfile}
        info={candidateProfile}
        user={user}
        refetch={() => {
          dispatch(getCandidateProfileAction({ user_code: user.get.userCode }));
        }}
      />
      <GvProfileCard>
        <GvLeftCard>
          <HueCard
            title={"Projects"}
            hue={235}
            saturation={100}
            lightness={71}
            editable={true}
            onClick={() => {
              candidateProfile.candidateProjects.length < 10 &&
                setEditProject(true);
            }}
          >
            <>
              <ProjectEditDialog
                id={null}
                open={editProject}
                setOpen={setEditProject}
                info={{}}
                addFlag={true}
                user={user}
                refetch={() => {
                  // window.location.reload();
                  dispatch(
                    getCandidateProfileAction({ user_code: user.get.userCode })
                  );
                }}
              />

              <ProjectCard data={candidateProfile} />
            </>
          </HueCard>
        </GvLeftCard>
        <GvRightCard>
          <HueCard
            title={"Portfolio and Files"}
            hue={235}
            saturation={100}
            lightness={71}
            editable={true}
            onClick={() => {
              candidateProfile.candidateFiles.length < 5 && setEditFile(true);
            }}
          >
            <>
              <PortfolioAddDialog
                open={editFile}
                setOpen={setEditFile}
                info={candidateProfile}
                user={user}
                refetch={() => {
                  // window.location.reload();
                  dispatch(
                    getCandidateProfileAction({ user_code: user.get.userCode })
                  );
                }}
              />
              <FileCard data={candidateProfile.candidateFiles} />
            </>
          </HueCard>
        </GvRightCard>
      </GvProfileCard>

      <HueCard
        hue={235}
        saturation={100}
        lightness={71}
        title="Certifications"
        editable={true}
        onClick={() => {
          candidateProfile.certifications.length < 20 &&
            setEditCertification(true);
        }}
      >
        <>
          <CertificateEditDialog
            open={editCertification}
            setOpen={setEditCertification}
            addFlag={true}
            info={candidateProfile}
            user={user}
            refetch={() => {
              // window.location.reload();
              dispatch(
                getCandidateProfileAction({ user_code: user.get.userCode })
              );
            }}
          />
          {candidateProfile.certifications
            .slice(0, certificationToShow)
            .map((certification: Certification) => (
              <CertificationDetail
                data={certification}
                key={certification.id}
                editable={true}
              />
            ))}

          {candidateProfile &&
            candidateProfile.certifications &&
            candidateProfile.certifications.length > 1 &&
            (candidateProfile.certifications.length > 0 ? (
              certificationToShow === 1 ? (
                <LoadingButton
                  onClick={() => {
                    showCertification(candidateProfile.certifications.length);
                  }}
                  fullWidth={true}
                >
                  Show More
                </LoadingButton>
              ) : (
                <LoadingButton
                  onClick={() => {
                    showCertification(1);
                  }}
                  fullWidth={true}
                >
                  Show Less
                </LoadingButton>
              )
            ) : (
              <div>No certification details provided...</div>
            ))}
        </>
      </HueCard>

      {
        <HueCard
          hue={235}
          saturation={100}
          lightness={71}
          title="Work Experience"
          editable={true}
          onClick={() => {
            candidateProfile.candidateExperiences.length < 20 &&
              setEditExperience(true);
          }}
        >
          <>
            <WorkExEditDialog
              open={editExperience}
              setOpen={setEditExperience}
              info={candidateProfile}
              user={user}
              addFlag={true}
              refetch={() => {
                // window.location.reload();
                dispatch(
                  getCandidateProfileAction({ user_code: user.get.userCode })
                );
              }}
            />
            {candidateProfile.candidateExperiences
              .slice(0, experienceToShow)
              .map((candidateExperience) => (
                <WorkDetail data={candidateExperience} editable={true} />
              ))}
            {candidateProfile &&
              candidateProfile.candidateExperiences &&
              candidateProfile.candidateExperiences.length > 1 &&
              (candidateProfile.candidateExperiences.length > 0 ? (
                experienceToShow === 1 ? (
                  <LoadingButton
                    onClick={() => {
                      showExperience(
                        candidateProfile.candidateExperiences.length
                      );
                    }}
                    fullWidth={true}
                  >
                    Show More
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      showExperience(1);
                    }}
                    fullWidth={true}
                  >
                    Show Less
                  </LoadingButton>
                )
              ) : (
                <div>No Work Experience details provided...</div>
              ))}
          </>
        </HueCard>
      }
      {
        <HueCard
          hue={235}
          saturation={100}
          lightness={71}
          title="Education"
          editable={true}
          onClick={() => {
            candidateProfile.educationList.length < 20 &&
              setEditEducation(true);
          }}
        >
          <>
            <EducationEditDialog
              open={editEducation}
              setOpen={setEditEducation}
              addFlag={true}
              info={candidateProfile}
              user={user}
              refetch={() => {
                // window.location.reload();
                dispatch(
                  getCandidateProfileAction({ user_code: user.get.userCode })
                );
              }}
            />
            {candidateProfile.educationList
              .slice(0, educationToShow)
              .map((education: Education) => (
                <EducationDetail
                  data={education}
                  key={education.id}
                  editable={true}
                />
              ))}
            {candidateProfile &&
              candidateProfile.educationList &&
              candidateProfile.educationList.length > 1 &&
              (candidateProfile.educationList.length > 0 ? (
                educationToShow === 1 ? (
                  <LoadingButton
                    onClick={() => {
                      showEducation(candidateProfile.educationList.length);
                    }}
                    fullWidth={true}
                  >
                    Show More
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={() => {
                      showEducation(1);
                    }}
                    fullWidth={true}
                  >
                    Show Less
                  </LoadingButton>
                )
              ) : (
                <div>No Education details provided...</div>
              ))}
          </>
        </HueCard>
      }
    </>
  );
};

export default ProfileDetails;
