import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { Resource } from "src/models/Resource";
import { CandidateProfileModel } from "./models";
import {
  bookmarkToCandidate,
  candidateProfile,
  removeBookmarkToCandidate,
} from "./service";

export const getCandidateProfileAction = createAsyncThunk<
  any,
  { candidate_code: string; user_code: string; employerCode: string },
  any
>(
  "recruiterCandidateInfo/getById",
  async ({ candidate_code, user_code, employerCode }, { rejectWithValue }) => {
    try {
      return await candidateProfile(candidate_code, user_code, employerCode);
      //return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bookmarkCandidateAction = createAsyncThunk<
  any,
  { user_code: string; candidate_id: number },
  any
>(
  "recruiterCandidateBookMark/getById",
  async ({ user_code, candidate_id }, { rejectWithValue }) => {
    try {
      return await bookmarkToCandidate(user_code, candidate_id);
      //return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeBookmarkCandidateAction = createAsyncThunk<
  any,
  { user_code: string; candidate_id: number },
  any
>(
  "recruiterRemoveCandidateBookMark/getById",
  async ({ user_code, candidate_id }, { rejectWithValue }) => {
    try {
      return await removeBookmarkToCandidate(user_code, candidate_id);
      //return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  isPending: false,
} as Resource<CandidateProfileModel>;

export const RecruiterReducers = createSlice({
  name: "RecruiterCandidateDetail",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getCandidateProfileAction.pending, (state) => {
      return {
        isPending: true,
      } as Resource<CandidateProfileModel>;
    });
    builder.addCase(
      getCandidateProfileAction.fulfilled,
      (state, { payload }) => {
        console.log(payload, "Payload");

        return {
          resource: { ...payload },
        } as Resource<CandidateProfileModel>;
      }
    );

    builder.addCase(getCandidateProfileAction.rejected, () => {
      return {
        errorMessage: "Some error 1",
      } as Resource<CandidateProfileModel>;
    });

    builder.addCase(bookmarkCandidateAction.pending, (state) => {
      let currentState = current(state);

      console.log(currentState);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            bookmarked: false,
          },
        } as Resource<CandidateProfileModel>;
      }
      return currentState;
    });

    builder.addCase(removeBookmarkCandidateAction.pending, (state) => {
      let currentState = current(state);

      console.log(currentState);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            bookmarked: false,
          },
        } as Resource<CandidateProfileModel>;
      }
      return currentState;
    });

    builder.addCase(bookmarkCandidateAction.fulfilled, (state, { payload }) => {
      console.log(current(state));

      let currentState = current(state);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            bookmarked: true,
          },
        } as Resource<CandidateProfileModel>;
      }
      return currentState;
    });

    builder.addCase(
      removeBookmarkCandidateAction.fulfilled,
      (state, { payload }) => {
        console.log(current(state));

        let currentState = current(state);

        if ("resource" in currentState) {
          return {
            resource: {
              ...currentState.resource,
              bookmarked: false,
            },
          } as Resource<CandidateProfileModel>;
        }
        return currentState;
      }
    );

    builder.addCase(
      removeBookmarkCandidateAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        if ("resource" in currentState) {
          console.log(currentState.resource.bookmarked);
          return {
            resource: {
              ...currentState.resource,
              bookmarked: false,
            },
          } as Resource<CandidateProfileModel>;
        }
        return currentState;
      }
    );

    builder.addCase(
      bookmarkCandidateAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        if ("resource" in currentState) {
          console.log(currentState.resource.bookmarked);
          return {
            resource: {
              ...currentState.resource,
              bookmarked: false,
            },
          } as Resource<CandidateProfileModel>;
        }
        return currentState;
      }
    );
  },
});
export default RecruiterReducers.reducer;
