import React, { FC, ReactElement } from "react";

import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../theme";
import Typography, { TypoProps } from "../Typography";
import { Plus } from "@icon-park/react";
import ToggleButton from "@mui/material/ToggleButton";

const GvCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  font-family: Inter, sans-serif;
  border-radius: 8px;
  padding: 20px 15px 15px 15px;
  margin-top: 12px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 25px 30px 30px 30px;
  }
`;

const GvCardTitle = styled.div`
  border-bottom: 1px solid ${colors.nv70};
  font-weight: 600;
`;

const GvCardBody = styled.div`
  padding-top: 15px;
  font-weight: ${typography["body2"].weight};
  font-size: ${typography["body2"].size};
  color: ${typography["body2"].color};
  line-height: 1.35em;
`;

const GvButton = styled.div``;

interface TitleProps extends TypoProps {
  hue: number;
  saturation: number;
  lightness: number;
}

const Title = styled(Typography)<TitleProps>`
  color: ${(props) => `hsl(${props.hue}, 40%, 50%)`};
  display: inline-block;
  border-bottom: 2px solid
    ${(props) => `hsl(${props.hue}, ${props.saturation}%, ${props.lightness}%)`};
  padding-bottom: 15px;
  padding-right: 20px;
  margin-bottom: -1.5px;
`;

interface CardProps {
  children: string | ReactElement;
  title: string;
  hue?: number;
  saturation?: number;
  lightness?: number;
  editable?: boolean;
  onClick?: () => void;
}

const HueCard: FC<CardProps> = ({
  children,
  title,
  editable,
  hue = 235,
  saturation = 200,
  lightness = 200,
  onClick,
}) => {
  const [selected, setSelected] = React.useState(false);

  return (
    <GvCard>
      <GvCardTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Title
          variant="h5"
          hue={hue}
          saturation={saturation}
          lightness={lightness}
        >
          {title}
        </Title>
        {editable ? (
          <GvButton>
            <ToggleButton
              value="check"
              selected={selected}
              onClick={onClick}
              onChange={() => {
                setSelected(!selected);
              }}
              style={{
                color: "#4d55b3",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                gap: "3px",
                fontWeight: "700",
                backgroundColor: "#E8EBF0",
                borderRadius: "5px",
                width: "83px",
                height: "30px",
                marginBottom: "15px",
              }}
            >
              <Plus
                style={{
                  fontSize: "15px",
                  color: "#4d55b3",
                }}
              />
              Add
            </ToggleButton>
          </GvButton>
        ) : (
          <></>
        )}
      </GvCardTitle>
      <GvCardBody>{children}</GvCardBody>
    </GvCard>
  );
};

export default HueCard;
