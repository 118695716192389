import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import SideBar from "src/views/recruiter/RecruiterContract/common/SideBar";
import { AllApplication, CollectionFiles, Delivery } from "@icon-park/react";

import Summary from "src/views/recruiter/RecruiterContract/Summary";
import Deliverables from "src/views/recruiter/RecruiterContract/Deliverables";
import Terms from "src/views/recruiter/RecruiterContract/Terms";

import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import Footer from "src/views/recruiter/RecruiterContract/Footer";
import { Close } from "@icon-park/react";
const CSView = styled.div`
  display: grid;
  grid-template: 60px 1fr 80px / 1fr;
  border-radius: 15px;
  background-color: ${colors.white};
  grid-template-areas:
    "header"
    "content"
    "footer";
  height: 73vh;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template: 60px 1fr 80px / 2fr 8fr;
    grid-template-areas:
      "header header"
      "stepper content"
      "footer footer";
    height: 75vh;
  }
`;

const CSHeader = styled.div`
  grid-area: header;
  border-bottom: 1px solid ${colors.nv80};
  background-color: ${colors.white};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`;

const CSideBarView = styled.div`
  grid-area: stepper;
  border-right: 1px solid ${colors.nv80};
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CSFooter = styled.div`
  grid-area: footer;
  background-color: ${colors.n90};
  border-radius: 8px;
  height: 80px;
  display: block;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    display: block;
  }
`;

const CSContent = styled.div`
  grid-area: content;
  border-radius: 15px;
  padding: 15px 30px;
`;

const STEPS = [
  {
    name: "Summary",
    key: "summary",
    active: true,
    completed: false,
    icon: AllApplication,
  },
  {
    name: "Deliverables",
    key: "deliverables",
    active: false,
    completed: false,
    icon: CollectionFiles,
  },
  {
    name: "Terms",
    key: "terms",
    active: false,
    completed: false,
    icon: Delivery,
  },
];

const ContractStepper = (props) => {
  const navigate = useNavigate();
  const [steps, setSteps] = React.useState(STEPS);
  const [currentStep, setCurrentStep] = React.useState(0);
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);

  const authRef = useRef(null);

  const handleNext = () => {
    if (currentStep >= STEPS.length - 1) {
      return;
    }

    let nextStep = currentStep + 1;
    steps[currentStep].completed = true;
    steps[currentStep].active = false;
    steps[nextStep].active = true;

    setSteps(steps);
    setCurrentStep(nextStep);
  };

  const handleBack = () => {
    if (currentStep <= 0) {
      return;
    }
    let prevStep = currentStep - 1;
    steps[currentStep].completed = false;
    steps[currentStep].active = false;
    steps[prevStep].active = true;
    setCurrentStep(prevStep);
  };

  const callSubmit = () => {
    authRef.current.handleSubmit();
  };

  const getCurrentStep = (index) => {
    switch (index) {
      case 0:
        return (
          <Summary
            cprops={props}
            handleNext={handleNext}
            handleBack={handleBack}
            ref={authRef}
            currentStep={currentStep}
            navigate={navigate}
          />
        );
      case 1:
        return (
          <Deliverables
            handleNext={handleNext}
            handleBack={handleBack}
            ref={authRef}
            cprops={props}
            currentStep={currentStep}
            navigate={navigate}
          />
        );
      case 2:
        return (
          <Terms
            handleNext={handleNext}
            handleBack={handleBack}
            ref={authRef}
            cprops={props}
            currentStep={currentStep}
            navigate={navigate}
          />
        );

      default:
        return null;
    }
  };

  return (
    <CSView>
      <CSHeader>
        <p>{"Create New Contract"}</p>
        <Close
          theme="outline"
          size="24"
          fill="#8C8C8C"
          style={{
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.reload();
            props.setOpen(false);
          }}
        />
      </CSHeader>
      {matches && (
        <CSideBarView>
          <SideBar steps={STEPS} />
        </CSideBarView>
      )}
      <CSContent>{getCurrentStep(currentStep)}</CSContent>
      <CSFooter>
        <Footer
          callSubmit={callSubmit}
          handleNext={handleNext}
          handleBack={handleBack}
          currentStep={currentStep}
        ></Footer>
      </CSFooter>
    </CSView>
  );
};

export default ContractStepper;
