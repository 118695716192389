import React, { FC, useContext } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Link,
  Collapse,
  IconButton,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Cookies from "universal-cookie/es6";

import LinkedinAuth from "../../components/auth/LinkedinAuth";
import Page from "src/components/Page";
import axios from "axios";
import UserContext from "src/context/user-context";
import { LOGIN_URL } from "src/utils/constants";
import { parseJwt } from "src/utils/generic";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import GoogleLogin from "react-google-login";
import { trackError, trackEvent, trackGoogleAdsLoginEvent, trackFBLogin } from "src/utils/analytics";
import { useDispatch } from "react-redux";
import { setAccessToken } from "src/redux/slice/userSlice";
import Typography from "src/designsystem/Core/Typography";
import { colors } from "src/designsystem/theme";
import styled from "@emotion/styled";
import Textfield from "src/designsystem/Forms/Textfield";
import SubmitButton from "src/designsystem/Forms/SubmitButton";
import {login} from "./state/auth" ;
import TokenService from "src/services/TokenService";
import authService from "src/views/auth/state/authService";

const GvTypography = styled(Typography)`
    color: ${colors.white};
`
const GvTitle = styled(Typography)`
    color: ${colors.white};
    margin-left: -5px;
`

const initialValues = {
  email: "",
  password: "",
};

const loginSubmit = (
  values: any,
  actions: any,
  navigate: any,
  userContext: any,
  setGlobalError: any,
  dispatch: any
) => {

    const email:any=values.email;
    const password:any=values.password;
    authService.login(email,password)
     .then((response:any) => {
       trackGoogleAdsLoginEvent();
         trackFBLogin();
          actions.setSubmitting(false);
          console.log(response);
          const userObj = TokenService.getUser();
          const redirectPath=TokenService.getRedirectPath();
          const isRedirectPathAvaiable=redirectPath? true : false;
          //localStorage.setItem("auth", JSON.stringify(userObj));
          userContext.user.set(userObj);
          dispatch(setAccessToken(response));
          if (userObj.userType === "individual_candidate") {
           if (userObj.showOnboarding) {
             navigate("/o/candidate", { replace: true });
           } 
           else if (isRedirectPathAvaiable){
            TokenService.removeRedirectPath();
             return  navigate(redirectPath, { replace: true }); 
           }
           else {
             navigate("/c/dashboard", { replace: true });
           
           }
         } else if (userObj.userType === "organization_employer"){
           if (userObj.showOnboarding) {
             navigate("/o/recruiter", { replace: true });
       
           } else {
             navigate("/r/home", { replace: true });
            
           }
         }
         else {
            navigate("/u/type", { replace: true });
         }
     })
     .catch(() => {
       actions.setSubmitting(false);
         //handleServerResponse(false, error.response.data.error);
         setGlobalError({
           hasError: true,
           errorMessage: "Invalid Credentials",
         });
         trackError("Invalid Credentials", false);
     });
 };

const handleGoogleLoginSuccess = (response: any) => {
  console.log(response);
};

const handleGoogleLoginFailure = (response: any) => {
  console.log(response);
};

const LoginView: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const userContext = useContext(UserContext);
  const [globalError, setGlobalError] = React.useState({
    hasError: false,
    errorMessage: "",
  });
  const searchParams = useLocation().search;
  const toRegister = "/register" + searchParams;

  return (
    <Page title="Login">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email address")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values, actions) => {
          loginSubmit(
            values,
            actions,
            navigate,
            userContext,
            setGlobalError,
            dispatch
          )
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <GvTitle variant="h1">Welcome Back</GvTitle>
            <GvTypography>
              Don&apos;t have an account?{" "}
              <Link
                component={RouterLink}
                to={toRegister}
                variant="h6"
                style={{ color: "#a3f3ff" }}
              >
                Sign up
              </Link>
            </GvTypography>
            <div style={{ height: '20px' }} />
            <Textfield
              name="email"
              label="Email"
              type="email"
              dark
            />
            <Textfield
              name="password"
              label="Password"
              type="password"
              dark
            />
            <GvTypography>
              Forgot Password?{" "}
              <Link
                component={RouterLink}
                to="/reset-password"
                variant="h6"
                style={{ color: "#a3f3ff" }}
              >
                Reset
              </Link>
            </GvTypography>
            <Collapse in={globalError.hasError}>
              <Alert
                style={{ marginBottom: "15px" }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setGlobalError({
                        hasError: false,
                        errorMessage: "",
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity="error"
              >
                {globalError.errorMessage}
              </Alert>
            </Collapse>
            <div style={{ height: '20px' }} />
            <SubmitButton
              isSubmitting={isSubmitting}
              fullWidth
              type="submit"
              onClick={() => trackEvent("Clicked Login Button", "Button")}
              dark
            >
              Sign in
            </SubmitButton>
            {/* <Grid item xs={12}>
                    <GoogleLogin
                      clientId="320721361621-hn7nbnnblf2r36pol6njriidgt8v4sr3.apps.googleusercontent.com"
                      buttonText="Log in with Google"
                      onSuccess={handleGoogleLoginSuccess}
                      onFailure={handleGoogleLoginFailure}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Grid> */}
            {/*<Grid item xs={6}>
                      <LinkedinAuth 
                        text="Sign in with Linkedin"
                        callback={callbackLinkedIn}
                        scope={["r_liteprofile","r_emailaddress"]}
                        clientId="772acmrlxsl42r"
                        />
            </Grid> */}
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default LoginView;
