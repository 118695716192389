import React from "react";
import PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071BD",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#0071BD",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function VistasSwitch(props) {
  // const [state, setState] = React.useState({
  //   checked: true,
  // });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={props.checked}
          onChange={props.onChange}
        // name="checked"
        />
      }
      label={props.label}
    />
  );
}

VistasSwitch.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
};
