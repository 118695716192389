import React, { useState, useContext, useEffect } from "react";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import UserContext from "src/context/user-context";
import Textfield from "@mui/material/TextField";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";


const ChatContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px auto 60px;
  gap: 5px;
  height: 85vh;
  background-color: ${colors["p100"]};
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
  gap: 10px;
  overflow-y: auto;
  align-self: stretch;
`;

const MessageInputContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 1fr;
  align-self: end;
  gap: 10px;
  padding: 10px;
`;

const MessageItemSelf = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  align-items: center;
`;

const MessageItemNotSelf = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const MessageText = styled.div`
  background: ${colors.s99};
  position: relative;
  max-width: 50%;
  min-height: 40px;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 18px;
  font-weight: 500;
  box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageTextSelf = styled.div`
  background: #fff;
  position: relative;
  max-width: 50%;
  min-height: 40px;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 0.9em;
  line-height: 18px;
  font-weight: 500;
  box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChannelHeader = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${colors.s99};
  border-bottom: 1px solid ${colors.nv80};
  align-self: start;
  font-weight: 600;
  box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.08);
`;

const ActiveChat = ({ activeChannel,messages,sendPrivateMessage }) => {
  const channel = activeChannel;
  const [singleMessage, setSingleMessage] = useState("");

  const userContext = useContext(UserContext);
  const userCode = userContext.user.get.userCode;

  // useEffect(() => {
  //   getReceiver();
  // }, [activeChannel]);

  const members = {};
  let receiver;
  if (channel.members[0].userCode === userCode) {
    members.me = channel.members[0];
    members.other = channel.members[1];
    receiver = channel.members[1].userCode;
  } else {
    members.me = channel.members[1];
    members.other = channel.members[0];
    receiver = channel.members[0].userCode;
  }


  const handleEventChange = (event) => {
    setSingleMessage(event.target.value);
  };

  const handleClick = () => {
    let sendMessageData = {
      channel: activeChannel.channelCode,
      sender: userCode,
      message: singleMessage,
      receiver : receiver,
    };
    sendPrivateMessage(sendMessageData);

  };
  return (
    <ChatContainer>
      <ChannelHeader>
        <Avatar
          src={members.other.profilePicUrl}
          alt={members.other.name}
          size="50px"
        />
        <p>{members.other.name}</p>
      </ChannelHeader>
      <MessagesContainer>
        {messages.map((message, i) => {
          if (message.sender === userCode) {
            return (
              <MessageItemSelf>
                <Avatar
                  alt={members.me.name}
                  src={members.me.profilePicUrl}
                  size="40px"
                />
                <MessageTextSelf>{message.content}</MessageTextSelf>
              </MessageItemSelf>
            );
          }
          return (
            <MessageItemNotSelf>
              <Avatar
                alt={members.other.name}
                src={members.other.profilePicUrl}
                size="40px"
              />
              <MessageText>{message.content}</MessageText>
            </MessageItemNotSelf>
          );
        })}
      </MessagesContainer>
      <MessageInputContainer>
        <div>
          <Textfield
            style={{ backgroundColor: "#FFF" }}
            fullWidth
            placeholder="Type your message"
            onChange={handleEventChange}
          />
        </div>
        <div style={{ paddingTop: "10px" }}>
          <Button
            variant="contained"
            onClick={handleClick}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </div>
      </MessageInputContainer>
    </ChatContainer>
  );
};

export default ActiveChat;
