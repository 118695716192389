import { createSlice } from '@reduxjs/toolkit'
import { parseJwt } from "src/utils/generic";
import { USER_SERVICE } from "src/utils/constants";
const initialState = {
  isLoggedIn: false,
  token:null,
  userCode: null,
  name: null,
  email: null,
  userType: null,
  firstTimeLogin: false,
  gender: null,
  redirectUrl: null,
  showOnboarding:false
}
//TODO: A map with paths that contain messages for certain paths for redirect. A JSON file maybe? 
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      //localStorage.setItem('token', action.payload)
      state.token = action.payload
      if (action.payload != null) {
      const decryptedToken = parseJwt(action.payload)
      state.isLoggedIn = true
      state.name = `${decryptedToken.firstName} ${decryptedToken.lastName}`
      state.userCode = decryptedToken.userCode
      state.email = decryptedToken.email
      state.userType = decryptedToken.userType
      state.firstTimeLogin = decryptedToken.firstTimeLogin
      state.gender = decryptedToken.gender
      state.showOnboarding=decryptedToken.showOnboarding

      }
    },
    initializeToken: (state, action) => {
      if (action.payload != null) {
        const decryptedToken = parseJwt(action.payload)
        state.isLoggedIn = true
        state.name = `${decryptedToken.firstName} ${decryptedToken.lastName}`
        state.userCode = decryptedToken.userCode
        state.email = decryptedToken.email
        state.userType = decryptedToken.userType
        state.firstTimeLogin = decryptedToken.firstTimeLogin
        state.gender = decryptedToken.gender
        state.showOnboarding=decryptedToken.showOnboarding
      }
    },
    setRedirect: (state, action) => {
      state.redirectUrl = action.payload
    }
  }
})



export const {setAccessToken, setRedirect, initializeToken } = userSlice.actions

export default userSlice.reducer

