import axios from "axios";
import {
  CREATE_CANDIDATE_BOOKMARK,
  GET_CANDIDATE_BOOKMARKS,
  GET_EMPLOYER_CANDIDATE_URL,
  REMOVE_CANDIDATE_BOOKMARK,
} from "src/utils/constants";

export const candidateProfile = async (
  candidate_code: string,
  user_code: string,
  employerCode: string
) => {
  console.log(candidate_code, user_code, employerCode);

  let response = await axios({
    method: "GET",
    url:
      GET_EMPLOYER_CANDIDATE_URL +
      "?userCode=" +
      candidate_code +
      "&employerCode=" +
      employerCode,
  });
  let resp = await axios({
    method: "GET",
    url: GET_CANDIDATE_BOOKMARKS + "?user_code=" + user_code,
  });
  let matchRecs = resp.data.find(
    (t: any) => Number(t.id) === Number(response.data.id)
  );
  response.data = { ...response.data, bookmarked: !!matchRecs };
  console.log(response.data, "manipulated response data");
  return response.data;
};

// export const isBookmarked = async (user_code: string, candidateID: number) => {
//   const response = await axios({
//     method: "GET",
//     url: GET_CANDIDATE_BOOKMARKS + "?user_code=" + user_code,
//   });

//   let matchRecs = response.data.find(
//     (t: any) => Number(t.id) === Number(response.data.id)
//   );
//   response.data = { ...response.data, bookmarked: !!matchRecs };
//   console.log(response.data, "manipulated response data");
//   return response.data;
// };

export const bookmarkToCandidate = async (
  user_code: string,
  candidateID: number
) => {
  const response = await axios.post(
    CREATE_CANDIDATE_BOOKMARK +
      "?user_code=" +
      user_code +
      "&candidate_id=" +
      candidateID
  );
  return response.data;
};

export const removeBookmarkToCandidate = async (
  user_code: string,
  candidateID: number
) => {
  const response = await axios.post<boolean>(
    REMOVE_CANDIDATE_BOOKMARK +
      "?user_code=" +
      user_code +
      "&candidate_id=" +
      candidateID
  );

  return response.data;
};

export default candidateProfile;
