import {
  Chip, Grid, InputAdornment, InputLabel,
  TextField, Typography
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import clsx from "clsx";
import { Formik } from "formik";
import React, { useContext } from "react";
import VistasCardNew from "src/components/custom/VistasCardNew";
import UserContext from "src/context/user-context";
import * as Yup from "yup";
import OnBoardingContext from "../../../context/onboarding-context";
import Footer from "./Footer";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import TokenService from "src/services/TokenService";
import {
  CANDIDATE_CATEGORY_LIST, CANDIDATE_CATEGORY_URL, CANDIDATE_CATEGORY_EXPERTISE_URL, ONBOARD_CANDIDATE_EXPERTISE
} from "src/utils/constants";
import {trackFBCandidateLead} from "src/utils/analytics";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  skillsSelect: {
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: theme.typography.text.small,
    color: "gray",
  },
  subtitle: {
    fontSize: 9,
  },
  expertise: {
    backgroundColor: "white",
    margin: "5px 0",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      //<--- here
      backgroundColor: "#f4f6f8",
      margin: 0,
      // paddingLeft: 10,
    },
  },
  inputLabel: { fontSize: "16px", color: "#212830", fontWeight: "500" },
  btnItem: {
    textAlign: "center",
    textTransform: "capitalize",
    width: "152px",
    justifyContent: "center",
    flexDirection: "row",
    // padding: "10px 0px",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#006fba",
    },
    [theme.breakpoints.down("sm")]: {
      width: "110px",
    },
  },
  textview: {
    backgroundColor: "white",
    margin: "5px 0",
  },
  discText: {
    color: "grey",
    fontStyle: "italic",
    fontSize: "0.75rem",
  },
}));

const Expertise = ({ className, handleNext, handleBack, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const OnBoardingCtx = useContext(OnBoardingContext);
  const isAgency = userContext.user.get.isAgency;
  const maxSummary = 1000;
  const maxTitle = 120;
  const [view, setView] = React.useState("Basic");
  console.log("is agency" + isAgency);

  var initialValues = {
    category: "",
    categoryExpertise: [],
    categoryExpertiseLevel: "Basic",
    categoryExperience: "",
    skills: [],
    categoryExpertiseList: [],
    categorySkillsList: [],
    profileSummary: "",
    profileTitle: "",
    profileKeywords: [],
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string()
      .required("Category is required"),
    categoryExpertise: Yup.array()
      .min(1,"Atleast 1 Expertise is required")
      .required("Expertise is required"),
    categoryExperience: Yup.number("Must be a number")
      .positive("Must be a positive number")
      .max(255)
      .required("Overall Years of Experience is required"),
    skills: Yup.array()
      .min(1, "Atleast 1 Top Skill is required")
      .required("Top Skill is required"),
    profileSummary: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(1000, "Must be less than 1000 characters")
      .required("Write something about yourself"),
    profileTitle: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(120, "Must be less than 120 characters")
      .required("Title is required"),
  });

  function submitCandidateExpertise(values, actions) {
  
    
    var expertiseData = {
      userCode: userContext.user.get.userCode,
      summary: values.profileSummary,
      title: values.profileTitle,
      jobCategory: values.category,
      jobSubCategory: values.categoryExpertise.join(','),
      totalExperience: values.categoryExperience,
      expertiseLevel: values.categoryExpertiseLevel,
      candidateSkills: values.skills,
    };

    OnBoardingCtx.addExpertise(values);

    axios({
      method: "POST",
      url: ONBOARD_CANDIDATE_EXPERTISE,
      data: expertiseData,
    })
      .then((response) => {
        trackFBCandidateLead()
        TokenService.getRefreshToken()
          .then((response) => {
            const userObj = TokenService.getUser();
            userContext.user.set(userObj);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((error) => {
        actions.setSubmitting(false);
      });
  }

  const handleCategoryChange = (event, values, setValues) => {

    axios.get( CANDIDATE_CATEGORY_URL, {
        params: {
          category: values.category
        }
      })
      .then((response) => {
        values.categoryExpertiseList = response.data;
        setValues(values)
      })
      .catch((error) => {
        
      });
  };

  const handleCategoryExpertiseChange = (event, values, setValues) => {
    axios.get( CANDIDATE_CATEGORY_EXPERTISE_URL, {
      params: {
        category: values.category,
        expertise: values.categoryExpertise.join(',')
      }
    })
    .then((response) => {
      values.categorySkillsList = response.data;
      setValues(values)
    })
    .catch((error) => {
      
    });

  };


  if (OnBoardingCtx.hasExpertise()) {
    if (localStorage.getItem("Expertise")) {
      initialValues = JSON.parse(localStorage.getItem("Expertise"));
      // setCount(profileSummary.length);
    } else {
      initialValues = OnBoardingCtx.expertise;
      // setCount(profileSummary.length);
    }

  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitCandidateExpertise(values, actions);
        handleNext(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <VistasCardNew label="About your self">
            <Grid container spacing={2}>
            <Grid item md={10} sm={10}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}
                  // style={{ display: "flex" }}
                >
                  Title*
                </InputLabel>
              </Grid>
              <Grid item md={2} sm={2}>
                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}> */}
                {/* <InputLabel shrink className={classes.inputLabel}> */}
                <Typography textAlign={"right"}>
                  {values.profileTitle.length}/{maxTitle}
                </Typography>
                {/* </InputLabel> */}
                {/* </div> */}
              </Grid>
              <Grid item md={12} sm={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth
                  id="profileTitle"
                  placeholder="Title you want to appear along with your profile"
                  className={classes.expertise}
                  multiline
                  inputProps={{ maxLength: maxTitle }}
                  variant="outlined"
                  rows={1}
                  name="profileTitle"
                  value={values.profileTitle}
                  onChange={(e) => {
                    handleChange(e);
                    //handleTitleChange(e, values, setValues);
                  }}
                  error={touched.profileTitle && Boolean(errors.profileTitle)}
                  helperText={touched.profileTitle && errors.profileTitle}
                />
              </Grid>

              <Grid item md={10} sm={10}>
                <InputLabel shrink className={classes.inputLabel}>
                  Summary*
                </InputLabel>
              </Grid>
              <Grid item md={2} sm={2}>
                <Typography textAlign="right">
                  {values.profileSummary.length}/{maxSummary}
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} style={{ paddingTop: 0 }}>
                <TextField
                  fullWidth
                  id="profileSummary"
                  placeholder="A summary of your offering and what you are passionate about"
                  className={classes.expertise}
                  multiline
                  variant="outlined"
                  inputProps={{ maxLength: maxSummary }}
                  rows={4}
                  name="profileSummary"
                  value={values.profileSummary}
                  onChange={handleChange}
                  error={
                    touched.profileSummary && Boolean(errors.profileSummary)
                  }
                  helperText={touched.profileSummary && errors.profileSummary}
                />
              </Grid>

              <Grid item md={12} sm={12}>
                <InputLabel shrink className={classes.inputLabel}>
                  Category*
                </InputLabel>
               
                <Autocomplete
                  id="category"
                  options={CANDIDATE_CATEGORY_LIST}
                  fullWidth
                  value={values.category}
                  renderInput={(params) => (
                    <TextField 
                      className={classes.expertise}
                      fullWidth 
                      {...params} 
                      placeholder="Select a Skill Category" />
                  )}
                  onChange={(e, val) => {
                    handleChange(e);
                    values.category = val;
                    setValues(values)
                    handleCategoryChange(e, values, setValues)
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <InputLabel shrink className={classes.inputLabel}>
                  Expertise*
                </InputLabel>
                <Autocomplete
                  multiple
                  id="expertise"
                  options={values.categoryExpertiseList}
                  disableCloseOnSelect
                  fullWidth
                  value={values.expertise}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField 
                      className={classes.expertise}
                      fullWidth 
                      {...params} 
                      placeholder="Select Upto 3 Expertise Areas" />
                  )}
                  onChange={(e, val) => {
                    handleChange(e);
                    if (val.length > 3) {
                      val.splice(3, 1);
                    }
                    values.categoryExpertise = val;
                    setValues(values)
                    handleCategoryExpertiseChange(e, values, setValues)
                  }}
                />
              </Grid>

              <Grid item md={12} sm={12}>
                <InputLabel shrink className={classes.inputLabel}>
                  Skills*
                </InputLabel>
                <Autocomplete
                  multiple
                  id="skills"
                  disableClearable
                  options={values.categorySkillsList}
                  freeSolo
                  name="skills"
                  value={values.skills}
                  onChange={(e, val) => {
                    let skills = values.skills;
                    if (
                      e.target.localName === "li" &&
                      val.length < skills.length
                    ) {
                      return;
                    }
                    if (val.length > 10) {
                      val.splice(10, 1);
                    }
                    setValues((oldVal) => {
                      var temp = { ...oldVal };
                      temp.skills = val;
                      return temp;
                    });
                  }}
                  limitTags={10}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.expertise}
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Choose a skill OR type a new skill and press enter to add"
                      name="skills"
                      // required
                      error={Boolean(touched.skills && errors.skills)}
                      helperText={touched.skills && errors.skills}
                      multiline
                      rows={2}
                      size="small"
                    />
                  )}
                />
                <Typography className={classes.discText} variant="body">
                  Find your skill or Type the skill name and press enter
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <InputLabel shrink className={classes.inputLabel}>
                  Overall Experience*
                </InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  className={classes.expertise}
                  name="categoryExperience"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment>Years</InputAdornment>,
                  }}
                  required
                  variant="outlined"
                  size="small"
                  value={values.categoryExperience}
                  error={Boolean(
                    touched.categoryExperience && errors.categoryExperience
                  )}
                  helperText={
                    touched.categoryExperience && errors.categoryExperience
                  }
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </VistasCardNew>
          <Footer handleBack={handleBack} handleSubmit={handleSubmit}></Footer>
        </form>
      )}
    </Formik>
  );
};

export default Expertise;
