import React, { useState, useContext, useEffect } from "react";
import { Resource } from "../../../models/Resource";
import ContractListeModel, {
  ContractDetailModel,
  ContractDetailResponse,
} from "src/views/recruiter/RecruiterContract/state//models";
import UserContext from "src/context/user-context";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getContractListAction } from "src/views/recruiter/RecruiterContract/state/reducer";
import Section from "src/designsystem/Core/Section";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import Button from "src/designsystem/Core/Button";
import ContractListItem from "src/derivedComponents/ContractListItem";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({}));

const CJDView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const TopCard = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Suggestions = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 25px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const ContractList = ({}: {}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userContext = useContext(UserContext) as any;
  var user_code = userContext.user.get.userCode;

  const [contractItemToShow, setContractItemToShow] = useState(2);

  useEffect(() => {
    dispatch(getContractListAction({ user_code }));
  }, []);

  const state: any = useSelector<any, Resource<ContractDetailResponse[]>>(
    (state) => {
      return state.contractinfo;
    }
  );


  if ("isPending" in state) {

    return <div>Pending</div>;
  } else if ("resource" in state) {
     
    return SucessView(
      state.resource.allContract,
      state.resource.activeContract,
      user_code,
      dispatch,
      classes,
      contractItemToShow,
      setContractItemToShow,
    );
  } else if ("errorMessage" in state) {
    return errorView(state.errorMessage);
  } else {
    return <div>Fire! Fire! Fire!</div>;
  }
};

const errorView = (errorMessage: string) => {
  return <h1>{errorMessage}</h1>;
};

const SucessView = (
  allContract: ContractDetailResponse[],
  activeContract:ContractDetailResponse[],
  user_code: string,
  dispatch: any,
  classes: any,
  contractItemToShow: any,
  setContractItemToShow: any,
) => {
  function showContractItem(length: any) {
    setContractItemToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  return (
    <CJDView>
      <Suggestions>
        <Section children={"Active Contracts"} />

        {activeContract && activeContract.length > 0 ? (
          <div style={{ display: "grid", rowGap: "10px" }}>
            {activeContract &&activeContract.slice(0, contractItemToShow)
                .map((ContractDetailModel: any) => (
                  <Link
                    to={
                      "/r/contract/" + ContractDetailModel.contract.contractId
                    }
                  >
                    <ContractListItem data={ContractDetailModel} />
                  </Link>
                ))}

            {activeContract && activeContract.length > 2 ? (
              contractItemToShow === 2 ? (
                <Button
                  fullWidth
                  onClick={() => {
                    showContractItem(activeContract.length);
                  }}
                >
                  Show More
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={() => {
                    showContractItem(2);
                  }}
                >
                  Show Less
                </Button>
              )
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div>No Active Contract...</div>
        )}

        <Section children={"All Contracts"} />

        {allContract && allContract.length > 0 ? (
          <div style={{ display: "grid", rowGap: "10px" }}>
            {allContract &&allContract.slice(0, contractItemToShow)
                .map((ContractDetailModel: any) => (
                  <Link to={"/r/contract/" + ContractDetailModel.contract.contractId}>
                    <ContractListItem data={ContractDetailModel} />
                  </Link>
                ))}

            {allContract && allContract.length > 2 ? (
              contractItemToShow === 2 ? (
                <Button
                  fullWidth
                  onClick={() => {
                    showContractItem(allContract.length);
                  }}
                >
                  Show More
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={() => {
                    showContractItem(2);
                  }}
                >
                  Show Less
                </Button>
              )
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div>No Contract...</div>
        )}
      </Suggestions>
    </CJDView>
  );
};

export default ContractList;
