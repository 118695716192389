import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import Typography from "src/designsystem/Core/Typography";
import GVButton from "src/designsystem/Core/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import RewardDialog from "src/views/candidate/CandidateReward/RewardDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  BASE_URL,
} from "src/utils/constants";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
} from "@mui/material";

const GvRewardCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 250px 1fr;
  }
`;
const GvRewardLeft = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 0px;
  }
`;
const GvRewardDetails = styled.div`
  display: grid;
  background-color: ${colors.white};
  grid-template-columns: 250px 1fr;
`;
const GvRewardRight = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  border-top: 1px solid ${colors.nv80};
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    border-top: 0px;
    border-left: 1px solid ${colors.nv80};
    padding: 30px;
  }
`;

interface RewardCardProps {
  totalPoints: string;
  userCode: string
}

const RewardCard: FC<RewardCardProps> = ({ totalPoints,userCode }) => {
  // const [toggle, setToggle] = useState(false)
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const [hasBookMarked, setToggle] = useState(true);
  const dummyHue = 201;
  const isLoggedInUser=true;
  const [editSummary, setEditSummary] = useState(false);
  return (
    <GvRewardCard>
      <GvRewardLeft>
        {/* <Cover hue={dummyHue} /> */}
        <GvRewardDetails>
          <Card>
            <CardContent>
              <Typography variant="h4">Refferal and Rewards</Typography>
            </CardContent>
            <CardActionArea>
              <CardMedia
                component="img"
                image="/static/images/post_job.svg"
                title="Refferal and Rewards"
              />
            </CardActionArea>
          </Card>
        </GvRewardDetails>
        {isLoggedInUser ? (
          <RewardDialog
            open={editSummary}
            setOpen={setEditSummary}
            info={" "}
            user={" "}
            refetch={() => {
              window.location.reload();
            }}
          />
        ) : null}
      </GvRewardLeft>
      <GvRewardRight>
        <div>
          <Typography variant="label" gutterBottom>
            Total Rewards Earned
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Typography variant="h3">{totalPoints} points</Typography>
              <GVButton
                onClick={() => {
                  setEditSummary(true);
                  setToggle(true);
                }}
              >
                Claim
              </GVButton>
            </div>
          </Typography>
        </div>
        <div>
          <Typography variant="label" gutterBottom>
            Refer and Earn 🌟
          </Typography>
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <Typography variant="label">
                Earn 50 points for every refferal
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Typography variant="body2">Your Refferal Code</Typography>
                {/* <TextField
                size="small"
                variant="outlined"
              /> */}
                <Button variant="outlined" endIcon={<ContentCopyIcon  />}>
                {userCode}
                </Button>
              </div>
              {/* <Bookmark
                        toggle={true}
                        onClick={() => setToggle(false)}
                      /> */}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Typography variant="body2">Your Refferal Link</Typography>
                {/* <TextField size="small" variant="outlined" /> */}
                <Button variant="outlined" endIcon={<ContentCopyIcon />}>
               
                {BASE_URL}{userCode}
                </Button>
              </div>
              {/* <Bookmark toggle={true} onClick={() => setToggle(false)} /> */}
            </div>
          </Typography>
        </div>
      </GvRewardRight>
    </GvRewardCard>
  );
};

export default RewardCard;
