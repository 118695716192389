import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import NotificationMenu from "src/components/notifications";
import UserContext from "src/context/user-context";
import {
  NOTIFICATIONS_URL,
  UNREAD_NOTIFICATIONS_URL,
} from "src/utils/constants";
import Snackbar from "@mui/material/Snackbar";
import ToastNotification from "src/components/notifications/toastNotification";
import WebSocketUtil from "src/utils/WebSocketUtil";
import { MessageContext } from "./message-context";

const NotificationContext = createContext({ notifications: [] });
const NotificationMenuContext = createContext(null);
const CurrentNotificationContext = createContext({
  content: [],
  addContent: () => {},
});
const ReadNotificationContext = createContext(true);

const NotificationProvider = ({ children }) => {
  const userContext = useContext(UserContext);
  const messageContext = useContext(MessageContext);

  const loggedInUserCode = userContext.user.get.userCode;
  //Contains all notifications for the user
  const [notifications, setNotifications] = useState([]);
  //Content set to the toast notification
  const [content, setContent] = useState({
    title: "",
    description: "",
  });
  //Opens the toast notification
  const [open, setOpen] = React.useState(false);
  //Sets if the notification badge shows that there are unread notifications
  const [hasRead, setRead] = React.useState(true);
  //Opens and closes the notification menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotification = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const addContent = (content) => {
    setContent(content);
    handleNotification();
  };

  const fetchNotifications = async () => {
    const response = await axios
      .get(NOTIFICATIONS_URL + `?user=` + loggedInUserCode)
      .catch((err) => console.log(err));

    if (response !== undefined) {
      if (response.data && response.data.length > 0) {
        setNotifications(response.data);
      }
    }
  };
  const fetchUnread = async () => {
    const response = await axios
      .get(UNREAD_NOTIFICATIONS_URL + `?user=` + loggedInUserCode)
      .catch((err) => console.log(err));

    if (response !== undefined) {
      setRead(response.data.notifications.Read);
    }
  };

  useEffect(() => {
    messageContext.addNotificationHandler((message) => {
      addContent(JSON.parse(message.message));
    });
    return () => {
      messageContext.addNotificationHandler(null);
    };
  }, [messageContext.connectionWs]);

  useEffect(() => {
    if (!open) {
      fetchUnread();
    }
  }, [userContext, open]);

  useEffect(() => {
    if (anchorEl != null) {
      fetchNotifications();
      if (messageContext.connectionWs) {
        WebSocketUtil.readNotification(
          userContext.user.get.userCode,
          messageContext.connectionWs
        );
      }
    }
  }, [anchorEl]);

  return (
    <NotificationContext.Provider value={{ notifications }}>
      <ReadNotificationContext.Provider value={{ hasRead, setRead }}>
        <NotificationMenuContext.Provider value={{ anchorEl, setAnchorEl }}>
          <NotificationMenu />
          <CurrentNotificationContext.Provider value={{ content, addContent }}>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
              autoHideDuration={6000}
            >
              <ToastNotification content={content} in={open} />
            </Snackbar>
            {children}
          </CurrentNotificationContext.Provider>
        </NotificationMenuContext.Provider>
      </ReadNotificationContext.Provider>
    </NotificationContext.Provider>
  );
};

export {
  NotificationProvider,
  NotificationContext,
  NotificationMenuContext,
  CurrentNotificationContext,
  ReadNotificationContext,
};
