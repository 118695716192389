import React from "react";
import { colors } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import CandidateProfileScore from "src/derivedComponents/CandidateProfileScore";

import Logo from "src/components/Logo";

const useStyles = makeStyles((theme) => ({
  sideBar: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    width: "20vw",
    padding: 20,
    backgroundColor: "white",
  },
  logo: {
    width: "100%",
    height: 60,
  },
  stepsWrapper: {
    marginTop: 40,
    marginLeft: 60,
  },
  step: {
    marginBottom: 5,
    border: "1px solid transparent",
    borderLeft: `2px solid transparent`,
    color: theme.palette.text.primaryBlue,
  },
  activeStep: {
    border: "1px solid #CAD3E6",
    borderLeft: `2px solid ${theme.palette.text.primaryBlue}`,
  },
  incompleteStep: {
    color: "gray",
  },
  completedIcon: {
    width: 15,
    height: 15,
  },
  stepIcon: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
}));

const SideBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.sideBar}>
      <div className={classes.logo}>
        <Logo />
      </div>
      <div className={classes.stepsWrapper}>
        {props.steps.map((step, index) => {
          const Icon = step.icon;
          return (
            <div
              className={clsx(
                classes.step,
                step.active && classes.activeStep,
                !step.active && !step.completed && classes.incompleteStep
              )}
              key={step.name}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{ display: "inline-block", alignContent: "center" }}
                >
                  <Icon className="stepIcon" />
                </div>
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  <span>{step.name}</span>
                </div>
                {!step.active && step.completed ? (
                  <CheckCircleIcon style={{ color: colors.green[500] }} />
                ) : null}
              </div>
            </div>
          );
        })}
        <div style={{ paddingTop: "150px" }}>
          <CandidateProfileScore />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
