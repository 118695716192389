import Grid from "@mui/material/Grid";
import {colors} from "src/designsystem/theme";
import Typography from 'src/designsystem/Core/Typography';
import styled from '@emotion/styled'
import JobSummaryDashboardCard from "../../RecruiterJobView/JobSummaryDashboardCard";

const SectionTitle = styled(Typography)`
    display: inline-block;
    border-bottom: 2px solid ${colors.p40};
    padding-bottom: 15px;
    padding-right: 20px;
    margin-bottom: 10px;
    color: ${colors.p40};
`

export default function DashboardJobs({jobs, getCandidateProfile}){
    let jobCards = [];
    for (let i = 0; i < jobs.length; i += 1) {
        jobCards.push(<Grid item xs={12} lg={6}><JobSummaryDashboardCard jobPost={jobs[i]} getCandidateProfile={getCandidateProfile}/></Grid>);
    }

    return (
        <div>
            <SectionTitle variant="h4">Open Jobs</SectionTitle>
            <Grid container spacing={1}>
            {jobCards}
            </Grid>
        </div>
        
    )
}
