import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  CardHeader,
  Divider,
  CardContent,
  Slider,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import DateFnsUtils from "@date-io/date-fns";
import { setIn, useFormik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/lab";
import axios from "axios";
import moment from "moment";
import {
  POST_PROFILE_EDUCATION_URL,
  DELETE_PROFILE_EDUCATION_URL,
} from "src/utils/constants";

import { DegreeType } from "src/utils/constants";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));

const EducationEditDialog = ({
  open,
  setOpen,
  info,
  user,
  refetch,
  addFlag,
}) => {
  const classes = useStyles();
  const [initVal, setInitVal] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (addFlag) {
      setInitVal({
        degree: "",
        major: "",
        college: "",
        yearOfCompletion: "",
      });
    } else {
      setInitVal({
        degree: info.degree,
        major: info.major,
        college: info.college,
        yearOfCompletion: info.yearOfCompletion,
      });
    }
  }, [addFlag, info]);
  const deleteEducation = () => {
    axios
      .delete(
        DELETE_PROFILE_EDUCATION_URL +
          info.id +
          "/?userCode=" +
          user.get.userCode
      )
      .then(function (response) {
        console.log(response);
        refetch();
        setOpen(false);
      })
      .catch((err) => console.log(err));
  };
  const validationSchema = yup.object({
    degree: yup
      .string("Must be valid text")
      .oneOf(["Bachelors", "Masters", "Doctoral"])
      .required("Degree is required"),
    major: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(45, "Must be less than 45 characters")
      .required("Major is required"),
    college: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(45, "Must be less than 45 characters")
      .required("College is required"),
    yearOfCompletion: yup
      .number("Must be a valid year")
      .typeError("Year is required")
      // .min(1900, "Invalid Date")
      .min(1900, "Year should be greater than 1900")
      .max(
        new Date().getFullYear() + 5,
        "Year should be less than " + (new Date().getFullYear() + 5)
      )
      .required("Year is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initVal,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (addFlag) {
        const valuesWebService = {
          degree: values.degree,
          major: values.major,
          college: values.college,
          // yearOfCompletion: values.yearOfCompletion,
          yearOfCompletion: values.yearOfCompletion,
        };

        axios
          .post(POST_PROFILE_EDUCATION_URL, valuesWebService, {
            params: { userCode: user.get.userCode },
          })
          // .post(
          //   `${POST_PROFILE_EDUCATION_URL}/?userCode=${user.user.get.userCode}`,
          //   valuesWebService
          // )
          .then(function (response) {
            console.log(response);
            setOpen(false);
            refetch();
          })
          .catch((err) => console.log(err));
      } else {
        const valuesWebService = {
          id: info.id,
          degree: values.degree,
          major: values.major,
          college: values.college,
          yearOfCompletion: parseInt(values.yearOfCompletion),
        };

        axios
          .put(POST_PROFILE_EDUCATION_URL, valuesWebService, {
            params: { userCode: user.get.userCode },
          })
          .then(function (response) {
            console.log(response);
            setOpen(false);
            refetch();
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        {addFlag ? (
          <Typography variant="h6">Add New Education</Typography>
        ) : (
          <Typography variant="h6">Edit Education</Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item sm={6} md={6} xs={12}>
              <TextField
                fullWidth
                label="Degree*"
                className={classes.field}
                name="degree"
                onChange={(e, val) => {
                  formik.handleChange(e);
                  formik.values.degree = val;
                }}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.degree}
              >
                {DegreeType.length > 0 &&
                  DegreeType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </TextField>
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <TextField
                name="major"
                label="Major*"
                value={formik.values.major}
                onChange={formik.handleChange}
                error={formik.touched.major && Boolean(formik.errors.major)}
                helperText={formik.touched.major && formik.errors.major}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <TextField
                name="college"
                label="College*"
                value={formik.values.college}
                onChange={formik.handleChange}
                error={formik.touched.college && Boolean(formik.errors.college)}
                helperText={formik.touched.college && formik.errors.college}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                clearable
                inputVariant="outlined"
                views={["year"]}
                margin="normal"
                name="yearOfCompletion"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Year of Completion*"
                    className={classes.field}
                    error={
                      formik.touched.yearOfCompletion &&
                      Boolean(formik.errors.yearOfCompletion)
                    }
                    helperText={
                      formik.touched.yearOfCompletion &&
                      formik.errors.yearOfCompletion
                    }
                  />
                )}
                value={String(formik.values.yearOfCompletion)}
                onChange={(date) => {
                  console.log(moment(date).format("YYYY"));
                  console.log(date);
                  formik.setFieldValue(
                    "yearOfCompletion",
                    moment(date).format("YYYY")
                  );
                }}
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 5))
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.field}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!addFlag && (
            <Button
              style={{ color: "#FF4A4A" }}
              onClick={() => deleteEducation()}
            >
              Delete
            </Button>
          )}
          <Button color="primary" type="submit">
            {addFlag ? "Add Education" : "Save changes"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EducationEditDialog;
