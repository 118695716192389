import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import Typography from "src/designsystem/Core/Typography";
const CSSider = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  border-radius: 8px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
  gap: 15px;
`;

const Header = styled(Typography)`
  font-weight: 500;
  font-size: 0.9rem;
  color: gray;
`;

const CSSiderStep = styled.div`
  border-radius: 8px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  gap: 15px;
  padding: 15px 15px;
`;

const useStyles = makeStyles((theme) => ({
  step: {
    marginBottom: 5,
    padding: "5px 5px",
    width:200,
    border: "1px solid transparent",
    borderRadius: 5,
    borderLeft: `2px solid transparent`,
    color: theme.palette.text.primaryBlue,
  },
  activeStep: {
    border: "1px solid #CAD3E6",
    borderLeft: `2px solid ${theme.palette.text.primaryBlue}`,
  },
  incompleteStep: {
    color: "gray",
  },
  stepIcon: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
}));

const SideBar = (props) => {
  const classes = useStyles();

  return (
    <CSSider>
      <div>
        <div>
          {props.steps.map((step, index) => {
            const Icon = step.icon;
            return (
              <CSSiderStep>
                <div
                  className={clsx(
                    classes.step,
                    step.active && classes.activeStep,
                    !step.active && !step.completed && classes.incompleteStep
                  )}
                  key={step.name}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <Icon className="stepIcon" />
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                      }}
                    >
                      <Header variant="label">
                        <span>{step.name}</span>
                      </Header>

                    </div>
                    {!step.active && step.completed ? (
                      <CheckCircleIcon />
                    ) : null}
                  </div>
                </div>
              </CSSiderStep>
            );
          })}
        </div>
      </div>
    </CSSider>
  );
};

export default SideBar;
