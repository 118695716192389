import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import JobDetailEntity from "src/views/candidate/CandidateJobView/state/models";
import { useDispatch } from "react-redux";

import {
  Box,
  Container,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  FormLabel,
  Radio,
  DialogActions,
  Button,
  Typography,
  Grid,
  Drawer,
  Hidden,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Page from "src/components/Page";
import PortfolioStepper from "../../../components/steps/PortfolioStepper";
import UserContext from "src/context/user-context";
import FeedCard from "src/components/cards/feed-card";
import axios from "axios";
import { C_AND_JOB_REC_URL } from "src/utils/constants";
import CandidateInfoCard from "./InfoCard";
import { useTheme } from "@mui/material/styles";

import {
  CredentialsLeft,
  CredentialsRight,
} from "src/views/candidate/CandidateDetails/Credentials";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Rowing } from "@mui/icons-material";
import CandidateWelcome from "./CandidateWelcome";
import { trackEvent } from "src/utils/analytics";
import JobCardMini from "src/derivedComponents/JobCardMini";

// TODO: get from theme/constants
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  span: {
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      minWidth: "35px",
      minHeight: "66px",
    },
  },
  hello: {
    fontWeight: "400",
    fontSize: "2rem",
    letterSpacing: "1.1px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "27px",
      fontCharacter: 0,
      color: "#3D4347",
      verticalAlign: "top",
      horizontalAlign: "left",
      display: "flex",
    },
    marginTop: "25px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogPaper: {
    minHeight: "90vh",
  },
  desktopDrawer: {
    maxWidth: 280,
    width: "100%",
    top: 65,
    height: "calc(100% - 64px)",
    borderRight: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerPaper: {
    width: drawerWidth,
    top: 65,
    height: "calc(100% - 64px)",
    borderRight: 0,
    backgroundColor: "#fffefe",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      top: 0,
      padding: 0,
    },
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    width: `calc(100% - ${drawerWidth}px)`,
    padding: theme.spacing(3),
  },
  customAlert: {
    backgroundColor: "#FFE996",
  },
  alertIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -25,
      padding: 0,
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    direction: "row",
    whiteSpace: "no-wrap",
    flexWrap: "wrap",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
  spanJob: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      marginBottom: "-20px",
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [indiDialogOpen, setIndiDialogOpen] = React.useState(false);

  var user_code = userContext.user.get.userCode;

  const [profileType, setProfileType] = React.useState("individual");

  const [jobRecs, setJobRecs] = React.useState([]);

  const [candidateProfile, setCandidateProfile] = React.useState({});

  const [alertOpen, setAlertOpen] = React.useState(true);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [defaultDashboard, setDashboard] = React.useState(false);

  const handleProfileTypeChange = (e) => {
    setProfileType(e.target.value);
  };

  const [profileSelectorDialogOpen, setProfileSelectorDialogOpen] =
    React.useState(false);

  useEffect(() => {
    // code to run on component mount
    if (userContext.user.firstTimeLogin) {
      setProfileSelectorDialogOpen(true);
    } else {
      //getJobRecommendations(userContext.user.get.userCode);
    }
  }, [userContext]);

  const handleClose = () => {
    // do nothing
    setIndiDialogOpen(false);
  };

  const handleProfileSelectorClose = () => {
    setProfileSelectorDialogOpen(false);
  };

  const handleProfileSelector = (e) => {
    setProfileSelectorDialogOpen(false);
    var userObj = userContext.user.get;
    if (profileType === "individual") {
      userObj.isAgency = false;
    } else {
      userObj.isAgency = true;
    }
    userContext.user.set(userObj);
    setIndiDialogOpen(true);
  };

  useEffect(() => {
    let mounted = true;
    // code to run on component mount
    if (userContext.user.get.firstTimeLogin) {
      // Once Agency flow is there uncomment below line and remove setIndiDialogOpen(true);
      //to show the agency/individual selection dialog
      //setProfileSelectorDialogOpen(true);
      var userObj = userContext.user.get;
      userObj.isAgency = false;
      userContext.user.set(userObj);
      //setIndiDialogOpen(true);
      setDashboard(true);
    } else {
      axios({
        method: "GET",
        url: C_AND_JOB_REC_URL + "?userCode=" + userContext.user.get.userCode,
      })
        .then((response) => {
          console.log(response);
          setDashboard(false);
          setIndiDialogOpen(false);
          setCandidateProfile(response.data.candidateProfile);
          setJobRecs(response.data.jobRecs.jobs);
        })
        .catch((error) => {
          //handleServerResponse(false, error.response.data.error);
        });
    }

    return () => (mounted = false);
  }, [userContext]);

  const dispatch = useDispatch();

  function handleApplication(job) {
    let newRecs = [...jobRecs];
    let match = newRecs.find((t) => t.id === job.id);
    if (match) {
      match.candidateHasApplied = true;
    }
    setJobRecs(() => newRecs);
  }

  function handleBookmark(job) {
    let newRecs = [...jobRecs];
    let match = newRecs.find((t) => t.id === job.id);
    if (match) {
      match.bookmarked = !match.bookmarked;
    }
    setJobRecs(() => newRecs);
  }

  const mainDashboard = (
    <React.Fragment>
      <Page className={classes.root} title="Dashboard">
        <Dialog
          open={indiDialogOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
          classes={{ paper: classes.dialogPaper }}
          disableEscapeKeyDown
          fullScreen={fullScreen}
        >
          <DialogContent>
            <PortfolioStepper />
          </DialogContent>
        </Dialog>
        <Dialog
          open={profileSelectorDialogOpen}
          onClose={handleProfileSelectorClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
          disableEscapeKeyDown
        >
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Please select from below</FormLabel>
              <RadioGroup
                defaultValue="individual"
                aria-label="profileSelector"
                name="profile-selector"
                value={profileType}
                onChange={handleProfileTypeChange}
              >
                <FormControlLabel
                  value="individual"
                  control={<Radio />}
                  label="I am a freelancer"
                />
                <FormControlLabel
                  value="agency"
                  control={<Radio />}
                  label="I represent an agency"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleProfileSelector();
              }}
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>

        <Box component="span" m={1} color="text.primary">
          <Collapse in={alertOpen}>
            <Alert
              icon={false}
              severity="success"
              className={classes.customAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                  className={classes.alertIcon}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Avail Comprehensive Health Insurance Exclusively for members of
              GigVistas {"  "}
              &nbsp; &nbsp;
              <Hidden smUp>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.open(
                        "https://gigvistas.com/benefits/insurance/?utm_source=webapp&utm_medium=gigvistas"
                      );
                    }}
                  >
                    Find Out More
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      trackEvent("clicked_buy_insurance_alyve", "Button");
                      window.open(
                        "https://programs.alyve.health/programs/affinity-insurance?org=gigvistas"
                      );
                    }}
                  >
                    Buy Insurance
                  </Button>
                </div>
              </Hidden>
              <Hidden smDown>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.open(
                      "https://gigvistas.com/benefits/ensurance/?utm_source=webapp&utm_medium=gigvistas"
                    );
                  }}
                >
                  Find Out More
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    trackEvent("clicked_buy_insurance_alyve", "Button");
                    window.open(
                      "https://programs.alyve.health/programs/affinity-insurance?org=gigvistas"
                    );
                  }}
                >
                  Buy Insurance
                </Button>
              </Hidden>
            </Alert>
          </Collapse>
          <Grid container className={classes.flex}>
            <Typography
              variant={"h3"}
              gutterBottom
              display="block"
              className={classes.hello}
            >
              {" "}
              <span role="img" aria-label="hello">
                👋{" "}
              </span>
              Welcome, {userContext.user.get.firstName}
            </Typography>
            <div
              item
              xs={8}
              justify="space-between"
              class={classes.buttonGroup}
            >
              <Link to="/c/search">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  width="10px"
                >
                  Search Gigs
                </Button>
              </Link>
              <Link to="/c/candidate">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Edit Profile
                </Button>
              </Link>
            </div>
          </Grid>
        </Box>
        <CandidateInfoCard candidateProfile={candidateProfile} />

        <Box component="span" m={1} color="text.secondary">
          <h4 className={classes.spanJob}> Recommended Jobs</h4>
        </Box>
        {/* {jobRecs.map((jobRec, key) => (
          // <FeedCard
          //   left={<CredentialsLeft entry={jobRec} />}
          //   right={
          //     <CredentialsRight
          //       entry={jobRec}
          //       handleApplication={handleApplication}
          //       handleBookmark={handleBookmark}
          //     />
          //   }
          //   leftWidth="70vw"
          //   rightWidth="30vw"
          //   key={key}
          // />
        ))} */}
        <div style={{ display: "grid", gap: "10px" }}>
          {jobRecs &&
            jobRecs.map((jobRec) => (
              <JobCardMini
                dispatch={dispatch}
                jobDetailEntity={jobRec}
                jobID={jobRec.id}
                user_code={user_code}
                jobDetails={jobRec}
                organization={{
                  logo: jobRec.logo ?jobRec.logo[0]:null,
                  name: jobRec.companyName[0],
                }}
              />
            ))}
        </div>
      </Page>
    </React.Fragment>
  );

  return <div>{defaultDashboard ? <CandidateWelcome /> : mainDashboard}</div>;
};

export default Dashboard;
