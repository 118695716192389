import { MESSAGE_SERVICE_WEBSOCKET_URL } from "src/utils/constants";

export default {
  openConnection: (sender, onmessage) => {
    if (window["WebSocket"]) {
      const conn = new WebSocket(
        MESSAGE_SERVICE_WEBSOCKET_URL + "?user=" + sender
      );
      conn.onclose = function (evt) {
        //TODO: Handle Close COnnection
      };
      conn.onmessage = function (evt) {
        var messages = evt.data.split("\n");
        for (var i = 0; i < messages.length; i++) {
          messages[i] && onmessage(JSON.parse(messages[i]));
        }
      };

      return conn;
    }
  },
  sendMessage: (sender, receiver, message, connection) => {
    connection.send(
      JSON.stringify({
        sender: sender,
        receiver: receiver,
        message: message,
        type: "MESSAGE",
      })
    );
  },
  readMessage: (sender, receiver, connection) => {
    connection.send(
      JSON.stringify({
        sender: sender,
        receiver: receiver,
        message: "",
        read: true,
        type: "MESSAGE",
      })
    );
  },

  //Notifications
  readNotification: (sender, connection) => {
    connection.send(
      JSON.stringify({
        sender: sender,
        receiver: "",
        message: "",
        read: true,
        type: "NOTIFICATION",
      })
    );
  },
};
