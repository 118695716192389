import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { GET_CANDIDATE_COMPLETE_SCORE } from "src/utils/constants";

import styled from "@emotion/styled";

import OnBoardingContext from "src/context/onboarding-context";
import UserContext from "src/context/user-context";
import axios from "axios";

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const GVProgress = styled.div``;

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        borderRadius: "100px",
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          borderRadius: "200px",
          background: "#fefcff",
          border: "1px solid grey",
          // boxShadow: "inset 2px 2px 2px 2px cyan",
          // backgroundColor: "transparent",
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface CandidateProfileScoreProps {
  completePoints: number;
  totalPoints: number;
  userCode: string;
}

const CandidateProfileScore: FC<CandidateProfileScoreProps> = (props) => {
  const onBoardingContext = useContext(OnBoardingContext);
  const userContext = useContext(UserContext);
  const [completePoints, setCompletePoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(1);

  // useEffect(() => {
  //   const dummyData = {
  //     summary: 1,
  //     title: 1,
  //     category: 1,
  //     expertise: 0,
  //     overallExperience: 1,
  //     skills: 0,
  //     workExperience: 0,
  //     educationDetails: ,
  //     certificationDetails: 0,
  //     pinCode: 0,
  //     availabilityPref: 0,
  //     expectedRate: 0,
  //     locationType: 0,
  //     profilePicture: 0,
  //     projects: 0,
  //     attachments: 0,
  //   };
  //   setCompletePoints(
  //     Object.values(dummyData).filter((value) => value === 1).length
  //   );
  //   setTotalPoints(Object.values(dummyData).length);
  // }, [onBoardingContext.getCurrentStep, userContext]);

  const calculateScore = (data: any) => {
    setCompletePoints(
      Object.values(data).filter((value) => value === 1).length
    );
    setTotalPoints(Object.values(data).length);
  };

  useEffect(() => {
    axios({
      url:
        GET_CANDIDATE_COMPLETE_SCORE +
        "?userCode=" +
        userContext.user.get.userCode,
      method: "GET",
    }).then((response) => {
      calculateScore(response.data);
    });
  }, [onBoardingContext.getCurrentStep, userContext]);

  return (
    <GVProgress>
      <CircularProgressWithLabel
        value={(100 * completePoints) / totalPoints}
        size={150}
      />
      <div
        style={{
          color: "grey",
          fontStyle: "italic",
          fontSize: "0.85rem",
          margin: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        Completeness Score
      </div>
    </GVProgress>
  );
};

export default CandidateProfileScore;
