  import React, { useContext } from "react";

  import { breakpoints, colors } from "src/designsystem/theme";
  import Logo from "src/components/Logo";
  import styled from "@emotion/styled";
  import Typography from "src/designsystem/Core/Typography";
  import Button from "src/designsystem/Core/Button";
  const CSFooter = styled.div`
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  grid-template-columns:1fr 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
  `;

  const CSFooterLeft = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    grid-template-columns: 1fr;
    padding: 10px;
  `;
  const CSFooterRight = styled.div`
    display: flex;
    gap: 15px;
    padding: 20px 20px 20px 20px;
    justify-content: flex-end;
    grid-template-columns: 1fr 1fr;
    @media (min-width: ${breakpoints["lg"]}) {
      grid-template-columns: 200px 1fr;
      gap: 10px;
      padding: 20px;
    }
  `;
  export default function Footer(props) {
    return (
      <CSFooter>
      
          <CSFooterLeft>
              <Logo />
          </CSFooterLeft>

          <CSFooterRight>
          {props.currentStep === 1 || props.currentStep === 2 ? (
            <Button
              color="primary"
              size="small"
              onClick={props.handleBack}
              variant="outlined"
            >
             
              Back
             
            </Button>
             ):<></>}
            {props.currentStep === 2 ? (
            <Button
              color="primary"
              size="small"
              onClick={props.callSubmit}
              variant="contained"
          
            >
              
                Create Contract
         
            </Button>
            ) : (
              <Button
              color="primary"
              size="small"
              onClick={props.callSubmit}
              variant="contained"
             
            >
             
                Next
           
            </Button>
            )}
          </CSFooterRight>
    
      </CSFooter>
    );
  }
