import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rewardService from "./rewardService";

const initialState = [];

export const getCandidateReward = createAsyncThunk(
  "candidate/rewards",
  async (userCode) => {
    const res = await rewardService.getReward(userCode);
    return res.data;
  }
);

export const getCandidateReferral = createAsyncThunk(
  "candidate/referral",
  async ({ referralUserCode, referredToUser }) => {
    const res = await rewardService.getReferral({referralUserCode, referredToUser});
    return res.data;
  }
);

const RewardReducers = createSlice({
  name: "rewards",
  initialState,
  extraReducers: {
    [getCandidateReward.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [getCandidateReferral.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
  },
});




export default RewardReducers.reducer;