//TODO: Environment variables


export const BASE_URL = "https://app.gigvistas.com/";


export const USER_SERVICE = BASE_URL + "user-service/api/v1/user";

export const WEB_APP_API_SERVICE = BASE_URL + "web-app-api/gig/api/v1";
export const MESSAGE_SERVICE = BASE_URL + "message-service/api";
export const PARTNER_SERVICE = BASE_URL + "partner-service/api/v1";

export const MESSAGE_SERVICE_WEBSOCKET_URL =
  "wss://app.gigvistas.com/message-service/ws";
export const MESSAGE_CHANNEL_LIST_URL  = "https://app.gigvistas.com/mpn-service/api/messages/user-channel?userCode=";
export const MESSAGE_CHAT_URL  = "https://app.gigvistas.com/mpn-service/api/messages/";
export const UNREAD_MESSAGE_URL  = "https://app.gigvistas.com/mpn-service/api/messages/unread";
export const READ_MESSAGE_URL  = "https://app.gigvistas.com/mpn-service/api/messages/read";

// export const USER_SERVICE = "http://localhost:8080/user-service/api/v1/user";
// export const WEB_APP_API_SERVICE =
//   "http://localhost:8085/web-app-api/gig/api/v1";

// export const MESSAGE_SERVICE = "http://localhost:3000/message-service/api";
// export const MESSAGE_SERVICE_WEBSOCKET_URL = "ws://localhost:3000/message-service/ws";
export const SHARE_JOBDETAIL_URL = "https://gigvistas.com/gigs/detail/";
export const LOGIN_URL = USER_SERVICE + "/login";
export const LOGOUT_URL = USER_SERVICE + "/logout";
export const REGISTER_URL = USER_SERVICE + "/";
export const UPDATE_USER_PROFILE_URL = USER_SERVICE + "/";
export const EMAIL_VERIFIED_URL = USER_SERVICE + "/account/email/verified";
export const SEND_EMAIL_VERIFICATION_LINK_URL =
  USER_SERVICE + "/account/verification-email-link";
export const RESET_PASSWORD_EMAIL_URL = USER_SERVICE + "/reset/password";
export const MESSAGES_URL = MESSAGE_SERVICE + "/messages";
export const UNREAD_MESSAGES_URL = MESSAGE_SERVICE + "/messages/unread";

export const GET_USER_PROFILES_URL = USER_SERVICE + "/profiles";

export const DELETE_ACCOUNT_URL = USER_SERVICE + "/delete-account";
export const GET_USER_REWARD_URL = USER_SERVICE + "/reward";
export const GET_USER_REFERRAL_URL = USER_SERVICE + "/reward/referral";

export const NOTIFICATIONS_URL = MESSAGE_SERVICE + "/notifications";
export const UNREAD_NOTIFICATIONS_URL =
  MESSAGE_SERVICE + "/notifications/unread";

export const JOB_CATEGORY_URL =
  WEB_APP_API_SERVICE + "/skills/master?categories=";

export const CANDIDATE_CATEGORY_URL =
  WEB_APP_API_SERVICE + "/skills/master/expertise";

export const CANDIDATE_CATEGORY_EXPERTISE_URL =
  WEB_APP_API_SERVICE + "/skills/master/expertise/multi-skills";


export const CREATE_CANDIDATE_URL = WEB_APP_API_SERVICE + "/candidate";

export const UPDATE_CANDIDATE_URL = WEB_APP_API_SERVICE + "/candidate/";

export const UPDATE_CANDIDATE_PREFERENCE_URL =
  WEB_APP_API_SERVICE + "/candidate/preference";

export const GET_CANDIDATE_URL = WEB_APP_API_SERVICE + "/candidate/";

export const POST_CANDIDATE_URL = WEB_APP_API_SERVICE + "/candidate/";

export const JOB_APPLICATION_URL = WEB_APP_API_SERVICE + "/candidate/job-app";

export const GET_CANDIDATE_JOB_APPLICATIONS =
  WEB_APP_API_SERVICE + "/candidate/get-app";

export const GET_CANDIDATE_JOB_APPLICATION_CONFIRMATION =
  WEB_APP_API_SERVICE + "/candidate/pre-apply-checker";

export const POST_JOB_URL = WEB_APP_API_SERVICE + "/jobPost";

export const POST_DRAFT_URL = POST_JOB_URL + "/draft-jobs";

export const POST_DRAFT_ACTIVATE_URL = POST_JOB_URL + "/draft-jobs/activate";

export const DELETE_JOB_URL =
  WEB_APP_API_SERVICE + "/jobPost/job-post-status/delete";

export const DELETE_DRAFT_JOB_URL = WEB_APP_API_SERVICE + "/jobPost/draft-jobs";

export const GET_DRAFT_JOB_URL = WEB_APP_API_SERVICE + "/jobPost/draft-jobs";

export const GET_DRAFT_DETAIL = WEB_APP_API_SERVICE + "/jobPost/draft-job";

export const UPDATE_JOB_URL = WEB_APP_API_SERVICE + "/employer/updateJob";

export const UPDATE_PUT_JOB_URL = WEB_APP_API_SERVICE + "/jobPost";

export const GET_JOBID_URL = WEB_APP_API_SERVICE + "/employer/jobId";

export const GET_CANDIDATE_JOBID_URL = WEB_APP_API_SERVICE + "/candidate/job/";

export const GET_JOB_BOOKMARKS = WEB_APP_API_SERVICE + "/bookmarks/candidate";

export const GET_CANDIDATE_BOOKMARKS =
  WEB_APP_API_SERVICE + "/bookmarks/employer";

export const CREATE_JOB_BOOKMARK =
  WEB_APP_API_SERVICE + "/bookmarks/addCandidateBookmark";

export const REMOVE_JOB_BOOKMARK =
  WEB_APP_API_SERVICE + "/bookmarks/removeCandidateBookmark";

export const CREATE_CANDIDATE_BOOKMARK =
  WEB_APP_API_SERVICE + "/bookmarks/addEmployerBookmark";

export const REMOVE_CANDIDATE_BOOKMARK =
  WEB_APP_API_SERVICE + "/bookmarks/removeEmployerBookmark";

export const SHORTLIST_CANDIDATE_URL = WEB_APP_API_SERVICE + "/short-list";

export const REMOVE_SHORTLIST_CANDIDATE_URL =
  WEB_APP_API_SERVICE + "/short-list";

export const GET_JOB_APPLICANTS_URL =
  WEB_APP_API_SERVICE + "/employer/jobApplicants";

export const GET_JOB_APPLICANTS_PAGINATION_URL =
  WEB_APP_API_SERVICE + "/employer/jobApplicants-pagination";

export const GET_JOB_SHORTLIST_APPLICANTS_URL =
  WEB_APP_API_SERVICE + "/employer/shortlisted";

export const CLOSED_JOB_URL =
  WEB_APP_API_SERVICE + "/jobPost/job-post-status/close";

export const GET_CANDIDATE_RECOMMENDATIONS =
  WEB_APP_API_SERVICE + "/candidate/search/candidate-rec";

export const GET_CANDIDATE_RECOMMENDATIONS_MULTIPLE =
  WEB_APP_API_SERVICE + "/candidate/search/candidate-rec-multiple";

export const GET_JOB_ID = WEB_APP_API_SERVICE + "/employer/jobId";

//export const UPDATE_JOB_URL = WEB_APP_API_SERVICE + "/employer/addJobs";

export const GET_EMPLOYER_JOBS_URL = WEB_APP_API_SERVICE + "/employer/jobs/";

export const GET_CONTACT_URL = WEB_APP_API_SERVICE + "/contact";

export const UPDATE_CANDIDATE_EXPERTISE_URL =
  CREATE_CANDIDATE_URL + "/expertise";

export const UPDATE_CANDIDATE_EXPERIENCE_URL =
  CREATE_CANDIDATE_URL + "/experience";

export const UPDATE_CANDIDATE_ED_AND_CERT_URL =
  CREATE_CANDIDATE_URL + "/ed-cert";

export const JOB_SEARCH_SERVICE_URL = WEB_APP_API_SERVICE + "/job/search";

export const JOB_RECOMMENDATION_URL = JOB_SEARCH_SERVICE_URL + "/job-rec";

export const C_AND_JOB_REC_URL = CREATE_CANDIDATE_URL + "/c-rec";

export const CANDIDATE_SEARCH_URL = WEB_APP_API_SERVICE + "/candidate/search";

export const ORGANIZATION_URL = WEB_APP_API_SERVICE + "/organization";

export const ORGANIZATION_URL_V2 = USER_SERVICE + "/organization/onboard/organization-employer";

export const UPDATE_ORGANIZATION_URL = USER_SERVICE + "/organization/emp-org";

export const POST_CONTRACT_URL = WEB_APP_API_SERVICE + "/contract";

export const GET_EMPLOYER_CANDIDATE_URL =
  WEB_APP_API_SERVICE + "/candidate/employer-candidate";
export const GET_EMPLOYEE_CONTRACT_URL =
  WEB_APP_API_SERVICE + "/employer/contracts";

export const GET_CANDIDATE_CONTRACT_URL =
  WEB_APP_API_SERVICE + "/candidate/contracts";

export const CONTRACT_SUMMARY_URL = WEB_APP_API_SERVICE + "/contract/";

export const CONTRACT_DELIVERABLES_URL =
  WEB_APP_API_SERVICE + "/contract/deliverables";

export const CONTRACT_TIMEBASED_URL =
  WEB_APP_API_SERVICE + "/contract/time-based";

export const CONTRACT_MILESTONE_URL =
  WEB_APP_API_SERVICE + "/contract/milestone";
export const CONTRACT_SAVE_MILESTONE_URL =
  WEB_APP_API_SERVICE + "/contract/milestone";

export const CONTRACT_LIST_URL =
  WEB_APP_API_SERVICE + "/contract/rec-contracts";

export const CONTRACT_REC_ACTIVELIST_URL =
  WEB_APP_API_SERVICE + "/contract/rec-active-contracts";

export const CONTRACT_FILEUPLOAD_URL =
  WEB_APP_API_SERVICE + "/contract/attachments";

export const CONTRACT_DELETE_ATTACHMENT_URL =
  WEB_APP_API_SERVICE + "/contract/attachment/";

export const CONTRACT_DOWNLOAD_ATTACHMENT_URL =
  WEB_APP_API_SERVICE + "/contract/attachment/";

export const CONTRACT_ATTACHMENT_URL =
  WEB_APP_API_SERVICE + "/contract/attachments";

export const CONTRACT_DETAIL_URL = WEB_APP_API_SERVICE + "/contract/";

export const UPLOAD_AVATAR_URL = WEB_APP_API_SERVICE + "/candidate/profile-pic";

//Candidate edit API's
export const PUT_PROFILE_HIGHLIGHTS_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/highlights";

export const PUT_PROFILE_PREFERENCE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/expertise-and-preferences-summary";

export const PUT_PROFILE_SUMMARY_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/summary";

export const POST_PROFILE_WORKEX_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/work-ex";

export const DELETE_PROFILE_WORKEX_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/work-ex/";

export const POST_PROFILE_EDUCATION_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/education";

export const DELETE_PROFILE_EDUCATION_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/education/";

export const POST_PROFILE_CERTIFICATE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/cert";

export const DELETE_PROFILE_CERTIFICATE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/cert/";

export const POST_PROFILE_FILE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/file";

export const GET_PROFILE_FILE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/file/";

export const DELETE_PROFILE_FILE_URL =
  WEB_APP_API_SERVICE + "/candidate/profile/file/";

export const PROJECT_URL = WEB_APP_API_SERVICE + "/candidate/profile/project";

export const GET_CANDIDATE_COMPLETE_SCORE =
  WEB_APP_API_SERVICE + "/candidate-utils/profile-score";

export const GET_CANDIDATE_PROFILE_TRACKER =
  WEB_APP_API_SERVICE + "/candidate-utils/profile-tracker";

// Candidate's View
export const GET_SIMILAR_GIGS =
  WEB_APP_API_SERVICE + "/job/search/get-similar-jobs";

// Candidate Profile Setup State API
export const GET_CANDIDATE_PROFILE_SETUP_STATE =
  WEB_APP_API_SERVICE + "/candidate-utils/profile-setup";

// Recruiter Profile Setup State API
export const GET_RECRUITER_PROFILE_SETUP_STATE =
  WEB_APP_API_SERVICE + "/employer-utils/profile-setup";

export const UPLOAD_ORG_LOGO_URL = WEB_APP_API_SERVICE + "/organization/logo";

export const UPLOAD_ORG_LOGO_URL_V2 = USER_SERVICE + "/organization/logo";

export const GET_EMP_ORG_URL = USER_SERVICE + "/organization/organization-employer";

// PARTNER SERVICE APIS

export const USER_PARTNER_PRODUCT_URL = PARTNER_SERVICE + "/product";

// JD generation
export const GENERATE_JD_URL = WEB_APP_API_SERVICE + "/jobPost/generate-jd";

// new onboarding APIs
export const ONBOARD_CANDIDATE_EXPERTISE = USER_SERVICE + "/onboard/individual-candidate/expertise";


// pixels  
export const GLOGIN_URL = USER_SERVICE + "/glogin";

export const GA_MEASUREMENT_ID = "UA-216147963-1";

export const GA_MEASUREMENT_ID_GOOGLE_ADS = "AW-318365323";

export const GA_GTM = "GTM-W2SDJVL";

export const FB_PIXEL_ID = "247619617256374";

export const RECRUITER_SEARCH_RESULT_DOTTED_MENU_OPTION = [
  {
    id: "Initiate Gig",
    name: "Initiate Gig",
  },
];

export const RECRUITER_JOB_DOTTED_MENU_OPTION = [
  {
    id: "Close",
    name: "Close Job",
  },
  {
    id: "Delete",
    name: "Delete Job",
  },
];

export const JOB_LIST = [
  { value: "0", label: " Select the job" },
  { id: "1", category: "Select the job" },
];

export const JOB_CATEGORY_LIST = [
  {
    value: "Data Science and Analytics",
    label: "Data Science and Analytics",
  },
  {
    value: "Web and mobile development",
    label: "Web and mobile development",
  },
  { value: "IT and Networking", label: "IT and Networking" },
  { value: "Writing", label: "Writing" },
  { value: "Creative And Media Art", label: "Creative And Media Art" },
  { value: "Sales and Marketing", label: "Sales and Marketing" },
  {
    value: "Engineering And Architecture",
    label: "Engineering And Architecture",
  },
  { value: "Accounting and Finance", label: "Accounting and Finance" },
  {
    value: "HR and Organisational Development",
    label: "HR and Organisational Development",
  },
  { value: "Legal", label: "Legal" },
  {
    value: "General Management and Strategy",
    label: "General Management and Strategy",
  },
  { value: "Admin and Customer Support", label: "Admin and Customer Support" },
];

export const CANDIDATE_CATEGORY_LIST = [
  "Data Science and Analytics",
  "Web and mobile development",
  "IT and Networking",
  "Writing",
  "Creative And Media Art",
  "Sales and Marketing",
  "Engineering And Architecture",
  "Accounting and Finance",
  "HR and Organisational Development",
  "Legal",
  "General Management and Strategy",
  "Admin and Customer Support",
];


export const JOB_CATEGORY_EXPERTISE_LIST = [
  "Machine Learning ",
  "Data Engineering",
  "Data processing",
  "Artificial Intelligence",
  "NLP software engineering",
  "Deep Learning ",
  "A/B Testing",
  "Data Extraction",
  "Data Mining",
  "Data Visualisation",
  "Experimentation and testing",
  "",
];

export const JOB_CATEGORY_SKILL_LIST = [
  "Java",
  "Java Script",
  "C++",
  "C",
  "Python",
  "Linux",
  "Unix",
  "SQL",
  "MySQL",
  "NoSQL",
  "MongoDB",
  "AWS",
  "Azure",
  "Docker",
  "Kubernetes",
  "GCP",
  "Statistics",
  "Mathematics",
  "MATLAB",
  "Excel",
  "SAS",
  "SPSS",
  "R",
  "Tableau",
  "Algorithm",
  "Data Mining",
  "Data Extraction",
  "Data Management",
  "Data Visualisation",
  "Data Structures",
  "Data Analysis",
  "Tensor Flow",
  "Microsoft Cognitive Toolkit",
  "Predictive Modelling",
  "Regression",
  "Deep learning",
  "Machine Learning",
  "Power Bi",
];

export const TimePreference = [
  { value: "Days", label: "Days" },
  { value: "Hours", label: "Hours" },
  { value: "Month", label: "Month" },
];

export const PerTimePreference = [
  { value: "Per Day", label: "Per Day" },
  { value: "Per-Hour", label: "Per Hour" },
  { value: "Per Month", label: "Per Month" },
];

export const ProjectType = [
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
];

export const CurrencyIcon = {
  USD: "$",
  INR: "₹",
};

export const CurrencyFormat = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];

export const LocationPreference = [
  { value: "Remote", label: "Remote" },
  { value: "Onsite", label: "Onsite" },
  { value: "Hybrid", label: "Hybrid" },
];

export const TimingPreference = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Flexible", label: "Flexible" },
];

export const DegreeType = [
  { value: "", label: "" },
  { value: "Bachelors", label: "Bachelors" },
  { value: "Masters", label: "Masters" },
  { value: "Doctoral", label: "Doctoral" },
];

export const INDUSTRY_LIST = [
  { value: "", label: "" },
  { value: "Accounting", label: "Accounting" },
  { value: "Airlines/Aviation", label: "Airlines/Aviation" },
  {
    value: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
  },
  { value: "Alternative Medicine", label: "Alternative Medicine" },
  { value: "Animation", label: "Animation" },
  { value: "Apparel & Fashion", label: "Apparel & Fashion" },
  {
    value: "Architecture & Planning",
    label: "Architecture & Planning",
  },
  { value: "Arts & Crafts", label: "Arts & Crafts" },
  { value: "Automotive", label: "Automotive" },
  { value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
  { value: "Banking", label: "Banking" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Broadcast Media", label: "Broadcast Media" },
  { value: "Building Materials", label: "Building Materials" },
  {
    value: "Business Supplies & Equipment",
    label: "Business Supplies & Equipment",
  },
  { value: "Capital Markets", label: "Capital Markets" },
  { value: "Chemicals", label: "Chemicals" },
  {
    value: "Civic & Social Organization",
    label: "Civic & Social Organization",
  },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Commercial Real Estate", label: "Commercial Real Estate" },
  {
    value: "Computer & Network Security",
    label: "Computer & Network Security",
  },
  { value: "Computer Games", label: "Computer Games" },
  { value: "Computer Hardware", label: "Computer Hardware" },
  { value: "Computer Networking", label: "Computer Networking" },
  { value: "Computer Software", label: "Computer Software" },
  { value: "Construction", label: "Construction" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Consumer Services", label: "Consumer Services" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Dairy", label: "Dairy" },
  { value: "Defense & Space", label: "Defense & Space" },
  { value: "Design", label: "Design" },
  { value: "E-learning", label: "E-learning" },
  { value: "Education Management", label: "Education Management" },
  {
    value: "Electrical & Electronic Manufacturing",
    label: "Electrical & Electronic Manufacturing",
  },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Environmental Services", label: "Environmental Services" },
  { value: "Events Services", label: "Events Services" },
  { value: "Executive Office", label: "Executive Office" },
  { value: "Facilities Services", label: "Facilities Services" },
  { value: "Farming", label: "Farming" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Fine Art", label: "Fine Art" },
  { value: "Fishery", label: "Fishery" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Food Production", label: "Food Production" },
  { value: "Fundraising", label: "Fundraising" },
  { value: "Furniture", label: "Furniture" },
  { value: "Gambling & Casinos", label: "Gambling & Casinos" },
  {
    value: "Glass, Ceramics & Concrete",
    label: "Glass, Ceramics & Concrete",
  },
  { value: "Government Administration", label: "Government Administration" },
  { value: "Government Relations", label: "Government Relations" },
  { value: "Graphic Design", label: "Graphic Design" },
  {
    value: "Health, Wellness & Fitness",
    label: "Health, Wellness & Fitness",
  },
  { value: "Higher Education", label: "Higher Education" },
  { value: "Hospital & Health Care", label: "Hospital & Health Care" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Import & Export", label: "Import & Export" },
  {
    value: "Individual & Family Services",
    label: "Individual & Family Services",
  },
  { value: "Industrial Automation", label: "Industrial Automation" },
  { value: "Information Services", label: "Information Services" },
  {
    value: "Information Technology & Services",
    label: "Information Technology & Services",
  },
  { value: "Insurance", label: "Insurance" },
  { value: "International Affairs", label: "International Affairs" },
  {
    value: "International Trade & Development",
    label: "International Trade & Development",
  },
  { value: "Internet", label: "Internet" },
  { value: "Investment Banking", label: "Investment Banking" },
  { value: "Investment Management", label: "Investment Management" },
  { value: "Judiciary", label: "Judiciary" },
  { value: "Law Enforcement", label: "Law Enforcement" },
  { value: "Law Practice", label: "Law Practice" },
  { value: "Legal Services", label: "Legal Services" },
  { value: "Legislative Office", label: "Legislative Office" },
  {
    value: "Leisure, Travel & Tourism",
    label: "Leisure, Travel & Tourism",
  },
  { value: "Libraries", label: "Libraries" },
  {
    value: "Logistics & Supply Chain",
    label: "Logistics & Supply Chain",
  },
  { value: "Luxury Goods & Jewelry", label: "Luxury Goods & Jewelry" },
  { value: "Machinery", label: "Machinery" },
  { value: "Management Consulting", label: "Management Consulting" },
  { value: "Maritime", label: "Maritime" },
  { value: "Market Research", label: "Market Research" },
  {
    value: "Marketing & Advertising",
    label: "Marketing & Advertising",
  },
  {
    value: "Mechanical Or Industrial Engineering",
    label: "Mechanical Or Industrial Engineering",
  },
  { value: "Media Production", label: "Media Production" },
  { value: "Medical device", label: "Medical device" },
  { value: "Medical Practice", label: "Medical Practice" },
  { value: "Mental Health Care", label: "Mental Health Care" },
  { value: "Military", label: "Military" },
  { value: "Mining & Metals", label: "Mining & Metals" },
  { value: "Motion Pictures & Film", label: "Motion Pictures & Film" },
  { value: "Museums & Institutions", label: "Museums & Institutions" },
  { value: "Music", label: "Music" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Newspapers", label: "Newspapers" },
  {
    value: "Non-profit Organization Management",
    label: "Non-profit Organization Management",
  },
  { value: "Oil & Energy", label: "Oil & Energy" },
  { value: "Online Media", label: "Online Media" },
  { value: "Outsourcing/Offshoring", label: "Outsourcing/Offshoring" },
  { value: "Package/Freight Delivery", label: "Package/Freight Delivery" },
  { value: "Packaging & Containers", label: "Packaging & Containers" },
  {
    value: "Paper & Forest Products",
    label: "Paper & Forest Products",
  },
  { value: "Performing Arts", label: "Performing Arts" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Philanthropy", label: "Philanthropy" },
  { value: "Photography", label: "Photography" },
  { value: "Plastics", label: "Plastics" },
  { value: "Political Organization", label: "Political Organization" },
  {
    value: "Primary/Secondary Education",
    label: "Primary/Secondary Education",
  },
  { value: "Printing", label: "Printing" },
  {
    value: "Professional Training & Coaching",
    label: "Professional Training & Coaching",
  },
  { value: "Program development", label: "Program development" },
  { value: "Public Policy", label: "Public Policy" },
  {
    value: "Public Relations & Communications",
    label: "Public Relations & Communications",
  },
  { value: "Public Safety", label: "Public Safety" },
  { value: "Publishing", label: "Publishing" },
  { value: "Railroad Manufacture", label: "Railroad Manufacture" },
  { value: "Ranching", label: "Ranching" },
  { value: "Real Estate", label: "Real Estate" },
  {
    value: "Recreational Facilities & Services",
    label: "Recreational Facilities & Services",
  },
  { value: "Religious Institutions", label: "Religious Institutions" },
  {
    value: "Renewables & Environment",
    label: "Renewables & Environment",
  },
  { value: "Research", label: "Research" },
  { value: "Restaurants", label: "Restaurants" },
  { value: "Retail", label: "Retail" },
  {
    value: "Security & Investigations",
    label: "Security & Investigations",
  },
  { value: "Semiconductors", label: "Semiconductors" },
  { value: "Shipbuilding", label: "Shipbuilding" },
  { value: "Sporting Goods", label: "Sporting Goods" },
  { value: "Sports", label: "Sports" },
  { value: "Staffing & Recruiting", label: "Staffing & Recruiting" },
  { value: "Supermarkets", label: "Supermarkets" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Textiles", label: "Textiles" },
  { value: "Think Tanks", label: "Think Tanks" },
  { value: "Tobacco", label: "Tobacco" },
  {
    value: "Translation & Localization",
    label: "Translation & Localization",
  },
  {
    value: "Transportation/Trucking/Railroad",
    label: "Transportation/Trucking/Railroad",
  },
  { value: "Utilities", label: "Utilities" },
  {
    value: "Venture Capital & Private Equity",
    label: "Venture Capital & Private Equity",
  },
  { value: "Veterinary", label: "Veterinary" },
  { value: "Warehousing", label: "Warehousing" },
  { value: "Wholesale", label: "Wholesale" },
  { value: "Wine & Spirits", label: "Wine & Spirits" },
  { value: "Wireless", label: "Wireless" },
  { value: "Writing & Editing", label: "Writing & Editing" },
  { value: "Other", label: "Other" },
];
