import React from "react";
import PropTypes from "prop-types";
import { alpha, InputBase, FormControl, InputLabel } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

const VistasInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: 600,
  },
}));

export default function VistasTextField(props) {
  const classes = useStyles();
  return (
    <FormControl fullWidth={true}>
      <InputLabel shrink htmlFor={props.id} className={classes.inputLabel}>
        {props.label}
      </InputLabel>
      <VistasInput defaultValue={props.defaultValue} id={props.id} {...props} />
    </FormControl>
  );
}

VistasTextField.propTypes = {
  // children: PropTypes.node.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
};
