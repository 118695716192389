import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MessageInput from "./MessageInput";
import MessageItem from "./MessageItem";
import MessageList from "./MessageList";

const useStyles = makeStyles({
  // chatConversation: {
  //   flexGrow: 1,
  //   flexShrink: 1,
  //   flexBasis: "auto",
  // },
  sendMessage: {
    position: "relative",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: "#fff",
    padding: "1rem",
  },
});

const ChatBox = (props) => {
  const classes = useStyles();
  const {
    sendMessage,
    owner,
    messages,
    isTyping,
    ownerAvatar,
    typing,
    resetTyping,
  } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  let timeout = null;

  /* catch the sendMessage signal and update the loading state then continues the sending instruction */
  const sendMessageLoading = (sender, senderAvatar, message) => {
    setIsLoading(true);
    sendMessage(sender, senderAvatar, message);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  return (
    <div className={classes.chatConversation}>
      {/* <Title owner={props.owner} /> */}
      <MessageList owner={props.owner} messages={props.messages} />
      <div className={classes.sendMessage}>
        {/* <TypingIndicator owner={props.owner} isTyping={props.isTyping} /> */}
        <MessageInput
          isLoading={isLoading}
          owner={props.owner}
          ownerAvatar={props.ownerAvatar}
          sendMessage={props.sendMessage}
          sendMessageLoading={sendMessageLoading}
          typing={props.typing}
          resetTyping={props.resetTyping}
        />
      </div>
    </div>
  );
};

export default ChatBox;
