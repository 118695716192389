export const initializeGA = (GA_MEASUREMENT_ID, GA_MEASUREMENT_ID_GOOGLE_ADS, GA_GTM, userID) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag("js", new Date());
  gtag("config", GA_MEASUREMENT_ID, {
    user_id: userID,
  });

  gtag("config", GA_MEASUREMENT_ID_GOOGLE_ADS, {
    user_id: userID,
  });

  gtag("config", GA_GTM, {
    user_id: userID,
  });

  //Track initial time load
  var timeSincePageLoad = Math.round(performance.now());

  gtag("event", "initial_load", {
    name: "load",
    value: timeSincePageLoad,
    event_category: "JS Dependencies",
  });
};

// Action ex: Clicked Login Button
// Event Category ex: Button
export const trackEvent = (action, category, value) => {
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("event", action, {
    event_category: category,
    value: value,
  });
};

export const trackError = (description, fatal) => {
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("event", "exception", {
    description: description,
    fatal: fatal,
  });
};

export const trackGoogleAdsRegisterEvent = () => {
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('event', 'conversion', {'send_to': 'AW-318365323/DMMkCIjW1o0DEIu955cB'})
}

export const trackGoogleAdsLoginEvent = () => {
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('event', 'conversion', {'send_to': 'AW-318365323/yCqoCNvT340DEIu955cB'});
}

export const trackFBLead = () => {
  // post init, fbq function should be available on the window object
  if(typeof window.fbq === 'function'){
    window.fbq('track', 'Lead');
  }
}

export const trackFBLogin = () => {
  // post init, fbq function should be available on the window object
  if(typeof window.fbq === 'function'){
    window.fbq('track', 'Login');
  }
}

export const trackFBEmployerLead = () => {
  // post init, fbq function should be available on the window object
  if(typeof window.fbq === 'function'){
    window.fbq('track', 'Employer-Lead');
  }
}

export const trackFBCandidateLead = () => {
  // post init, fbq function should be available on the window object
  if(typeof window.fbq === 'function'){
    window.fbq('track', 'Candidate-Lead');
  }
}

export const trackFBPageView = () => {
    // post init, fbq function should be available on the window object
    if(typeof window.fbq === 'function'){
      window.fbq('track', 'PageView');
    }
}