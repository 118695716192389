import React, { useContext } from "react";
import { Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Moment from "react-moment";

import {
  NotificationContext,
  NotificationMenuContext,
} from "src/context/notification-context";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    border: "none",
    outline: "none",
  },
  menuItem: {
    minHeight: "75px",
    padding: "10px",
    borderBottom: "1px solid lightgrey",
    display: "flex",
  },
  menuText: {
    padding: "5px",
  },
  menuImg: {
    flex: "0 0 50px",
  },
  menuTitle: {
    fontWeight: "bold",
    color: "#597A96",
    fontSize: "1rem",
  },
  menuDescription: {
    color: "grey",
    fontSize: "0.9rem",
    margin: "5px 0",
  },
  menuDate: {
    fontSize: "0.8rem",
  },
}));

const NotificationMenu = () => {
  const classes = useStyles();

  const notificationContext = useContext(NotificationContext);
  const notificationMenuContext = useContext(NotificationMenuContext);

  const handleClose = () => {
    notificationMenuContext.setAnchorEl(null);
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={notificationMenuContext.anchorEl}
      keepMounted
      open={Boolean(notificationMenuContext.anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          height: "400px",
          width: "400px",
          borderRadius: "8px",
        },
      }}
    >
      <div className={classes.menuContainer}>
        {notificationContext.notifications == 0 && (
          <p style={{ textAlign: "center", marginTop: "175px" }}>
            No Notifications
          </p>
        )}
        {notificationContext.notifications.map((notification, i) => (
          <div className={classes.menuItem} key={i}>
            <img
              className={classes.menuImg}
              src="/static/logo.svg"
              alt="logo"
              height="50px"
              width="50px"
            />
            <div className={classes.menuText}>
              <p className={classes.menuTitle}>
                {notification.notification.title}
              </p>
              <p className={classes.menuDescription}>
                {notification.notification.description}
              </p>
              <Moment fromNow className={classes.menuDate}>
                {notification.time}
              </Moment>
            </div>
          </div>
        ))}
      </div>
    </Menu>
  );
};

export default NotificationMenu;
