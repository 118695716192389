import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ConversationRow from "./ConversationRow";

const useStyles = makeStyles({
  convTimeline: {
    display: "flex",
    height: 290,
    padding: "1rem",
    //borderTopLeftRadius: 8,
    //borderTopRightRadius: 8,
    backgroundColor: "#fff",
    flexDirection: "column-reverse",
    overflowY: "auto",
  },
});

const ConversationMessageList = (props) => {
  const classes = useStyles();
  const messages = [...props.messages];

  return (
    <div className={classes.convTimeline}>
      {messages
        .slice(0)
        .reverse()
        .map((messageItem) => (
          <ConversationRow
            key={messageItem.id}
            owner={props.owner}
            sender={messageItem.sender}
            senderAvatar={messageItem.profilePicUrl}
            senderFirstName={messageItem.senderFirstName}
            senderLastName={messageItem.senderLastName}
            message={messageItem.message}
          />
        ))}
    </div>
  );
};

export default ConversationMessageList;
