import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Button,
  Typography,
  Chip,
  useTheme,
  Hidden,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { Link } from "react-router-dom";
import axios from "axios";
import UserContext from "src/context/user-context";
import {
  JOB_APPLICATION_URL,
  CREATE_JOB_BOOKMARK,
  REMOVE_JOB_BOOKMARK,
} from "src/utils/constants";
import IconButton from "@mui/material/IconButton";
import Avatar from "src/components/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#1a2740",
  },
  logo: {
    height: "100px",
    width: "100%",
    marginTop: "10px",
  },
  name: {
    fontSize: "1.7rem",
    margin: "5px 0px 6px -1px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5rem",
      margin: "0px 0px 5px -1px",
    },
    fontWeight: "500",
    color: "#1A2740",
    lineHeight: "25px",
  },
  title: {
    marginBottom: "10px",
    fontSize: "1rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.9rem",
    },
    color: "black",
  },
  textBold: {
    fontSize: 16,
    fontWeight: "bold",
  },
  textMedium: {
    fontSize: 16,
  },
  textSmall: {
    fontSize: 14,
  },
  textSummary: {
    width: "50%",
  },
  textLabelMedium: {
    fontSize: 16,
    opacity: 0.78,
  },
  textLabelSmall: {
    fontSize: "1.2rem",
    opacity: 0.78,
    fontWeight: 500,
    marginTop: "20px",
  },
  center: {
    display: "flex",
    textAlign: "center",
  },
  left: {
    display: "flex",
    textAlign: "left",
  },
  workTypeChip: {
    marginLeft: 20,
    border: "none",
  },
  workTypeContract: {
    color: "#F08080",
    background: "#FFC0CB",
  },
  workTypeSubCategory: {
    background: "#FFC0CB",
  },
  workTypeFT: {
    color: "#9400D3",
    background: "lightblue",
  },
  workTypePT: {
    color: "darkgreen",
    background: "lightgreen",
  },
  padTop: {
    paddingTop: 3,
  },
  footerLeft: {
    marginTop: 25,
    marginBottom: 15,
  },
  skillChip: {
    borderRadius: 3,
    fontWeight: 600,
    background: "#F9F9F9",
    borderColor: "#DADFEB",
    marginRight: 8,
  },
  infoButton: {
    fontSize: 12,
    color: "grey",
    display: "flex",
    justifyContent: "flex-end",
  },
  infoContent: {
    marginTop: 4,
  },
  footerRight: {
    marginTop: 10,
  },
  avatar: {
    width: 115,
    height: 115,
    [theme.breakpoints.down("sm")]: {
      width: 115,
      height: 115,
    },
  },
  divider: {
    width: 300,
  },
  button: {},
  budget: {
    color: "#223657",
    fontSize: "1.4rem",
    fontWeight: "600",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
}));

export const CredentialsLeft = ({ entry, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
  //console.log("ENTRYID:", entry["id"]);
  const jobDetails = "/c/jobDetails/" + entry.id;

  let skills = entry.topSkills || [];
  let skillsLeft = 0;
  if (skills.length < 4 && entry.additionalSkills?.length > 0) {
    skills = skills.concat(entry.additionalSkills);
  }
  let skillsToShow = skills;
  if (skills.length > 3) {
    skillsLeft = skills.length - 3;
    skillsToShow = skills.slice(0, 3);
  }

  return (
    <>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Link to={jobDetails}>
            <Avatar
              className={classes.avatar}
              URL={entry.companyLogo}
              firstName={entry.companyName}
              height={matchesXS ? 80 : 115}
              width={matchesXS ? 80 : 115}
            />
          </Link>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Link to={jobDetails}>
            <Typography className={classes.name}>
              {entry.title[0]?.length > 30
                ? entry.title[0]?.slice(0, 30) + "..."
                : entry.title[0]}
            </Typography>
          </Link>
          <p className={classes.title}>
            {(entry.category + ", " + entry.expertise)?.length > 50
              ? (entry.category + ", " + entry.expertise)?.slice(0, 50) + "..."
              : entry.category + ", " + entry.expertise}
          </p>
          <p style={{ color: "#626B7E" }}>{entry.companyName}</p>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" spacing={5} className={classes.footerLeft}>
        <Grid item xs zeroMinWidth>
          {skillsToShow &&
            skillsToShow.length > 0 &&
            skillsToShow.map((skill, key) => (
              <Chip
                key={key}
                icon={<CheckIcon />}
                className={classes.skillChip}
                variant="outlined"
                size="small"
                label={skill?.length > 15 ? skill?.slice(0, 15) + "..." : skill}
              />
            ))}
          {skillsLeft > 0 && (
            <Chip
              className={classes.skillChip}
              variant="outlined"
              size="small"
              label={"+ " + skillsLeft + " more..."}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const CredentialsRight = ({
  entry,
  handleApplication,
  handleBookmark,
  ...rest
}) => {
  const classes = useStyles();

  const userContext = useContext(UserContext);

  //entry.candidateHasApplied = true;

  const hasJobStatusState = entry && entry.status ? true : false;

  function applyToJob() {
    console.log("inside job application");

    var params = {
      userCode: userContext.user.get.userCode,
      jobId: entry.id,
      status: "REVIEW",
    };

    axios({
      method: "post",
      url: JOB_APPLICATION_URL,
      params,
    })
      .then((response) => {
        console.log("POST JOB APPLICATION SUCCESSFUL");
        //set searchResults here
        handleApplication(entry);
      })
      .catch(function (error) {
        console.log("POST JOB APPLICATION ERROR");
        console.log(error);
      });
  }

  function bookmarkJob() {
    var params = {
      user_code: userContext.user.get.userCode,
      job_id: entry.id,
    };

    axios({
      method: "post",
      url: CREATE_JOB_BOOKMARK,
      params,
    })
      .then((response) => {
        console.log("POST JOB BOOKMARK SUCCESSFUL", response);
        handleBookmark(entry);
      })
      .catch(function (error) {
        console.log("POST JOB BOOKMARK ERROR");
        console.log(error);
      });
  }

  function removeBookmarkJob() {
    var params = {
      user_code: userContext.user.get.userCode,
      job_id: entry.id,
    };

    axios({
      method: "post",
      url: REMOVE_JOB_BOOKMARK,
      params,
    })
      .then((response) => {
        console.log("REMOVE JOB BOOKMARK SUCCESSFUL", response);
        handleBookmark(entry);
      })
      .catch(function (error) {
        console.log("REMOVE JOB BOOKMARK ERROR");
        console.log(error);
      });
  }

  return (
    <>
      {entry.timeCommitment && (
        <Chip
          label={entry.timeCommitment}
          color="primary"
          size="small"
          className={classes.chip}
        />
      )}
      {entry.durations && (
        <Typography
          variant="body2"
          display="block"
          className={classes.textLabelSmall}
        >
          {entry.durations}
        </Typography>
      )}
      <p className={classes.textLabelSmall}>Overall Budget</p>
      <Typography variant="body2" className={classes.budget}>
        {entry.budget != undefined ? "₹" + ` ` + entry.budget : "NA"}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          placeItems: "center",
        }}
      >
        {!entry.candidateHasApplied && !!hasJobStatusState ? (
          <Button
            className={classes.button}
            onClick={applyToJob}
            variant="outlined"
            color="primary"
            fullWidth={true}
          >
            Apply
          </Button>
        ) : (
          <Button>{entry.status ? "Applied" : hasJobStatusState}</Button>
        )}
        {!entry.bookmarked ? (
          <IconButton onClick={bookmarkJob} size="large">
            <TurnedInNotOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton onClick={removeBookmarkJob} size="large">
            <TurnedInIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};
