import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Grid,
  Select,
  InputLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { UPDATE_USER_PROFILE_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";
import TokenService from "src/services/TokenService";
import CheckIcon from "@mui/icons-material/Check";
const useStyles = makeStyles({
  root: { lineHeight: "44px", color: "#595959" },
  heading: {
    lineHeight: "44px",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
  },
  status: {
    color: "green",
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "30px",
  },
});

const PersonalInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    userCode: "",
    firstName: "",
    lastName: "",
    gender: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const userContext = useContext(UserContext);
  useEffect(() => {
    setValues({
      ...values,
      userCode: userContext.user.get.userCode,
      firstName: userContext.user.get.firstName,
      lastName: userContext.user.get.lastName,
      gender: userContext.user.get.gender,
    });
  }, [userContext]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setDisabled(true);
    axios.put(UPDATE_USER_PROFILE_URL, values).then((response) => {
      setDisabled(false);
      const userObj = TokenService.getUser();
      userObj.firstName = values.firstName;
      userObj.lastName = values.lastName;
      userObj.gender = values.gender;
      userContext.user.set(userObj);
      showStatus("Profile Updated Successfully..");
      TokenService.getRefreshToken()
        .then((response) => {
          const userObj = TokenService.getUser();
          userContext.user.set(userObj);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
  const showStatus = (message) => {
    setStatus(message);
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };

  return (
    <form className={classes.root}>
      <div
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography className={classes.heading}>
          Personal Information
          <CheckIcon sx={{ color: "#50DD93" }} />
        </Typography>
      </div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} lg={6} sx={{ lineHeight: "40px" }}>
          <InputLabel className={classes.inputLabel}>First Name</InputLabel>
          <TextField
            fullWidth
            name="firstName"
            onChange={handleChange}
            type="text"
            value={values.firstName}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
          <TextField
            fullWidth
            name="lastName"
            onChange={handleChange}
            type="text"
            value={values.lastName}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputLabel className={classes.inputLabel}>Gender</InputLabel>
          <Select
            fullWidth
            native
            name="gender"
            value={values.gender}
            onChange={handleChange}
          >
            <option aria-label="None" value="" />
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
            <option value={"other"}>Other</option>
          </Select>
        </Grid>

        <Divider />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Grid>
        {status && (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.status}>
              {status}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PersonalInfo;
