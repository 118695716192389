import React, { useState, useEffect,useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

import MoreDetails from "./MoreDetails";

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import JobStepper from "src/components/job-steps/JobStepper";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import JobStepperEdit from "src/components/job-steps/JobStepperEdit";
import axios from "axios";
import UserContext from "src/context/user-context";
import { UpdateListenerContext } from 'src/context/update-listener-context';

import { GET_EMPLOYER_JOBS_URL } from "src/utils/constants";


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dialogPaper: {
    minHeight: "90vh",
  },
  title: {
    width: "90%",
    whiteSpace: "noWrap",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Results = ({ className, toggler, setToggler ,...rest }) => {
  const classes = useStyles();
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [jobs, setJobs] = useState([]);

  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;

  const extraContext=useContext(UpdateListenerContext);

  useEffect(() => {
    handleClose();
  }, [toggler]);


  useEffect(() => {
    getJobs();
  }, [toggler,extraContext.updateListeners]);

  const getJobs = () => {
    axios({
      method: "GET",
      url: GET_EMPLOYER_JOBS_URL,
      params: {
        userCode: user_code,
      },
    })
      .then((response) => {
        console.log("WE GOT THE JOBS", response.data);
        setJobs(response.data);
      })
      .catch((error) => {
        console.log("WE DONT GOT THE JOBS");
      });
  };



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLimitChange = (event) => {
    console.log(event);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <>
            <Button variant="contained" color="secondary" onClick={handleOpen}>
              Post New Gig
            </Button>
          </>
        }
        title="Gigs"
      />
      {/* <PerfectScrollbar> */}
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell># of Applicants</TableCell>
              <TableCell>Posted On</TableCell>
              <TableCell>Location Type</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs?.slice(page * limit, page * limit + limit).map((job) => {
              const jobDetailLink = "/r/jobDetails/" + job.id;
              return (
                <TableRow hover key={job.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <RouterLink to={jobDetailLink}>
                        <Typography className={classes.title}>
                          <Link>
                            {job.title.length > 25
                              ? job.title.slice(0, 25) + "..."
                              : job.title}
                          </Link>
                        </Typography>
                      </RouterLink>
                      <MoreDetails title={job.title} desc={job.description} />
                    </Box>
                  </TableCell>
                  <TableCell>{job.jobApplications.length}</TableCell>
                  <TableCell>
                    {moment(job.createdDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={
                        job?.projectType
                          ? job?.projectType?.charAt(0).toUpperCase() +
                            job?.projectType?.slice(1)
                          : "Not Specified"
                      }
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{job.status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={jobs.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Box>
      {/* </PerfectScrollbar> */}
      <Dialog
        onBackdropClick="false"
        fullScreen={isMobile}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          className={classes.close}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <JobStepper setToggler={setToggler} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.array.isRequired,
};

export default Results;




