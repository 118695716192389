import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));

const RewardDialogHistory = ({ open, setOpen, data, user, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [limit, setLimit] = useState(10);
  const [contracts, setContracts] = useState(data);
  const validationSchema = yup.object({});

  useEffect(() => {
    if (data === null) {
      setContracts([]);
    } else {
      setContracts(data);
    }
  }, [data]);

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">Rewards Earnings History</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form>
        <DialogContent dividers>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Created </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.slice(0, limit).map((contract, i) => (
                <TableRow hover key={i}>
                  <TableCell>{contract.id}</TableCell>
                  <TableCell>{contract.points}</TableCell>
                  <TableCell>{contract.source}</TableCell>
                  <TableCell>{contract.transactionType}</TableCell>
                  <TableCell>
                    {" "}
                    {moment(contract.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions></DialogActions>
      </form>
    </Dialog>
  );
};

export default RewardDialogHistory;
