import React, { forwardRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import {  TextField } from "@mui/material";
import Button from "src/designsystem/Core/Button";
import * as Yup from "yup";
import GigTextField from "src/components/custom/GigTextField";
import { DatePicker } from "@mui/lab";
import { Formik, FieldArray } from "formik";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import { colors, breakpoints, typography } from "src/designsystem/theme";
import styled from "@emotion/styled";
import moment from "moment";
import { CONTRACT_DELIVERABLES_URL,CONTRACT_ATTACHMENT_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";
import Typography from "src/designsystem/Core/Typography";
import TokenService from "src/services/TokenService";
import { useDispatch, useSelector } from "react-redux";
import { submitFormInfo } from "src/views/recruiter/RecruiterContract/state/contractStepperReducers";
import FileCard from "src/designsystem/Derivative/FilesCard";
import { CONTRACT_FILEUPLOAD_URL } from "src/utils/constants";
import AttachmentCard from "src/designsystem/Derivative/AttachmentCard";
const CSView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;

const CSCalenderCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const CSCalenderLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 200px 1fr;
    gap: 30px;
    padding: 15px 0px;
  }
`;
const CSCalenderRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 0px 15px 15px 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 200px 1fr;
    gap: 30px;
    padding: 15px;
  }
`;

const CSDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints["lg"]}) {
    flex-direction: column;
  }
`;

const Header = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
`;

const CSCardBody = styled.div`

padding-top: 15px;
font-weight: ${typography['body2'].weight};
font-size: ${typography['body2'].size};
color: ${typography['body2'].color};
line-height: 1.35em;
`

const initialValues = {
  contractId:"",
  startDate: new Date(),
  endDate: new Date(Date.now() + 3600 * 1000 * 24),
  deliverables: "",
  file:{},
  fileName:""
};

const validationSchema = Yup.object().shape({
  deliverables: Yup.string() .min(1, "Must atleast be 1 character").required("Deliverables is required"),
});

const Deliverables = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const [initVal, setInitVal] = useState(initialValues);
  const [attachment, setAttachment] = useState([]);
  var user_code = userContext.user.get.userCode;
  const { useImperativeHandle } = React;

  const contractId= initVal.contractId;
  const deliverables=initVal.deliverables;
  let methodType="POST";
  let params={
    contractId: contractId,
  };

  if (contractId && contractId != null &&deliverables) {
    methodType="PUT"
    params={
      contractId: contractId,
    };
  } else {
    methodType="POST"
    params={
      contractId: contractId,
    };
  }
 

  useImperativeHandle(
    ref,
    () => ({
      handleSubmit,
    }),
    []
  );

  const handleSubmit = (values, actions) => {
    submitSaveDeliverable(values, actions);
  };

  const submitSaveDeliverable = (values, actions) => {
    var deliverableData = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
      deliverables: values.deliverables,
    };
    dispatch(submitFormInfo({ methodType, endPoint:CONTRACT_DELIVERABLES_URL,formData:deliverableData,params,props}));
  };


  const state = useSelector(state=>state.contractStepperinfo)

  function uploadFile (values,event) {
    try {
      const formData = new FormData();
      formData.append("contractId", contractId);
      formData.append("employerCode", userContext.user.get.userCode);
      formData.append("file", values.file);
      
      axios({
        method: "POST", 
        url: CONTRACT_FILEUPLOAD_URL,
        data: formData,
      })
        .then((res) => {
          console.log("Response from upload image", res.data);
          setAttachment(res.data);
          event.currentTarget=null;
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.error(e);
    }
  };
  
  useEffect(() => {
    getAllAttachment();
  }, [contractId]);

  

  const getAllAttachment = () => {
    if (contractId) {
      axios({
        method: "GET",
        url: CONTRACT_ATTACHMENT_URL,
        params: {
          contractId: contractId,
        },
      })
        .then((res) => {
          console.log("Response from getAllAttachment", res.data);
          setAttachment(res.data);
        })
        .catch((error) => {
          console.log("WE DONT GOT THE getAttachments");
        });
    }
  };

  useEffect(() => {
    if (state && state.resource ) {
      setInitVal(
        {
          contractId:state.resource.contractId,
          startDate: state.resource.startDate,
          endDate: state.resource.endDate,
          deliverables: state.resource.deliverables,
        }
      )
    }
  }, ["resource" in state]);

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={initVal}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitSaveDeliverable(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CSView>
            <CSCalenderCard>
              <CSCalenderLeft>
                <CSDetails>
                  <Header variant={"label"} gutterBottom>
                    Expected Start Date
                  </Header>
                  <DatePicker
                    inputVariant="outlined"
                    inputFormat="yyyy-MM-dd"
                    margin="dense"
                    id="startDate"
                    renderInput={(props) => (
                      <TextField {...props} size="small" />
                    )}
                    name={"startDate"}
                    value={values.startDate}
                    onChange={(date) => {
                      values.startDate = date;
                      setValues(values);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    size="small"
                    fullWidth
                    
                  />
                </CSDetails>
              </CSCalenderLeft>
              <CSCalenderRight>
                <CSDetails>
                  <Header variant={"label"} gutterBottom>
                    Expected End Date
                  </Header>
                  <DatePicker
                    inputVariant="outlined"
                    inputFormat="yyyy-MM-dd"
                    renderInput={(props) => (
                      <TextField {...props} size="small" />
                    )}
                    margin="dense"
                    id="endDate"
                    name={"endDate"}
                    value={values.endDate}
                    minDate={values.startDate}
                    onChange={(date) => {
                      values.endDate = date;
                      setValues(values);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    size="small"
                    fullWidth
                    //disableFuture
                  />
                </CSDetails>
              </CSCalenderRight>
            </CSCalenderCard>
            <Header variant={"label"}>Detailed Deliverables*</Header>
            <GigTextField
             style={{
              marginBottom: 0,
            }}
              inputLabelText=""
              multiline
              rows={4}
              name="deliverables"
              value={values.deliverables}
              placeholder="Deliverables"
              id="deliverables"
              onChange={handleChange}
              error={Boolean(touched.deliverables && errors.deliverables)}
              helperText={touched.deliverables && errors.deliverables}
            />

            <Header variant={"label"}>Attachments</Header>
            <Button
              variant="contained"
              color="primary"
              style={{
                display: "flex",

                width: "200px",
              }}
              component="label"
            >
              <input
                type="file"
                hidden
                style={{ display: "none" }}
                accept=".doc, .docx,.pdf"
                onChange={(event) => {
                  values.file=event.currentTarget.files[0];
                  if (event.currentTarget.files[0] != null) {
                    values.fileName=event.currentTarget.files[0].name;

                  } 
                  uploadFile(values,event)
                }}
              />
              <AttachFileSharpIcon />
              Attach File
            </Button>
            <CSCardBody>
            <AttachmentCard data={attachment} deletable={true}  refetch={() => {
                dispatch(getAllAttachment());
              }}/>
             </CSCardBody> 
              
          </CSView>
        </form>
      )}
    </Formik>
  );
});

export default Deliverables;
