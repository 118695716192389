import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie/es6";
import { parseJwt } from "src/utils/generic";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import UserContext from "src/context/user-context";
import { USER_SERVICE } from "src/utils/constants";
import { LOGOUT_URL } from "src/utils/constants";


const getRefreshToken = () =>  {
  return axios.post(`${USER_SERVICE}/authToken`)
    .then(response => {
      if (response.data) {
        setCookiesAccessToken(response.data);
      }
      return response.data;
    });
}

const getLogout = () =>  {
  return axios.get(LOGOUT_URL)
    .then(response => {
      if (response.data) {
        removeCookiesAccessToken();
        removeRedirectPath();
      }
      return response.data;
    });
}




const getCookiesAccessToken = () => {
  const cookies = new Cookies();
  const acessToken = cookies.get("AcessToken");
  return acessToken;

};

const setRedirectPath = (redirectPath) => {
  const cookies = new Cookies()
  let date = new Date();
  date.setTime(date.getTime() + 1000 * 60 * 60 * 24);
  cookies.set("Redirect", redirectPath, { path: "/", expires: date });
};

const getRedirectPath= () => {
  const cookies = new Cookies();
  if(cookies.get("Redirect")){
  const redirectPath = cookies.get("Redirect");
  return redirectPath;
}

};


const removeRedirectPath = () => {
  const cookies = new Cookies()
  cookies.remove("Redirect", { path: "/" });
};

const setCookiesAccessToken = (token) => {
  const cookies = new Cookies()
  let date = new Date();
  date.setTime(date.getTime() + 1000 * 60 * 60 * 24);
  cookies.set("AcessToken", token, { path: "/", expires: date });
};


const getUser = () => {
  if(getCookiesAccessToken()){
  var userObj = parseJwt(getCookiesAccessToken());
  }
  return userObj;
};


const removeCookiesAccessToken = () => {
  const cookies = new Cookies()
  cookies.remove("AcessToken", { path: "/" });
};

const removeOnboardingData = () => {
  localStorage.removeItem("Expertise");
  localStorage.removeItem("Experience");
  localStorage.removeItem("Education");
  localStorage.removeItem("Certification");
};


const TokenService = {
  getCookiesAccessToken,
  setCookiesAccessToken,
  setRedirectPath,
  getRedirectPath,
  removeRedirectPath,
  removeOnboardingData,
  getUser,
  removeCookiesAccessToken,
  getRefreshToken,
  getLogout
};

export default TokenService;