import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Resource } from "src/models/Resource";
import { ContractDetailEntity, ContractDetailResponse } from "src/views/recruiter/RecruiterContract/state/models";
import getContractDetails from "./service";

export const getContractListAction = createAsyncThunk<
  any,
  { user_code: string },
  any
>("contract/rec-contracts", async ({ user_code }, { rejectWithValue }) => {
  try {
    return  await getContractDetails(user_code);
  } catch (error) {
    return rejectWithValue(error);
  }
});


const initialState = {
  isPending: false,
} as Resource<ContractDetailEntity[]>;

export const ContractReducers = createSlice({
  name: "Contract",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getContractListAction.pending, (state) => {
      return {
        isPending: true,
        
      } as Resource<ContractDetailEntity[]>;
    });
    builder.addCase(getContractListAction.fulfilled,
      (state, { payload }) => {
        return {
          resource: { ...payload },
        } as Resource<ContractDetailEntity[]>;
      }
    );

    builder.addCase(getContractListAction.rejected, () => {
      return {
        errorMessage: "Some error 1",
      } as Resource<ContractDetailEntity[]>;
    });
  },
  

});


export default ContractReducers.reducer;