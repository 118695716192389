import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  useTheme,
  Button,
  Divider,
  Grid,
  Input,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import {
  EMAIL_VERIFIED_URL,
  SEND_EMAIL_VERIFICATION_LINK_URL,
} from "src/utils/constants";
import UserContext from "src/context/user-context";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    rowGap: "10px",
    lineHeight: "40px",
    color: "#595959",
    [theme.breakpoints.up("lg")]: {
      lineHeight: "55px",
    },
  },
  heading: {
    color: "red",
    lineHeight: "55px",
    fontWeight: "600",
    fontSize: "20px",
  },
  verified: {
    display: "flex",
  },
  textBold: {
    fontWeight: "bold",
  },
  refreshIcon: {
    cursor: "pointer",
    marginLeft: 5,
  },
  statusText: {
    margin: 2,
  },
  statusIcon: {
    margin: 2,
  },
}));

const DeactivateAccount = ({ className, ...rest }) => {
  const classes = useStyles();
  const [verificationStatus, setVerificationStatus] = useState("not-verified");
  // const [waiting, setWaiting] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const userContext = useContext(UserContext);
  const [email, setEmail] = useState("");
  // validationSchema: Yup.object({
  //   password: Yup.string().required('Password is required'),
  //   passwordConfirmation: Yup.string()
  //      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  // });
  const fetchVerificationStatus = () => {
    axios
      .get(EMAIL_VERIFIED_URL + "?userCode=" + userContext.user.get.userCode)
      .then((response) => {
        setVerificationStatus(response.data.status);
      });
  };
  useEffect(() => {
    // setWaiting(0);
    setEmail(userContext.user.get.email);
    // fetchVerificationStatus();
  }, [userContext]);

  const handleSubmit = () => {
    axios
      .post(
        SEND_EMAIL_VERIFICATION_LINK_URL +
          "?userCode=" +
          userContext.user.get.userCode
      )
      .then((response) => {
        setDisabled(false);
        fetchVerificationStatus();
        // startWaitTimer();
      });
  };

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickDeactivate = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDialog(false);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} lg={12}>
        <div>
          <Typography className={classes.heading}>
            Deactivate Account
          </Typography>
        </div>
        {matches ? (
          <Divider
            sx={{
              width: "853px",
              height: "1px",
              background: "#D9D9D9",
            }}
          />
        ) : (
          <Divider
            sx={{
              width: "250px",
              height: "1px",
              background: "#D9D9D9",
            }}
          />
        )}
        <div
          sx={{
            // width: "755px",
            // height: " 24px",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#595959",
          }}
        >
          Once you deactivate your account,you can still login but your profile
          will not be visible to others. Please be certain.
        </div>
        <div>To Continue , Please enter your password.</div>
        <div>Password:</div>
        <TextField name="password" type="password" size="small"></TextField>
        <div
          sx={{
            display: "grid",
            gridTemplate: "1fr/1fr 1fr",
            // gap: "14px",
          }}
        >
          <Button
            onClick={handleClickDeactivate}
            sx={{
              border: "1px solid #F65441",
              borderRadius: "7px",
              color: "#F65441",
              marginRight: "14px",
            }}
          >
            Deactivate your Account
          </Button>
          <Button
            sx={{
              border: "1px solid #50DD93",
              borderRadius: "7px",
              color: "#50DD93",
            }}
          >
            Cancel{" "}
          </Button>
          <Dialog
            onBackdropClick="false"
            // onClose={() => setOpen(false)}
            aria-labelledby="responsive-dialog-title"
            open={openDialog}
            onClose={(event, reason) => {
              if (reason === "backdropClick") {
                return;
              }
              handleCloseDialog();
            }}
            p={2}
          >
            <DialogTitle id="responsive-dialog-title">
              Deactivate Account
            </DialogTitle>

            <DialogContent>
              <Divider
                sx={{
                  width: "100%",
                  // height: "1px",
                  background: "#D9D9D9",
                  margin: "5px",
                }}
              />
              <DialogContentText>
                Are you sure you want to deactivate your account? Once you
                deactivate your account,you can still login but your profile
                will not be visible to others. Please be certain.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                sx={{
                  background: "#F65441",
                  borderRadius: "7px",
                  color: "white",
                }}
                onClick={() => {}}
                variant="contained"
              >
                Deactivate Account
              </Button>
              <Button
                sx={{
                  borderRadius: "7px",
                  border: "1px solid #606063",
                  color: "#595959",
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
};

export default DeactivateAccount;
