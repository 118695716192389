import { Navigate } from "react-router-dom";
import CandidateLayout from "src/layouts/CandidateLayout";
import MainLayout from "src/layouts/MainLayout";
import RecruiterLayout from "src/layouts/RecruiterLayout";
import LoginView from "src/views/auth/LoginView";
import RegisterView from "src/views/auth/RegisterView";
import EditCandidateProfile from "src/views/candidate/CandidateProfile";
import DashboardView from "src/views/candidate/DashboardView";
import NotFoundView from "src/views/errors/NotFoundView";
import CandidateDetails from "src/views/recruiter/RecruiterDetails/ProfileDetails";
import SettingsView from "src/views/settings/SettingsView";
import OnboardingLayout from "./layouts/OnboardingLayout";


import CandidateSearchView from "src/views/candidate/CandidateSearchView";

import CandidateGigs from "src/views/candidate/CandidateGigs";
import CandidateJobs from "src/views/candidate/CandidateJobs";
import RecruiterCandidates from "src/views/recruiter/RecruiterCandidates";
import RecruiterDashboardView from "src/views/recruiter/RecruiterDashboardView";
import RecruiterGigs from "src/views/recruiter/RecruiterGigs";
import RecruiterJobs from "src/views/recruiter/RecruiterJobs";
import RecruiterSearchView from "src/views/recruiter/RecruiterSearchView";

import { validateAuth } from "src/utils/generic";
import Messaging from "./components/messaging";
import CandidateOnboarding from "./views/onboarding/Candidate";
import RecruiterOnboarding from "./views/onboarding/Recruiter";
import UserTypeSelector from "./views/usertype/UserTypeSelector";

import CandidateJobView from "src/views/candidate/CandidateJobView";
import RecruiterJobView from "src/views/recruiter/RecruiterJobView";
import ResetPasswordView from "./views/auth/ResetPasswordView";

import AuthGuardRoute from "src/components/auth/AuthGuardRouteHandler";
import RedirectRoute from "src/components/auth/RedirectLoginRoute";
import CandidateReward from "src/views/candidate/CandidateReward/RewardView";
import OrganizationView from "src/views/recruiter/RecruiterDetails/OrganizationDetail";
import ProfileDetails from "src/views/recruiter/RecruiterDetails/ProfileDetails";
import AuthLayout from "./layouts/AuthLayout";
import SkillsUpgrade from "./views/candidate/SkillsUpgrade";
import ContractDetail from "./views/recruiter/RecruiterContract/ContractDetail";
import ContractList from "./views/recruiter/RecruiterContract/ContractList";
import UserTypeLayout from "./layouts/UserTypeLayout";
import RecruiterMessagesView from "src/views/recruiter/RecruiterMessagesView";
import CandidateMessagesView from "./views/candidate/CandidateMessagesView";

const routes = (isLoggedIn, isCandidate) => [
  {
    path: "c",
    element: isLoggedIn ? (
      <AuthGuardRoute
        component={CandidateLayout}
        componentName={"CandidateLayout"}
      />
    ) : (
      <RedirectRoute
        component={CandidateLayout}
        componentName={"CandidateLayout"}
      />
    ),
    children: [
      { path: "search", element: <CandidateSearchView /> },
      { path: "messages", element: <CandidateMessagesView /> },
      { path: "messages/:channelCode", element: <CandidateMessagesView /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "account", element: <SettingsView /> },
      { path: "jobs", element: <CandidateJobs /> },
      { path: "candidate", element: <EditCandidateProfile /> },
      { path: "gigs", element: <CandidateGigs /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "jobDetails/:jobId", element: <CandidateJobView /> },
      { path: "skills-upgrade", element: <SkillsUpgrade /> },
      { path: "profile-details", element: <ProfileDetails /> },
      { path: "reward", element: <CandidateReward /> },
    ],
  },
  {
    path: "r",
    element: isLoggedIn ? (
      <AuthGuardRoute
        component={RecruiterLayout}
        componentName={"RecruiterLayout"}
      />
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "search", element: <RecruiterSearchView /> },
      { path: "home", element: <RecruiterDashboardView /> },
      { path: "account", element: <SettingsView /> },
      { path: "jobs", element: <RecruiterJobs /> },
      { path: "gigs", element: <RecruiterGigs /> },
      { path: "candidates", element: <RecruiterCandidates /> },
      { path: "candidate/:id", element: <CandidateDetails /> },
      { path: "messages", element: <RecruiterMessagesView /> },
      { path: "messages/:channelCode", element: <RecruiterMessagesView /> },
      { path: "candidates", element: <RecruiterCandidates /> },
      { path: "candidate", element: <CandidateDetails /> },
      { path: "contract", element: <ContractList /> },
      {
        path: "contract/:contractId",
        element: <ContractDetail pageTitle="Contract Details" />,
      },
      { path: "*", element: <Navigate to="/404" /> },
      {
        path: "jobDetails/:jobId",
        element: <RecruiterJobView pageTitle="Job Details" />,
      },
      {
        path: "jobDrafts/:jobId",
        element: <RecruiterJobView pageTitle="Job Draft" />,
      },
      { path: "org", element: <OrganizationView /> },
    ],
  },
  {
    path: "o",
    element: isLoggedIn ? (
      <AuthGuardRoute
        component={OnboardingLayout}
        componentName={"OnboardingLayout"}
      />
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "candidate", element: <CandidateOnboarding /> },
      { path: "recruiter", element: <RecruiterOnboarding /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    onEnter: validateAuth.bind(this),
    element: (
      <AuthGuardRoute component={AuthLayout} componentName={"AuthLayout"} />
    ),
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "login", element: <LoginView /> },
      { path: "reset-password", element: <ResetPasswordView /> },
      { path: "register", element: <RegisterView /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "type", element: <UserTypeSelector /> },
    ],
  },
  {
    path: "/",
    onEnter: validateAuth.bind(this),
    element: <MainLayout />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "u",
    element: isLoggedIn ? (
      <AuthGuardRoute
        component={UserTypeLayout}
        componentName={"UserTypeLayout"}
      />
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "type", element: <UserTypeSelector /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
