import TokenService from "src/services/TokenService";
import { useLocation, Navigate } from "react-router-dom";
import {getCookieValueByKey} from "src/utils/cookieUtils";

const paramsFromCookies = getCookieValueByKey("gigvistas-user");

export const RedirectRoute = ({ component: RouteComponent, componentName }) => {
  const allowedPaths = ["/login", "/reset-password", "/register", "/404"];
  const location = useLocation();
  if (!allowedPaths.includes(location.pathname)) {
    TokenService.setRedirectPath(location.pathname);
  }
  const searchParams = useLocation().search;
  const toLogin = "/login" + searchParams;
  const toRegister = "/register" + searchParams;

  if(paramsFromCookies){
    return <Navigate to={toRegister} />;  
  }
  return <Navigate to={toLogin} />;
};

export default RedirectRoute;
