import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import JobDetails from "../job/JobDetails";
import JobAdditionalDetails from "../job/JobAdditionalDetails";

import PropTypes from "prop-types";

import UserContext from "src/context/user-context";
import { UpdateListenerContext } from "src/context/update-listener-context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { POST_JOB_URL } from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(1) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Primary Details", "Additional Details"];

const JobStepper = ({ setToggler, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const extraContext = useContext(UpdateListenerContext);

  const [activeStep, setActiveStep] = React.useState(0);
  const [jobpost, setJobPost] = React.useState({
    title: "",
    description: "",
    category: "",
    expertise: "",
    proficiencyLevel: "",
    skills: [],
    preferredSkills: [],
    projectType: "remote",
    timeCommitment: "Flexible",
    duration: "",
    timeUnit: "Hours",
    budget: "",
    location_id: 0,
    created_date: Date.now(),
    qualifications: "",
    certifications: "",
    currencyCode: "INR",
  });

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function submitJob(newJobPost) {
    console.log(
      "submitJob has been called with:" + userContext.user.get.userCode
    );

    var params = {
      userCode: userContext.user.get.userCode,
    };
    let backendJobPost = {};
    backendJobPost.title = newJobPost.title;
    backendJobPost.description = newJobPost.description;
    backendJobPost.category = newJobPost.category;
    backendJobPost.expertise = newJobPost.expertise;
    backendJobPost.proficiencyLevel = newJobPost.proficiencyLevel;
    backendJobPost.projectType = newJobPost.projectType;
    backendJobPost.timeCommitment = newJobPost.timeCommitment;
    backendJobPost.duration = newJobPost.duration;
    backendJobPost.timeUnit = newJobPost.timeUnit;
    backendJobPost.budget = newJobPost.budget;
    backendJobPost.currencyCode = newJobPost.currencyCode;
    backendJobPost.qualifications = newJobPost.qualifications;
    backendJobPost.certifications = newJobPost.certifications;
    backendJobPost.jobPostSkills = newJobPost.jobPostSkills;
    backendJobPost.jobPostLocations = newJobPost.jobPostLocationList;

    axios({
      method: "POST",
      url: POST_JOB_URL,
      params,
      data: backendJobPost,
    })
      .then((response) => {
        console.log("POSTING JOB WAS SUCCESSFUL ON NONE", response);
        //toggle the state
        setToggler((toggler) => !toggler);
        // console.log("AFTER", toggler);
        navigate("/r/jobDetails/" + response.data.id);
        extraContext.updateListeners();
      })
      .catch((error) => {
        console.log("POSTING JOB WAS NOT SUCCESSFUL");
        //actions.setSubmitting(false);
      });
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobDetails
            funcFlag="addNewJob"
            handleNext={handleNext}
            jobpost={jobpost}
            setJobPost={setJobPost}
            setToggler={setToggler}
          />
        );
      case 1:
        return (
          <JobAdditionalDetails
            funcFlag="addNewJob"
            handleNext={handleNext}
            handleBack={handleBack}
            jobpost={jobpost}
            setJobPost={setJobPost}
            submitJob={submitJob}
            setToggler={setToggler}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Post a new Gig
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <>{getStepContent(activeStep)}</>
      </main>
    </>
  );
};

JobStepper.propTypes = {
  className: PropTypes.string,
};

export default JobStepper;
