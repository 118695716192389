import React, { useState } from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles({
  menu: {
    padding: 6,
    background: "rgba(0, 0, 0, 0.04)",
    borderRadius: 0,
  },
});

const DottedHorizontalMenu = ({ options, handleMenuSelection }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(() => event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(() => null);
  };
  const handleSelection = (selectedValue) => {
    handleMenuSelection(selectedValue);
    setAnchorEl(() => null);
  };
  return (
    <>
      <IconButton onClick={handleClick} className={classes.menu} size="large">
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleSelection(option.name)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

DottedHorizontalMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleMenuSelection: PropTypes.func,
};

export default DottedHorizontalMenu;
