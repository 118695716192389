import React, { useState, useContext, useEffect } from "react";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import UserContext from "src/context/user-context";
import Textfield from "@mui/material/TextField";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from 'src/designsystem/Core/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
  background-color: ${colors.p100};
  overflow-y: scroll;
  gap: 2px;
`;

const ChannelItem = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid ${colors.nv80};
    background-color: ${props => (props.selected ? colors.s99 : colors.p100)};
    box-shadow: ${props => (props.selected ? "0px 10px 10px -9px rgba(0, 0, 0, 0.08)" : "none")};
    font-weight: 500;
    cursor: pointer;
`;



const ChannelList = (props) => {
    const {channelList,activeChannel,setActiveChannel}= props;
    const userContext = useContext(UserContext);
    const [selectedChannel, setSelectedChannel] = useState();
    const userCode = userContext.user.get.userCode;
    useEffect(() => {
      if(Object.keys(activeChannel).length > 0 && channelList && channelList.length > 0){
        setSelectedChannel(activeChannel.channelCode);
      }
    }, [activeChannel]);

    const chooseChannel = (channel) => {
      setSelectedChannel(channel.channelCode);
      setActiveChannel(channel);
    }

    return (
        <ChannelContainer>
          <div style={{margin:"10px"}}><Typography variant="h5">Contacts</Typography></div>
          {channelList.map((channel,i) => {
            let otherMember = channel.members[0].userCode === userCode ? channel.members[1] : channel.members[0];
            let isSelected = selectedChannel === channel.channelCode;
            return (
              <ChannelItem selected={isSelected} onClick={() => {chooseChannel(channel)}}>
                <Avatar src={otherMember.profilePicUrl} alt={otherMember.name} size="50px"/>
                <p>{otherMember.name}</p>
              </ChannelItem>
              
              )
          })}
        </ChannelContainer>
    )
}

export default ChannelList;