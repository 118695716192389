import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../theme";

import Project from "src/derivedComponents/Project";

import { Hidden } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Frame from "../../Core/Frame";

import Project1 from "src/designsystem/Derivative/ProjectCard/projectImages/project1.svg";
import Project2 from "src/designsystem/Derivative/ProjectCard/projectImages/project2.svg";
import Project3 from "src/designsystem/Derivative/ProjectCard/projectImages/project3.svg";
import Project4 from "src/designsystem/Derivative/ProjectCard/projectImages/project4.svg";
import Project5 from "src/designsystem/Derivative/ProjectCard/projectImages/project5.svg";
import Project6 from "src/designsystem/Derivative/ProjectCard/projectImages/project6.svg";
import Project7 from "src/designsystem/Derivative/ProjectCard/projectImages/project7.svg";
import Project8 from "src/designsystem/Derivative/ProjectCard/projectImages/project8.svg";
import Project9 from "src/designsystem/Derivative/ProjectCard/projectImages/project9.svg";
import Project10 from "src/designsystem/Derivative/ProjectCard/projectImages/project10.svg";
import { CandidateProject } from "src/views/candidate/CandidateProfile/state/models";

const GvProjectCard = styled.div`
  //background-color: ${colors.white};
  //border: 1px solid ${colors.nv80};
  //border-radius: 8px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;

const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  @media (max-width: ${breakpoints["sm"]}) {
    grid-template-columns: 1fr 0.5fr;
  }
`;

const MobileView = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;
interface ProjectCardProps {
  // data: CandidateProject;
  data: any;
  onClick?: (props: { src: string | null; name: string; project: {} }) => void;
}

const ProjectCard: FC<ProjectCardProps> = ({ data }) => {
  console.log(data);
  const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);

  const toggleFrame = () => {
    console.log(toggle);
    setToggle(!toggle);
  };

  const images = [
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
    Project7,
    Project8,
    Project9,
    Project10,
  ];

  const projects = data.candidateProjects;

  return (
    <GvProjectCard>
      <GvGigDetails>
        <Hidden smDown>
          {projects.length > 4
            ? projects.slice(0, 3).map((project: any, i: number) => {
                return (
                  <Project
                    id={project.id}
                    key={project.id}
                    src={images[i]}
                    name={project.title}
                    project={project}
                    canEdit={project.canEdit === true}
                    alt={project.projectName}
                    size={matches ? "180px" : "170px"}
                  />
                );
              })
            : projects.slice(0, 4).map((project: any, i: number) => {
                return (
                  <Project
                    id={project.id}
                    key={project.id}
                    src={images[i]}
                    name={project.title}
                    project={project}
                    canEdit={project.canEdit === true}
                    alt={project.projectName}
                    size={matches ? "180px" : "170px"}
                  />
                );
              })}

          {projects.length > 4 && (
            <Frame
              hue={toggle ? 85 : 235}
              children={""}
              onClick={() => toggleFrame()}
            />
          )}
          {toggle &&
            projects
              .slice(3, projects.length)
              .map((project: any, i: number) => {
                return (
                  <Project
                    id={project.id}
                    key={project.id}
                    src={images[i + 3]}
                    name={project.title}
                    project={project}
                    alt={project.projectName}
                    canEdit={project.canEdit === true}
                    size={matches ? "180px" : "170px"}
                  />
                );
              })}
        </Hidden>
        <Hidden smUp>
          {projects.slice(0, 1).map((project: any, i: number) => {
            return (
              <Project
                id={project.id}
                key={project.id}
                src={images[i]}
                name={project.title}
                project={project}
                alt={project.projectName}
                canEdit={project.canEdit === true}
                size={matches ? "180px" : "170px"}
              />
            );
          })}
          {projects.length > 1 && (
            <Frame
              hue={toggle ? 85 : 235}
              children={""}
              onClick={() => toggleFrame()}
            />
          )}
          <MobileView>
            {toggle &&
              projects
                .slice(1, projects.length)
                .map((project: any, i: number) => {
                  return (
                    <Project
                      src={images[i + 1]}
                      name={project.title}
                      id={project.id}
                      key={project.id}
                      project={project}
                      alt={project.projectName}
                      canEdit={project.canEdit === true}
                      size={matches ? "180px" : "170px"}
                    />
                  );
                })}
          </MobileView>
        </Hidden>
      </GvGigDetails>
    </GvProjectCard>
  );
};

export default ProjectCard;
