import React, { useEffect, useContext, FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import styled from '@emotion/styled'
import { ReactComponent as Search } from "src/icons/navIcons/search-unsel.svg";
import { ReactComponent as SearchSel } from "src/icons/navIcons/search-sel.svg";
import { ReactComponent as Dash } from "src/icons/navIcons/dash-unsel.svg";
import { ReactComponent as DashSel } from "src/icons/navIcons/dash-sel.svg";
import { ReactComponent as Job } from "src/icons/navIcons/job-unsel.svg";
import { ReactComponent as JobSel } from "src/icons/navIcons/job-sel.svg";
import { ReactComponent as Contract } from "src/icons/navIcons/contract-unsel.svg";
import { ReactComponent as ContractSel } from "src/icons/navIcons/contract-sel.svg";
import { ReactComponent as Skill } from "src/icons/navIcons/skill-unsel.svg";
import { ReactComponent as SkillSel } from "src/icons/navIcons/skill-sel.svg";
import { breakpoints } from "src/designsystem/theme";

interface Item {
  href: string
  icon: FC
  iconActive: FC
  title: string
}

const items: Item[] = [
  {
    href: "/c/search",
    icon: Search,
    iconActive: SearchSel,
    title: "Search",
  },
  {
    href: "/c/dashboard",
    icon: Dash,
    iconActive: DashSel,
    title: "Dashboard",
  },
  {
    href: "/c/jobs",
    icon: Job,
    iconActive: JobSel,
    title: "Jobs",
  },
  {
    href: "/c/gigs",
    icon: Contract,
    iconActive: ContractSel,
    title: "Gigs",
  },
  {
    href: "/c/skills-upgrade",
    icon: Skill,
    iconActive: SkillSel,
    title: "Skills Upgrade",
  },
];


const GvNavbar = styled.div`
display: grid;
place-items: center;
padding: 5px;
@media (min-width: ${breakpoints["lg"]}) {
  display: block;
  padding: 15px 5px 5px 5px;
} 
`
const List = styled.li`
display: inline;
@media (min-width: ${breakpoints["lg"]}) {
  display: block;
} 
`

const NavBar: FC = () => {

  return (
    <GvNavbar>
      <ul>
        {items.map((item) => (
          <List>
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              Icon={item.icon}
              IconActive={item.iconActive}
            />
          </List>
        ))}
      </ul>
    </GvNavbar>
  );
};

export default NavBar;
