import React, { useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import UserContext from "src/context/user-context";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: "white",
    textAlign: "center",
  },
  media: {
    height: 300,
  },
  headerText: {
    fontSize: "2.5rem",
    margin: "0px 0px -5px 0px",
    fontWeight: "600",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  hello: {
    fontWeight: "500",
    fontSize: "1.8rem",
    letterSpacing: "1.1px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "27px",
      fontCharacter: 0,
      color: "#3D4347",
      verticalAlign: "top",
      horizontalAlign: "left",
      display: "flex",
    },
    marginTop: "25px",
  },
}));

const CandidateWelcome = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { handleJobPostOpen, user } = props;

  const handleClick = (link) => {
    navigate(link);
  };

  const handleWindowOpen = (link) => {
    window.open(link);
  };

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        display="block"
        className={classes.hello}
      >
        {" "}
        <span role="img" aria-label="hello">
          👋{" "}
        </span>
        Welcome, {userContext.user.get.firstName}
      </Typography>

      <Typography
        variant="h3"
        gutterBottom
        display="block"
        className={classes.headerText}
      >
        Let's get you started
      </Typography>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <Card
            className={classes.root}
            onClick={() => {
              handleClick("/c/search");
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image="/static/images/apply-tile.svg"
                title="Apply for Jobs"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Apply for Jobs
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Search jobs by skill, location, flexibility and more
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <RouterLink to="/r/search">
                <Button color="primary">
                  Continue
                  <PlayArrowIcon color="primary"></PlayArrowIcon>
                </Button>
              </RouterLink>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <Card
            className={classes.root}
            onClick={() => {
              handleClick("/o/candidate");
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image="/static/images/profile-setup-tile.svg"
                title="Complete Profile Setup"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Complete Profile Setup
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Specify work mode, preferences, skills, budget and more.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Continue
                <PlayArrowIcon color="primary"></PlayArrowIcon>
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <Card
            className={classes.root}
            onClick={() => {
              handleWindowOpen(
                "https://programs.alyve.health/programs/affinity-insurance?org=gigvistas"
              );
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image="/static/images/insurance-tile.svg"
                title="Complete Insurance Setup"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Complete Insurance Setup
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Complete purchasing the health insurance on Alyve
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Continue
                <PlayArrowIcon color="primary"></PlayArrowIcon>
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CandidateWelcome;
