import React from "react";
import PropTypes from "prop-types";
import {
  alpha,
  InputBase,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

const VistasInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
    },
    "&  .MuiFormHelperText-root.Mui-error": {
      margin: 0,
      backgroundColor: theme.palette.background.paper,
      padding: 0,
    },
    // backgroundColor: theme.palette.common.white,
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontWeight: 600,
  },
}));

export default function VistasTextFieldOutlined(props) {
  const classes = useStyles();
  return (
    <FormControl fullWidth={true} required={props.required}>
      <InputLabel
        shrink
        htmlFor={props.id}
        className={classes.inputLabel}
        error={props.error}
      >
        {props.inputLabelText}
      </InputLabel>
      <VistasInput
        defaultValue={props.defaultValue}
        id={props.id}
        required={props.required}
        error={props.error}
        variant={"outlined"}
        helperText={props.helperText}
        {...props}
      />
    </FormControl>
  );
}

VistasTextFieldOutlined.propTypes = {
  // children: PropTypes.node.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  helperText: PropTypes.string,
};
