import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import getInitials from "src/utils/getInitials";
import JobStepper from "src/components/job-steps/JobStepper";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dialogPaper: {
    minHeight: "90vh",
  },
}));

const Results = ({ className, jobs, ...rest }) => {
  const classes = useStyles();
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedJobIds;

    if (event.target.checked) {
      newSelectedJobIds = jobs.map((job) => job.gig_id);
    } else {
      newSelectedJobIds = [];
    }

    setSelectedJobIds(newSelectedJobIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedJobIds.indexOf(id);
    let newSelectedJobIds = [];

    if (selectedIndex === -1) {
      newSelectedJobIds = newSelectedJobIds.concat(selectedJobIds, id);
    } else if (selectedIndex === 0) {
      newSelectedJobIds = newSelectedJobIds.concat(selectedJobIds.slice(1));
    } else if (selectedIndex === selectedJobIds.length - 1) {
      newSelectedJobIds = newSelectedJobIds.concat(selectedJobIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedJobIds = newSelectedJobIds.concat(
        selectedJobIds.slice(0, selectedIndex),
        selectedJobIds.slice(selectedIndex + 1)
      );
    }

    setSelectedJobIds(newSelectedJobIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Posted On</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs?.slice(page * limit, page * limit + limit).map((job) => {
                const jobDetailLink = "/c/jobDetails/" + job.id;
                return (
                  <TableRow hover key={job.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <RouterLink to={jobDetailLink}>
                          <Typography className={classes.title}>
                            <Link>
                              {job.title.length > 25
                                ? job.title.slice(0, 25) + "..."
                                : job.title}
                            </Link>
                          </Typography>
                        </RouterLink>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{ __html: job.description }}
                      ></div>
                    </TableCell>
                    <TableCell>
                      {moment(job.createdDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {job.jobPostLocations[0]
                        ? job.jobPostLocations[0]?.city
                        : "  _ "}
                    </TableCell>
                    <TableCell>{job.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={jobs.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent dividers>
          <JobStepper />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.array.isRequired,
};

export default Results;
