import React,  { forwardRef,useState,useEffect } from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { InputAdornment, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import {
  TimePreference,
  PerTimePreference,
  CurrencyFormat,
  CONTRACT_TIMEBASED_URL,
} from "src/utils/constants";

import VistasCardNew from "src/components/custom/VistasCardNew";
import { colors, breakpoints } from "src/designsystem/theme";
import styled from "@emotion/styled";
import Typography from "src/designsystem/Core/Typography";

import { useDispatch, useSelector } from "react-redux";
import { submitFormInfo } from "src/views/recruiter/RecruiterContract/state/contractStepperReducers";
const useStyles = makeStyles((theme) => ({
  helperTextLayout: {
    "& .css-1wxe1b7-MuiFormHelperText-root": {
      marginLeft: 0,
    },
    "&  .css-b19n4k-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      marginTop: 14,
      backgroundColor: theme.palette.common.white,
      padding: 15,
    },
    "&  .css-1wxe1b7-MuiFormHelperText-root.Mui-error": {
      marginTop: 10,
      
    }
  },
}));
const CSView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;
const CSCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr; 
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 150px 125px 1fr;
  }
`;
const CSLeft = styled.div`
  display: grid;
  gap: 15px;
  padding-right: 15px;
  grid-template-columns: 1fr;

`;
const CSBBLeft = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;

`;
const CSRight = styled.div`
  display: grid;
  gap: 15px;
  
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
    gap: 30px;
   
  }
`;

const CSBCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 150px 150px 1fr;
  }
`;

const Header = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: gray;
`;

const CSBLeft = styled.div`
  display: grid;
  gap: 15px;
  padding-right: 15px;
  grid-template-columns: 1fr;
 
`;
const CSBRight = styled.div`
  display: grid;
  gap: 15px;

  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    gap: 30px;
 
  }
`;
const initialValues = {
  contractId:"",
  rateUnit: "Per Day",
  currencyCode: "INR",
  fees: "",
  duration: "",
  timeUnit: "Days",
};

const TimeBased = forwardRef((props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { useImperativeHandle } = React;
  const [initVal, setInitVal] = useState(initialValues);

  const contractId= initVal.contractId;
  const fees=initVal.fees;
  let methodType="POST";
  let params={
    contractId: contractId,
  };

  if (contractId && contractId != null&&fees) {
    methodType="PUT"
    params={
      contractId: contractId,
    };
  } else {
    methodType="POST"
    params={
      contractId: contractId,
    };
  }
  

  useImperativeHandle(ref, () => ({
    handleSubmit
  }), []);

  const handleSubmit = (values, actions) => {
    submitSaveTimeBased(values, actions);
  };

  const submitSaveTimeBased = (values, actions) => {

   

    var termsData = {
      rateUnit: values.rateUnit,
      currencyCode: values.currencyCode,
      fees: values.fees,
      duration: values.duration,
      timeUnit: values.timeUnit,
    };
    dispatch(submitFormInfo({ methodType, endPoint:CONTRACT_TIMEBASED_URL,formData:termsData,params,props}));

}

    const state = useSelector(state=>state.contractStepperinfo)
    console.log("DJSummaryState",state);
  
    useEffect(() => {
      if (state && state.resource ) {
        setInitVal(
          {
            contractId:state.resource.contractId,
            rateUnit: state.resource.rateUnit?state.resource.rateUnit:"Per Day",
            currencyCode: state.resource.currencyCode?state.resource.currencyCode:"INR",
            fees: state.resource.fees,
            duration: state.resource.duration,
            timeUnit: state.resource.timeUnit?state.resource.timeUnit:"Days",
          }
        )
      }
    }, ["resource" in state]);
    
    const validationSchema = Yup.object().shape({
      fees: Yup.number("Must be a number").positive("Must be positive").max(255).required("Fees is required"),
      duration: Yup.number("Must be a number").positive("Must be positive").max(255).required("Time is required")
    });

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={initVal}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitSaveTimeBased(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
        actions,
      }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CSView>
          <Header variant={"label"} gutterBottom>
          Billing Rate
            </Header>
        
              <CSCard>
                <CSLeft>
                  <TextField
                    variant="outlined"
                    name="rateUnit"
                    size="small"
                    onChange={(e) => {
                      e.preventDefault();
                      handleChange(e);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values.rateUnit}
                  >
                    {PerTimePreference.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </CSLeft>

                <CSRight>
                  <CSCard>
                    <CSBBLeft>
                      <TextField
                        variant="outlined"
                        name="currencyCode"
                        size="small"
                        onChange={(e) => {
                          e.preventDefault();
                          handleChange(e);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.currencyCode}
                      >
                        {CurrencyFormat.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </CSBBLeft>
                    <CSRight>
                      <TextField
                         className={classes.helperTextLayout}
                        type="number"
                        name="fees"
                        variant="outlined"
                        required
                        value={values.fees}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        size="small"
                        error={Boolean(touched.fees && errors.fees)}
                        helperText={touched.fees && errors.fees}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    </CSRight>
                  </CSCard>
                </CSRight>
              </CSCard>
           
              <Header variant={"label"} gutterBottom>
              Time Commitment and Duration
            </Header>
           
              <CSBCard>
                <CSBLeft>
                  <TextField
                    className={classes.helperTextLayout}
                    type="number"
                    name="duration"
                    variant="outlined"
                    size="small"
                    required
                    value={values.duration}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    error={Boolean(touched.duration && errors.duration)}
                    helperText={touched.duration && errors.duration}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />
                </CSBLeft>
                <CSBRight>
                  <TextField
                    className={classes.helperTextLayout}
                    variant="outlined"
                    name="timeUnit"
                    size="small"
                    onChange={(e) => {
                      e.preventDefault();
                      handleChange(e);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values.timeUnit}
                  >
                    {TimePreference.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </CSBRight>
              </CSBCard>

           
  
          </CSView>
        </form>
      )}
    </Formik>
  );
});

export default TimeBased;
