export const getURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  return `${base}?${query}`;
};

export const queryToObject = (queryString) => {
  const pairsString =
    queryString[0] === "?" ? queryString.slice(1) : queryString;
  const pairs = pairsString
    .split("&")
    .map((str) => str.split("=").map(decodeURIComponent));
  return pairs.reduce(
    (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
    {}
  );
};

export const getURL = (clientId, state, scope) => {
  const current = encodeURIComponent(window.location.href);
  const base =
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code&";

  const fullScope =
    scope && scope.length
      ? `&scope=${encodeURIComponent(scope.join(" "))}`
      : "";

  return `${base}client_id=${clientId}&redirect_uri=${current}&state=${state}${fullScope}`;
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const parseNameToInitials = (firstName, lastName) => {
  var f =
    firstName && firstName.length > 0
      ? firstName.substring(0, 1).toUpperCase()
      : "";
  var l =
    lastName && lastName.length > 0
      ? lastName.substring(0, 1).toUpperCase()
      : "";
  return f + l;
};

export const validateAuth = () => {
  console.log("called validate auth");
};

export const getSterilizeUtmParams = (params) => {

  if(!params || params.length === 0){
    return '';
  }

  const allowedParams = ["utm_source","utm_medium","utm_campaign","utm_content","utm_term"];

  let cleanedParams = [];
  params.split("&").forEach(pair => {
    let item = pair.split("=");
    if(item 
      && item.length>0 
      && allowedParams.includes(item[0])) {
        cleanedParams.push(pair)
    }
  });

  return cleanedParams.length > 0 ? '?'+cleanedParams.join("&") : '';
}

export function getUrlQueryStringFromObj(query){
  var queryString = Object.keys(query).map(function(key) {
    return key + '=' + query[key]
  }).join('&');
  return queryString;
}

// json object of params
export const buildURLWithQueryParams = (base, params) => {
  const query = new URLSearchParams(params).toString();
  return `${base}?${query}`;
};