import axios, {AxiosResponse} from "axios";
import {
  GET_JOBID_URL,
  GET_JOB_APPLICANTS_URL,
  GET_CANDIDATE_RECOMMENDATIONS,
  CREATE_CANDIDATE_BOOKMARK,
  REMOVE_CANDIDATE_BOOKMARK,
  CLOSED_JOB_URL,
  DELETE_JOB_URL,
  SHORTLIST_CANDIDATE_URL,
  REMOVE_SHORTLIST_CANDIDATE_URL,
  GET_DRAFT_DETAIL,
  GET_JOB_APPLICANTS_PAGINATION_URL,
  GET_JOB_SHORTLIST_APPLICANTS_URL

} from "src/utils/constants";
import {BookMarkedCandidate} from "./models";


export const getDraft=async (jobId:number,user_code:string)=>{
  const response=await axios.request({
    method:"GET",
    url:GET_DRAFT_DETAIL+"?jobId="+jobId,
  })
  console.log(response.data)
  
  return response.data;
}

export const getJobInfo = async (jobID: number) => {
  const response = await axios({
    method: "GET",
    url: GET_JOBID_URL,
    params: {
      jobId: jobID
    }
  });
  return response.data;
};

export const getJobApplicants  = async (jobID:number,user_code:string,page_Number: number) => {
  const response = await axios.request({
    method: "GET",
    url: GET_JOB_APPLICANTS_PAGINATION_URL,
    params: {
      employerCode: user_code,
      jobId: jobID,
      pageNumber: page_Number,
    }
  });

  console.log(response);
  return response.data;

};


export const getJobShortListApplicants  = async (jobID:number,user_code:string,page_Number: number) => {
  const response = await axios.request({
    method: "GET",
    url: GET_JOB_SHORTLIST_APPLICANTS_URL,
    params: {
      employerCode: user_code,
      jobId: jobID,
    }
  });

  console.log(response);
  return response.data;

};


/*
export const getCandidateRecommendations = async (
  jobID: number,
  user_code: string
) => {
  const response = await axios({
    method: "GET",
    url: GET_CANDIDATE_RECOMMENDATIONS,
    params: {
      jobId: jobID,
      pageNumber: 0,
      user_code: user_code
    }
  });
  console.log("can");
  console.log(response.data);
  return response.data;
};
*/

// export const getCandidateRecommendations: (
//   jobId: number
// ) => Promise<RecommendationInfo> = async jobID => {
//   const response = await axios.request<any, { candidates: {id:number}[] }>({
//     method: "GET",
//     url: GET_CANDIDATE_RECOMMENDATIONS,
//     params: {
//       jobId: jobID
//     }
//   });
  

//   return {
//     candidates: response.candidates.map(candidate => {

//       //const years = candidate.months / 12;
//       //const months = candidate.months % 12;
//       const id = candidate.id;

//       return {
//         id: 100,
//       }
//     })
//   }

// };

export const getCandidateRecommendations = async (jobID:number,user_code:string) => {
  const response = await axios.request({
    method: "GET",
    url: GET_CANDIDATE_RECOMMENDATIONS,
    params: {
      jobId: jobID,
      user_code: user_code,
      pageNumber: 0
    }
  });

 return response.data;
};

export const bookmarkToCandidate = async (user_code: string, candidateID: string) => {
  const response = await axios.post<BookMarkedCandidate>(
    CREATE_CANDIDATE_BOOKMARK + "?user_code=" + user_code + "&candidate_id=" + candidateID
  );
  return response.data;
}

export const removeBookmarkCandidate = async (user_code: string, candidateID: string) => {
  const response = await axios.post<boolean>(
    REMOVE_CANDIDATE_BOOKMARK + "?user_code=" + user_code + "&candidate_id=" + candidateID
  );

  return response.data;
}

export const closeJob = async (jobID: number) => {
  const response = await axios.put(
    CLOSED_JOB_URL + "?jobId=" + jobID
  );
  return response.data;
}

export const deleteJob = async (jobID: number) => {
  const response = await axios.put(
    DELETE_JOB_URL + "?jobId=" + jobID
  );
  return response.data;
}


export const shortlistCandidate = async (candidateID: string,jobID: number) => {
  const response = await axios.post(
    SHORTLIST_CANDIDATE_URL + "?candidateId=" + candidateID + "&jobId=" + jobID
  );
  return response.data;
}

export const removeShortlistCandidate = async (candidateID: string,jobID: number) => {
  const response = await axios.delete<boolean>(
    REMOVE_SHORTLIST_CANDIDATE_URL + "?candidateId=" + candidateID + "&jobId=" + jobID
  );

  return response.data;
}