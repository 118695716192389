import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  filterTitle: {
    fontSize: 21,
    lineHeight: "25px",
    fontWeight: 600,
  },
  filterItem: {
    margin: 5,
    padding: "2px 5px",
    borderRadius: 4,

    "&:not(:first-child)": {
      borderRadius: 4,
      marginLeft: 1,
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    },

    "&:not(:last-child)": {
      borderRadius: 4,
    },
  },
  filterSelected: {
    backgroundColor: "#2B7ED8",
  },
});

const MultiSelectFilterGroup = (props) => {
  const classes = useStyles();
  const { title, label, filters, selectedFilters, setSelectedFilters } = props;
  const [formats, setFormats] = React.useState(() => []);

  // useEffect(() => {
  //   setFormats(selectedFilters);
  // }, []);

  const handleFormat = (event, newFormats) => {
    var temp = selectedFilters;
    temp[title] = newFormats;
    setSelectedFilters(temp);
    setFormats(newFormats);
  };

  return (
    <>
      <Typography variant="h5" className={classes.filterTitle}>
        {label}
      </Typography>
      <ToggleButtonGroup
        value={formats}
        onChange={handleFormat}
        aria-label="text formatting"
        className={classes.root}
        classes={{ groupedHorizontal: classes.filterItem }}
      >
        {filters.map((f) => (
          <ToggleButton
            value={f}
            aria-label={f}
            key={f}
            classes={{ selected: classes.filterSelected }}
            className={classes.filterItem}
            selected={selectedFilters[title]?.includes(f) ? true : false}
          >
            {/* <FormatBoldIcon /> */}
            {f}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

MultiSelectFilterGroup.propTypes = {
  filters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object,
  title: PropTypes.string,
};

export default MultiSelectFilterGroup;
