import React, { FC, useState, useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import RewardCard from "src/derivedComponents/RewardCard";
import Card from "src/designsystem/Core/Card";
import Typography from "src/designsystem/Core/Typography";
import { useDispatch, useSelector } from "react-redux";
import GVButton from "src/designsystem/Core/Button";
import UserContext from "src/context/user-context";
import { getCandidateReward } from "./state/RewardReducers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import RewardDialogHistory from "src/views/candidate/CandidateReward/RewardDialogHIstory";
import { Button } from "@mui/material";
const CJDView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const TopCard = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Suggestions = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 25px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const RewardView = ({ jobID }) => {
  const initialRewardState = {
    id: null,
    totalPoints: "",
    referredBy: "",
    lastUpdated: false,
    rewardTransactions: [],
  };
  const [hasBookMarked, setToggle] = useState(true);
  const [currentReward, setCurrentReward] = useState(initialRewardState);
  const userContext = useContext(UserContext);
  var userCode = userContext.user.get.userCode;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedInUser = true;
  const [editSummary, setEditSummary] = useState(false);

  useEffect(() => {
    getReward();
  }, []);

  const getReward = () => {
    dispatch(getCandidateReward(userCode))
      .unwrap()
      .then((data) => {
        // console.log(data);
        setCurrentReward({
          id: data.id,
          totalPoints: data.totalPoints,
          referredBy: data.referredBy,
          lastUpdated: data.lastUpdated,
          rewardTransactions: data.rewardTransactions,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <CJDView>
      <TopCard>
        <RewardCard
          totalPoints={currentReward.totalPoints}
          userCode={userCode}
        />
      </TopCard>

      <Card title={"Have a refferal code?"}>
        <div>
          <Typography variant="body2" gutterBottom>
            Claim By 15th Jan 2022 to avail benefits
          </Typography>
          <Typography variant="body2" gutterBottom>
            Enter Referral Code
          </Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <TextField size="small" variant="outlined" />
            </div>
            <Bookmark toggle={true} onClick={() => setToggle(false)} /> */}
            <Button variant="outlined" endIcon={<ArrowForwardIcon />}>
              {userCode}
            </Button>
          </div>
        </div>
      </Card>

      <Card title={"Rewards History"}>
        <div style={{ display: "flex", gap: "15px" }}>
          <GVButton
            // onClick={() => {

            //   navigate("/c/reward/history",{currentReward});
            //   // setToggle(true);
            // }}
            onClick={() => {
              setEditSummary(true);
              setToggle(true);
            }}
          >
            Rewards Earnings History
          </GVButton>
        </div>
      </Card>
      {isLoggedInUser ? (
        <RewardDialogHistory
          open={editSummary}
          setOpen={setEditSummary}
          data={currentReward.rewardTransactions}
        />
      ) : null}
    </CJDView>
  );
};

export default RewardView;
