import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ChatBox from "./ChatBox";

const useStyles = makeStyles({
  chatApp: {
    display: "flex",
    alignContent: "flex-start",
    justifyContent: "center",
    padding: "10rem 1rem",
    animation: "fadeChatApp 0.6s ease-in-out",
    animationFillMode: "forwards",
  },
});

const messagesData = [
  {
    id: 1,
    sender: "Shun",
    senderAvatar: "https://i.pravatar.cc/150?img=32",
    message: "Hello 👋",
  },
  {
    id: 2,
    sender: "Gabe",
    senderAvatar: "https://i.pravatar.cc/150?img=56",
    message: "Hey!",
  },
  {
    id: 3,
    sender: "Gabe",
    senderAvatar: "https://i.pravatar.cc/150?img=56",
    message: "How are you?",
  },
  {
    id: 4,
    sender: "Shun",
    senderAvatar: "https://i.pravatar.cc/150?img=32",
    message: "Great! It's been a while... 🙃",
  },
  {
    id: 5,
    sender: "Gabe",
    senderAvatar: "https://i.pravatar.cc/150?img=56",
    message: "Indeed.... We're gonna have to fix that. 🌮🍻",
  },
];

const Messaging = (props) => {
  const classes = useStyles();
  const [isTyping, setIsTyping] = React.useState([]);
  const [messages, setMessages] = React.useState(messagesData);

  /* adds a new message to the chatroom */
  const sendMessage = (sender, senderAvatar, message) => {
    setTimeout(() => {
      let newMessageItem = {
        id: this.state.messages.length + 1,
        sender: sender,
        senderAvatar: senderAvatar,
        message,
      };
      setMessages([...messages, newMessageItem]);

      resetTyping(sender);
    }, 400);
  };
  /* updates the writing indicator if not already displayed */
  const typing = (writer) => {
    if (!this.state.isTyping[writer]) {
      let stateTyping = isTyping;
      stateTyping[writer] = true;
      setIsTyping(stateTyping);
    }
  };
  /* hide the writing indicator */
  const resetTyping = (writer) => {
    let stateTyping = isTyping;
    stateTyping[writer] = false;
    setIsTyping(stateTyping);
  };

  let users = {};
  let chatBoxes = [];

  /* user details - can add as many users as desired */
  users[0] = { name: "Shun", avatar: "https://i.pravatar.cc/150?img=32" };
  //   users[1] = { name: "Gabe", avatar: "https://i.pravatar.cc/150?img=56" };

  /* creation of a chatbox for each user present in the chatroom */
  Object.keys(users).map(function (key) {
    let user = users[key];
    chatBoxes.push(
      <ChatBox
        key={key}
        owner={user.name}
        ownerAvatar={user.avatar}
        sendMessage={sendMessage}
        typing={typing}
        resetTyping={resetTyping}
        messages={messages}
        isTyping={isTyping}
      />
    );
  });
  return <div className={classes.chatApp}>{chatBoxes}</div>;
};

// https://codepen.io/swaibu/pen/OJLZjLb?editors=1010
export default Messaging;
