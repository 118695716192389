import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "./authService";

const user = JSON.parse(localStorage.getItem("user"));

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ values }) => {
    const response = await AuthService.registerUser(values);
      return response;
  }
);

// export const login = createAsyncThunk(
//   "auth/login",
//   async ( email, password ) => {
//     try {
//       const response = await AuthService.login(email, password);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

export const login = createAsyncThunk(
  "auth/login",
  async (email, password) => {
    const response = await AuthService.login(email, password);
    return response;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [registerUser.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [registerUser.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      //state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
     // state.user = null;
    },
    // // [logout.fulfilled]: (state, action) => {
    // //   state.isLoggedIn = false;
    // //   state.user = null;
    // },
  },
});

const { reducer } = authSlice;
export default reducer;
