import React, { FC, useContext } from "react";
import AccessDenied from "src/views/errors/AccessDenied";
import { useSelector, useDispatch } from "react-redux";
import {
  useLocation,
  useSearchParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  setAccessToken,
  initializeToken,
  setRedirect,
} from "src/redux/slice/userSlice";

import Cookies from "universal-cookie/es6";
import TokenService from "src/services/TokenService";
import UserContext from "src/context/user-context";
import { refreshToken } from "src/views/auth/state/auth";

export const AuthGuardRoute = ({
  component: RouteComponent,
  componentName,
}) => {
  const user = TokenService.getUser();
  const redirectPath = TokenService.getRedirectPath();
  const isRedirectPathAvaiable = redirectPath ? true : false;
  const isAuthenticated = TokenService.getCookiesAccessToken() ? true : false;
  const userTypeSelected = user?.userType === undefined ? false : true;

  const isCandidate =
    isAuthenticated && user && user.userType === "individual_candidate" ? true : false;
  const showOnboarding =
    isAuthenticated && user && user.showOnboarding ? true : false;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const allowedPaths = ["/login", "/reset-password", "/register", "/404"];


  if (isAuthenticated) {
    if (location.pathname === "/login" || location.pathname === "/register") {
      if (userTypeSelected && isCandidate) {
        if (showOnboarding) {
          return <Navigate to="/o/candidate" replace />;
        } else if (
          isRedirectPathAvaiable &&
          redirectPath.includes("c/jobDetails")
        ) {
          TokenService.removeRedirectPath();
          return <Navigate to={redirectPath} replace />;
        } else {
          return <Navigate to="/c/dashboard" replace />;
        }
      } else if (userTypeSelected && !isCandidate){
        if (showOnboarding) {
          return <Navigate to="/o/recruiter" replace />;
        } else {
          return <Navigate to="/r/home" replace />;
        }
      } else {
        return <RouteComponent />;
      }
    } else if (userTypeSelected && isCandidate) {
      if (showOnboarding && !location.pathname.includes('/o/candidate')) {
        return <Navigate to="/o/candidate" replace />;
      } else if (componentName === "CandidateLayout" ||componentName === "AuthLayout" || componentName === "OnboardingLayout")
      {
        return <RouteComponent />;
      }
      else {
        return <AccessDenied />
      }
    } else if (userTypeSelected && !isCandidate) {
      if (showOnboarding && !location.pathname.includes('/o/recruiter')) {
        return <Navigate to="/o/recruiter" replace />;
      }
      else if (componentName === "RecruiterLayout" ||componentName === "AuthLayout" ||componentName === "OnboardingLayout")
      {
        return <RouteComponent />;
      } 
      else {
        return  <AccessDenied />;
      }
    }
    else {
      return <RouteComponent />;
    } 
  } else {
   
    // TokenService.getRefreshToken()
    // .then(response => {
    //   if (response) {
    //     getRedirectRoute();
    //   } else {
    //     return <RouteComponent />;// login
    //   }
    // })
    // .catch(e => {
    //   console.log(e);
    // });
    
    return <RouteComponent />;// login
  }

};

export default AuthGuardRoute;
