import { useContext, useState, useEffect } from "react";
import { Grid,TextField, InputLabel } from "@mui/material";


const Assessment = () => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <InputLabel shrink >
                    Name
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 10 }}
                    name="assessmentName"
                    variant="outlined"
                    required
                    fullWidth
                  />
            </Grid>
            <Grid item xs={12}>
                <InputLabel shrink >
                    Description
                  </InputLabel>
                  <TextField
                    inputProps={{ maxLength: 10 }}
                    name="description"
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                  />
            </Grid>
        </Grid>

    )

}

export default Assessment;