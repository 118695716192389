import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: "10px 0px",
  },
  sectionLabel: {
    fontSize: theme.typography.text.small,
    fontWeight: "bold",
    color: "gray",
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.accent.main}`,
    fontFamily: theme.typography.fontFamily,
    "& span": {
      display: "inline-block",
      paddingBottom: 5,
      paddingRight: 5,
      borderBottom: `3px solid ${theme.palette.text.primaryBlue}`,
    },
  },
  sectionContent: {
    margin: "20px 0px",
  },
}));

const VistasCardNew = (props) => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className={classes.sectionLabel}>
        <Typography variant="outlined">{props.label}</Typography>
      </div>
      <div className={classes.sectionContent}>{props.children}</div>
    </section>
  );
};

VistasCardNew.propTypes = {
  label: PropTypes.string,
};

export default VistasCardNew;
