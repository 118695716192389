import React, { FC, useContext } from "react";
import Page from "src/components/Page";

import JobDetailView from "./JobDetailView";
import { useParams } from "react-router-dom";

const JobIdDetails: FC = () => {

  let { jobId } = useParams();

  return (
    <Page title="Job Details">
      <JobDetailView jobID={parseInt(jobId!, 10)} />
    </Page>
  );
};

export default JobIdDetails;
