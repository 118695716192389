import React, { useState, useContext, useEffect } from "react";
import {
  useTheme,
  Button,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import TokenService from "src/services/TokenService";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakpoints } from "src/designsystem/theme";

import { DELETE_ACCOUNT_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";

const useStyles = makeStyles({
  root: {
    display: "grid",
    rowGap: "10px",
    lineHeight: "55px",
    color: "#595959",
  },
  button: {
    "&:hover": {
      backgroundColor: "#F3F1F8",
      color: "#F65441",
    },
    "&:active": {
      backgroundColor: "#FFFFFF",
    },
  },

  heading: {
    color: "red",
    lineHeight: "55px",
    fontWeight: "600",
    fontSize: "20px",
  },
  verified: {
    display: "flex",
  },
  textBold: {
    fontWeight: "bold",
  },
  refreshIcon: {
    cursor: "pointer",
    marginLeft: 5,
  },
  statusText: {
    margin: 2,
  },
  statusIcon: {
    margin: 2,
  },
});

const DeleteAccount = ({ className, ...rest }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    userCode: "",
  });

  const userContext = useContext(UserContext);
  const [flag, setFlag] = React.useState(true);

  const handleClick = () => {
    setFlag(!flag);
  };

  useEffect(() => {
    setValues({
      ...values,
      userCode: userContext.user.get.userCode,
    });
  }, [userContext]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    axios.delete(DELETE_ACCOUNT_URL, values).then((response) => {
      setDisabled(false);
      const userObj = TokenService.getUser();
      userContext.user.set(userObj);
      TokenService.getRefreshToken()
        .then((response) => {
          const userObj = TokenService.getUser();
          userContext.user.set(userObj);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickDelete = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDialog(false);
  };
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} lg={12}>
        <div>
          <Typography className={classes.heading}>Delete Account</Typography>
          {matches ? (
            <Divider
              sx={{
                width: "100%",
                height: "1px",
                background: "#D9D9D9",
              }}
            />
          ) : (
            <Divider
              sx={{
                width: "95%",
                height: "1px",
                background: "#D9D9D9",
              }}
            />
          )}
        </div>

        <div
          sx={{
            width: "755px",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "14px",
            color: "#595959",
            whiteSpace: "nowrap",
          }}
        >
          Once you delete your account, there is no going back. Please be
          certain.
        </div>
        <div>
          <Button
            onClick={handleClickDelete}
            sx={{
              border: "1px solid #F65441",
              borderRadius: "7px",
              color: "red",
            }}
          >
            Delete your Account
          </Button>
          <Dialog
            onBackdropClick="false"
            aria-labelledby="responsive-dialog-title"
            open={openDialog}
            onClose={(event, reason) => {
              if (reason === "backdropClick") {
                return;
              }
              handleCloseDialog();
            }}
            p={2}
          >
            <DialogTitle id="responsive-dialog-title">
              Delete Account
              {matches ? (
                <Divider
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#D9D9D9",
                  }}
                />
              ) : (
                <Divider
                  sx={{
                    width: "95%",
                    height: "1px",
                    background: "#D9D9D9",
                  }}
                />
              )}
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete your account? If you delete your
                account, you will permanently lose your data.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                className={classes.button}
                sx={{
                  border: "1px solid #F65441",
                  borderRadius: "7px",
                  color: "red",
                }}
                onClick={handleSubmit}
              >
                Delete Account{" "}
              </Button>
              <Button
                sx={{
                  borderRadius: "7px",
                  border: "1px solid #606063",
                  color: "#595959",
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
};

export default DeleteAccount;
