import Cookies from "universal-cookie/es6";


// 'gigvistas-user' 
export const getCookieValueByKey = (key) => {
    let cookies = new Cookies();
    let val = cookies.get(key);
    return unescape(val);
};

export const deleteCookie = (key) => {
    let cookies = new Cookies();
    cookies.remove(key, {
      expires: new Date(0),
      domain: ".gigvistas.com",
      path: "/"
    });
}

