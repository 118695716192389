import React, { useState, useContext, useEffect } from "react";
import { Resource } from "../../../models/Resource";
import JobDetailEntity from "./state/models";
import UserContext from "src/context/user-context";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getJobDetailAction } from "./state/CandidateReducers";
import Logo from "src/designsystem/Core/Avatar";
import JobCard from "src/derivedComponents/JobCard";
import Card from "src/designsystem/Core/Card";
import JobCardMini from "src/derivedComponents/JobCardMini";
import Section from "src/designsystem/Core/Section";
import Typography from "src/designsystem/Core/Typography";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import SimilarGigs from "src/derivedComponents/SimilarGigs";

import Button from "src/designsystem/Core/Button";
import Locations from "src/designsystem/Derivative/Locations";
const useStyles = makeStyles((theme) => ({}));

const CJDView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const TopCard = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Suggestions = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 25px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const WideDiv = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const JobDetailView = ({ jobID }: { jobID: number }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [jobPostsByEmployeToShow, setJobPostsByEmployeToShow] =
    React.useState(2);

  const userContext = useContext(UserContext) as any;

  var user_code = userContext.user.get.userCode;

  useEffect(() => {
    dispatch(getJobDetailAction({ jobID, user_code }));
  }, [jobID]);

  const state: Resource<JobDetailEntity> = useSelector<
    any,
    Resource<JobDetailEntity>
  >((state) => {
    return state.jobInfo;
  });

  console.log(state);

  if ("isPending" in state) {
    console.log("pending");
    return <div>Pending</div>;
  } else if ("resource" in state) {
    console.log("success");
    if (state.resource.jobInfo.jobPost) {
      return SucessView(
        state.resource,
        jobID,
        user_code,
        dispatch,
        classes,
        jobPostsByEmployeToShow,
        setJobPostsByEmployeToShow
      );
    } else {
      return emptyView();
    }
  } else if ("errorMessage" in state) {
    console.log("error");
    return errorView(state.errorMessage);
  } else {
    return <div>Fire! Fire! Fire!</div>;
  }
};

const emptyView = () => {
  return <h1>Invalid JobId</h1>;
};

const errorView = (errorMessage: string) => {
  return <h1>{errorMessage}</h1>;
};

const SucessView = (
  data: JobDetailEntity,
  jobID: number,
  user_code: string,
  dispatch: any,
  classes: any,
  jobPostsByEmployeToShow: any,
  setJobPostsByEmployeToShow: any
) => {
  function showJobPosts(length: any) {
    setJobPostsByEmployeToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  var skills = data.jobInfo?.jobPost?.jobPostSkills?.filter((s: any) => {
    if (s.isAdditionalSkill) return s;
  });
  var prof_skills = data.jobInfo?.jobPost?.jobPostSkills?.filter((s: any) => {
    if (!s.isAdditionalSkill) return s;
  });
  return (
    <CJDView>
      <TopCard>
        <JobCard
          jobDetailEntity={data}
          jobID={jobID}
          user_code={user_code}
          dispatch={dispatch}
        />
      </TopCard>

      <WideDiv>
        <Card title={"Job Description"}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.jobInfo?.jobPost?.description,
            }}
          ></div>
        </Card>
      </WideDiv>

      {data.jobInfo?.jobPost?.addedLocations?.length > 0 ? (
        <WideDiv>
          <Card title={"Job Location(s)"}>
            <Locations
              jobPostLocations={data.jobInfo?.jobPost?.addedLocations}
            />
          </Card>
        </WideDiv>
      ) : (
        <></>
      )}

      <WideDiv>
        <Card title={"About the Company"}>
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              gridTemplateColumns: "110px 1fr",
              gap: "15px",
            }}
          >
            <Logo
              src={data.jobInfo.organization.logo}
              alt={data.jobInfo.organization.name}
              size="110px"
            />
            <div>
              <Typography variant="h4" gutterBottom>
                {data.jobInfo?.organization?.name}
              </Typography>
              {data.jobInfo?.organization?.description}
            </div>
          </div>
        </Card>
      </WideDiv>

      <Suggestions>
        <Section children={"Similar Gigs"} />

        {data.moreLikeThisJobs && data.moreLikeThisJobs.length > 0 ? (
          data.moreLikeThisJobs.slice(0, jobPostsByEmployeToShow)
          .map((jobDetail: any) => (
            <SimilarGigs
              dispatch={dispatch}
              jobDetailEntity={jobDetail}
              jobID={jobDetail.id}
              user_code={user_code}
              jobDetails={jobDetail}
              organization={{
                logo: jobDetail.logo ? jobDetail.logo[0] : null,
                companyName: jobDetail.companyName[0],
              }}
            />
          ))
        ): (<></>)}
        {data.moreLikeThisJobs && data.moreLikeThisJobs.length > 0 ? (
          jobPostsByEmployeToShow === 2 ? (
            <Button
              onClick={() => {
                showJobPosts(data.moreLikeThisJobs.length);
              }}
            >
              Show More
            </Button>
          ) : (
            <Button
              onClick={() => {
                showJobPosts(2);
              }}
            >
              Show Less
            </Button>
          )
        ) : (
          <div>No Similar Gigs...</div>
        )}
      </Suggestions>
    </CJDView>
  );
};

export default JobDetailView;
