import React, { useState, useEffect, useContext } from "react";
import { Paper, Typography, IconButton, Button, TextField, 
  InputAdornment, Popper, List, ListItem, ListItemText, 
  useMediaQuery, Stack, Pagination } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TuneIcon from "@mui/icons-material/Tune";
import SearchFilter from "./SearchFilter";
import axios from "axios";
import UserContext from "src/context/user-context";
import { useTheme } from "@emotion/react";
import { JOB_SEARCH_SERVICE_URL } from "src/utils/constants";
import { JOB_SEARCH_FILTERS } from "src/utils/searchConstants";

import { useDispatch } from "react-redux";
import JobCardMini from "src/derivedComponents/JobCardMini";
import * as Yup from "yup";
import { Formik } from "formik";
import { SearchOutlined } from "@mui/icons-material";
import PageviewIcon from "@mui/icons-material/Pageview";
import Fuse from "fuse.js";
import { MASTER_KEYWORDS } from "src/utils/searchConstants";
import {queryToObject} from "src/utils/generic";

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    padding: 0,
    width: "99.5%",
    display: "flex",
    border: "none",
    margin: "20px 0px",
  },
  searchWidget: {
    width: "99.5%",
  },
  resultsHeader: {
    color: "#1A2740",
    display: "flex",
    justifyContent: "space-between",
  },
  resultsTitle: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "27px",
    opacity: 0.6,
  },
  root: {
    display: "block",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
  },
}));

const SearchView = (props, data, jobDetails) => {
  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();

  const userContext = useContext(UserContext);
  var user_code = userContext.user.get.userCode;

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [facetResults, setFacetResults] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [numFound, setNumFound] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [selectedFilters, setSelectedFilters] = useState({});

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));

  const placeHolderText = "Eg. web developer, python, customer service";

  const initialValues = {
    searchTerm: "",
  };
  const validationSchema = Yup.object().shape({});

  const fuse = new Fuse(MASTER_KEYWORDS, {
    keys: ["k", "t"],
    includeScore: true,
    threshold: 0.1,
    distance: 10,
    limit: 6
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [autoSuggestOptions, setAutoSuggestOptions] = React.useState([]);

  const [suggestWidth, setSuggestWidth] = React.useState(500);

  useEffect(() => {
    setSuggestWidth(document.getElementById("searchTerm").offsetWidth + 155);
  }, []);

  function getAutoSuggestOptions(e) {
    const term = e.target.value;
    console.log("getAutoSuggestOptions ", term);
    if(term.length > 2){
      const results = fuse.search(term).map((suggestion) => suggestion.item);
      if(results.length > 0){
        setAutoSuggestOptions(results);
        setAnchorEl(e.target.parentElement.parentElement);
      }else {
        setAutoSuggestOptions([]);
        setAnchorEl(null);
      }
    }
    else{
      setAutoSuggestOptions([]);
      setAnchorEl(null);
    }
  }

  function expandSuggestionType(t){
    if(t === "c"){
      return "in Categories";
    }
    else if(t === "e"){
      return "in Expertise";
    }
    else return "in Skills";
  }

  function prepareFacetFromAutoSuggest(term, type){
    let facet = {};
    if(type === "c"){
      facet = {"category":[term]};
    }
    else if(type === "e"){
      facet = {"expertise":[term]};
    }
    else {
      facet = {"preferred_skills":[term],
              "skills":[term]};
    };
    return JSON.stringify(facet);
  }

  const urlBasedSearchSubmit = (searchParams) => {
    setSearchResults([]);
    axios({
      method: "get",
      url: JOB_SEARCH_SERVICE_URL+searchParams,
    })
      .then((response) => {
        let temp = searchResults;
        temp = temp.concat(response.data["jobs"]);
        setSearchResults(temp);
        setAndFormatFacetResults(response.data["fc"]);
        setNumFound(response.data.numFound);
        let nextPage = pageNum + 1;
        setPageNum(nextPage);
        setTotalPages(Math.ceil(response.data.numFound/25)); // since page size is 25
      })
      .catch(function (error) {
        console.log("POST CALL ERROR");
        console.log(error);
      });
  };

  useEffect(() => {
    let searchParams = window.location.search;
    if(searchParams){
      urlBasedSearchSubmit(searchParams)
    }
  },[]);


  function submitSuggestedSearch(term, type) {
    var params = {
      q: "",
      p: 0,
      sf: "",
      userCode: userContext.user.get.userCode,
      f: prepareFacetFromAutoSuggest(term,type)
    };
    window.location.search = "?"+ new URLSearchParams(params).toString()
  }

  function submitSearch(values, actions) {
    
    var params = {
      q: values.searchTerm,
      p: 0,
      sf: "",
      userCode: userContext.user.get.userCode,
    };
    window.location.search = "?"+ new URLSearchParams(params).toString()
    setSearchTerm(values.searchTerm);
  }

  function setAndFormatFacetResults(facets) {
    let filters = [];

    for (const [k, label] of Object.entries(JOB_SEARCH_FILTERS)) {
      let temp = {};
      temp.name = k;
      temp.type = "radio";
      temp.label = label;
      let opt = [];
      let optVal = [];
      for (const [key, value] of Object.entries(facets[k])) {
        if (value !== 0) {
          opt.push(key);
          optVal.push(value);
        }
      }
      if (opt !== []) {
        temp.options = opt;
        temp.optionsVal = optVal;
        filters.push(temp);
      }
    }
    setFacetResults(filters);
  }

  function handleApplication(job) {
    let newResults = [...searchResults];
    let match = newResults.find((t) => t.id === job.id);
    if (match) {
      match.candidateHasApplied = true;
    }
    setSearchResults(() => newResults);
  }

  function handleBookmark(job) {
    let newResults = [...searchResults];
    let match = newResults.find((t) => t.id === job.id);
    if (match) {
      match.bookmarked = !match.bookmarked;
    }
    setSearchResults(() => newResults);
  }

  function hanglePageChange(page) {
    let searchParams = window.location.search;
    let searchParamsObj = queryToObject(searchParams);
    
    searchParamsObj["p"] = page - 1;
    searchParamsObj["f"] = searchParamsObj["f"].replaceAll('+', ' '); // replace + with space
    setPageNum(page);
    window.location.search = "?"+ new URLSearchParams(searchParamsObj).toString();
  }


  return (
    <>
      <div className={classes.widgetContainer}>
      <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            submitSearch(values, actions);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setValues,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              autoComplete="off"
              noValidate
              className={classes.root}
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <Typography
                variant={matchesXS ? "h6" : "h3"}
                gutterBottom
                display="block"
                className={classes.flexItem}
              >
                Search Gigs
              </Typography>
              <br />
              <TextField
                fullWidth
                required
                variant="outlined"
                value={values.searchTerm}
                onChange={(e)=>{
                  handleChange(e)
                  getAutoSuggestOptions(e)
                }}
                className={classes.flexItem}
                placeholder={placeHolderText}
                display="block"
                name="searchTerm"
                id="searchTerm"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined edge="start" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {matchesXS ? (
                        <IconButton
                          color="primary"
                          onClick={handleSubmit}
                          type="submit"
                          aria-label="search"
                          component="span"
                          size="large"
                        >
                          <PageviewIcon
                            style={{
                              height: "50px",
                              width: "50px",
                            }}
                          />
                        </IconButton>
                      ) : (
                        <Button variant="contained" color="primary" type="submit">
                          Search
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <Popper
                id="auto-suggest-popover"
                open={open}
                anchorEl={anchorEl}
                placement={"bottom-start"}

              >
                <Paper>
                  <List style={{width:suggestWidth+"px"}} dense={true}>
                    {autoSuggestOptions.map((option) => (
                        <ListItem style={{cursor:"pointer"}} key={option.k + option.t}>
                        <ListItemText
                          onClick={() => {submitSuggestedSearch(option.k, option.t)}}
                          primary={option.k}
                          secondary={expandSuggestionType(option.t)}
                        />
                      </ListItem>
                      ))}
                  </List>
                </Paper>
              </Popper>
            </form>
          )}
        </Formik>
      </div>

      {searchResults && searchResults.length > 0 ? (
        <>
          <div className={classes.resultsHeader}>
            <Typography variant="h5" className={classes.resultsTitle}>
              {numFound} Gigs Found.
            </Typography>
            <IconButton color="default" onClick={() => handleFilter()} size="large">
              <TuneIcon />
            </IconButton>
          </div>

          <div style={{ display: "grid", gap: "10px" }}>
                {searchResults &&
                  searchResults.map((searchResult) => (
                    <JobCardMini
                      dispatch={dispatch}
                      jobDetailEntity={searchResult}
                      jobID={searchResult.id}
                      user_code={user_code}
                      jobDetails={searchResult}
                      organization={{
                        logo: searchResult.logo ? searchResult.logo[0] : "",
                        name: searchResult.companyName[0],
                      }}
                    />
                  ))}
          </div>

          <div style={{ display: "grid", gap: "10px", justifyContent:"center", marginTop: "10px" }}>
            <Stack spacing={2}>
              <Pagination count={totalPages} page={pageNum} variant="outlined" shape="rounded" 
                onChange={(e,page) => hanglePageChange(page)}/>
            </Stack>
          </div>

          <SearchFilter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            facetResults={facetResults}
            setFacetResults={setFacetResults}
            setSearchResults={setSearchResults}
            searchTerm={searchTerm}
            setNumFound={setNumFound}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </>
      ) : (
        <Typography variant="h5" className={classes.resultsTitle}>
              No Jobs Found ! Please try a different query
            </Typography>
      )}
    </>
  );
};

export default SearchView;
