import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import DatePicker from "@mui/lab/DatePicker";

import CloseIcon from "@mui/icons-material/Close";
import {
  POST_PROFILE_FILE_URL,
  PROJECT_URL,
  WEB_APP_API_SERVICE,
} from "src/utils/constants";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { CurrentNotificationContext } from "src/context/notification-context";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Project from "src/derivedComponents/Project";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getCandidateProfileAction } from "../state/reducer";
import { Schema } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));

const ProjectEditDialog = ({
  id,
  open,
  setOpen,
  user,
  refetch,
  addFlag,
  info,
}) => {
  console.log(user, "USSSERRRR");
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentNotification = useContext(CurrentNotificationContext);
  const FILE_SIZE = 5000 * 1024;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteProject = () => {
    console.log("Trying to delete project");
    axios
      .delete(
        // DELETE_PROFILE_PROJECT_URL
        //POST_PROFILE_FILE_URL + info.id + "/?userCode=" + user.user.get.userCode
        ` ${PROJECT_URL}/${id}?userCode=${user.get.userCode}`
      )
      .then(function (response) {
        console.log(response);
        refetch();
        setOpen(false);
        // window.location.reload();
        refetch();
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = yup.object({
    title: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(12, "Must be less than 12 characters")
      .required("Title is required"),
    summary: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(1000, "Must be less than 1000 characters")
      .required("Summary is required"),
    startDate: yup
      .date()
      .required("Start date is required")
      .max(new Date(), "Future date not allowed"),
    endDate: yup
      .date()
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(startDate, "End date cant be before start date")
      )
      .required("End date is required"),
  });

  let initialValues;
  let showFile = false;
  if (addFlag) {
    initialValues = {
      title: "",
      summary: "",
      startDate: "",
      endDate: "",
      client: "",
    };
  } else {
    initialValues = {
      name: info.projectName,
      title: info.title,
      summary: info.summary,
      startDate: info.startDate,
      endDate: info.endDate,
      client: info.client,
    };
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = {
        title: values.title,
        summary: values.summary,
        startDate: values.startDate,
        endDate: values.endDate,
        client: values.client,
      };
      if (addFlag) {
        axios
          .post(`${PROJECT_URL}?userCode=${user.get.userCode}`, formData)
          .then((res) => {
            // currentNotification.addContent({
            //   title: "Success",
            //   description: "The file has been Uploaded successfully",
            // });
            console.log(res.data);
            // resetForm();
            refetch();
            setOpen(false);
          })
          .catch((err) => {
            console.log(err.response.data);
            currentNotification.addContent({
              title: "Failed",
              description: err.response.data,
            });
          });
      } else {
        console.log("trying to update data!!");
        axios
          .put(`${PROJECT_URL}/?userCode=${user.get.userCode}`, {
            ...formData,
            id: id,
          })
          .then((res) => {
            // currentNotification.addContent({
            //   title: "Success",
            //   description: "The file has been Uploaded successfully",
            // });
            console.log(res.data);
            // alert("posted")
            // resetForm();
            refetch();
            setOpen(false);
          })
          .catch((err) => {
            console.log(err.response.data);
            currentNotification.addContent({
              title: "Failed",
              description: err.response.data,
            });
          });
      }
    },
  });
  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        {user.get.userType === "individual_candidate" ? (
          <Typography variant="h6">
            {addFlag ? "Upload Project" : "Edit Project"}
          </Typography>
        ) : (
          <Typography></Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                disabled={!(user.get.userType === "individual_candidate")}
                id="title"
                name="title"
                label="Title*"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <TextField
                disabled={!(user.get.userType === "individual_candidate")}
                id="summary"
                label="Summary*"
                name="summary"
                value={formik.values.summary}
                onChange={formik.handleChange}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={formik.touched.summary && formik.errors.summary}
                variant="outlined"
                className={classes.field}
                multiline
                rows={8}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                clearable
                disabled={!(user.get.userType === "individual_candidate")}
                inputVariant="outlined"
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                margin="normal"
                id="startDate"
                name="startDate"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Start Date*"
                    className={classes.field}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                )}
                // minDate={formik.values.startDate}
                value={formik.values.startDate}
                onChange={(date) => {
                  console.log(date);
                  if (date === null) {
                    formik.setFieldValue("startDate", "");
                    return;
                  }
                  console.log(formik.values);
                  formik.setFieldValue("startDate", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                clearable
                disabled={!formik.values.startDate || !(user.get.userType === "individual_candidate")}
                inputVariant="outlined"
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                margin="normal"
                id="endDate"
                name="endDate"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="End Date*"
                    className={classes.field}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                )}
                value={formik.values.endDate}
                onChange={(date) => {
                  if (date === null) {
                    formik.setFieldValue("endDate", "");
                    return;
                  }
                  formik.setFieldValue("endDate", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.field}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={!(user.get.userType === "individual_candidate")}
                id="client"
                name="client"
                label="Client"
                value={formik.values.client}
                onChange={formik.handleChange}
                error={formik.touched.client && Boolean(formik.errors.client)}
                helperText={formik.touched.client && formik.errors.client}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            {showFile && (
              <Grid item xs={12}>
                <TextField
                  id="fileName"
                  value={formik.values.fileName}
                  onChange={formik.handleChange}
                  error={formik.touched.file && Boolean(formik.errors.file)}
                  helperText={formik.touched.file && formik.errors.file}
                  variant="outlined"
                  className={classes.field}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "140px" }}
                        component="label"
                      >
                        Select File
                        <input
                          type="file"
                          hidden
                          style={{ display: "none" }}
                          accept="
                                            image/jpg,
                                            image/jpeg,
                                            image/png,
                                            application/pdf,
                                            application/ppt,
                                            "
                          onChange={(event) => {
                            formik.setFieldValue(
                              "file",
                              event.currentTarget.files[0]
                            );
                            if (event.currentTarget.files[0] != null) {
                              formik.setFieldValue(
                                "fileName",
                                event.currentTarget.files[0].name
                              );
                            }
                          }}
                        />
                        <AttachFileSharpIcon />
                      </Button>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {(user.get.userType === "individual_candidate") ? (
          <DialogActions>
            {info === null || addFlag == true ? null : (
              <Button
                style={{ color: "#FF4A4A" }}
                onClick={() => deleteProject()}
              >
                Delete
              </Button>
            )}
            <Button color="primary" type="submit">
              {addFlag ? "Upload" : "Save"}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions></DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default ProjectEditDialog;
