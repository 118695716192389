import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import SearchView from "./SearchView";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "calc(100vh - 120px)",
  },
  container: {
    minHeight: "calc(100vh - 120px)",
  },
}));

const CandidateSearch = () => {
  const classes = useStyles();
  const [view, setView] = useState("search");

  useEffect(() => {
    // code to run on component mount
  }, []);

  return (
    <Page className={classes.root} title="Search Gigs">
      <SearchView />
    </Page>
  );
};

export default CandidateSearch;
