import axios from "axios";
import JobDetailEntity, { BookMarkedJob, JobDetailData } from "./models";
import {
  CREATE_JOB_BOOKMARK,
  GET_CANDIDATE_JOB_APPLICATIONS,
  GET_JOBID_URL,
  GET_JOB_BOOKMARKS,
  JOB_APPLICATION_URL,
  REMOVE_JOB_BOOKMARK,
  GET_CANDIDATE_JOBID_URL,
  GET_SIMILAR_GIGS,
} from "src/utils/constants";
import { SimilarGigs } from "src/views/candidate/CandidateJobView/state/models";

const jobDetail = async (jobID: number, user_code: string) => {
  const response = await axios({
    method: "GET",
    url: GET_CANDIDATE_JOBID_URL + "?jobId=" + jobID + "&userCode=" + user_code,
  });
  return response.data;
};

const similarJobs = async (jobID: number) => {
  const response = await axios({
    method: "GET",
    url: GET_SIMILAR_GIGS + "?jobId=" + jobID,
  });

  return response.data;
};

// const appliedJobs = async (userCode: string) => {
//   const response = await axios({
//     method: "GET",
//     url: GET_CANDIDATE_JOB_APPLICATIONS + "?user_code=" + userCode,
//   });

//   return response.data;
// };

// const bookmarkedJobs = async (userCode: string) => {
//   const resposne = await axios({
//     method: "GET",
//     url: GET_JOB_BOOKMARKS + "?user_code=" + userCode,
//   });
//   return resposne.data;
// };

// const foundJobId = (jobs: JobDetailData[], jobID: number): boolean => {
//   if (!jobs || jobs.length === 0) {
//     return false;
//   }
//   return jobs.map((item: JobDetailData) => item.id).indexOf(+jobID) !== -1;
// };

const getJobDetails = async (
  userCode: string,
  jobID: number
): Promise<JobDetailEntity> => {
  const jobDetailResponse = await Promise.all([
    jobDetail(jobID, userCode),
    similarJobs(jobID),
    // appliedJobs(userCode),
    // bookmarkedJobs(userCode),
  ]);

  let jobDetails: JobDetailEntity = {
    jobInfo: jobDetailResponse[0],
    moreLikeThisJobs: jobDetailResponse[1]?.moreLikeThisJobs,
    // applied: foundJobId(jobDetailResponse[2], jobID),
    // bookmarked: foundJobId(jobDetailResponse[3], jobID),
  };
  /*
    let jobDetails:JobDetailEntity={
        jobInfo:{
            jobPost:{
                id:123,
                title:"Test",
                description:"Test",
                category:"Test",
                expertise:"Test",
                proficiencyLevel:"Test",
                skills:"Test",
                preferredSkills:"Test",
                projectType:"Test",
                timeCommitment:"Test",
                duration:10,
                timeUnit:"Test",
                budget:"Test",
                createdDate:null,
                updatedDate:null,
                qualifications:"Test",
                certifications:"Test",
                modifiedBy:"Test",
                preferredQualifications:"Test",
                salary:10000,
                experience:"Test",
                status:"Test",
                workLocationPreference:"Test",
                jobPostSkills:[{
                    jobPostSkillId:10,
                    skillName:"Test",
                    isAdditionalSkill:true
                }]
                },
                employer:{
                    employerId:10,
                    userCode:"Test"
                },
                organization:{
                    name:"Test",
                    organizationId:10,
                    logo:"test",
                    description:"test",
                    website:"test"
                }
            },
            applied:true,
            bookmarked:true,
        }
        */

  return Promise.resolve(jobDetails);
  //return jobDetails;
};

export const similarGigs = async (jobID: number) => {
  const response = await axios.get(GET_SIMILAR_GIGS + "?jobId=" + jobID);

  return response.data;
};

export const applyToJob = async (user_code: string, jobID: number) => {
  const response = await axios.post(
    JOB_APPLICATION_URL +
      "?userCode=" +
      user_code +
      "&jobId=" +
      jobID +
      "&status=REVIEW"
  );

  return response.data;
};

export const bookmarkToJob = async (user_code: string, jobID: number) => {
  const response = await axios.post<BookMarkedJob>(
    CREATE_JOB_BOOKMARK + "?user_code=" + user_code + "&job_id=" + jobID
  );
  return response.data;
};

export const removeBookmark = async (user_code: string, jobID: number) => {
  const response = await axios.post<boolean>(
    REMOVE_JOB_BOOKMARK + "?user_code=" + user_code + "&job_id=" + jobID
  );

  return response.data;
};

export default getJobDetails;
