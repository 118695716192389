import React, { FC, ReactElement, ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import { motion } from "framer-motion";
import { useField } from "formik";
import { Checkbox, FormHelperText } from "@mui/material";
import Typography from "src/designsystem/Core/Typography";
import InputUnstyled from '@mui/base/InputUnstyled';


const FormContainer = styled.div`
  margin-bottom: 10px;
`;
const GvFormLabel = styled(Typography)<DarkProps>`
  color: ${(props) => (props.dark ? colors.white : colors.text)};
  font-size: 1rem;
  margin-bottom: 5px;
`;

interface DarkProps {
  dark?: boolean;
}

const GvHelperText = styled(Typography)<DarkProps>`
  font-size: 0.9rem;
  // color: ${(props) => (props.dark ? "#FFE2D1" : colors.e60)};
  color: #f3f808;
  font-size: 1rem;
  font-weight: 600;
  margin: 0px 0.5px;
  display: inline;
  border-radius: 8px;
  padding: 3px;
`;
const GvInput = styled("input")`
  width: 100%;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  color: ${colors.text};
  background: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 6px;
  padding: 12px 12px;
  transition: all 150ms ease;

  &:hover {
    border-color: ${colors.p60};
  }

  &:focus {
    outline: 1px solid ${colors.p40};
    border-color: ${colors.p40};
    // outline: 1px solid ${colors.e50};
    // border-color: ${colors.e50};
  }
`;

interface TextfieldProps {
  name: string;
  label: string;
  type?: string;
  dark?: boolean;
}

const Textfield: FC<TextfieldProps> = ({ name, label, dark, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { value, touched, error } = meta;

  return (
    <FormContainer>
      <GvFormLabel dark={dark}>{label}</GvFormLabel>
      <InputUnstyled components={{ Input: GvInput }} {...field} {...props} />

      {Boolean(touched && error) && (
        <GvHelperText dark={dark}>{error}</GvHelperText>
      )}
    </FormContainer>
  );
};

export default Textfield;
