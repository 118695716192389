import styled from "@emotion/styled";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { FC } from "react";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import ContractDetailModel, { UserProfile } from "src/views/recruiter/RecruiterContract/state/models";
import { breakpoints, colors } from "../../designsystem/theme";
const GvContractCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 330px;
  }
`;
const GvGigLeft = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 20px;
  }
`;
const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;
const GvGigRight = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  border-top: 1px solid ${colors.nv80};
  padding: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    border-top: 0px;
    border-left: 1px solid ${colors.nv80};
    padding: 20px;
  }
`;

const CSTopView = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 1fr 1fr;
  background-color: ${colors.white};
  border-right: none;
 
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr ;
  }
`;

const CSView = styled.div`
background-color: ${colors.white};
border: 1px solid ${colors.nv80};
font-family: Inter, sans-serif;
border-radius: 8px;
padding: 10px;
@media (min-width: ${breakpoints["lg"]}) {
  grid-template-columns: 1fr 1fr ;
  padding: 10px;
}
`;
const Header = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 0.8rem;
  color: gray;
`;

const SubTitle = styled(Typography)`
  font-weight: 650;
  font-size: 0.8rem;
  color: ${colors.text};
`;


const Descriptive = styled(Typography)`
  font-weight: 650;
  font-size: 0.9rem;
  color: gray;
`;

interface ContractCardProps {
  contractDetail: ContractDetailModel;
  userDetail:UserProfile
  user_code: string;
  dispatch: any;
}

const ContractTopCard: FC<ContractCardProps> = ({
  contractDetail,
  userDetail,
  user_code,
  dispatch,
}) => {
  // const [toggle, setToggle] = useState(false)
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);

  return (
    <GvContractCard>
      <GvGigLeft>
        <GvGigDetails>
        <Header variant="label">
        <div style={{ marginTop: "10px", alignItems: "center", gap: "5px" }}>
            <Typography variant={matches ? "h3" : "h4"}>
              {contractDetail.title}
            </Typography>
            <div style={{ height: "5px" }} />
           
            <div
              dangerouslySetInnerHTML={{
                __html: contractDetail.description,
              }}
            ></div>

            <div style={{ height: "15px" }} />
          </div>
          </Header>
        </GvGigDetails>
           
        <CSTopView>
        <CSView>
        <Title variant="body">
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
     
              <Avatar src={userDetail.profilePicUrl} alt="Certificate" size="60px" />
              <div>
                <Title variant="body">{`${userDetail.firstName} ${userDetail.lastName}`}</Title>
                <div style={{ height: "3px" }} />
                <Descriptive variant="body2">{"TestTitle"}</Descriptive>
              </div>
            </div>
        </Title>
        </CSView>
        </CSTopView>
    
      </GvGigLeft>
      <GvGigRight>
        <div>
        <Header variant="label">
            Start Date
         
         
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarDot
                theme="outline"
                size="20"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
               <SubTitle variant="label"> 
              {contractDetail.startDate ? moment(contractDetail.startDate).format("DD/MM/YYYY") : moment(new Date()).format("DD/MM/YYYY") }
              </SubTitle>
            </div>
          </Header>
        </div>
        <div>
        <Header variant="label">
            End Date
      
      
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarDot
                theme="outline"
                size="20"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
               <SubTitle variant="label">  
                {contractDetail.endDate ? moment(contractDetail.endDate).format("DD/MM/YYYY") : "Specify the data" }
                </SubTitle>
            </div>
          </Header>
        </div>
        <div>
        <Header variant="label">
            Total Expenditure
  
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <AddSubtract
                theme="outline"
                size="25"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
               <SubTitle variant="label">  
              {"₹ " + 6000}
              </SubTitle>
            </div>
          </Header>
        </div>

        <div style={{ display: "flex", gap: "15px" }}>
        <Button  style={{textTransform: 'none'}} size="medium" variant="contained" onClick={() => {}}>
          
            Go to linked Job Post
          </Button>
          
          <Button  style={{textTransform: 'none'}} size="medium" variant="outlined" onClick={() => {}}>
            Edit
          </Button>
        </div>
      </GvGigRight>
    </GvContractCard>
  );
};

export default ContractTopCard;
