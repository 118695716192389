import { useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import { USER_SERVICE } from "src/utils/constants";
import TokenService from "src/services/TokenService";
import {getCookieValueByKey} from "src/utils/cookieUtils";

const allowedPaths = ["/login", "/reset-password", "/register", "/404"];

const paramsFromCookies = getCookieValueByKey("gigvistas-user");

const AuthHandler = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getCookiesAccessToken();

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        if (error.response.status === 401 && !originalConfig.url.includes('/authToken')) {
          return axios
            .post(`${USER_SERVICE}/authToken`)
            .then((res) => {
             TokenService.setCookiesAccessToken(res.data);
              //dispatch(setAccessToken(res.data));
              error.config.headers["Authorization"] = `Bearer ${res}`;
              return axios.request(error.config);
            })
            .catch(function (error) {
              if (error.response) {
                if (
                  error.response.status === 500 &&
                  !allowedPaths.includes(window.location.pathname)
                ) {
                  //dispatch(setAccessToken(null));
                  if(paramsFromCookies){
                    navigate("/register");  
                  }
                  else {
                    navigate("/login");
                  }
                }
              }
            });
        }
      }

      return Promise.reject(error);
    }
  );

return children;
};

export default AuthHandler;
