import { Card,CardContent, Grid} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import DottedHorizontalMenu from "../../../components/dottedHorizontalMenu";

import EventIcon from '@mui/icons-material/Event';
import { RECRUITER_SEARCH_RESULT_DOTTED_MENU_OPTION } from "../../../utils/constants";
import {colors, breakpoints} from "src/designsystem/theme";
import Typography from 'src/designsystem/Core/Typography';
import styled from '@emotion/styled';
import makeStyles from "@mui/styles/makeStyles";
import { display } from "@mui/system";
import moment from "moment";
import LTChip from "src/designsystem/Derivative/LTChip";

const JobTitle = styled(Typography)`
    display: block;
    padding-bottom: 5px;
    padding-right: 20px;
    color: ${colors.p20};
`

const CardContainer = styled.div`
    display: grid;
    margin: 0px;
    grid-template-columns: 1fr;
    grid-template-columns: 75% 25%;
`

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        cursor: "pointer",
    }
}))

const AppStat = ({count, text}) => {
    const Metric = styled.div`
        display: block;
        padding: 5px;
        padding-bottom: 0;
        color: ${colors.s40};
        font-weight: 600;
        font-size: 1.4rem;
        text-align: center;
    `
    const MetricText = styled.div`
        display: block;
        padding: 5px;
        padding-top: 1px;
        color: ${colors.p10};
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;
    `

    const MetricContainer = styled.div`
        display: grid;
        justify-content: center;
    `

    return (
        <MetricContainer>
            <Metric>{count}</Metric>
            <MetricText>{text}</MetricText>
        </MetricContainer>
    );
}

const GeneralInfo = ({ jobPost, getCandidateProfile, ...rest }) => {
  const navigate = useNavigate();
  const workLocationPreference = jobPost.workLocationPreference;

  const handleMenuSelection = () => {};



  const applicants = jobPost.applicantUserCodes || [];

  const recCandidates = jobPost.recommendedCandidates || [];

  const metricCount = applicants.length === 0 ? recCandidates.length : applicants.length;
  const metricText = applicants.length === 0 ? "matches" : "applicants";

  const handleClick = () => {
    navigate("/r/jobDetails/" + jobPost.id);
  };

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent onClick={handleClick}>
        <CardContainer>
            <div>
                <JobTitle variant="h5">{jobPost.title}</JobTitle>
                <div style={{display:"inline-block",marginRight:"10px", marginBottom:"5px"}}>
                    <div style={{display:"inline-block"}}>
                        <EventIcon style={{verticalAlign:"bottom"}}/>
                    </div> 
                    <div style={{display:"inline-block"}}>
                        <p style={{fontSize:"0.9em"}}>
                            {moment(jobPost.updatedDate).format('MMMM Do YYYY')}
                        </p>
                    </div>
                </div>
                <div style={{display:"inline-block"}}>
                    <div style={{display:"inline-block"}}>
                        <LTChip style = {{display:"inline-block", float:"right"}}
                            timePref={jobPost.timeCommitment}
                            location={jobPost.projectType}
                            fixedWidth={true}
                            />
                    </div>
                </div>
            </div>
            <div> <AppStat count={metricCount} text={metricText}></AppStat></div>
        </CardContainer>
      </CardContent>
    </Card>
  );
};

const JobSummaryDashboardCard = ({ jobPost, getCandidateProfile, ...rest }) => {
  return (
    <GeneralInfo jobPost={jobPost} getCandidateProfile={getCandidateProfile} />
  );
};

export default JobSummaryDashboardCard;
