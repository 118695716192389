import React, { FC, ReactElement, ReactNode } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import { motion } from "framer-motion";
import { useField } from "formik";
import { Checkbox, FormHelperText } from "@mui/material";
import Typography from "src/designsystem/Core/Typography";

const FormContainer = styled.div``;
const GvFormLabel = styled(Typography)<DarkProps>`
  color: ${(props) => (props.dark ? colors.white : colors.text)};
  font-size: 1.1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  align-items: center;
`;

interface DarkProps {
  dark?: boolean;
}

const GvHelperText = styled(Typography)<DarkProps>`
  font-size: 1rem;
  font-weight: 600;
  // color: ${(props) => (props.dark ? "#FFE2D1" : colors.e60)};
  color: #f3f808;
  margin: 2px -1px;
`;
const BpIcon = styled(motion.div)<DarkProps>`
  border-radius: 3px;
  width: 18px;
  height: 18px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2),
    inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
  Mui-focusVisible & {
    outline: "2px auto rgba(19,124,189,.6)";
    outline-offset: 2;
  }
  input:hover ~ & {
    background-color: #ebf1f5;
  }
  input:disabled ~ & {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
  }
`;

const BpCheckedIcon = styled(BpIcon)<DarkProps>`
  background-color: ${(props) => (props.dark ? colors.p30 : colors.p40)};
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0)
  );
  &:before {
    display: block;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
    content: "";
  }
  input:hover ~ & {
    background-color: ${(props) => (props.dark ? colors.p20 : colors.p30)};
  }
`;

interface CheckboxProps {
  name: string;
  label: string | ReactNode;
  dark?: boolean;
}

const FormCheckbox: FC<CheckboxProps> = ({ name, label, dark }) => {
  const [field, meta, helpers] = useField(name);
  const { value, touched, error } = meta;
  const { setValue } = helpers;

  return (
    <FormContainer>
      <CheckboxContainer>
        <Checkbox
          checked={value}
          {...field}
          style={{ padding: "0px 0px 0px 0.5px" }}
          sx={{
            "&:hover": { bgcolor: "transparent" },
          }}
          disableRipple
          checkedIcon={
            <BpCheckedIcon
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              dark={dark}
            />
          }
          icon={
            <BpIcon
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              dark={dark}
            />
          }
        />
        <GvFormLabel dark={dark}>{label}</GvFormLabel>
      </CheckboxContainer>

      {Boolean(touched && error) && (
        <GvHelperText dark={dark}>{error}</GvHelperText>
      )}
    </FormContainer>
  );
};

export default FormCheckbox;
