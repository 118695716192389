import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  CardHeader,
  Divider,
  CardContent,
  Slider,
  Chip,
  Radio,
  FormLabel,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Switch,
  InputLabel,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import VistasSwitch from "src/components/custom/VistasSwitch";

import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ExpertiseForm from "src/components/steps/ExpertiseForm";
import {
  POST_CANDIDATE_URL,
  PUT_PROFILE_HIGHLIGHTS_URL,
  JOB_CATEGORY_URL,
  JOB_CATEGORY_LIST,
  JOB_CATEGORY_SKILL_LIST,
  JOB_CATEGORY_EXPERTISE_LIST,
  ProjectType,
  LocationPreference,
  TimingPreference,
  CurrencyFormat,
} from "src/utils/constants";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { truncate, values } from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  info: {
    width: "calc(100% - 50px)",
    margin: " 0 25px",
  },
  availPref: {
    width: "calc(100% - 50px)",
    margin: "10px 25px",
  },
  fieldLevel: {
    width: "calc(100% - 50px)",
    margin: "10px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
  radioOpt: {
    margin: "15px 25px",
    paddingRight: 0,
  },
  radioOptlast: {
    margin: "0px 25px",
    paddingRight: 0,
  },
  distanceIp: {
    marginLeft: "18px",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("lg")]: {
      margin: "8px",
    },
  },
  // sectionHeader: {

  //     marginBottom: "10px",
  //     [theme.breakpoints.down("xs")]: {
  //         fontSize: ".8rem",

  //       }
  // }
}));

const ProfileEditDialog = ({ open, setOpen, info, user, refetch }) => {
  // console.log(user, "user***");
  const category = JOB_CATEGORY_LIST;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openPinCode, setOpenCode] = useState(true);
  const [address, setAddress] = React.useState("");
  const validationSchema = Yup.object({
    firstName: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(45, "Must be less than 45 characters")
      .required("First name is required"),
    lastName: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(45, "Must be less than 45 characters")
      .required("Last Name is required"),
    summary: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(1000, "Must be less than 1000 characters")
      .required("Write something about yourself"),
    // email: Yup.string().email("Invalid email").required("Required"),
    title: Yup.string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(120, "Must be less than 120 characters")
      .required("Title is required"),
    expectedFees: Yup.number("Must be a number")
      .typeError("Invalid Rate")
      .min(1, "Invalid Fee")
      .required("Rate is required")
      .nullable(),
    // pinCode: Yup.number("Must be a number")
    //   .typeError("Must be a number")
    //   .min(100000, "Invalid Pincode")
    //   .max(999999, "Invalid Pincode")
    //   .required("Pincode is required")
    //   .nullable(),
    top5Skills: Yup.array()
      .min(1, "Atleast 1 Top Skill is required")
      .required("Top Skill is required"),
    totalExperience: Yup.number("Must be numeric")
      .typeError("Must be numeric")
      .positive("Must be positive number")
      .max(255)
      .required("Overall Years of Experience is required"),
    category: Yup.string().max(255).required("Category is required"),
    subCategory: Yup.string().max(255).required("Expertise is required"),
    expertiseLevel: Yup.string()
      .max(255)
      .required("Expertise Level is required"),
    gigProjectType: Yup.string().required("Location type is required"),
    distanceFromCurrentLocation: Yup.number().when("distancePrefopt", {
      is: (distancePrefopt) => {
        console.log("inside distance val", distancePrefopt);
        return distancePrefopt === "certainDistance";
      },
      then: Yup.number()
        .required("A value is required")
        .min(10, "Must be in range 10 -1000")
        .max(1000, "Must be in range 10 -1000")
        .nullable(true),
      otherwise: Yup.number().nullable(true),
    }),
    distancePrefopt: Yup.string().when("gigProjectType", {
      is: (gigProjectType) => {
        console.log("inside distance pref val", gigProjectType);
        return gigProjectType === "Onsite" || gigProjectType === "Hybrid";
      },
      then: Yup.string().required("Distance Preference is required"),
      otherwise: Yup.string().nullable(true),
    }),
    currencyCode: Yup.string().required(),
  });
  const [topSkills, setTopSkills] = useState([]);
  const [additionalSkills, setAdditionalSkills] = useState([]);
  // useEffect(() => {
  //     info.candidateSkills.map((skill, i) => {
  //         if (skill.isAdditionalSkill === true) {
  //             setAdditionalSkills(prevSkills => [...prevSkills, skill]);

  //         } else {
  //             setTopSkills(prevSkills => [...prevSkills, skill]);

  //         }
  //     })
  // }, [info])

  function handleLocationChange(event) {
    setAddress(event);
  }

  const onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };

  const formik = useFormik({
    initialValues: {
      firstName: info.userProfile.firstName,
      lastName: info.userProfile.lastName,
      title: info.title ?? "",
      category: info.jobCategory,
      subCategory: info.jobSubCategory,
      expectedFees: info.expectedFees,
      top5Skills: topSkills,
      otherSkills: additionalSkills,
      totalExperience: info.totalExperience,
      summary: info.summary ?? "",
      canWorkFullTimeGigs: info.canWorkFullTimeGigs ?? true,
      gigProjectType: info.gigProjectType ?? "Remote",
      expertiseLevel: info.expertiseLevel,
      categoryExpertiseList: JOB_CATEGORY_EXPERTISE_LIST,
      categorySkillsList: JOB_CATEGORY_SKILL_LIST,
      pinCode: info.pinCode,
      availabilityPref: TimingPreference.map((p) => p.value).includes(
        info.availabilityPref
      )
        ? info.availabilityPref
        : "Full-Time",
      distanceFromCurrentLocation: info.distancePref ?? 10,
      distancePrefopt: !!info.distancePref
        ? "certainDistance"
        : "Doesn't matter",
    currencyCode: info.currencyCode ?? "INR",
     candidateLocationDetail:  info.candidateLocation? Object.values(info.candidateLocation).filter(function(candidateLocation,index,rep){return (index !== 0);}): []
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, "submit values");
      let candidateSkills = [];
      let candidateLocationsList = [];
     
      values.candidateLocationDetail.forEach((jobFormattedLocation, i) => {
        geocodeByAddress(jobFormattedLocation)
          .then((results) => candidateLocationsList.push(results[0]))
          .catch((error) => console.error(error));
      });
      
      values.top5Skills.map((skill, i) => {
        let tempSkill = {
          skillName: skill,
          // rating: skill.skillLevel,
          isAdditionalSkill: false,
        };
        candidateSkills.push(tempSkill);
      });
      values.otherSkills.map((skill, i) => {
        let tempSkill = {
          skillName: skill,
          // rating: null,
          isAdditionalSkill: true,
        };
        candidateSkills.push(tempSkill);
      });

      if (values.distancePrefopt === "Doesn't matter") {
        values.distanceFromCurrentLocation = null;
      }
      
      setTimeout(() => { 
      const valuesWebService = {
        userProfile: {
          firstName: values.firstName,
          lastName: values.lastName,
          userCode: user.get.userCode,
        },
        title: values.title,
        expectedFees: values.expectedFees,
        candidateSkills: candidateSkills,
        totalExperience: values.totalExperience,
        jobSubCategory: values.subCategory,
        jobCategory: values.category,
        summary: values.summary,
        expertiseLevel: values.expertiseLevel,
        canWorkFullTimeGigs: values.canWorkFullTimeGigs,
        gigProjectType: values.gigProjectType,
        // pinCode: values.pinCode,
        userCode: user.get.userCode,
        availabilityPref: values.availabilityPref,
        distancePref: parseInt(values.distanceFromCurrentLocation),
        currencyCode: values.currencyCode,
        candidateLocationDetail:candidateLocationsList[0],
      };

      axios
        .put(PUT_PROFILE_HIGHLIGHTS_URL, valuesWebService, {
          params: { userCode: user.get.userCode },
        })
        .then(function (response) {
          console.log(response);
          refetch();
          setOpen(false);
        })
        .catch((err) => console.log(err));
      }, 1000);
    },
  });

  useEffect(() => {
    getTopSkills(info);
    if (info.gigProjectType === "Remote") {
      setOpenCode(true);
    }
  }, []);

  // useEffect(() => {
  //   getSubCategory(info.jobCategory);
  // }, []);

  useEffect(() => {
    getAdditionalSkills(info);
  }, []);

  const getAdditionalSkills = (info) => {
    var filterAddtionalSkill = info.candidateSkills.filter((skill, i) => {
      return skill.isAdditionalSkill === true;
    });
    var additionalSkill = filterAddtionalSkill.map((skill, i) => {
      return skill.skillName;
    });
    formik.values.otherSkills = additionalSkill;
  };

  const getTopSkills = (info) => {
    var filterTopSkill = info.candidateSkills.filter((skill, i) => {
      return skill.isAdditionalSkill === false;
    });

    var topSkill = filterTopSkill.map((skill, i) => {
      if (skill.isAdditionalSkill === false) {
        return skill.skillName;
      }
    });
    formik.values.top5Skills = topSkill;
  };

  const handleCategoryChange = (event, values, setValues) => {
    axios({
      method: "GET",
      url: JOB_CATEGORY_URL + event.target.value,
    })
      .then((response) => {
        console.log(response);
        formik.values.categoryExpertiseList = response.data;
        formik.values.category = event.target.value;
        setValues(values);
        axios({
          method: "GET",
          url:
            JOB_CATEGORY_URL +
            values.category +
            "%7C" +
            values.categoryExpertiseList[0],
        })
          .then((response) => {
            console.log(response);
            formik.values.categorySkillsList = response.data;
            formik.values.subCategory = values.categoryExpertiseList[0];
            setValues(values);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  function addNewSkill(values, setValues) {
    if (values.top5Skills.length < 5) {
      values.top5Skills.push({ skillName: "", skillLevel: 4 });
      setValues(values);
    }
  }

  function removeSkill(i, values, setValues) {
    console.log("i " + i);
    if (values.top5Skills.length > 1) {
      values.top5Skills.splice(i, 1);
      setValues(values);
    }
  }

  function handleProjectTypeChange(e, values, setValues) {
    values.gigProjectType = e.target.value;
    setValues(values);
    console.log("project type prefs" + e.target.value);
    console.log(values);
    if (
      formik.values.gigProjectType === "Onsite" ||
      formik.values.gigProjectType === "Blended"
    ) {
      setOpenCode(true);
    } else {
      setOpenCode(false);
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCategoryExpertiseChange = (event, values, setValues) => {
    console.log(event.target.value);
    console.log("inside handleCategoryExpertiseChange " + event.target.value);
    values.subCategory = event.target.value;
    setValues(values);
  };

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
  };

  function valuetext(value) {
    return `${value}`;
  }

  if (
    !formik.values.categoryExpertiseList.includes(formik.values.subCategory)
  ) {
    formik.values.categoryExpertiseList.push(formik.values.subCategory);
  }

  if (info.title == null) {
    formik.setFieldValue("title", "");
  }

  if (info.summary == null) {
    formik.setFieldValue("summary", "");
  }



  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">Edit Profile</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="firstName"
                label="First Name*"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="lastName"
                label="Last Name*"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="title"
                label="Title*"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            {/* <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                className={classes.field}
              />
            </Grid> */}
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="summary"
                label="About yourself*"
                multiline={true}
                minRows={3}
                maxRows={3}
                value={formik.values.summary}
                onChange={formik.handleChange}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={formik.touched.summary && formik.errors.summary}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                className={classes.field}
                id="category"
                fullWidth
                label="Category*"
                name="category"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleCategoryChange(e, formik.values, formik.setValues);
                }}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.category}
                size="medium"
                error={Boolean(
                  formik.touched.category && formik.errors.category
                )}
                helperText={formik.touched.category && formik.errors.category}
              >
                {JOB_CATEGORY_LIST.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                className={classes.field}
                id="subCategory"
                fullWidth
                label="Expertise*"
                name="subCategory"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleCategoryExpertiseChange(
                    e,
                    formik.values,
                    formik.setValues
                  );
                }}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.subCategory}
                size="medium"
                error={Boolean(
                  formik.touched.subCategory && formik.errors.subCategory
                )}
                helperText={
                  formik.touched.subCategory && formik.errors.subCategory
                }
              >
                {formik.values.categoryExpertiseList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="expectedFees"
                label="Fees Per Hour*"
                value={formik.values.expectedFees}
                onChange={formik.handleChange}
                error={
                  formik.touched.expectedFees &&
                  Boolean(formik.errors.expectedFees)
                }
                helperText={
                  formik.touched.expectedFees && formik.errors.expectedFees
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                label="Currency*"
                className={classes.field}
                fullwidth
                variant="outlined"
                name="currencyCode"
                size="medium"
                onChange={(e) => {
                  e.preventDefault();
                  formik.handleChange(e);
                }}
                select
                SelectProps={{ native: true }}
                value={formik.values.currencyCode}
              >
                {CurrencyFormat.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="totalExperience"
                label="Total Experience*"
                value={formik.values.totalExperience}
                onChange={formik.handleChange}
                error={
                  formik.touched.totalExperience &&
                  Boolean(formik.errors.totalExperience)
                }
                helperText={
                  formik.touched.totalExperience &&
                  formik.errors.totalExperience
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={5}
              xs={12}
              lg={5}
              className={classes.radioOptlast}
            >
              <FormLabel component="legend">
                Proficiency Level{" "}
                <Tooltip
                  title={
                    <ul>
                      <li>
                        Basic: Understanding of basic concepts - relatively new
                        to the field
                      </li>
                      <li>
                        Proficient: Good hands on understanding: can work
                        independently in the field
                      </li>
                      <li>
                        Expert: Significant experience; can also guide/train
                        others
                      </li>
                    </ul>
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      handleAnchorOpen(e, <></>);
                    }}
                    size="large"
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <RadioGroup
                row
                id="categoryExpertiseLevel"
                aria-label="Proficiency Level"
                name="expertiseLevel"
                value={formik.values.expertiseLevel}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="Basic"
                  control={<Radio />}
                  label="Basic"
                />
                <FormControlLabel
                  value="Proficient"
                  control={<Radio />}
                  label="Proficient"
                />
                <FormControlLabel
                  value="Expert"
                  control={<Radio />}
                  label="Expert"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <CardHeader subheader="SKILLS" />
          <Divider />
          <Grid container>
            <Grid item sm={12} md={12} xs={12} className={classes.field}>
              <Autocomplete
                multiple
                id="top5Skills"
                disableClearable
                options={formik.values.categorySkillsList.map(
                  (option) => option
                )}
                freeSolo
                name="top5Skills"
                value={formik.values.top5Skills}
                onChange={(e, val) => {
                  let top5SkillsValues = formik.values.top5Skills;
                  if (
                    e.target.localName === "li" &&
                    val.length < top5SkillsValues.length
                  ) {
                    return;
                  }
                  if (val.length > 10) {
                    val.splice(10, 1);
                  }
                  formik.setValues((oldVal) => {
                    var temp = { ...oldVal };
                    temp.top5Skills = val;
                    return temp;
                  });
                }}
                limitTags={10}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.expertise}
                    fullWidth
                    {...params}
                    variant="outlined"
                    label="Find your skill*"
                    placeholder="Choose a skill OR type a new skill and press enter to add"
                    name="skills"
                    multiline
                    rows={2}
                    size="small"
                    error={
                      formik.touched.top5Skills &&
                      Boolean(formik.errors.top5Skills)
                    }
                    helperText={
                      formik.touched.top5Skills && formik.errors.top5Skills
                    }
                  />
                )}
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12} className={classes.field}>
              <Autocomplete
                multiple
                id="otherSkills"
                options={formik.values.categorySkillsList.map(
                  (option) => option
                )}
                freeSolo
                name="otherSkills"
                value={formik.values.otherSkills}
                onChange={(e, val) => {
                  let otherSkillsValues = formik.values.otherSkills;
                  if (
                    e.target.localName === "li" &&
                    val.length < otherSkillsValues.length
                  ) {
                    return;
                  }
                  if (val.length > 10) {
                    val.splice(10, 1);
                  }

                  formik.setValues((oldVal) => {
                    var temp = { ...oldVal };
                    temp.otherSkills = val;
                    console.log("TPS:", temp);
                    return temp;
                  });
                }}
                limitTags={10}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.expertise}
                    fullWidth
                    {...params}                 
                    variant="outlined"
                    label="Additional Skills"
                    placeholder="Choose a skills OR type a new skill and press enter to add"
                    name="skills"
                    multiline
                    rows={2}
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
          <CardHeader subheader="PREFERENCES" />
          <Divider />
          <Grid container>
            <Grid item sm={6} md={6} xs={12}>
            <PlacesAutocomplete
                  value={address}           
                  onChange={(e) => {
                    handleLocationChange(e);
                  }}
                  onError={onError}
                  // searchOptions={searchOptions}
                  shouldFetchSuggestions={address.length > 3}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <Autocomplete
                        multiple
                        id="tags-filled"
                     
                        options={suggestions.map(
                          (suggestion) => suggestion.description
                        )}
                        value={formik.values.candidateLocationDetail}
                        onChange={(e, val) => {
                          let candidateLocationDetail =
                          formik.values.candidateLocationDetail;
                          if (
                            e.target.localName === "li" &&
                            val.length < candidateLocationDetail.length
                          ) {
                            return;
                          }
                          if (val.length > 1) {
                            val.splice(1, 1);
                          }
                          formik.setValues((oldVal) => {
                            var temp = { ...oldVal };
                            temp.candidateLocationDetail = val;
                            console.log("TPS:", temp);
                            return temp;
                          });
                        }}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <div {...getSuggestionItemProps(option)}>
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            </div>
                          ))
                         
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...getInputProps()}
                            className={classes.field}
                            label="Candidate Location"
                            variant="outlined"
                            
                            rows={2}
                            size="small"
                            error={Boolean(
                              formik.touched.candidateLocationDetail &&
                              formik.errors.candidateLocationDetail
                            )}
                            helperText={
                              formik.touched.candidateLocationDetail &&
                              formik.errors.candidateLocationDetail
                            }
                          />
                        )}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                className={classes.field}
                fullWidth
                label="Location Type*"
                name="gigProjectType"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleProjectTypeChange(e, formik.values, formik.setValues);
                }}
                // required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.gigProjectType}
                size="large"
              >
                {LocationPreference.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            {(formik.values.gigProjectType === "Onsite" ||
              formik.values.gigProjectType === "Hybrid") && (
              <Grid item container sm={6} md={12} xs={12} lg={12}>
                <Grid item sm={6} md={5} className={classes.radioOpt}>
                  <FormLabel component="legend">
                    Work Distance Preference*
                  </FormLabel>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="distancePrefopt"
                      value={formik.values.distancePrefopt}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="Doesn't matter"
                        control={<Radio />}
                        label="Doesn't matter"
                      />
                      <FormControlLabel
                        value="certainDistance"
                        control={<Radio />}
                        label=" Within _ kms of my location"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {formik.values.distancePrefopt === "certainDistance" &&
                  (formik.values.gigProjectType === "Onsite" ||
                    formik.values.gigProjectType === "Hybrid") && (
                    <Grid item md={6} sm={12} className={classes.distanceIp}>
                      <TextField
                        label="Distance*"
                        fullWidth
                        className={classes.field}
                        name="distanceFromCurrentLocation"
                        size="large"
                        onChange={formik.handleChange}
                        value={formik.values.distanceFromCurrentLocation}
                        InputProps={{
                          endAdornment: <InputAdornment>Kms</InputAdornment>,
                        }}
                        error={Boolean(
                          formik.errors.distanceFromCurrentLocation ||
                            !formik.values.distanceFromCurrentLocation
                        )}
                        helperText={
                          formik.errors.distanceFromCurrentLocation ||
                          !formik.values.distanceFromCurrentLocation
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            )}

            <Grid item md={6} sm={6} xs={12} lg={6}>
              <FormLabel component="legend" className={classes.info}>
                Timing Preference
                <Tooltip
                  title={
                    <ul>
                      <li>Full Time: Work for atleast 8 hours per day</li>
                      <li>Part Time: Work for 4 hours per day</li>
                      <li>Flexible: Work in any mode</li>
                    </ul>
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      handleAnchorOpen(e);
                    }}
                    size="large"
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <TextField
                className={classes.availPref}
                fullWidth
                // label="Timing Preference*"
                name="availabilityPref"
                onChange={formik.handleChange}
                // required
                variant="outlined"
                select
                SelectProps={{ native: true }}
                value={formik.values.availabilityPref}
                size="large"
              >
                {TimingPreference.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              sm={6}
              md={5}
              xs={12}
              lg={5}
              className={classes.radioOptlast}
            >
              <FormLabel component="legend">
                Proficiency Level{" "}
                <Tooltip
                  title={
                    <ul>
                      <li>Basic - Relatively new to the field</li>
                      <li>Proficient - Significant experience in the field</li>
                      <li>Expert - Deep Expertise in the field</li>
                    </ul>
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      handleAnchorOpen(e, <></>);
                    }}
                    size="large"
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <RadioGroup
                row
                id="categoryExpertiseLevel"
                aria-label="Proficiency Level"
                name="expertiseLevel"
                value={formik.values.expertiseLevel}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="Basic"
                  control={<Radio />}
                  label="Basic"
                />
                <FormControlLabel
                  value="Proficient"
                  control={<Radio />}
                  label="Proficient"
                />
                <FormControlLabel
                  value="Expert"
                  control={<Radio />}
                  label="Expert"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit">
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProfileEditDialog;
