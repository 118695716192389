import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button, InputLabel, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import axios from "axios";
import UserContext from "src/context/user-context";
import VistasTextField from "src/components/custom/VistasTextField";
import VistasTextFieldOutlined from "src/components/custom/VistasTextFieldOutlined";
import Avatar from "src/components/Avatar";
import { motion } from "framer-motion";
import { UPLOAD_ORG_LOGO_URL_V2 } from "src/utils/constants";
import { UploadImageDialog } from "src/components/dialogs/UploadImageDialog";
import TokenService from "src/services/TokenService";
import { ORGANIZATION_URL_V2 } from "src/utils/constants";

import {trackFBEmployerLead} from "src/utils/analytics";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "5vw",
    height: "90vh",
  },

  sectionHeader: {
    fontWeight: 600,
    color: "#2B7ED8",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      fontSize: "25px",
    },
  },
  avatarContainer: {
    width: "115px",
    height: "115px",
    borderRadius: "14px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 25px 50px -12px",
    position: "relative",
  },
  companyLogo: {
    "$$ .letterContainer": {
      backgroundColor: "#00CAF6",
    },
  },
  editText: {
    position: "absolute",
    color: "white",
    width: "115px",
    height: "115px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    borderRadius: "14px",
    zIndex: "1",
  },
  inputLabel: {
    fontWeight: 600,
  },

}));

const Organization = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const [iconEdit, setIconEdit] = useState(true);
  const [editAvatar, setEditAvatar] = useState(false);

  const [logoUrl, setLogoUrl] = useState("");

  const profileEditVariants = {
    unselected: { opacity: 0 },
    selected: { opacity: 1 },
  };

  const initialValues = {
    name: "",
    description: "",
    website: "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string("Must be valid name")
      .min(1, "Must atleast be 1 character")
      .required("Organization Name is required"),
    website: yup
      .string("Must be valid name")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    // .required('Please enter valid website')
  });

  const submitExpertiseDetails = (values, actions) => {
    axios({
      method: "POST",
      url: ORGANIZATION_URL_V2,
      data: values,
      params: {
        userCode: userContext.user.get.userCode,
      },
    })
      .then((response) => {
        // var userObj = JSON.parse(localStorage.getItem("auth"));
        // userObj.firstTimeLogin = false;
        // localStorage.setItem("auth", JSON.stringify(userObj));
        // userContext.user.set(userObj);
        
        trackFBEmployerLead();
        
        TokenService.getRefreshToken()
          .then((response) => {
            actions.setSubmitting(false);
             actions.resetForm();
             navigate("/r/home", { replace: true });
          })
          .catch((e) => {
            actions.setSubmitting(false);
          });

      })
      .catch((error) => {
        
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submitExpertiseDetails(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form autoComplete="off" noValidate className={clsx(classes.root)}>
          <Grid
            container
            spacing={2}
            className={classes.formContainer}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h4" className={classes.sectionHeader}>
                Set up your Organization{" "}
              </Typography>
              <Hidden mdDown>
                <Typography variant="body1">
                  Please add the details of your Organization
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="body2">
                  Please add the details of your Organization
                </Typography>
              </Hidden>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <InputLabel shrink className={classes.inputLabel}>
                    Organization Logo
                  </InputLabel>
                  <div className={classes.avatarContainer}>
                  
                    <div>
                      <motion.div
                        initial="unselected"
                        animate={iconEdit ? "selected" : "unselected"}
                        variants={profileEditVariants}
                        className={classes.editText}
                        onMouseEnter={() => setIconEdit(true)}
                        onMouseLeave={() => setIconEdit(false)}
                        onClick={() => setEditAvatar(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M14.078 7.061l2.861 2.862-10.799 10.798-3.584.723.724-3.585 10.798-10.798zm0-2.829l-12.64 12.64-1.438 7.128 7.127-1.438 12.642-12.64-5.691-5.69zm7.105 4.277l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z"
                            fill="#F4F6F8"
                          />
                        </svg>
                      </motion.div>
                      <UploadImageDialog
                        URL={UPLOAD_ORG_LOGO_URL_V2}
                        userCode={userContext.user.get.userCode}
                        open={editAvatar}
                        setOpen={setEditAvatar}
                        refetch={(res) => {
                          setEditAvatar(false);
                          console.log("after refetch", res);
                          setLogoUrl(res.data.s3PublicUrl);
                        }}
                      />
                    </div>

                    <Avatar
                      URL={logoUrl}
                      firstName={values.name}
                      height={115}
                      width={115}
                      className={classes.companyLogo}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <VistasTextFieldOutlined
                required
                inputLabelText="Name of the Organization"
                name="name"
                value={values.name}
                onChange={handleChange}
                id="name"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <VistasTextField
                label="About the Organization"
                name="description"
                value={values.description}
                onChange={handleChange}
                id="description"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <VistasTextFieldOutlined
                inputLabelText="Website"
                name="website"
                value={values.website}
                onChange={handleChange}
                id="website"
                error={Boolean(touched.website && errors.website)}
                helperText={touched.website && errors.website}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default Organization;
