import React, { FC, ReactElement } from "react";
import styled from "@emotion/styled";
import Typography from "../Typography";
import { colors } from "../../theme";

interface GvAvatar {
  hue: number;
  size: string;
  src?: string | null;
}
const GvAvatar = styled.div<GvAvatar>`
  background-color: hsl(${(props) => props.hue}, 40%, 50%);
  background-image: url(${(props) => props.src});
  background-size: cover;
  border: 1px solid ${colors.nv80};
  font-family: Inter, sans-serif;
  border-radius: 8px;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  font-size: calc(${(props) => props.size} / 2.5);
  color: ${colors.white};
  display: grid;
  place-items: center;
  user-select: none;
`;

interface AvatarProps {
  src?: string | null;
  alt?: string;
  size?: string;
}

const Card: FC<AvatarProps> = ({ src, alt, size = "110px" }) => {
  let initials: string = "";
  let color: number = 200;

  const words = alt ? alt.trim().split(" ") : "";
  if (words.length > 0) {
    initials = words[0].charAt(0).toUpperCase();
    color = (initials.charCodeAt(0) - 65) * 15 - 1;
    if (words.length > 1) {
      initials += words[words.length - 1].charAt(0).toUpperCase();
    }
  }

  return (
    <GvAvatar
      children={(src === "" || src === null) && initials}
      hue={color}
      size={size}
      src={src}
    />
  );
};

export default Card;
