import React, { useEffect, useState, useContext } from "react";
import { Container, Tabs, Tab, AppBar, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import Results from "./Results";
import UserContext from "src/context/user-context";
import Contracts from "src/views/candidate/CandidateJobs/Contracts";
import axios from "axios";
import {
  GET_JOB_BOOKMARKS,
  GET_CANDIDATE_JOB_APPLICATIONS,
} from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CandidateJobs = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  const [jobs, setJobs] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const [credentials, setCredentials] = useState([]);
  const [activeCredentials, setActiveCredentials] = useState("myjobs");

  var user_code = userContext.user.get.userCode;

  useEffect(() => {
    // code to run on component mount
    //setJobs(SAMPLE_RECRUITER_JOBS);
    getAppliedJobs();
    getBookmarks();
  }, [userContext]);

  const handleChange = (event, newValue) => {
    setActiveCredentials(newValue);
    if (newValue.toLowerCase() == "myjobs") {
      setJobs(appliedJobs);
    } else {
      setJobs(bookmarks);
    }
  };

  const getBookmarks = () => {
    axios({
      method: "GET",
      url: GET_JOB_BOOKMARKS,
      params: {
        user_code: user_code,
      },
    })
      .then((response) => {
        console.log("WE GOT THE BOOKMARKS", user_code, "::", response.data);
        setBookmarks(response.data);
      })
      .catch((error) => {
        console.log("WE DONT GOT THE BOOKMARKS:", user_code);
      });
  };

  const getAppliedJobs = () => {
    axios({
      method: "GET",
      url: GET_CANDIDATE_JOB_APPLICATIONS,
      params: {
        user_code: user_code,
      },
    })
      .then((response) => {
        console.log("WE GOT THE APPLICATIONS", user_code, "::", response.data);
        setAppliedJobs(response.data);
        setJobs(response.data);
      })
      .catch((error) => {
        console.log("WE DONT GOT THE APPLICATIONS:", user_code);
      });
  };

  return (
    <Page className={classes.root} title="Applied Gigs">
      {/*<Contracts />*/}
      <AppBar position="static" color="default">
        <Tabs
          value={activeCredentials}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          onChange={handleChange}
        >
          <Tab label="Applied Gigs" value="myjobs" />
          <Tab label="Saved Gigs" value="bookmarks" />
        </Tabs>
      </AppBar>
      <Results jobs={jobs} />
    </Page>
  );
};

export default CandidateJobs;
