import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import UserContext from "src/context/user-context";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
    backgroundColor: "white",
    textAlign: "center",
  },
  media: {
    height: 300,
  },
  welcomeIcon: {
    marginRight: 10,
  },
}));

const NoContact = () => {
  const classes = useStyles();
  const userContext = React.useContext(UserContext);
  const isCandidate = userContext.user.get.userType === "individual_candidate" ? true : false;
  const redirectLink = isCandidate ? "/c/search" : "/r/search";
  const infoText = isCandidate
    ? "You can start conversation with recruiters after job application. Start searching for job and apply."
    : "Start the conversation with selected candidate after searching them.";

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          className={classes.media}
          image="/static/images/no_message.svg"
          title="Search Candidates"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Nothing Yet
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {infoText}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <RouterLink to={redirectLink}>
          <Button color="primary">
            Search
            <PlayArrowIcon color="primary"></PlayArrowIcon>
          </Button>
        </RouterLink>
      </CardActions>
    </Card>
  );
};

export default NoContact;
