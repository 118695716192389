import React, { useEffect, useState, useContext } from "react";
import { Container, Grid, Tabs, Tab, AppBar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "src/components/Page";
import { SAMPLE_RECRUITER_CANDIDATES } from "./sample-data";
import Results from "./Results";
import UserContext from "src/context/user-context";
import RightDrawer from "src/components/drawer/Drawer";
import ChatWidget from "src/components/messaging/ChatWidget";
import SetupStatusCardRecruiter from "src/components/cards/SetupStatusCardRecruiter";
import axios from "axios";
import { GET_CANDIDATE_BOOKMARKS } from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const RecruiterCandidates = () => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  const [candidates, setCandidates] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);

  const [credentials, setCredentials] = useState([]);
  const [activeCredentials, setActiveCredentials] = useState("bookmarks");

  var user_code = userContext.user.get.userCode;

  useEffect(() => {
    //setCandidates(SAMPLE_RECRUITER_CANDIDATES);
    getBookmarks();
  }, [userContext]);

  /*const handleChange = (event, newValue) => {
    setActiveCredentials(newValue);
    if (newValue.toLowerCase() == "mycandidates") {
      //let filteredCredentials = jobInfo.filter(c => c.type === newValue);
      //setCredentials([]);
      setCandidates(SAMPLE_RECRUITER_CANDIDATES);
    } else {
      setCandidates(bookmarks);
    }
  };*/

  const getBookmarks = () => {
    axios({
      method: "GET",
      url: GET_CANDIDATE_BOOKMARKS,
      params: {
        user_code: user_code,
      },
    })
      .then((response) => {
        console.log("WE GOT THE BOOKMARKS", user_code, "::", response.data);
        //setBookmarks(response.data);
        setCandidates(response.data);
      })
      .catch((error) => {
        console.log("WE DONT GOT THE BOOKMARKS:", user_code);
      });
  };

  return (
    <Page className={classes.root} title="Bookmarks">
      <AppBar position="static" color="default">
        <Tabs
          value={activeCredentials}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          /*onChange={handleChange}*/
        >
          {/*<Tab label="My Candidates" value="mycandidates" />*/}
          <Tab label="Bookmarks" value="bookmarks" />
        </Tabs>
      </AppBar>
      <Results candidates={candidates} />
    </Page>
  );
};

export default RecruiterCandidates;
