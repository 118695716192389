import React, { useState, useContext, useEffect } from "react";
import {
  Drawer,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultiSelectFilterGroup from "./MultiSelectFilterGroup";
import RangerSlideFilter from "./RangeSliderFilter";
import axios from "axios";
import { JOB_SEARCH_SERVICE_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";
import { JOB_SEARCH_FILTERS } from "src/utils/searchConstants";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    backgroundColor: "#ffffff",
    padding: "75px 45px 0px",
    color: "#1A2740",
    position: "relative",
  },
  filterTitle: {
    fontSize: 32,
    lineHeight: "39px",
    opacity: 0.84,
    marginBottom: 20,
  },
  filterBlock: {
    margin: "25px 0px",
  },
  filterActions: {
    position: "sticky",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    height: 50,
    width: "100%",
    padding: 10,
    borderTop: "1px solid gray",
    backgroundColor: "#ffffff",
  },
  filterActionButton: {
    margin: "0px 5px",
  },
}));

const filterData = [
  {
    name: "Job Roles",
    type: "radio",
    options: ["Associate", "Executive", "Senior", "Lorem", "Lorem", "Lorem"],
  },
  {
    name: "Salary Range",
    type: "slider",
    startValue: 1000,
    endValue: 3000,
    min: 0,
    max: 10000,
    options: ["Verified", "Unverified", "New"],
  },
];

const SearchFilter = ({
  showFilter,
  setShowFilter,
  facetResults,
  setFacetResults,
  setSearchResults,
  searchTerm,
  setNumFound,
  selectedFilters,
  setSelectedFilters,
  ...rest
}) => {
  const classes = useStyles();
  //const { showFilter } = props;
  const [value, setValue] = React.useState("female");

  console.log("GOT THE FILTERS:", facetResults);

  //const [selectedFilters, setSelectedFilters] = useState({});

  const userContext = useContext(UserContext);

  // useEffect(() => {
  //   console.log("THE SELECTED FILTERS:", selectedFilters);
  // });

  const toggleDrawer = () => {};
  const handleChange = () => {};

  const handleClearFilter = () => {
    // TODO: handle clear filter
    //props.setShowFilter(false);
    var temp = {};
    setSelectedFilters(() => temp);
  };
  const handleFilter = () => {
    // TODO: handle filter form
    //props.setShowFilter(false);
    console.log("THE SELECTED FILTERS HERE:", selectedFilters);

    var params = {
      searchTerm: searchTerm,
      pageNumber: 0,
      sortField: "",
      userCode: userContext.user.get.userCode,
    };

    axios({
      method: "post",
      url: JOB_SEARCH_SERVICE_URL,
      params,
      data: selectedFilters,
    })
      .then((response) => {
        console.log("POST FILTERS SUCCESSFUL");
        console.log("FILTERED RES:", response.data);
        setSearchResults(response.data["jobs"]);
        setNumFound(response.data["numFound"]);
        setAndFormatFacetResults(response.data["fc"]);
        //setFacetResults(response.data["fc"]);
      })
      .catch(function (error) {
        console.log("POST CALL ERROR");
        console.log(error);
      });

    setShowFilter(false);
  };

  function setAndFormatFacetResults(facets) {
    let filters = new Array();

    for (const [k, label] of Object.entries(JOB_SEARCH_FILTERS)) {
      let temp = {};
      temp.name = k;
      temp.type = "radio";
      temp.label = label;
      let opt = [];
      let optVal = [];
      for (const [key, value] of Object.entries(facets[k])) {
        if (value != 0) {
          opt.push(key);
          optVal.push(value);
        }
      }
      if (opt != []) {
        temp.options = opt;
        temp.optionsVal = optVal;
        filters.push(temp);
      }
    }
    setFacetResults(filters);
  }

  return (
    <Drawer anchor={"right"} open={showFilter} onClose={toggleDrawer}>
      <Paper elevation={0} className={classes.drawer}>
        <Typography variant="h5" className={classes.filterTitle}>
          Add Filters
        </Typography>

        {facetResults.map((filter, idx) => {
          return (
            <Accordion key={idx}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {filter.label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filter.type === "radio" && (
                  <div className={classes.filterBlock} key={filter.name}>
                    <MultiSelectFilterGroup
                      title={filter.name}
                      filters={filter.options}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </div>
                )}
                {filter.type === "slider" && (
                  <div className={classes.filterBlock} key={filter.name}>
                    <RangerSlideFilter
                      title={filter.name}
                      min={filter.min}
                      max={filter.max}
                      start={filter.startValue}
                      end={filter.endValue}
                    />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}

        <div className={classes.filterActions}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClearFilter}
            className={classes.filterActionButton}
          >
            Clear Filters
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleFilter}
            className={classes.filterActionButton}
          >
            Done
          </Button>
        </div>
      </Paper>
    </Drawer>
  );
};

export default SearchFilter;
