import React, { FC } from "react";
import styled from "@emotion/styled";
import { colors } from "../../theme";
import Button from "src/designsystem/Core/Button";
import { Message } from "@icon-park/react";
import { motion } from "framer-motion";
import Typography from "src/designsystem/Core/Typography";
import HueButton from "src/designsystem/Core/HueButton";
import Tooltip from "@mui/material/Tooltip";

const GvMessageButton = styled(Button)<MBProps>`
  padding: 0em;
`;
const GvHueMessageButton = styled(HueButton)<MBProps>`
  padding: 0em;
`;
const ButtonText = styled(Typography)`
  color: white;
`;

interface MBProps {
  withText?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  hue?: number;
}

const MessageButton: FC<MBProps> = ({
  withText = false,
  onClick,
  fullWidth,
  hue,
}) => {
  if (hue) {
    return (
      <GvHueMessageButton
        withText={withText}
        variant={withText ? "contained" : "outlined"}
        onClick={onClick}
        fullWidth={fullWidth}
        hue={hue}
      >
        <Tooltip title="Message" arrow>
          <motion.div
            whileTap={{ x: 5 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            {withText ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Message
                  theme={"filled"}
                  size="20"
                  fill={colors.white}
                  style={{
                    display: "grid",
                    placeItems: "center",
                  }}
                />
                <ButtonText>Message</ButtonText>
              </div>
            ) : (
              <Message
                theme={"outline"}
                size="20"
                fill={`hsl(${hue}, 40%, 50%)`}
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              />
            )}
          </motion.div>
        </Tooltip>
      </GvHueMessageButton>
    );
  } else {
    return (
      <GvMessageButton
        withText={withText}
        variant={withText ? "contained" : "outlined"}
        onClick={onClick}
        fullWidth={fullWidth}
      >
        <Tooltip title="Message" arrow>
          <motion.div
            whileTap={{ x: 5 }}
            style={{ padding: fullWidth ? "0.4em" : "0.8em" }}
          >
            {withText ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Message
                  theme={"filled"}
                  size="20"
                  fill={colors.white}
                  style={{ display: "grid", placeItems: "center" }}
                />
                <ButtonText>Message</ButtonText>
              </div>
            ) : (
              <Message
                theme={"outline"}
                size="20"
                fill={colors.p40}
                style={{ display: "grid", placeItems: "center" }}
              />
            )}
          </motion.div>
        </Tooltip>
      </GvMessageButton>
    );
  }
};

export default MessageButton;
