import React from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Link as MaterialLink,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import PowerIcon from "@mui/icons-material/PowerSettingsNew";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import UserContext from "src/context/user-context";
import { Link } from "react-router-dom";
import Avatar from "src/components/Avatar";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { LOGOUT_URL } from "src/utils/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TokenService from "src/services/TokenService";
import {
  setAccessToken,
  initializeToken,
  setRedirect,
} from "src/redux/slice/userSlice";
const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: "15px",
    color: "#1A2740",
    borderTop: "2px solid #e7ebee",
    fontSize: "1rem",
  },
  avatar: {
    borderRadius: "8px",
  },
  userInfo: {
    padding: "10px",
    border: "none",
    outline: "none",
    margin: "0px 10px",
  },
  name: {
    color: "#2C80D5",
    fontSize: "1.2rem",
    marginBottom: "5px",
  },
  userType: {
    color: "grey",
    fontSize: "0.9rem",
    margin: "10px 0px",
  },
  icons: {
    color: "#1A2740",
    marginRight: "25px",
    marginLeft: "18px",
    fontSize: "2rem",
    fontWeight: "200",
    padding: "4px",
  },

  iconButton: {
    borderRadius: "0px",
    height: "65px",
    width: "65px",
    display: "grid",
    placeItems: "center",
    padding: 0,
  },
}));

const ProfileDropdown = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userContext = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [supportDialogOpen, setSupportDialogOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isCandidate = userContext.user.get.userType === "individual_candidate" ? true : false;

  const settingsURL = isCandidate ? "/c/account" : "/r/account";
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fullName =
    userContext.user.get.firstName + ` ` + userContext.user.get.lastName;

  const handleSupportOpen = () => {
    setSupportDialogOpen(true);
  };

  const callLogout = () => {
    localStorage.removeItem("auth");
    TokenService.getLogout()
      .then((response) => {
        if (response) {
          navigate("/login", { replace: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSupportClose = () => {
    setSupportDialogOpen(false);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClick}
        className={classes.iconButton}
        disableRipple
        size="large"
      >
        <Avatar
          URL={userContext.user.get.profilePicUrl}
          height={41}
          width={41}
          firstName={userContext.user.get.firstName}
          lastName={userContext.user.get.lastName}
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            fontFamily: "sans-serif",
            borderRadius: "8px",
            backgroundColor: "#fff",
            minWidth: "265px",
          },
        }}
      >
        <div className={classes.userInfo}>
          <p className={classes.name}>{fullName}</p>
          <p>{userContext.user.get.email}</p>
        </div>
        {isCandidate ? (
          <Link to="/c/candidate">
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              <svg
                style={{ margin: "0px 25px" }}
                width="24"
                height="24"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24"
                  cy="12"
                  r="8"
                  fill="none"
                  stroke="#1A2740"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 44C42 34.0589 33.9411 26 24 26C14.0589 26 6 34.0589 6 44"
                  stroke="#1A2740"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Profile
            </MenuItem>
          </Link>
        ) : (
          <Link to="/r/org">
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              <svg
                style={{ margin: "0px 25px" }}
                width="24"
                height="24"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24"
                  cy="12"
                  r="8"
                  fill="none"
                  stroke="#1A2740"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42 44C42 34.0589 33.9411 26 24 26C14.0589 26 6 34.0589 6 44"
                  stroke="#1A2740"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Org. Profile
            </MenuItem>
          </Link>
        )}
        
        <Link to={settingsURL}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <svg
              style={{ margin: "0px 25px" }}
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" fill="white" fillOpacity="0.01" />
              <path
                d="M36.686 15.171C37.9364 16.9643 38.8163 19.0352 39.2147 21.2727H44V26.7273H39.2147C38.8163 28.9648 37.9364 31.0357 36.686 32.829L40.0706 36.2137L36.2137 40.0706L32.829 36.686C31.0357 37.9364 28.9648 38.8163 26.7273 39.2147V44H21.2727V39.2147C19.0352 38.8163 16.9643 37.9364 15.171 36.686L11.7863 40.0706L7.92939 36.2137L11.314 32.829C10.0636 31.0357 9.18372 28.9648 8.78533 26.7273H4V21.2727H8.78533C9.18372 19.0352 10.0636 16.9643 11.314 15.171L7.92939 11.7863L11.7863 7.92939L15.171 11.314C16.9643 10.0636 19.0352 9.18372 21.2727 8.78533V4H26.7273V8.78533C28.9648 9.18372 31.0357 10.0636 32.829 11.314L36.2137 7.92939L40.0706 11.7863L36.686 15.171Z"
                fill="none"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinejoin="round"
              />
              <path
                d="M24 29C26.7614 29 29 26.7614 29 24C29 21.2386 26.7614 19 24 19C21.2386 19 19 21.2386 19 24C19 26.7614 21.2386 29 24 29Z"
                fill="none"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinejoin="round"
              />
            </svg>
            Account
          </MenuItem>
        </Link>
        <MaterialLink
          to="mailto:support@gigvistas.com"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              handleSupportOpen();
            }}
          >
            <svg
              style={{ margin: "0px 25px" }}
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" fill="white" fillOpacity="0.01" />
              <path
                d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                fill="none"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinejoin="round"
              />
              <path
                d="M24 28.6249V24.6249C27.3137 24.6249 30 21.9386 30 18.6249C30 15.3112 27.3137 12.6249 24 12.6249C20.6863 12.6249 18 15.3112 18 18.6249"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 37.6249C25.3807 37.6249 26.5 36.5056 26.5 35.1249C26.5 33.7442 25.3807 32.6249 24 32.6249C22.6193 32.6249 21.5 33.7442 21.5 35.1249C21.5 36.5056 22.6193 37.6249 24 37.6249Z"
                fill="#1A2740"
              />
            </svg>
            Contact Support
          </MenuItem>
        </MaterialLink>
        <Link to="/">
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              callLogout();
            }}
          >
            <svg
              style={{ margin: "0px 25px" }}
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" fill="white" fillOpacity="0.01" />
              <path
                d="M23.9917 6L6 6L6 42H24"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M33 33L42 24L33 15"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 23.9917H42"
                stroke="#1A2740"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Sign Out
          </MenuItem>
        </Link>
      </Menu>

      <Dialog
        open={supportDialogOpen}
        onClose={handleSupportClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick="false"
      >
        <DialogTitle id="alert-dialog-title">{"GigVistas Support"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reach out to us at <strong>support@gigvistas.com</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSupportClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileDropdown;
