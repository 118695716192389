import React from "react";
import PropTypes from "prop-types";
import { FormLabel, Popover, Typography, IconButton,Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const ProficiencyInfo = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [popOverMessage, setPopOverMessage] = React.useState("");

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.target);
    setPopOverMessage(message);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setPopOverMessage("");
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <React.Fragment>
      <FormLabel component="legend">
        Proficiency Level{" "}
        <Tooltip
              title={
                    <ul>
                      <li>Basic - Relatively new to the field</li>
                      <li>Proficient - Significant experience in the field </li>
                      <li>Expert - Deep Expertise in the field</li>
                    </ul>
                  }
                >
        <IconButton
          onClick={(e) => {
            handleAnchorOpen(
              e,
              <>
                Basic - Relatively new to the field <br />
                Proficient - Significant experience in the field <br />
                Expert - Deep Expertise in the field
              </>
            );
          }}
          size="large"
        >
          <InfoIcon fontSize="small" />
        </IconButton>
        </Tooltip>
      </FormLabel>
      <Popover
        id="popover"
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleAnchorClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>{popOverMessage}</Typography>
      </Popover>
    </React.Fragment>
  );
};

ProficiencyInfo.propTypes = {
  className: PropTypes.string,
};

export default ProficiencyInfo;
