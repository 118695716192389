import axios from "axios";
import TokenService from "../../../services/TokenService";
import { LOGIN_URL ,REGISTER_URL} from "src/utils/constants";
import { parseJwt, getSterilizeUtmParams } from "src/utils/generic";
import {getCookieValueByKey} from "src/utils/cookieUtils";


const registerUser = (values) => {

  const paramsFromCookies = getCookieValueByKey("gigvistas-user");

  return axios({
    method: "POST",
    url: REGISTER_URL + getSterilizeUtmParams(paramsFromCookies),
    data: values
  });
};



const login = (email, password) => {
  return axios({
    method: "POST",
    url: LOGIN_URL,
    headers: {
      emailId: email,
      password: password,
    },
  })
    .then((response) => {
      if (response.data) {
        //localStorage.setItem("user", JSON.stringify(response.data));
        TokenService.setCookiesAccessToken(response.data);
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  registerUser,
  login,
  logout,
};

export default authService;
