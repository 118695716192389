import React, { useState, useContext, useEffect } from "react";
import { Resource } from "../../../models/Resource";
import ContractListeModel, {
  ContractDetailModel,
  ContractDetailResponse,
  ContractMilestones,
} from "src/views/recruiter/RecruiterContract/state//models";
import UserContext from "src/context/user-context";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getContractDetailAction } from "src/views/recruiter/RecruiterContract/state/contractDetailReducer";
import Section from "src/designsystem/Core/Section";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import JobDetailData from "src/models/JobDetailData";
import Button from "src/designsystem/Core/Button";
import TokenService from "src/services/TokenService";
import { useParams } from "react-router-dom";
import ContractTopCard from "src/derivedComponents/ContractDetailTopCard";
import Card from "src/designsystem/Core/Card";
import HueCard from "src/designsystem/Core/HueCard";
import FileCard from "src/designsystem/Derivative/FilesCard";
import ContractMileStoneCard from "src/derivedComponents/ContractMileStoneCard";
import AttachmentCard from "src/designsystem/Derivative/AttachmentCard";
const CJDView = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;
const TopCard = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Suggestions = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 25px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;
const Description = styled.div`
  @media (min-width: ${breakpoints["lg"]}) {
    grid-column: span 2;
  }
`;

const GvProfileCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
`;


const CSCard = styled.div`
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr; 
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr;
  }
`;
const CSLeft = styled.div`
  display: grid;
  gap: 15px;
  padding-right: 15px;
  grid-template-columns: 1fr;

`;

const CSRight = styled.div`
  display: grid;
  gap: 15px;
  
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
    gap: 30px;
   
  }
`;

const GvContractCard = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: ${breakpoints["sm"]}) {
    grid-template-columns: 1fr 0.5fr;
  }
`;


const ContractDetail = ({ contractID }: { contractID: number }) => {
  const dispatch = useDispatch();
  const { contractId } = useParams();
  let { jobId } = useParams();

  const userContext = useContext(UserContext) as any;
  var user_code = userContext.user.get.userCode;

  const [contractItem, setContractItemToShow] = useState(2);

  const [filterAllContract, setfilterAllContractToggle] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getContractDetailAction({ contractId }));
  }, []);

  const state: Resource<ContractDetailResponse> = useSelector<
    any,
    Resource<ContractDetailResponse>
  >((state) => {
    return state.contractDetailInfo;
  });



  if ("isPending" in state) {

    return <div>Pending</div>;
  } else if ("resource" in state) {
 
    return SucessView(
      state.resource,
      user_code,
      dispatch,
      contractItem,
      setContractItemToShow,
      filterAllContract,
      setfilterAllContractToggle,
      contractId
    );
  } else if ("errorMessage" in state) {
    return errorView(state.errorMessage);
  } else {
    return <div>Fire! Fire! Fire!</div>;
  }
};

const errorView = (errorMessage: string) => {
  return <h1>{errorMessage}</h1>;
};

const SucessView = (
  data: ContractDetailResponse,
  user_code: string,
  dispatch: any,
  contractItem: any,
  setContractItemToShow: any,
  filterAllContract: any,
  setfilterAllContractToggle: any,
  contractId:any
) => {
  function showContractItem(length: any) {
    setContractItemToShow(length);
    window.scroll({ top: 0, behavior: "smooth" });
  }

  return (
    <CJDView>
      <TopCard>
        <ContractTopCard
          contractDetail={data.contract}
          userDetail={data.userProfileResponse}
          user_code={user_code}
          dispatch={dispatch}
        />
      </TopCard>

      <CSCard>
        <CSLeft>
        <Card title={"Deliverables"}>
          <div>{data.contract.deliverables}</div>
        </Card>
        </CSLeft>

        <CSRight>
        <Card title={"Attachment"}>
        <AttachmentCard data={data.contract.contractAttachments} deletable={true} 
        refetch={() => {
          dispatch(getContractDetailAction({ contractId }));
              }}/>
        </Card>
        </CSRight>
      </CSCard>

      <Description>
        <Card title={"Contract Milestones"}>
        <GvContractCard>
          {data.contract.contractMilestones.map(
            (ContractMilestones: ContractMilestones,index:any) => (
             
              <ContractMileStoneCard
                contractMilestoneData={ContractMilestones}
                index={index}
                user_code={user_code}
                dispatch={dispatch}
              />
             
            )
          )}
           </GvContractCard>
        </Card>
      </Description>

      <Description>
        <Card title={"Discussion Thread"}>
          <div></div>
        </Card>
      </Description>
    </CJDView>
  );
};

export default ContractDetail;
