import React, { FC, ReactElement, ReactNode } from "react";
import styled from '@emotion/styled'

import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { colors, typography } from "../../theme";
import Button, { ButtonProps } from "../Button";


const GvButton = styled(Button)<HueButtonProps>`
      border: 1.5px solid ${props => `hsl(${props.hue}, 40%, 50%)`};
      width: 100%;
      &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 ${props => `hsl(${props.hue}, 50%, 80%)`}, 0 0 0 5px ${props => `hsl(${props.hue}, 60%, 80%)`};
      }

      ${props => props.variant === 'contained' ?
    `
      background-color: hsl(${props.hue}, 40%, 50%);
      &:hover {
        background-color: hsl(${props.hue}, 30%, 50%);
      }

      &.${buttonUnstyledClasses.active} {
        background-color: hsl(${props.hue}, 20%, 50%);
      }

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${colors.n80};
        color: ${colors.n50};
      }
    `
    :
    `
      color: hsl(${props.hue}, 40%, 50%);
      &:hover {
        background-color: hsl(${props.hue}, 40%, 50%, 10%);
      }

      &.${buttonUnstyledClasses.active} {
        background-color: hsl(${props.hue}, 40%, 50%, 20%);
      }

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${colors.n90 + '24'};
        color: ${colors.n50};
        border: 1.5px solid ${colors.n50};
      }
    `
  }

`

interface HueButtonProps extends ButtonProps {
  hue?: number
}

const HueButton: FC<HueButtonProps> = (props) => {
  return (
    <GvButton {...props} variant={props.variant ? props.variant : 'contained'} hue={props.hue ? props.hue : 188}/>
  )
}

export default HueButton