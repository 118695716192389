import React, { FC, ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet";
import {trackFBPageView} from "src/utils/analytics";

interface PageProps {
  title: string
  children: ReactNode
}

const Page: FC<PageProps> = ({ children, title = "" }) => {

  useEffect(() => {
    trackFBPageView();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

export default Page;
