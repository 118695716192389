import React, { useState, useEffect } from "react";
import axios from "axios";

const useRequest = (initUrl, userCode) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;
    const fetchProduct = async () => {
      setLoading(true);
      try {
        setError(null);
        const response = await axios({
          method: "GET",
          url: initUrl,
          params: {
            userCode,
          },
        });
        if (!ignore) setData(response.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchProduct();
    return () => {
      ignore = true;
    };
  }, [initUrl, userCode]);

  return { data, loading, error };
};

export default useRequest;
