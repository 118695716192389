import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Logo from "src/components/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid" + theme.palette.accent.main,
  },
  toolbar: {
    height: 64,
  },
  headerColor: theme.palette.light.main,
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
      color={classes.headerColor}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
