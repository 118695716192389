import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import JobDetails from "../job/JobDetails";
import JobAdditionalDetails from "../job/JobAdditionalDetails";
import PropTypes from "prop-types";

import UserContext from "src/context/user-context";
import { UpdateListenerContext } from "src/context/update-listener-context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UPDATE_PUT_JOB_URL ,POST_DRAFT_URL,POST_DRAFT_ACTIVATE_URL} from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(1) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Project Details", "Additional Details"];

const JobStepperEdit = ({
  funcFlag,
  toggler,
  setToggler,
  jobInfo,
  getJobInfo,
  ...rest
}) => {
  const classes = useStyles();

  const userContext = useContext(UserContext);
  const extraContext=useContext(UpdateListenerContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [jobpost, setJobPost] = React.useState(jobInfo);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const navigate = useNavigate();

  function submitJob(newJobPost) {
    console.log(
      "submitJob has been called with:" + userContext.user.get.userCode
    );
    //console.log("HERE IS THE JOBPOST:", jobpost);

    var params = {
      userCode: userContext.user.get.userCode,
    };

    let backendJobPost = {};
    backendJobPost.id = newJobPost.id;
    backendJobPost.title = newJobPost.title;
    backendJobPost.description = newJobPost.description;
    backendJobPost.category = newJobPost.category;
    backendJobPost.expertise = newJobPost.expertise;
    backendJobPost.proficiencyLevel = newJobPost.proficiencyLevel;
    backendJobPost.projectType = newJobPost.projectType;
    backendJobPost.timeCommitment = newJobPost.timeCommitment;
    backendJobPost.duration = newJobPost.duration;
    backendJobPost.timeUnit = newJobPost.timeUnit;
    backendJobPost.budget = newJobPost.budget;
    backendJobPost.currencyCode = newJobPost.currencyCode;
    backendJobPost.qualifications = newJobPost.qualifications;
    backendJobPost.certifications = newJobPost.certifications;
    backendJobPost.jobPostSkills = newJobPost.jobPostSkills;
    backendJobPost.jobPostLocations = newJobPost.jobPostLocationList;
    backendJobPost.addedLocations=newJobPost.addedLocations;
    backendJobPost.updatedDate= newJobPost.updatedDate;
    backendJobPost.status= newJobPost.status;
    backendJobPost.modifiedBy= newJobPost.modifiedBy;
    backendJobPost.createdDate= newJobPost.createdDate;
    backendJobPost.fullTime= newJobPost.fullTime;

    if(funcFlag==="editJob"){
      axios({
        method: "PUT",
        url: UPDATE_PUT_JOB_URL,
        params,
        data: backendJobPost,
      })
        .then((response) => {
          console.log("POSTING JOB WAS SUCCESSFUL ON EDIT");
          //toggle the state
          setToggler((toggler) => !toggler);
          getJobInfo(jobpost.id);
          console.log("AFTER", toggler);
        })
        .catch((error) => {
          console.log("POSTING JOB WAS NOT SUCCESSFUL");
          //actions.setSubmitting(false);
        });   
    }else if(funcFlag==="editDraft"){
      axios({
        method: "PUT",
        url: POST_DRAFT_ACTIVATE_URL,
        params,
        data: backendJobPost,
      }).then(response=>{
        setToggler((toggler) => !toggler);
        console.log("converting draft to job was successfull");
        navigate("/r/jobs");
        extraContext.notifyUpdateListeners();
      })
    }
       
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobDetails
            funcFlag={funcFlag}
            handleNext={handleNext}
            jobpost={jobpost}
            setJobPost={setJobPost}
            setToggler={setToggler}
          />
        );
      case 1:
        return (
          <JobAdditionalDetails
            funcFlag={funcFlag}
            handleNext={handleNext}
            handleBack={handleBack}
            jobpost={jobpost}
            setJobPost={setJobPost}
            submitJob={submitJob}
            setToggler={setToggler}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
          {  funcFlag==="editJob"? "Edit Gig" : "Edit Draft"}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <>{getStepContent(activeStep)}</>
      </main>
    </>
  );
};

JobStepperEdit.propTypes = {
  className: PropTypes.string,
};

export default JobStepperEdit;
