import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Typography,
  Switch,
  InputAdornment,
  InputLabel,
  Select,
  Input,
  MenuItem,
  ListItemText,
  Checkbox,
  CardHeader,
  Button,
  Popover,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UserContext from "src/context/user-context";
import InfoIcon from "@mui/icons-material/Info";
import { UPDATE_CANDIDATE_URL } from "src/utils/constants";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

var initialValues = {
  isOpenToFullTime: false,
  currentLocation: {
    pincode: "",
  },
  gigPrefs: {
    canWorkFullTimeGigs: false,
    hourlyFees: 0,
    projectPrefs: ["Remote"],
    distanceFromCurrentLocation: "",
  },
  ftePrefs: {
    sitePrefs: ["Remote"],
    lastSalary: "",
    distanceFromCurrentLocation: "",
  },
};

const projectTypes = [
  { value: "Remote", label: "Remote" },
  { value: "Onsite", label: "Onsite" },
  { value: "Blended", label: "Blended" },
];

const sitePrefs = [
  { value: "Remote", label: "Remote" },
  { value: "Onsite", label: "Onsite" },
  { value: "Blended", label: "Blended" },
];

const distancePrefs = [
  { value: 5, label: "Within 5kms" },
  { value: 10, label: "Within 10kms" },
  { value: -1, label: "No constraints" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = Yup.object().shape({});

const PreferencesForm = ({ className, handleBack, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const isAgency = userContext.user.get.isAgency;

  const [popOverMessage, setPopOverMessage] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setPopOverMessage(message);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setPopOverMessage("");
  };
  const openPopOver = Boolean(anchorEl);

  function handleProjectTypeChange(e, values, setValues) {
    values.gigPrefs.projectPrefs = e.target.value;
    setValues(values);
    console.log("project type prefs" + e.target.value);
    console.log(values);
  }

  function handleSiteTypeChange(e, values, setValues) {
    values.ftePrefs.sitePrefs = e.target.value;
    setValues(values);
  }

  function handleOpenToFTE(e, values, setValues) {
    values.isOpenToFullTime = e.target.checked ? true : false;
    setValues(values);
  }

  function handleCanWorkFullTimeGigs(e, values, setValues) {
    values.gigPrefs.canWorkFullTimeGigs = e.target.checked ? true : false;
    setValues(values);
  }

  function handleHourlyFees(e, values, setValues) {
    values.gigPrefs.hourlyFees = e.target.value;
    setValues(values);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        var candidate = {
          userCode: userContext.user.get.userCode,
          pinCode: values.currentLocation.pincode,
          expectedFees: values.gigPrefs.hourlyFees,
          canWorkFullTimeGigs: values.gigPrefs.canWorkFullTimeGigs,
          gigProjectType: values.gigPrefs.projectPrefs.join(","),
          gigDistance: values.gigPrefs.distanceFromCurrentLocation,
          isOpenToFullTime: values.isOpenToFullTime,
          fteJobType: values.ftePrefs.sitePrefs.join(","),
          fteDistance: values.ftePrefs.distanceFromCurrentLocation,
        };
        axios({
          method: "POST",
          url: UPDATE_CANDIDATE_URL,
          data: candidate,
        })
          .then((response) => {
            var userObj = JSON.parse(localStorage.getItem("auth"));
            userObj.firstTimeLogin = false;
            localStorage.setItem("auth", JSON.stringify(userObj));
            userContext.user.set(userObj);
            navigate("/c/dashboard", { replace: true });
          })
          .catch((error) => {
            actions.setSubmitting(false);
            //handleServerResponse(false, error.response.data.error);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={2} sm={12} xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Current Location
                  </Typography>
                </Grid>
                <Grid item md={2} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="pincode"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardHeader subheader="Gig Preferences" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} sm={12} xs={12}>
                  <FormControlLabel
                    label="Can you work full time on a project?"
                    labelPlacement="start"
                    control={
                      <Switch
                        onChange={(e) => {
                          handleChange(e);
                          handleCanWorkFullTimeGigs(e, values, setValues);
                        }}
                        name="canWorkFullTime"
                        color="primary"
                      />
                    }
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Expected Fees"
                    name="hourlyFees"
                    onChange={handleChange}
                    variant="outlined"
                    values={values.gigPrefs.hourlyFees}
                    onChange={(e) => {
                      handleChange(e);
                      handleHourlyFees(e, values, setValues);
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          per hour.
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="demo-mutiple-chip-label">
                      Location Type
                    </InputLabel>

                    <Select
                      fullWidth
                      labelId="project-type-checkbox-label"
                      id="project-type-checkbox"
                      multiple
                      value={values.gigPrefs.projectPrefs}
                      onChange={(e) => {
                        handleChange(e);
                        handleProjectTypeChange(e, values, setValues);
                      }}
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      variant="outlined"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{ "margin-right": "14px" }}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleAnchorOpen(e, "TBD - Project Type info");
                            }}
                            size="large"
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                    >
                      {projectTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          <Checkbox
                            checked={
                              values.gigPrefs.projectPrefs.indexOf(type.value) >
                              -1
                            }
                          />
                          <ListItemText primary={type.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  {values.gigPrefs.projectPrefs.includes("Onsite") ||
                  values.gigPrefs.projectPrefs.includes("Blended") ? (
                    <TextField
                      fullWidth
                      label="Distance from Current Location"
                      name="distanceFromCurrentLocation"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={(e) => {
                              handleAnchorOpen(e, "TBD - Hyperlocal info");
                            }}
                            size="large"
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    >
                      {distancePrefs.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  ) : (
                    <span />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      handleBack();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <Popover
              id="popover"
              open={openPopOver}
              anchorEl={anchorEl}
              onClose={handleAnchorClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography className={classes.typography}>
                {popOverMessage}
              </Typography>
            </Popover>
          </Card>
        </form>
      )}
    </Formik>
  );
};

PreferencesForm.propTypes = {
  className: PropTypes.string,
};

export default PreferencesForm;
