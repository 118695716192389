import React, { FC, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Page from "src/components/Page";

import { UserBusiness,Briefcase } from "@icon-park/react";
import ButtonSelect from "src/designsystem/Forms/ButtonSelectVertical";
import { colors } from "src/designsystem/theme";
import * as Yup from "yup";
import { Formik } from "formik";
import UserContext from "src/context/user-context";

import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import SubmitButton from "src/designsystem/Forms/SubmitButton";
import { Container, makeStyles } from "@mui/material";


const GvTitle = styled(Typography)`
  color: ${colors.white};
  margin-left: -3px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ContainerDiv = styled.div`
    padding: 10px;
    display: grid;
`

const initialValues = {
    userType: "",
    userCode: ""
  };

const registerSubmit = ( 
    values: any,
    actions: any,
    navigate: any,
    userContext: any,
    setGlobalError: any,
    dispatch: any
    ) => {
        let userObj = userContext.user.get;
        userObj.userType = values.userType;
        userContext.user.set(userObj);
        if(values.userType === "individual_candidate"){
            navigate("/o/candidate", { replace: true });
        }
        else {
            navigate("/o/recruiter", { replace: true });
        }
    }

const validationSchema = Yup.object().shape({
    userType: Yup.string().max(255).required("Please select the relevant option"),
  });


const UserTypeSelector: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const [globalError, setGlobalError] = useState({
    hasError: false,
    errorMessage: "",
  });

    return (
    <Page title="Select User Type">
        <ContainerDiv>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
            registerSubmit(
                values,
                actions,
                navigate,
                userContext,
                setGlobalError,
                dispatch
            );
            }}
        >
            {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            }) => (
            <form onSubmit={handleSubmit}>
                <GvTitle variant="h3">Welcome, {userContext.user.get.firstName}</GvTitle>
                <div style={{ height: "20px" }} />
                <ButtonSelect
                                name="userType"
                                dark
                                options={[
                                    {
                                        iconSel: (
                                            <Briefcase
                                                theme="outline"
                                                size="24"
                                                fill={colors.white} />
                                        ),
                                        iconUnsel: (
                                            <Briefcase theme="outline" size="24" fill={colors.p40} />
                                        ),
                                        value: "individual_candidate",
                                        title: "I'm a professional looking for work",
                                    },
                                    {
                                        iconSel: (
                                            <UserBusiness
                                                theme="outline"
                                                size="24"
                                                fill={colors.white} />
                                        ),
                                        iconUnsel: (
                                            <UserBusiness theme="outline" size="24" fill={colors.p40} />
                                        ),
                                        value: "organization_employer",
                                        title: "I'm an Employer looking to hire talent",
                                    },
                                ]} label={"Please select the option that describes you "}/>
                <div style={{ height: "20px" }} />
                <Collapse in={globalError.hasError}>
                <Alert
                    action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setGlobalError({
                            hasError: false,
                            errorMessage: "",
                        });
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }
                    severity="error"
                >
                    {globalError.errorMessage}
                </Alert>
                </Collapse>
                <SubmitButton
                isSubmitting={isSubmitting}
                
                type="submit"
                dark
                >
                    Next
                
                </SubmitButton>
            </form>
            )}
        </Formik>
        </ContainerDiv>
    </Page>
    );
}

export default UserTypeSelector;