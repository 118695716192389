import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const borderRadius = "10px";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: borderRadius,
  },
  letterContainer: {
    height: "100%",
    width: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "#8C99A9",
    color: "white",
    textTransform: "uppercase",
    fontSize: "80%",
    borderRadius: borderRadius,
  },
}));

const Avatar = ({ URL, firstName, lastName, height, width, borderRadius }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={{ height: height, width: width }}>
      {URL == null || URL == "" ? (
        <div className={classes.letterContainer}>
          <span style={{ fontSize: height * 0.45 }}>
            {firstName ? firstName[0] : ""}
            {lastName ? lastName[0] : ""}
          </span>
        </div>
      ) : (
        <img
          src={URL}
          alt="avatar"
          className={classes.container}
          height={height}
          width={width}
          style={{ borderRadius: borderRadius }}
        />
      )}
    </div>
  );
};

export default Avatar;
