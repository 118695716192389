import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk, current } from "@reduxjs/toolkit";
//import { current } from '@reduxjs/toolkit'
import getJobDetails, {
  similarGigs,
  applyToJob,
  bookmarkToJob,
  removeBookmark,
} from "./service";
import JobDetailEntity from "./models";
import {
  Resource,
  ResourceInitial,
  ResourcePending,
  ResourceSuccess,
  ResourceFailure,
} from "../../../../models/Resource";

import { resourceUsage } from "process";

import { any, bool } from "prop-types";

// const resource: JobResource = {
//   isPending: true,
// }

export const getJobDetailAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>("jobInfo/getById", async ({ jobID, user_code }, { rejectWithValue }) => {
  try {
    return await getJobDetails(user_code, jobID);
    //return rejectWithValue("error");
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const showSimilarGigs = createAsyncThunk<any, { jobID: number }>(
  "showSimilarGigs/getById",
  async ({ jobID }, { rejectWithValue }) => {
    try {
      return await similarGigs(jobID);
      //return rejectWithValue("error");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const applyToJobAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>("apply/jobID", async ({ jobID, user_code }, { rejectWithValue }) => {
  try {
    return await applyToJob(user_code, jobID);

    //console.log("creating action");

    //var delayPromise = new Promise(resolve => setTimeout(resolve, 1000));

    //return await delayPromise.then(() => {
    //throw "Mock error";
    //});
  } catch (error) {
    console.error(error);
    return rejectWithValue(error);
  }
});

export const bookmarkToJobAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>("bookmark/jobId", async ({ jobID, user_code }, { rejectWithValue }) => {
  try {
    return bookmarkToJob(user_code, jobID);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeBookmarkAction = createAsyncThunk<
  any,
  { jobID: number; user_code: string },
  any
>("removeBookmark/jobId", async ({ jobID, user_code }, { rejectWithValue }) => {
  try {
    return await removeBookmark(user_code, jobID);
  } catch (error) {
    return rejectWithValue(error);
  }
});

const initialState = {
  isPending: false,
} as Resource<JobDetailEntity>;

export const CandidateReducers = createSlice({
  name: "jobInfo",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getJobDetailAction.pending, (state) => {
      return {
        isPending: true,
      } as Resource<JobDetailEntity>;
    });
    builder.addCase(getJobDetailAction.fulfilled, (state, { payload }) => {
      //console.log(payload);
      return {
        resource: { ...payload },
      } as Resource<JobDetailEntity>;
    });

    builder.addCase(
      getJobDetailAction.rejected,
      (state, { meta, payload, error }) => {
        return {
          errorMessage: "Some error 1",
        } as Resource<JobDetailEntity>;
      }
    );

    builder.addCase(showSimilarGigs.pending, (state) => {
      return {
        isPending: true,
      } as Resource<JobDetailEntity>;
    });
    builder.addCase(showSimilarGigs.fulfilled, (state, { payload }) => {
      //console.log(payload);
      return {
        resource: { ...payload },
      } as Resource<JobDetailEntity>;
    });

    builder.addCase(
      showSimilarGigs.rejected,
      (state, { meta, payload, error }) => {
        return {
          errorMessage: "Some error 1",
        } as Resource<JobDetailEntity>;
      }
    );

    builder.addCase(applyToJobAction.pending, (state) => {
      //state.isJobInfoLoading = true;

      let currentState = current(state);

      //console.log(currentState);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            applied: "applying",
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });
    builder.addCase(applyToJobAction.fulfilled, (state, { payload }) => {
      //console.log(current(state));

      let currentState = current(state);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            applied: true,
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });
    builder.addCase(
      applyToJobAction.rejected,
      (state, { meta, payload, error }) => {
        let currentState = current(state);

        if ("resource" in currentState) {
          return {
            resource: {
              ...currentState.resource,
              applied: false,
            },
          } as ResourceSuccess<JobDetailEntity>;
        }
        return currentState;
      }
    );

    builder.addCase(bookmarkToJobAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      //console.log(currentState);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            bookmarked: "bookmarking",
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });
    builder.addCase(bookmarkToJobAction.fulfilled, (state, { payload }) => {
      //if (payload.status === 1) {
      //state.jobInfo.bookmarked = true;

      //}
      //console.log(current(state));

      let currentState = current(state);

      if ("resource" in currentState) {
        //console.log(r);

        return {
          resource: {
            ...currentState.resource,
            bookmarked: true,
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });
    builder.addCase(
      bookmarkToJobAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        if ("resource" in currentState) {
         
          return {
            resource: {
              ...currentState.resource,
              //bookmarked: false
            },
          } as ResourceSuccess<JobDetailEntity>;
        }
        return currentState;
      }
    );

    builder.addCase(removeBookmarkAction.pending, (state) => {
      //state.isJobInfoLoading=true;
      let currentState = current(state);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });

    builder.addCase(removeBookmarkAction.fulfilled, (state, { payload }) => {
  
      let currentState = current(state);

      if ("resource" in currentState) {
        return {
          resource: {
            ...currentState.resource,
            bookmarked: false,
          },
        } as ResourceSuccess<JobDetailEntity>;
      }
      return currentState;
    });

    builder.addCase(
      removeBookmarkAction.rejected,
      (state, { meta, payload, error }) => {
        //state.bookmarked = false;
        //state.failedMessage = error;});
        let currentState = current(state);

        if ("resource" in currentState) {
          return {
            resource: {
              ...currentState.resource,
              //bookmarked: false
            },
          } as ResourceSuccess<JobDetailEntity>;
        }
        return currentState;
      }
    );

    /*extraReducers: {
    // Job detail reducers
    [getJobDetailAction.pending]: (state, { }) => {
      state.isJobInfoLoading = true;
    },
    [getJobDetailAction.fulfilled]: (state, { payload, err }) => {
      state.isJobInfoLoading = false;
      state.jobInfo = payload;
    },
    [getJobDetailAction.rejected]: (state, { meta, payload, error }) => {
      state.failedMessage = error;
    },

    // Apply job reducers
    [applyToJobAction.pending]: (state, { }) => {
      state.isJobInfoLoading = true;
    },
    [applyToJobAction.fulfilled]: (state, { payload, error }) => {
      if (payload) {
        state.jobInfo.applied = true;
      }
    },
    [applyToJobAction.rejected]: (state, { payload, error }) => {
      state.jobInfo.applied = false;
      state.failedMessage = error;
    },

    // Bookmark job reducers
    [bookmarkToJobAction.pending]: (state, { }) => {
      state.isJobInfoLoading = true;
    },
    [bookmarkToJobAction.fulfilled]: (state, {payload, error }) => {
      if (payload.status === 1) {
        state.jobInfo.bookmarked = true;
      }
    },
    [bookmarkToJobAction.rejected]: (state, { payload, error }) => {
      state.jobInfo.bookmarked = false;
      state.failedMessage = error;
    },

    //Remove bookmark job reducers
    [removeBookmarkAction.pending]: (state, { }) => {
      state.isJobInfoLoading = true;
    },
    [removeBookmarkAction.fulfilled]: (state, { payload, error }) => {
      if (payload) {
        state.jobInfo.bookmarked = false;
      }
    },
    [removeBookmarkAction.rejected]: (state, { payload, error }) => {
      state.bookmarked = false;
      state.failedMessage = error;
    },
  }*/
  },
});
// export const { isJobInfoLoading, jobInfo, failedMessage } = CandidateReducers.actions;
export default CandidateReducers.reducer;
