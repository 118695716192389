import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  drawer: {
    width: "300px",
    height: "100%",
    backgroundColor: "#fffefe",
    borderRight: 0,
    paddingTop: "25px",
    borderLeft: "1px solid #DADFEB",
  },
});

const RightDrawer = (props) => {
  const classes = useStyles();

  return <div className={classes.drawer}>{props.children}</div>;
};

export default RightDrawer;
