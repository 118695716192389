import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import Typography from "src/designsystem/Core/Typography";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import CertificateIcon from "src/assets/newProfilePlaceholderIcons/certificateIcon.svg";
import ContractDetailModel, {
  ContractMilestones,
} from "src/views/recruiter/RecruiterContract/state/models";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { CONTRACT_MILESTONE_URL } from "src/utils/constants";
import AddMilestoneDialog from "src/views/recruiter/RecruiterContract/AddMilestoneDialog";
const GvContractCard = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr;
  }
`;

const GvContractDetails = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  padding: 5px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr ;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  }
`;

const Header = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 0.8rem;
  color: gray;
`;

const MileStoneTitle = styled(Typography)`
  font-weight: 650;
  font-size: 0.8rem;
  color: gray;
`;

interface ContractEditCardProps {
  contractMilestoneData: ContractMilestones;
  index: any;
  contractId:any;
  refetch: any;
  
}

const ContractMileStoneEditCard: FC<ContractEditCardProps> = ({
  contractMilestoneData,
  index,
  contractId,
  refetch
}) => {
  // const [toggle, setToggle] = useState(false)
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);
  const [open, setOpen] = useState(false);
  const handleDialogOpen = () => {
    
    setOpen(true);
  };

  const handleClickDelete = (milestoneId:any) => {
    axios({
      method: "DELETE",
      url: CONTRACT_MILESTONE_URL,
      params: {
        contractId: contractId,
        milestoneId:milestoneId
      },
    })
      .then((response) => {
       refetch();
      })
      .catch((error) => {
        console.log("WE DIDN'T GET THE MileStone");
      });
  };

  return (
    <GvContractCard>
      <GvContractDetails>
        <div>
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <MileStoneTitle variant="label">MileStone {index+1}</MileStoneTitle>
            </div>
          </Header>
        </div>

        <div>
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <Title variant="label">Name</Title>
              {contractMilestoneData.name}
              <Title variant="label">Description</Title>
              {contractMilestoneData.description}
            </div>
          </Header>
        </div>

        {/* <div>
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <Title variant="label">Description</Title>
              {contractMilestoneData.description}
            </div>
          </Header>
        </div> */}

        <div>
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <Title variant="label">Payment Due</Title>
              {contractMilestoneData.currencyCode + " " + contractMilestoneData.fees}
            </div>
          </Header>
        </div>
        <div>
          {/* // Date */}
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <Title variant="label">Expected Date</Title>
              {moment(contractMilestoneData.eta).format("DD/MM/YYYY")}
            </div>
          </Header>
        </div>
        <div>
          {/* // Date */}
          <Header variant="label">
            <div style={{ display: "grid", alignItems: "center", gap: "5px" }}>
              <Title variant="label">Action</Title>
              <div
                style={{ display: "flex", alignItems: "center", gap: "3px" }}
              >
                <IconButton
                  onClick={() => {
                     handleDialogOpen();
                  }}
                >
                  <Tooltip title="Edit" arrow>
                    <EditIcon />
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    handleClickDelete(contractMilestoneData.milestoneId);
                  }}
                >
                  <Tooltip title="Delete" arrow>
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </Header>
          <AddMilestoneDialog
              id={contractMilestoneData.milestoneId}
              open={open}
              setOpen={setOpen}
              addFlag={false}
              info={contractMilestoneData}
              refetch={() => {
                refetch()
              }}
      />
        </div>
       
      </GvContractDetails>
        
    </GvContractCard>
  );
};

export default ContractMileStoneEditCard;
