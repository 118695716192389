import axios from "axios";
import {
  GET_USER_REWARD_URL,
  GET_USER_REFERRAL_URL
} from "src/utils/constants";

const getReward = (userCode) => {
  return axios.get(GET_USER_REWARD_URL + "/"+userCode);
};

const getReferral = (data) => {
  return axios.post(GET_USER_REFERRAL_URL, data);
};

const rewardService = {
  getReward,
};



export default rewardService

