import React, { useContext } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Grid, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import OnBoardingContext from "../../../context/onboarding-context";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: "20vw",
    padding: "0px 80px",
    [theme.breakpoints.down("sm")]: {
      left: "0vw",
      padding: "0px 10px",
    },
    backgroundColor: "white",
    height: 60,
    borderTop: `1px solid ${theme.palette.accent.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const OnBoardingCtx = useContext(OnBoardingContext);
  return (
    <footer className={classes.footer}>
      {OnBoardingCtx.currentStep != 0 && OnBoardingCtx.currentStep != 1 && (
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Button
              color="primary"
              type="submit"
              size="small"
              onClick={props.handleNext}
              aria-label="search"
              component="span"
            >
              <DoubleArrowIcon />
              Skip
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            size="small"
            disabled={OnBoardingCtx.currentStep == 0}
            onClick={props.handleBack}
            variant="outlined"
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          {OnBoardingCtx.currentStep === 0 ||
          OnBoardingCtx.currentStep === 1 ? (
            <Button
              color="primary"
              size="small"
              onClick={props.handleSubmit}
              variant="contained"
            >
              <Typography variant="button" noWrap>
                Save & Continue
              </Typography>
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              onClick={props.handleNext}
              variant="contained"
            >
              <Typography variant="button" noWrap>
                Continue
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </footer>
  );
}
