import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CancelIcon from "@mui/icons-material/Cancel";

import UserContext from "src/context/user-context";
import ConversationMessageList from "./ConversationMessageList";
import ChatInput from "./ChatInput";

const useStyles = makeStyles((theme) => ({
  sendMessage: {
    position: "relative",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: "#fff",
    padding: "1rem",
  },
  toolbar: {
    alignItems: "flex-start",
  },
  title: {
    flexGrow: 1,
    textTransform: "capitalize",
    cursor: "pointer",
  },
}));

const ConversationWindow = (props) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const { contact, closeConversation, sendMessage, me, readMessage } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState([]);
  const isCandidate = userContext.user.get.userType === "individual_candidate" ? true : false;

  const handleTitleClick = () => {
    if (!isCandidate) {
      navigate("/r/candidate/" + contact.id);
    }
  };

  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, [userContext]);

  /* updates the writing indicator if not already displayed */
  const typing = (writer) => {
    // if (!this.state.isTyping[writer]) {
    //   let stateTyping = isTyping;
    //   stateTyping[writer] = true;
    //   setIsTyping(stateTyping);
    // }
  };
  /* hide the writing indicator */
  const resetTyping = (writer) => {
    // let stateTyping = isTyping;
    // stateTyping[writer] = false;
    // setIsTyping(stateTyping);
  };
  const handleSendMessage = (message) => {
    sendMessage(contact.id, message);
  };
  const handleReadMessage = () => {
    readMessage(contact.id);
  };
  /* catch the sendMessage signal and update the loading state then continues the sending instruction */
  const sendMessageLoading = (sender, senderAvatar, message) => {
    setIsLoading(true);
    handleSendMessage(message);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  return (
    <div className={classes.chatConversation}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={handleTitleClick}
          >
            {contact.name}
          </Typography>
          <IconButton
            onClick={closeConversation}
            className={classes.closeButton}
            color="inherit"
            edge="end"
            aria-label="menu"
            size="large"
          >
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <ConversationMessageList owner={me} messages={contact.messages} />
      <div className={classes.sendMessage}>
        {/* <TypingIndicator owner={props.owner} isTyping={props.isTyping} /> */}
        <ChatInput
          isLoading={isLoading}
          owner={me}
          ownerAvatar={me.avatar}
          sendMessage={handleSendMessage}
          sendMessageLoading={sendMessageLoading}
          handleFocus={handleReadMessage}
          typing={typing}
          resetTyping={resetTyping}
        />
      </div>
    </div>
  );
};

export default ConversationWindow;
