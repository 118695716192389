import React, { useState, useContext, useEffect, FC } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import TopBar from "src/components/navbar/TopBar";
import SetupStatusCard from "src/components/cards/SetupStatusCard";
import ChatWidget from "src/components/messaging/ChatWidget";
import PerfectScrollbar from "react-perfect-scrollbar";
import RightDrawer from "src/components/drawer/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { use100vh } from "react-div-100vh";
import styled from '@emotion/styled'
import { breakpoints } from "src/designsystem/theme";

interface ParentProps {
  height: number | null
}

const Parent = styled.div<ParentProps>`
display: grid;
height: 100vh;
height: ${props => props.height};
grid-template: 65px 1fr 130px/ 1fr;
grid-template-areas:
"top"
"content"
"nav";
@media (min-width: ${breakpoints["lg"]}) {
  grid-template: 65px 1fr / 85px 1fr 300px;
  grid-template-areas:
  "top top top"
  "nav content drawer";
} 
`

const Top = styled.div`
grid-area: top;
`

const Nav = styled.div`
grid-area: nav;
`

const Content = styled.div`
grid-area: content;
margin: 15px;
@media (min-width: ${breakpoints["lg"]}) {
  margin: 25px;
} 
`

const Drawer = styled.div`
grid-area: drawer;
`

const CandidateLayout: FC = () => {
  const height = use100vh();
  const matches = useMediaQuery(`(min-width:${breakpoints['lg']})`);

  const drawer = (
    <>
      <RightDrawer>
        <div>
          <SetupStatusCard />
          <ChatWidget />
        </div>
      </RightDrawer>
    </>
  );

  return (
    <Parent height={height}>
      <Top>
        <TopBar />
      </Top>
      <Nav>
        <NavBar />
      </Nav>

      <PerfectScrollbar>
        <Content>
          <Outlet />
        </Content>
      </PerfectScrollbar>
      
      {matches &&
        <Drawer>
          {drawer}
        </Drawer>
      }
    </Parent>
  );
};

export default CandidateLayout;
