import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    objectFit: "none",
    backgroundColor: "transparent",
  },
  parent: { objectFit: "none" },
  media: {
    borderRadius: "none",
    objectFit: "contain",
  },  
}));

const RecruiterWelcome = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { handleJobPostOpen, user } = props;

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <div className={classes.parent}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            className={classes.root}
            onClick={() => {
              handleClick("/r/search");
            }}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image={
                  matchesSM
                    ? "/static/images/search-candidates-mobile-view.svg"
                    : "/static/images/new-search-candidates.svg"
                }
                title="Search Candidates"
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Search Candidates
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Search candidates by skills, category and expertise. Start
                  engaging with candidates.
                </Typography>
              </CardContent> */}
            </CardActionArea>
            {/* <CardActions>
              <RouterLink to="/r/search">
                <Button color="primary">
                  Continue
                  <PlayArrowIcon color="primary"></PlayArrowIcon>
                </Button>
              </RouterLink>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.root} onClick={handleJobPostOpen}>
            <CardActionArea>
              <CardMedia
                component="img"
                className={classes.media}
                image={
                  matchesSM
                    ? "/static/images/post-job-mobile-view.svg"
                    : "/static/images/new-post-gig.svg"
                }
                title="Post Gig"
              />
              {/* <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Post Gig
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Specify work mode, preferences, skills, budget and more.
                </Typography>
              </CardContent> */}
            </CardActionArea>
            {/* <CardActions>
              <Button size="small" color="primary">
                Continue
                <PlayArrowIcon color="primary"></PlayArrowIcon>
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
};

export default RecruiterWelcome;
