import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
} from "@mui/material";
import LTChip from "src/designsystem/Derivative/LTChip";

import makeStyles from "@mui/styles/makeStyles";
import getInitials from "src/utils/getInitials";
import JobStepper from "src/components/job-steps/JobStepper";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dialogPaper: {
    minHeight: "90vh",
  },
}));
function getTimeCommitment(timePref) {
  if (timePref === "Full-Time") {
    return "Full-time";
  } else if (timePref === "Flexible") {
    return "Flexible";
  } else {
    return "Part-time";
  }
}

function getLocation(location) {
  if (location === "Remote") {
    return "Remote";
  } else if (location === "Onsite") {
    return "Onsite";
  } else {
    return "Hybrid";
  }
}
const Results = ({ className, candidates, fixedWidth, ...rest }) => {
  const classes = useStyles();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const handleSelectAll = (event) => {
    let newSelectedCandidateIds;

    if (event.target.checked) {
      newSelectedCandidateIds = candidates.map((candidate) => candidate.id);
    } else {
      newSelectedCandidateIds = [];
    }

    setSelectedCandidateIds(newSelectedCandidateIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCandidateIds.indexOf(id);
    let newSelectedCandidateIds = [];

    if (selectedIndex === -1) {
      newSelectedCandidateIds = newSelectedCandidateIds.concat(
        selectedCandidateIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCandidateIds = newSelectedCandidateIds.concat(
        selectedCandidateIds.slice(1)
      );
    } else if (selectedIndex === newSelectedCandidateIds.length - 1) {
      newSelectedCandidateIds = newSelectedCandidateIds.concat(
        selectedCandidateIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCandidateIds = newSelectedCandidateIds.concat(
        selectedCandidateIds.slice(0, selectedIndex),
        selectedCandidateIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCandidateIds(newSelectedCandidateIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Candidates" />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Expertise</TableCell>
                <TableCell>Work Preference</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates
                .slice(page * limit, page * limit + limit)
                .map((candidate) => {
                  const candidateDetailLink =
                    "/r/candidate/" + candidate.userCode;
                  return (
                    <TableRow
                      hover
                      key={candidate.id}
                      selected={
                        selectedCandidateIds.indexOf(candidate.id) !== -1
                      }
                    >
                      <TableCell>
                        <RouterLink to={candidateDetailLink}>
                          <Box alignItems="center" display="flex">
                            <Typography color="primary" variant="body1">
                              <Link>
                                {candidate.userProfile.firstName +
                                  " " +
                                  candidate.userProfile.lastName}
                              </Link>
                            </Typography>
                          </Box>
                        </RouterLink>
                      </TableCell>
                      <TableCell>{candidate.jobCategory}</TableCell>
                      <TableCell>{candidate.jobSubCategory?.join(",")}</TableCell>
                      <TableCell>
                        <LTChip
                          timePref={getTimeCommitment(
                            candidate.availabilityPref
                          )}
                          location={getLocation(candidate.gigProjectType)}
                          fixedWidth={true}
                        />
                      </TableCell>
                      <TableCell>
                        {candidate.city ? candidate?.city : " _ "}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={candidates.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  //jobs: PropTypes.array.isRequired
};

export default Results;
