import React, { FC, ReactElement } from "react";
import styled from '@emotion/styled'
import { colors, typography } from "../../theme";


const GvChip = styled.div<ChipProps>`
display: inline-block;
background-color: ${(props) => props.hue};
border: 1px solid ${colors.nv80};
border-radius: 3px;
user-select: none;
padding: 0.3em 1.2em;
color: ${colors.text};
font-family: Inter, sans-serif;
font-size: ${typography['body2'].size};
line-height: 1.35em;
display: grid;
place-items: center;
`

interface ChipProps {
  hue?: string;
  children: string | ReactElement[]
  className?: string
}

const Chip: FC<ChipProps> = (props) => {

  return (
    <GvChip {...props} />
  )
}

export default Chip