import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "src/designsystem/theme";
import Typography from "src/designsystem/Core/Typography";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import { ContractDetailResponse } from "src/views/recruiter/RecruiterContract/state/models";
import moment from "moment";
import Avatar from "src/designsystem/Core/Avatar";
const ContractCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 250px;
  }
`;
const GvGigLeft = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 90px 1fr;
  padding: 20px;
  border-right: none;
  border-bottom: 1px solid ${colors.nv80};
  @media (min-width: ${breakpoints["lg"]}) {
    border-right: 1px solid ${colors.nv80};
    border-bottom: none;
  }
`;
const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 110px 1fr;
`;

const GvGigRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 15px 15px 5px 15px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 200px;
    gap: 5px;
    padding: 15px 10px 15px 15px;
  }
`;

const CSTopView = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 70px 1fr;
  background-color: ${colors.white};
  border-right: none;
  @media (min-width: ${breakpoints["lg"]}) {
  }
`;

const CSView = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  font-family: Inter, sans-serif;
  border-radius: 8px;
  padding: 10px;
  @media (min-width: ${breakpoints["lg"]}) {
    padding: 10px;
  }
`;

const Header = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: ${colors.text};
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 0.8rem;
  color: gray;
`;

const SubTitle = styled(Typography)`
  font-weight: 650;
  font-size: 0.8rem;
  color: ${colors.text};
`;

const Descriptive = styled(Typography)`
  font-weight: 300;
  font-size: 0.8rem;
  color: gray;
`;
const Years = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 8px;
`;

interface ContractListrops {
  data: ContractDetailResponse;
}

const ContractListItem: React.FC<ContractListrops> = ({ data }) => {
  return (
    <ContractCard>
      <Header variant="label">
      <GvGigLeft>
        <GvGigDetails>
        
          <div style={{ marginRight: "20px" }}>
           {data.contract.title}
            <div style={{ height: "3px" }} />
            <SubTitle variant="body2">
              {" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: data.contract.description,
                }}
              ></div>
            </SubTitle>
          </div>
          
        </GvGigDetails>
        <CSTopView>
          <Avatar
            src={data.userProfileResponse.profilePicUrl}
            alt="Certificate"
            size="60px"
          />
          <div>
            {`${data.userProfileResponse.firstName} ${data.userProfileResponse.lastName}`}
            <div style={{ height: "3px" }} />
            <SubTitle variant="body2">{"TestTitle"}</SubTitle>
          </div>
        </CSTopView>
      </GvGigLeft>
      </Header>
      <GvGigRight>
        <div style={{ minWidth: "250px" }}>
          <Header variant="label">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarDot
                theme="outline"
                size="20"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              {data.contract.startDate
                ? moment(data.contract.startDate).format("DD/MM/YYYY")
                : moment(new Date()).format("DD/MM/YYYY")}
              {" - "}
              {data.contract.endDate
                ? moment(data.contract.endDate).format("DD/MM/YYYY")
                : "Specify the data"}{" "}
            </div>
          </Header>
          <div style={{ height: "3px" }} />
          <Typography variant="h5">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <AddSubtract
                theme="outline"
                size="20"
                strokeWidth={3}
                style={{ display: "grid", placeItems: "center" }}
              />
              <SubTitle variant="label">{"₹" + 6000}</SubTitle>
            </div>
          </Typography>
        </div>
      </GvGigRight>
    </ContractCard>
  );
};

export default ContractListItem;
