import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import axios from "axios";
import "date-fns";
import { DatePicker } from "@mui/lab";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import {
  INDUSTRY_LIST,
  UPDATE_CANDIDATE_EXPERIENCE_URL,
} from "src/utils/constants";

import UserContext from "src/context/user-context";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-marginNormal": {
      "margin-top": "0px",
      "margin-bottom": "0px",
    },
  },
}));

const initialValues = {
  workExperience: [
    {
      industry: {
        value: "",
        label: "",
      },
      companyName: "",
      startDate: null,
      endDate: null,
      role: "",
      description: "",
      isCurrentJob: false,
    },
  ],
};

const validationSchema = Yup.object().shape({
  workExperience: Yup.array().of(
    Yup.object().shape({
      companyName: Yup.string().max(255).required("Company Name is required"),
      role: Yup.string().max(255).required("Please enter you Role"),
      startDate: Yup.date().required("Start Date is required"),
    })
  ),
});

const WorkExperienceForm = ({ className, handleNext, handleBack, ...rest }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        var exList = [];
        values.workExperience.forEach((val, i) => {
          exList.push({
            companyName: val.companyName,
            industry: val.industry.value,
            projectDescription: val.description,
            position: val.role,
            startDate: val.startDate,
            endDate: val.endDate,
          });
        });

        axios({
          method: "POST",
          url:
            UPDATE_CANDIDATE_EXPERIENCE_URL +
            "?userCode=" +
            userContext.user.get.userCode,
          data: exList,
        })
          .then((response) => {
            console.log("THIS WAS SUCCESSFUL AS WELL");
            handleNext();
          })
          .catch((error) => {
            actions.setSubmitting(false);
            //handleServerResponse(false, error.response.data.error);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardHeader subheader="Please enter Organization Experience or Key Projects" />
            <CardContent>
              <FieldArray
                name="workExperience"
                render={(arrayHelpers) => (
                  <>
                    {values.workExperience.map((w, index) => (
                      <>
                        <Divider />
                        <CardContent>
                          <Grid key={index} container spacing={2}>
                            <Grid item md={4} sm={12} xs={12}>
                              <Autocomplete
                                freeSolo
                                id="industry"
                                disableClearable
                                value={w.industry}
                                options={INDUSTRY_LIST}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, val) => {
                                  console.log(val);
                                  values.workExperience[index].industry = val;
                                  setValues(values);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Industry"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                    size="small"
                                  />
                                )}
                                style={{ "margin-top": "0px" }}
                              />
                            </Grid>
                            <Grid item md={8} sm={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Company Name"
                                name={`workExperience.${index}.companyName`}
                                onChange={handleChange}
                                required
                                value={w.companyName}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                              <DatePicker
                                inputFormat="dd/MM/yyyy"
                                margin="normal"
                                id="start-date"
                                renderInput={(props) => (
                                  <TextField {...props} label="From" />
                                )}
                                required
                                value={w.startDate}
                                onChange={(date) => {
                                  values.workExperience[index].startDate = date;
                                  setValues(values);
                                }}
                                name={`workExperience.${index}.startDate`}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                size="small"
                                fullWidth
                                disableFuture
                              />
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                              {!w.isCurrentJob ? (
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  margin="normal"
                                  id="end-date"
                                  renderInput={(props) => (
                                    <TextField {...props} label="To" />
                                  )}
                                  value={w.endDate}
                                  onChange={(date) => {
                                    values.workExperience[index].endDate = date;
                                    setValues(values);
                                  }}
                                  name={`workExperience.${index}.endDate`}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  size="small"
                                  fullWidth
                                  disableFuture
                                />
                              ) : (
                                <DatePicker
                                  inputFormat="dd/MM/yyyy"
                                  margin="normal"
                                  id="end-date-disabled"
                                  renderInput={(props) => (
                                    <TextField {...props} label="To" />
                                  )}
                                  value={null}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  size="small"
                                  fullWidth
                                  disabled
                                  disableFuture
                                />
                              )}
                            </Grid>

                            <Grid item md={4} sm={12} xs={12}>
                              <FormControlLabel
                                label="I currently work here"
                                labelPlacement="end"
                                control={
                                  <Switch
                                    onChange={(e) => {
                                      values.workExperience[
                                        index
                                      ].isCurrentJob = e.target.checked;
                                      if (e.target.checked) {
                                        values.workExperience[index].endDate =
                                          null;
                                      }
                                      setValues(values);
                                    }}
                                    name={`workExperience.${index}.isCurrentJob`}
                                    color="primary"
                                    value={w.isCurrentJob}
                                  />
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Role"
                                onChange={handleChange}
                                name={`workExperience.${index}.role`}
                                required
                                value={w.role}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                fullWidth
                                label="Description"
                                name={`workExperience.${index}.description`}
                                onChange={handleChange}
                                value={w.description}
                                multiline
                                rows={4}
                                variant="outlined"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    ))}

                    <Grid container spacing={2}>
                      <Grid container justifyContent="flex-end">
                        <Button
                          variant="contained"
                          onClick={() =>
                            arrayHelpers.push({
                              industry: "",
                              companyName: "",
                              startDate: null,
                              endDate: null,
                              role: "",
                              description: "",
                              isCurrentJob: false,
                            })
                          }
                          size="small"
                        >
                          Add More
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </CardContent>
            <CardContent>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      handleBack();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit">
                    Next
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

WorkExperienceForm.propTypes = {
  className: PropTypes.string,
};

export default WorkExperienceForm;
