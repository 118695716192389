import React, { FC } from "react";
import styled from "@emotion/styled";

import { ButtonProps } from "../Button";


const Dice = styled.div<FrameProps>`
  width: 50px;
  height: 50px;
  margin: auto;
  background: ${(props) => `hsl(${props.hue}, 40%, 50%)`};
  border-radius: 8px;
`;
const Vector = styled.div`
  display: grid;
  width: 24px;
  height: 24px;
  grid-template: 1fr 1fr 1fr/ 1fr 1fr 1fr;
  gap: 3px;
  padding: 15px 0;
  margin: 0 auto;
`;

const Vector1 = styled.div`
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 30px;
`;

interface FrameProps extends ButtonProps {
  hue: number;
  onClick?: () => void;
}

const Frame: FC<FrameProps> = (props) => {
  return (
    <div style={{ marginTop: "50px" }} onClick={props.onClick}>
      <Dice {...props}>
        <Vector>
          {[...Array(9)].map(() => (
            <Vector1 />
          ))}
        </Vector>
      </Dice>
    </div>
  );
};

export default Frame;
