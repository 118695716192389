import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useState, useContext, useEffect } from "react";
import { useRoutes ,useNavigate,createSearchParams,useSearchParams} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "src/components/GlobalStyles";
import theme from "src/theme";
import routes from "src/routes";
import UserContext from "src/context/user-context";
import { MessageProvider } from "./context/message-context";
import { NotificationProvider } from "./context/notification-context";
import { initializeGA } from "./utils/analytics";
import { store } from "src/redux/store";
import { Provider } from "react-redux";
import AuthHandler from "./components/auth/AuthHandler";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {GA_MEASUREMENT_ID, GA_MEASUREMENT_ID_GOOGLE_ADS, GA_GTM, FB_PIXEL_ID} from 'src/utils/constants';
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie/es6";
import TokenService from "src/services/TokenService";
import ReactPixel from 'react-facebook-pixel';

const App = () => {
  var isLoggedIn = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const isAuthenticated = TokenService.getCookiesAccessToken()? true : false
  //GetUser API Call if AccessToken not exist
  
  const userObj = TokenService.getUser();
  const isCandidate = isAuthenticated &&userObj && userObj.isCandidate? true : false
  const routing = useRoutes(routes(isAuthenticated,isCandidate));
  const [user, setUser] = useState(isAuthenticated ? userObj : {});


  const userStore = {
    user: { get: user, set: setUser },
  };

  // initialize FB pixel
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FB_PIXEL_ID, {}, options);
  ReactPixel.pageView();


  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;

    document.body.appendChild(script);
    initializeGA(GA_MEASUREMENT_ID, GA_MEASUREMENT_ID_GOOGLE_ADS, GA_GTM, user.userCode);
    return () => {
      document.body.removeChild(script);
    };

  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserContext.Provider value={userStore}>    
                <AuthHandler>
                  <MessageProvider>
                    <NotificationProvider>
                      <GlobalStyles />
                      {routing}
                    </NotificationProvider>
                  </MessageProvider>
                </AuthHandler>
            </UserContext.Provider>
          </ThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
      </style>

      <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=3652220&conversionId=6020652&fmt=gif" />
    
        {/** Hotjar Tracking Code for https://gigvistas.com  */}

        <script dangerouslySetInnerHTML={{
                __html: `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:3414427,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `,
              }}
          />  
    </>
  );
};

export default App;