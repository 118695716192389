import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import DateFnsUtils from "@date-io/date-fns";
import { useFormik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/lab";
import axios from "axios";
import moment from "moment";
import {
  POST_PROFILE_WORKEX_URL,
  DELETE_PROFILE_WORKEX_URL,
} from "src/utils/constants";
import { INDUSTRY_LIST } from "src/utils/constants";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px 0",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
  currentJob: {
    fontWeight: 400,
    fontSize: "8px",
  },
}));

const WorkExEditDialog = ({ open, setOpen, info, user, refetch, addFlag }) => {
  // console.log(user, "user id");
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [initVal, setInitVal] = useState({});
  const [currentJob, setCurrentJob] = useState(false);
  useEffect(() => {
    if (addFlag) {
      setInitVal({
        companyName: "",
        industry: "",
        // projectTitle: "",
        projectDescription: "",
        position: "",
        isCurrentJob: false,
        startDate: "",
        endDate: "",
      });
    } else {
      setInitVal({
        companyName: info.companyName,
        industry: info.industry,
        // projectTitle: "",
        projectDescription: info.projectDescription,
        isCurrentJob: info.endDate == null ? true : false,
        position: info.position,
        startDate: info.startDate,
        endDate: info.endDate,
      });
    }
  }, [info]);
  const deleteWorkEx = () => {
    axios
      .delete(
        DELETE_PROFILE_WORKEX_URL + info.id + "/?userCode=" + user.get.userCode
      )
      .then(function (response) {
        console.log(response);
        refetch();
        setOpen(false);
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = yup.object({
    companyName: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Company Name is required"),
    industry: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Industry is required"),
    projectDescription: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(1000, "Must be less than 1000 characters")
      .required("Description is required"),
    position: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Position is required"),
    isCurrentJob: yup.boolean(),
    startDate: yup
      .date()
      .typeError("Start date can't be empty")
      .required("Start date is required"),
    endDate: yup
      .date()
      .typeError("End date is required")
      // .required("End date is required")
      .nullable()
      .when(
        "startDate",
        (startDate, schema) =>
          startDate &&
          schema.min(startDate, "End date can't be before Start Date")
      ),
    // .when("isCurrentJob", {
    //   is: currentJob,
    //   then: yup.date().required("End date is required"),
    //   otherwise: yup.date().notRequired(),
    // }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initVal,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (addFlag) {
        console.log("Inside OnSubmit");
        const valuesWebService = {
          companyName: values.companyName,
          industry: values.industry,
          // projectTitle: "",
          projectDescription: values.projectDescription,
          position: values.position,
          // startDate: moment(values.startDate).format("DD-MM-YYY"),
          // endDate: moment(values.endDate).format("DD-MM-YYYY"),
          startDate: values.startDate,
          endDate: values.endDate,
        };
        axios
          .post(POST_PROFILE_WORKEX_URL, valuesWebService, {
            params: { userCode: user.get.userCode },
          })
          .then(function (response) {
            console.log(response);
            refetch();
            setOpen(false);
          })
          .catch((err) => console.log(err));
      } else {
        const valuesWebService = {
          id: info.id,
          companyName: values.companyName,
          industry: values.industry,
          // projectTitle: "",
          projectDescription: values.projectDescription,
          position: values.position,
          // startDate: moment(values.startDate).format("DD-MM-YYYY"),
          // endDate: moment(values.endDate).format("DD-MM-YYYY"),
          startDate: values.startDate,
          endDate: values.endDate,
        };
        axios
          .put(POST_PROFILE_WORKEX_URL, valuesWebService, {
            params: { userCode: user.get.userCode },
          })
          .then(function (response) {
            console.log(response);
            refetch();
            setOpen(false);
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        {addFlag ? (
          <Typography variant="h6">Add New Work Experience</Typography>
        ) : (
          <Typography variant="h6">Edit Work Experience</Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item sm={6} md={12} xs={12}>
              <TextField
                id="companyName"
                label="Company Name*"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <Autocomplete
                id="industry"
                disableClearable
                value={formik.values.industry}
                options={INDUSTRY_LIST.map((industry) => industry["label"])}
                onChange={(e, val) => {
                  formik.setFieldValue("industry", val);
                }}
                renderInput={(params) => (
                  <TextField
                    label="Industry*"
                    {...params}
                    error={
                      formik.touched.industry && Boolean(formik.errors.industry)
                    }
                    helperText={
                      formik.touched.industry && formik.errors.industry
                    }
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    className={classes.field}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="position"
                label="Position*"
                value={formik.values.position}
                onChange={formik.handleChange}
                error={
                  formik.touched.position && Boolean(formik.errors.position)
                }
                helperText={formik.touched.position && formik.errors.position}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12} lg={12}>
              <TextField
                id="projectDescription"
                label="Description*"
                minRows={3}
                maxRows={6}
                multiline
                value={formik.values.projectDescription}
                onChange={formik.handleChange}
                error={
                  formik.touched.projectDescription &&
                  Boolean(formik.errors.projectDescription)
                }
                helperText={
                  formik.touched.projectDescription &&
                  formik.errors.projectDescription
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                inputVariant="outlined"
                inputFormat="MM/yyyy"
                clearable
                views={["month", "year"]}
                margin="normal"
                id="startDate"
                name="startDate"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Start Date*"
                    className={classes.field}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                )}
                value={formik.values.startDate}
                onChange={(date) => {
                  if (date === null) {
                    formik.setFieldValue("startDate", "");
                    return;
                  }
                  formik.setFieldValue("startDate", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.field}
              />
            </Grid>
            <Grid item sm={6} md={6} xs={12}>
              {!formik.values.isCurrentJob ? (
                <DatePicker
                  disabled={!formik.values.startDate}
                  inputVariant="outlined"
                  inputFormat="MM/yyyy"
                  views={["month", "year"]}
                  margin="normal"
                  id="endDate"
                  name="endDate"
                  clearable
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="End Date*"
                      className={classes.field}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      helperText={
                        formik.touched.endDate && formik.errors.endDate
                      }
                    />
                  )}
                  // minDate={formik.values.startDate}
                  // disableFuture
                  value={formik.values.endDate}
                  onChange={(date) => {
                    if (date === null) {
                      formik.setFieldValue("endDate", "");
                      return;
                    }
                    formik.setFieldValue("endDate", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              ) : (
                <DatePicker
                  inputVariant="outlined"
                  inputFormat="MM/yyyy"
                  views={["month", "year"]}
                  margin="normal"
                  name="endDate"
                  clearable
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="End Date*"
                      className={classes.field}
                    />
                  )}
                  id="end-date-disabled"
                  value={null}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  size="small"
                  fullWidth
                  disabled
                  disableFuture
                />
              )}
              <Grid item sm={6} md={6} xs={12}>
                <FormControlLabel
                  style={{
                    display: "flex",
                    // alignContent: "space-around",
                    justifyContent: "flex-end",
                    margin: "auto",
                  }}
                  // className={classes.currentJob}
                  label="I currently work here"
                  labelPlacement="end"
                  fullWidth
                  // className={classes.field}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        formik.setFieldValue("isCurrentJob", e.target.checked);
                        // setCurrentJob(e.target.checked);
                        if (e.target.checked) {
                          formik.setFieldValue("endDate", null);
                        } else {
                          formik.setFieldValue("endDate", new Date());
                        }

                        console.log("endDate", formik.values.endDate);
                        // setValues(formik.values);
                      }}
                      name={`formik.values.isCurrentJob`}
                      color="primary"
                      checked={formik.values.isCurrentJob}
                      value={formik.values.isCurrentJob}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!addFlag && (
            <Button style={{ color: "#FF4A4A" }} onClick={() => deleteWorkEx()}>
              Delete
            </Button>
          )}
          <Button color="primary" type="submit">
            {addFlag ? "Add Work Experience" : "Save changes"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WorkExEditDialog;
