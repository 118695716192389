import React, { useEffect, useState, useContext } from "react";

import {
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import backgroundPattern from "src/assets/rectangle.png";

import UserContext from "src/context/user-context";
import axios from "axios";

import { GET_RECRUITER_PROFILE_SETUP_STATE } from "src/utils/constants";

const setupSteps = [
  {
    key: "POST_GIG",
    label: (
      <Link to="/r/jobs" style={{ color: "#FBFDFF" }}>
        Post New Gig
      </Link>
    ),
    completed: false,
  },
  {
    key: "BOOKMARK_CANDIDATE",
    label: (
      <Link to="/r/search" style={{ color: "#FBFDFF" }}>
        Shortlist Candidates
      </Link>
    ),
    completed: false,
  },
  {
    key: "OFFER_CANDIDATE",
    label: (
      <Link to="/r/candidates" style={{ color: "#FBFDFF" }}>
        Initiate Gig
      </Link>
    ),
    completed: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: 255,
    opacity: 0.83,
    backgroundColor: "#0071bd",
    backgroundImage: `url(${backgroundPattern})`,
    backgroundPosition: "center",
    color: "#fbfdff",
    borderRadius: 8,
    paddingTop: 100,
    margin: "0px 25px 10px 25px",
  },
  title: {
    color: "#fbfdff",
  },
  setupSteps: {
    fontSize: 14,
    fontWeight: 500,
  },
  step: {
    height: 40,
    padding: 0,
  },
  stepIcon: {
    minWidth: 30,
  },
  completedStep: {
    color: "#28d497",
  },
  incompleteStep: {
    color: "#ffffff",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
  },
  actionButton: {
    color: "#fbfdff",
    borderRadius: 8,
    fontSize: 14,
    backgroundColor: "rgba(255, 255, 255, 0.49)",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#28d497",
  },
}))(LinearProgress);

const updateSetUpSteps = (stateResponse, setProgress, setProgressBarValue) => {
  let newSteps = [];
  let completedSteps = 0;
  setupSteps.forEach(function (step) {
    let stepNew = step;
    stepNew.completed = stateResponse[step.key];
    newSteps.push(stepNew);
    completedSteps += stepNew.completed ? 1 : 0;
  });
  setProgress((oldSteps) => {
    return newSteps;
  });

  let progBarValue = (100 / (setupSteps.length / completedSteps)).toFixed(2);

  setProgressBarValue((oldVal) => {
    return progBarValue;
  });
};

const SetupStatusCardRecruiter = (props) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(setupSteps);
  const userContext = useContext(UserContext);
  const [progressBarValue, setProgressBarValue] = useState(0);

  useEffect(() => {
    axios({
      method: "GET",
      url:
        GET_RECRUITER_PROFILE_SETUP_STATE +
        "?userCode=" +
        userContext.user.get.userCode,
    })
      .then((response) => {
        updateSetUpSteps(response.data, setProgress, setProgressBarValue);
      })
      .catch((error) => {
        //handleServerResponse(false, error.response.data.error);
      });
  }, [userContext]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <BorderLinearProgress
          variant="determinate"
          value={parseInt(progressBarValue)}
        />

        <List component="nav" className={classes.setupSteps}>
          {progress.map((step) => {
            return (
              <ListItem className={classes.step} key={step.label}>
                <ListItemIcon className={classes.stepIcon}>
                  {step.completed ? (
                    <CheckCircleIcon
                      color="primary"
                      className={classes.completedStep}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      color="primary"
                      className={classes.incompleteStep}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={step.label} />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default SetupStatusCardRecruiter;
