import React from "react";
import { SvgIcon } from "@mui/material";

export const RewardActiveIcon = (props) => {
  return (
    <SvgIcon
      width="21"
      height="31"
      viewBox="0 0 21 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.352966 0H20.3104V1.81432H0.352966V0Z" fill="#0071BD" />
      <path
        d="M18.4961 3.62842H14.8675V8.33929L18.4961 6.97855V3.62842Z"
        fill="#0071BD"
      />
      <path
        d="M5.79593 3.62842H2.1673V6.97855L5.79593 8.33929V3.62842Z"
        fill="#0071BD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2389 9.70003V12.7452C15.8166 13.2018 19.4033 17.0756 19.4033 21.7716C19.4033 26.7736 15.3338 30.8432 10.3317 30.8432C5.32958 30.8432 1.26013 26.7736 1.26013 21.7716C1.26013 17.0756 4.84679 13.2018 9.42455 12.7452V9.70003L7.61024 9.01966V3.62842H13.0532V9.01966L11.2389 9.70003ZM10.3317 24.6755L13.6947 26.4435L13.0525 22.6988L15.7733 20.0466L12.0132 19.5003L10.3317 16.0931L8.6502 19.5003L4.89022 20.0466L7.61096 22.6988L6.9687 26.4435L10.3317 24.6755Z"
        fill="#0071BD"
      />
      <path
        d="M10.8084 21.1587L10.3317 20.1929L9.85497 21.1587L8.78912 21.3136L9.56039 22.0654L9.37829 23.127L10.3317 22.6259L11.2851 23.127L11.103 22.0654L11.8744 21.3136L10.8084 21.1587Z"
        fill="#0071BD"
      />
    </SvgIcon>
  );
};

export const MortarBoardIcon = (props) => {
  return (
    <SvgIcon
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4722 4.55405L13.7632 0.0699902C13.5929 0.00987304 13.4071 0.00987304 13.2369 0.0699902L0.527872 4.55405C0.211887 4.66553 0.000422507 4.964 6.32128e-07 5.29908C-0.000421243 5.63415 0.210358 5.9331 0.526079 6.04532L13.2351 10.5634C13.3208 10.5939 13.4104 10.6092 13.5 10.6092C13.5896 10.6092 13.6792 10.5939 13.7649 10.5634L26.4739 6.04532C26.7896 5.9331 27.0004 5.63415 27 5.29908C26.9996 4.964 26.7881 4.66553 26.4722 4.55405Z"
        fill="#8598A8"
      />
      <path
        d="M25.1002 15.1122V8.2124L23.5181 8.77481V15.1122C23.0416 15.3864 22.7205 15.9003 22.7205 16.4896C22.7205 17.0789 23.0416 17.5928 23.5181 17.8671V21.7545C23.5181 22.1913 23.8723 22.5455 24.3091 22.5455C24.746 22.5455 25.1002 22.1913 25.1002 21.7545V17.8672C25.5767 17.593 25.8978 17.0789 25.8978 16.4897C25.8978 15.9004 25.5767 15.3864 25.1002 15.1122Z"
        fill="#8598A8"
      />
      <path
        d="M13.5 12.1907C13.2285 12.1907 12.9611 12.1446 12.7052 12.0536L5.56055 9.51367V12.9946C5.56055 13.8462 6.44896 14.5664 8.20112 15.1351C9.72836 15.6308 11.6102 15.9038 13.5 15.9038C15.3898 15.9038 17.2716 15.6308 18.7989 15.1351C20.5511 14.5664 21.4395 13.8462 21.4395 12.9946V9.51367L14.2949 12.0536C14.039 12.1446 13.7716 12.1907 13.5 12.1907Z"
        fill="#8598A8"
      />
    </SvgIcon>
  );
};
