import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PerfectScrollbar from "react-perfect-scrollbar";
import NoContact from "src/components/messaging/ChatWidget/Contacts/NoContact";

import ContactsListItem from "./ContactsListItem";

const useStyles = makeStyles({
  contactsList: {
    background: "#fff",
    borderRadius: 6,
    height: 400,
  },
  noContacts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
  },
});

const ContactsList = (props) => {
  const classes = useStyles();
  const { contacts, onContactSelection, me } = props;

  return (
    <div className={classes.contactsList}>
      <PerfectScrollbar>
        {contacts.length === 0 && (
          <div className={classes.noContacts}>
            <NoContact />
          </div>
        )}
        <div>
          {contacts.map((contact) => (
            <ContactsListItem
              key={contact.id}
              contact={contact}
              onContactSelection={onContactSelection}
            />
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ContactsList;
