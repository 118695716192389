import React, { useContext, useEffect,useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import DatePicker from "@mui/lab/DatePicker";

import CloseIcon from "@mui/icons-material/Close";
import {
  CONTRACT_SAVE_MILESTONE_URL,
  PROJECT_URL,
  CurrencyFormat,
} from "src/utils/constants";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { CurrentNotificationContext } from "src/context/notification-context";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { CONTRACT_MILESTONE_URL } from "src/utils/constants";
const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));



const AddMilestoneDialog = ({
  id,
  open,
  setOpen,
  refetch,
  addFlag,
  info,
}) => {
  let initialValues;
  if (addFlag) {
   initialValues = {
    contractId:"",
    name: "",
    description: "",
    eta: "",
    fees: "",
    currencyCode: "INR",
  };
  }
  else {
    initialValues = {
      name: info.name,
      description: info.description,
      eta: info.eta,
      fees: info.fees,
      currencyCode: info.currencyCode,
    };
  }

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentNotification = useContext(CurrentNotificationContext);
  const [initVal, setInitVal] = useState(initialValues);
  const contractId= initVal.contractId;

 

  const deleteMileStone = () => {

    axios({
      method: "DELETE",
      url: CONTRACT_MILESTONE_URL,
      params: {
        contractId: contractId,
        milestoneId: info.milestoneId
      },
    })
      .then((response) => {
       refetch();
      })
      .catch((error) => {
        console.log("WE DIDN'T GET THE MileStone");
      });
  };

  const validationSchema = yup.object({
    name: yup.string().max(255).required("Name is required"),
    description: yup.string().max(255).required("Description is required"),
    fees: yup.number("Must be a number").positive("Must be positive").max(255).required("Fees is required"),
    eta: yup.date().required("Eta date is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var milestoneData = {
        name: values.name,
        description: values.description,
        fees: values.fees,
        currencyCode: values.currencyCode,
        eta: values.eta,
      };

      if (addFlag) {
        axios
          .post(
            `${CONTRACT_SAVE_MILESTONE_URL}?contractId=${contractId}`,
            milestoneData
          )
          .then((res) => {
       
            setOpen(false);
            formik.resetForm();
            refetch();
          })
          .catch((err) => {
       
            currentNotification.addContent({
              title: "Failed",
              description: err.response.data,
            });
          });
      }
      else {
        axios
        .put(
          `${CONTRACT_SAVE_MILESTONE_URL}?contractId=${contractId}&milestoneId=${info.milestoneId}`,
          milestoneData
        )
        .then((res) => {
     
          setOpen(false);
          refetch();
        })
        .catch((err) => {
     
          currentNotification.addContent({
            title: "Failed",
            description: err.response.data,
          });
        });;
      }
    },
  });

    
  const state = useSelector(state=>state.contractStepperinfo)


  useEffect(() => {
    if (state && state.resource ) {
      setInitVal(
        {
          contractId:state.resource.contractId,
        
        }
      )
    }
  }, ["resource" in state]);

  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">
          {addFlag ? "Add Milestone" : "Update Milestone"}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="Name*"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="description"
                label="Description*"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                id="fees"
                label="Fees*"
                type="number"
                value={formik.values.fees}
                onChange={formik.handleChange}
                error={formik.touched.fees && Boolean(formik.errors.fees)}
                helperText={formik.touched.fees && formik.errors.fees}
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <TextField
                label="Currency*"
                className={classes.field}
                fullwidth
                variant="outlined"
                name="currencyCode"
                size="medium"
                onChange={(e) => {
                  e.preventDefault();
                  formik.handleChange(e);
                }}
                select
                SelectProps={{ native: true }}
                value={formik.values.currencyCode}
              >
                {CurrencyFormat.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={12} md={12} xs={12}>
              <DatePicker
                clearable
                inputVariant="outlined"
                margin="normal"
                id="eta"
                name="eta"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Eta*"
                    className={classes.field}
                    error={formik.touched.eta && Boolean(formik.errors.eta)}
                    helperText={formik.touched.eta && formik.errors.eta}
                  />
                )}
                value={formik.values.eta}
                onChange={(date) => {
                  if (date === null) {
                    formik.setFieldValue("eta", "");
                    return;
                  }
                  formik.setFieldValue("eta", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.field}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {info === null || addFlag == true ? null : (
            <Button
              style={{ color: "#FF4A4A" }}
              onClick={() => deleteMileStone()}
            >
              Delete
            </Button>
          )}
          <Button color="primary" type="submit">
            {addFlag ? "Add" : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddMilestoneDialog;
