import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../designsystem/theme";
import Avatar from "src/designsystem/Core/Avatar";
import Typography from "src/designsystem/Core/Typography";
import WorkIcon from "src/assets/newProfilePlaceholderIcons/workExpIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

import UserContext from "src/context/user-context";
import { CandidateExperience } from "src/views/candidate/CandidateProfile/state/models";
import WorkExEditDialog from "src/views/candidate/CandidateProfile/EditDialogs/WorkExEditDialog";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { getCandidateProfileAction } from "src/views/candidate/CandidateProfile/state/reducer";

const WorkCard = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 0.7fr;
  }
`;
const GvGigLeft = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: 90px 1fr;
  padding: 20px;
  border-right: none;
  border-bottom: 1px solid ${colors.nv80};
  @media (min-width: ${breakpoints["lg"]}) {
    border-right: 1px solid ${colors.nv80};
    border-bottom: none;
  }
`;
const GvGigRight = styled.div`
  display: grid;
  gap: 15px;
  padding: 15px 15px 5px 15px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr 200px;
    gap: 5px;
    padding: 15px 10px 15px 15px;
  }
`;

const Descriptive = styled(Typography)`
  font-weight: 300;
  font-size: 0.8rem;
  color: gray;
`;
const Years = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 8px;
`;
const Subtitle = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 500;
  color: #8d9199;
`;
interface WorkProps {
  data: CandidateExperience;
  editable?: boolean;
}

const WorkDetail: React.FC<WorkProps> = ({ data, editable }) => {
  const [open, setOpen] = useState(false);
  const userContext = useContext<any>(UserContext);
  const user: any = userContext.user;
  const startDate = new Date(data.startDate);
  const endDate = data.endDate != null ? new Date(data.endDate) : null;
  let diffTime =
    endDate == null
      ? Math.abs(new Date().valueOf() - startDate.valueOf())
      : Math.abs(endDate.valueOf() - startDate.valueOf());
  let diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
  const dispatch = useDispatch();
  // console.log(diffYears, "Years of experience");

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <WorkCard>
      <GvGigLeft>
        <Avatar src={WorkIcon} alt="Experience" size="60px" />
        <div>
          <Typography variant="body">{data.companyName}</Typography>
          <div style={{ height: "5px" }} />
          <Subtitle variant="label">{data.position}</Subtitle>
          <div style={{ height: "3px" }} />
          <Descriptive variant="body2">{data.projectDescription}</Descriptive>
        </div>
      </GvGigLeft>
      <GvGigRight>
        <div style={{ minWidth: "170px" }}>
          <Typography variant="body">
            {months[startDate.getMonth()]} {startDate.getFullYear()} -{" "}
            {endDate == null
              ? "Present"
              : months[endDate.getMonth()] + " " + endDate.getFullYear()}
          </Typography>
          <Years variant="label">{diffYears} years</Years>
        </div>
        {editable ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <WorkExEditDialog
              open={open}
              setOpen={setOpen}
              info={data}
              addFlag={false}
              user={user}
              refetch={() => {
                // window.location.reload();
                dispatch(
                  getCandidateProfileAction({ user_code: user.get.userCode })
                );
              }}
            />

            <IconButton
              aria-label="edit"
              onClick={() => {
                setOpen(true);
              }}
              size="small"
              style={{
                width: "30px",
                borderRadius: "100px",
              }}
            >
              <Tooltip title="Edit" arrow>
                <EditIcon />
              </Tooltip>
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </GvGigRight>
    </WorkCard>
  );
};

export default WorkDetail;
