import React, { FC,} from "react";
import { Dialog } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { breakpoints, colors } from "src/designsystem/theme";
import styled from "@emotion/styled";
import ContractStepper from "src/views/recruiter/RecruiterContract/ContractStepper";
import {
  AllApplication,
  CollectionFiles,
  Delivery,
  Close,
} from "@icon-park/react";

const GvDialog = styled(Dialog)`
  border: 1px solid ${colors.nv80};
  border-radius: 15px;
  font-family: Inter, sans-serif;
`;
const DialogLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: ${breakpoints["lg"]}) {
    grid-template-columns: 1fr ;
    height: 76vh;
   
  }

`;

const DialogContent = styled.div`
`;

const STEPS = [
  {
    name: "Summary",
    key: "expertise",
    active: true,
    completed: false,
    icon: AllApplication,
  },
  {
    name: "Deliverables",
    key: "experience",
    active: false,
    completed: false,
    icon: CollectionFiles,
  },
  {
    name: "Terms",
    key: "education",
    active: false,
    completed: false,
    icon: Delivery,
  },
];

interface RecruiterContracProps {
  open: any;
  setOpen: any;
  candidateUserCode: any;
  profileUrl: any;
  name: any;
  title: any;
}

const ContractDialog: FC<RecruiterContracProps> = ({
  open,
  setOpen,
  candidateUserCode,
  profileUrl,
  name,
  title,
}) => {
  const fullScreen = useMediaQuery(`(max-width:${breakpoints["lg"]})`);
  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") {
        return
    } else {
        setOpen(false)
    }
}
  return (
    <GvDialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <form autoComplete="off">
        <DialogLayout>  
        <DialogContent>  
            <ContractStepper
              cname={name}
              curl={profileUrl}
              ccode={candidateUserCode}
              ctitle={title}
              setOpen={setOpen}
            />
         </DialogContent>     
        </DialogLayout>
      </form>
    </GvDialog>
  );
};

export default ContractDialog;
