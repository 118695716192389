import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Hidden from "@mui/material/Hidden";

import CloseIcon from "@mui/icons-material/Close";
import { POST_PROFILE_FILE_URL } from "src/utils/constants";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { CurrentNotificationContext } from "src/context/notification-context";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
  discText: {
    color: "grey",
    fontStyle: "italic",
    fontSize: "0.75rem",
    marginLeft: "25px"
  },
}));

const PortfolioAddDialog = ({ open, setOpen, info, user, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentNotification = useContext(CurrentNotificationContext);
  const FILE_SIZE = 5000 * 1024;

  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setfileError] = useState(false);

  const validationSchema = yup.object({
    title: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(45, "Must be less than 45 character")
      .required("Title is required"),
    // fileName: yup
    //   .string("Must be a valid name")
    //   .required(
    //     "*A .docx/.doc or .pdf format file with max size of 5MB is required"
    //   ),
    file: yup
      .mixed()
      .required("*A .docx/.doc or .pdf format file with max size of 5MB is required")
      .test(
        "fileSize",
        "File is too large, should not exceed 5MB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test("fileType", "This file format is not supported ", (value) =>
        [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(value.type)
      )
      .required("A File is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      title: "",
      fileName: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("userCode", user.get.userCode);
      formData.append("title", values.title);
      formData.append("file", values.file);
      setIsUploading(true);
      axios
        .post(POST_PROFILE_FILE_URL, formData)
        .then((res) => {
          // currentNotification.addContent({
          //   title: "Success",
          //   description: "The file has been Uploaded successfully",
          // });
          // console.log(res.data);
          resetForm();
          refetch();
          setOpen(false);
          setIsUploading(true);
          setfileError(false);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          setIsUploading(false);
          setfileError(true);
        });
    },
  });

  let helperErrorText = !!fileError
    ? "Your file could not be uploaded "
    : (formik.touched.fileName && formik.errors.fileName) ||
      formik.errors.fileName ||
      formik.errors.file;

  return (
    <Dialog
      onBackdropClick="false"
      fullScreen={fullScreen}
      onClose={() => {
        formik.resetForm();
        setOpen(false);
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <div className={classes.title}>
        <Typography variant="h6">Upload Portfolio or Resume</Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            formik.resetForm();
            setOpen(false);
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="title"
                label="Title*"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="fileName"
                value={formik.values.fileName}
                onChange={formik.handleChange}
                error={
                  (formik.touched.fileName &&
                    Boolean(formik.errors.fileName)) ||
                  formik.errors.file ||
                  !!fileError
                }
                helperText={helperErrorText}
                variant="outlined"
                className={classes.field}
                disabled
                style={{
                  display: "flex",
                  alignContent: "space-between",
                  justifyContent: "flex-end",
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-end",
                        width: "200px",
                        borderRadius: "20px",
                      }}
                      component="label"
                    >
                      <Hidden smDown>Select File</Hidden>
                      <Hidden smUp>Select</Hidden>
                      <input
                        type="file"
                        hidden
                        style={{ display: "none" }}
                        accept=".doc, .docx,.pdf"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "file",
                            event.currentTarget.files[0]
                          );
                          if (event.currentTarget.files[0] != null) {
                            formik.setFieldValue(
                              "fileName",
                              event.currentTarget.files[0].name
                            );
                          }
                        }}
                      />
                      <AttachFileSharpIcon />
                    </Button>
                  ),
                }}
              />
            </Grid>
            <Typography className={classes.discText} variant="body">
              A .docx/.doc or .pdf format file with max size of 5MB is required
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isUploading ? (
            <CircularProgress style={{ width: "30px", height: "30px" }} />
          ) : (
            <Button color="primary" type="submit">
              <Hidden smDown>Upload</Hidden>
              <Hidden smUp>Upload File</Hidden>
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PortfolioAddDialog;
