import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
  Grid,
  Select,
  InputLabel,
  useTheme,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { UPDATE_USER_PROFILE_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";
import TokenService from "src/services/TokenService";
import CheckIcon from "@mui/icons-material/Check";
const useStyles = makeStyles({
  root: { lineHeight: "44px", color: "#595959" },
  heading: { lineHeight: "44px", fontSize: "18px" },
  status: {
    color: "green",
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "30px",
  },
});

const PersonalInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    userCode: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const userContext = useContext(UserContext);
  useEffect(() => {
    setValues({
      ...values,
      userCode: userContext.user.get.userCode,
      emailNotification: userContext.user.get.emailNotification,
      whatsappNotification: userContext.user.get.whatsappNotification,
    });
  }, [userContext]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.emailNotification]: event.target.value,
    });
  };
  const handleSubmit = () => {
    setDisabled(true);
    axios.put(UPDATE_USER_PROFILE_URL, values).then((response) => {
      setDisabled(false);
      const userObj = TokenService.getUser();
      userContext.user.set(userObj);
      showStatus("Checked");
      TokenService.getRefreshToken()
        .then((response) => {
          const userObj = TokenService.getUser();
          userContext.user.set(userObj);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
  const showStatus = (message) => {
    setStatus(message);
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <form className={classes.root}>
      <div>
        <Typography className={classes.heading}>
          Notification Preferences
        </Typography>
      </div>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} lg={12} sx={{ lineHeight: "40px" }}>
          <FormControlLabel
            // control={
            //   <Checkbox>
            //     {values.emailNotification == true ? <CheckIcon /> : ""}
            //   </Checkbox>
            // }
            control={<Checkbox defaultChecked />}
            label={
              matches ? (
                <Typography sx={{ fontSize: "16px" }}>
                  Receive Notifications via Email
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  Receive Notifications via Email
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item xs={12} lg={12} sx={{ lineHeight: "40px" }}>
          <FormControlLabel
            // control={
            //   <Checkbox>
            //     {values.whatsappNotification == true ? <CheckIcon /> : ""}
            //   </Checkbox>
            // }
            control={<Checkbox defaultChecked />}
            label={
              matches ? (
                <Typography sx={{ fontSize: "16px" }}>
                  Receive Notifications via WhatsApp
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                  Receive Notifications via WhatsApp
                </Typography>
              )
            }
          />
        </Grid>

        <Divider />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Grid>
        {status && (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.status}>
              {status}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PersonalInfo;
