import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors, typography } from "../../designsystem/theme";
import Typography from "src/designsystem/Core/Typography";
import { AddSubtract, CalendarDot } from "@icon-park/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import CertificateIcon from "src/assets/newProfilePlaceholderIcons/certificateIcon.svg";
import ContractDetailModel, { ContractMilestones } from "src/views/recruiter/RecruiterContract/state/models";

const GvContractDetails = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.nv80};
  border-radius: 8px;
  padding: 10px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;

const Header = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
`;

const Title = styled(Typography)`
  font-weight: 650;
  font-size: 0.8rem;
  color: gray;
`;

const MileStoneTitle = styled(Typography)`
  font-weight: 650;
  font-size: 0.8rem;
  color: gray;
`;
interface ContractCardProps {
  contractMilestoneData: ContractMilestones;
  index: any;
  user_code: string;
  dispatch: any;
}

const ContractMileStoneCard: FC<ContractCardProps> = ({
  contractMilestoneData,
  index,
  user_code,
  dispatch,
}) => {
  // const [toggle, setToggle] = useState(false)
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);

  return (
    
      <GvContractDetails>
        
      <MileStoneTitle  variant="label">MileStone {index+1}</MileStoneTitle>   
      <Header variant="label">{contractMilestoneData.name}</Header>
   
      <div>
      <Title variant="label">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            {contractMilestoneData.currencyCode==="INR" ? "₹ " + contractMilestoneData.fees : "$ " + contractMilestoneData.fees}
          </div>
        </Title>
      </div>
      <div>
        {/* // Date */}
        <Header variant="label">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CalendarDot
              theme="outline"
              size="20"
              strokeWidth={3}
              style={{ display: "grid", placeItems: "center" }}
            />
            {moment(contractMilestoneData.eta).format("DD/MM/YYYY")}
          </div>
        </Header>
      </div>
      </GvContractDetails>
   
  );
};

export default ContractMileStoneCard;
