import React from "react";
import { Typography, Icon, Button, Hidden } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  welcomeIcon: {
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      minWidth: "35px",
      minHeight: "66px",
    },
  },

  hello: {
    fontWeight: "400",
    fontSize: "2rem",
    letterSpacing: "1.1px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "27px",
      fontCharacter: 0,
      color: "#3D4347",
      verticalAlign: "top",
      horizontalAlign: "left",
      display: "flex",
      marginBottom: "15px",
    },
  },

  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

const RecruiterLandingHeader = (props) => {
  const { user, hasContent, handleOpen } = props;
  const classes = useStyles();
  return (
    <>
      <div className={classes.flex}>
        <h1 className={classes.hello}>
          {" "}
          <span role="img" aria-label="hello">
            👋{" "}
          </span>
          {hasContent ? "Welcome Back" : "Welcome"}, {user.get.firstName}
        </h1>
        {hasContent && (
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            Post New Gig
          </Button>
        )}
      </div>
      {!hasContent && (
        <Typography variant="body" style={{ fontSize: "1.2rem" }}>
          Let's get you started
        </Typography>
      )}
    </>
  );
};

export default RecruiterLandingHeader;
