import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import OfflinePinOutlinedIcon from "@mui/icons-material/OfflinePinOutlined";
import makeStyles from "@mui/styles/makeStyles";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import {
  EMAIL_VERIFIED_URL,
  SEND_EMAIL_VERIFICATION_LINK_URL,
} from "src/utils/constants";
import UserContext from "src/context/user-context";
import { BackspaceOutlined } from "@mui/icons-material";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import CheckIcon from "@mui/icons-material/Check";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
const useStyles = makeStyles({
  root: { ineHeight: "44px", color: "#595959" },
  heading: {
    lineHeight: "44px",
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
  },
  verified: {
    display: "flex",
    alignContent: "center",
    color: "50DD93",
  },
  inputLabel: {
    fontSize: "16px",
    color: "#595959",
    lineHeight: "30px",
  },
  textBold: {
    fontWeight: "bold",
  },
  refreshIcon: {
    cursor: "pointer",
    marginLeft: 5,
  },
  statusText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    color: "red",
    whiteSpace: "nowrap",
  },
  statusIcon: {
    margin: 2,
    color: "red",
  },
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const VerifyEmail = ({ className, ...rest }) => {
  const classes = useStyles();
  const [verificationStatus, setVerificationStatus] = useState(false);
  // const [waiting, setWaiting] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  // const fetchVerificationStatus = () => {
  //   axios
  //     .get(EMAIL_VERIFIED_URL + "?userCode=" + userContext.user.get.userCode)
  //     .then((response) => {
  //       setVerificationStatus(response.data.status);
  //     });
  // };
  // useEffect(() => {
  //   // setWaiting(0);
  //   setEmail(userContext.user.get.email);
  //   // fetchVerificationStatus();
  // }, [userContext]);

  //emailStatus:
  const [values, setValues] = useState({
    email: "",
    emailStatus: "",
  });

  const userContext = useContext(UserContext);

  useEffect(() => {
    setValues({
      ...values,
      email: userContext.user.get.email,
      emailStatus: userContext.user.get.emailStatus,
    });
  }, [userContext]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.email]: event.target.value,
    });
  };

  const handleSubmit = () => {
    // setDisabled(false);
    axios
      .post(
        SEND_EMAIL_VERIFICATION_LINK_URL +
          "?userCode=" +
          userContext.user.get.userCode
      )
      .then((response) => {
        setDisabled(false);
        handleClick();
        // fetchVerificationStatus();
        // startWaitTimer();
      });
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>
          Email Verification
          {verificationStatus == false ? (
            <PriorityHighOutlinedIcon sx={{ color: "#F65441" }} />
          ) : (
            <CheckIcon sx={{ color: "#50DD93" }} />
          )}
        </Typography>
        <InputLabel className={classes.inputLabel}>Email</InputLabel>
      </Grid>
      <Grid item xs={4} lg={5}>
        {values.email}
      </Grid>
      <Grid item xs={12} lg={5}>
        {verificationStatus == true && (
          <div className={classes.verified}>
            <OfflinePinIcon
              className={classes.statusIcon}
              color="#50DD93"
            ></OfflinePinIcon>
            <Typography className={classes.verified}> Verified </Typography>
          </div>
        )}
        {/* {((verificationStatus == "email-sent" && !waiting) || */}
        {verificationStatus == false && (
          <div className={classes.statusText}>
            <BackspaceOutlined
              className={classes.statusIcon}
              color="#F65441"
            ></BackspaceOutlined>
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginRight: "2px" }}
            >
              Not Verified
            </Typography>
            <Button onClick={handleSubmit} sx={{ textTransform: "capitalize" }}>
              Send Verification Email
            </Button>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%", backgroundColor: "#4695E2" }}
              >
                {/* <OfflinePinIcon /> */}
                <h3>Verification Email Sent!</h3>
                <p>Check your email and verify</p>
              </Alert>
            </Snackbar>
          </div>
        )}
        {/* {verificationStatus == "email-sent" && waiting !== 0 && ( */}
        {/* {verificationStatus == "email-sent" && ( */}
        {/* <>
          <Typography>
            Please check your email. If not received, please wait for
          </Typography>
          <Typography className={classes.textBold}>
          </Typography>
          <Typography>seconds before sending the request again.</Typography>
        </> */}
        {/* )} */}
      </Grid>
      {/* <Grid item xs={12}>
        {((verificationStatus == "email-sent" && !waiting) ||
        {(verificationStatus == "email-sent" ||
          verificationStatus == "not-verified") && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              // disabled={disabled}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </Box>
        )}
      </Grid> */}
    </Grid>
  );
};

export default VerifyEmail;
