import React, { FC, ReactElement, ReactNode } from "react";
import styled from '@emotion/styled'

import MUIButton, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { colors, typography } from "../../theme";

const GvButton = styled(MUIButton)`
      padding: ${(props: ButtonProps) => props.thick ? '1.1em 2em' : '0.8em 2em'};
      border-radius: 5px;
      font-weight: ${typography['button'].weight};
      font-family: Inter, sans-serif;
      font-size: ${typography['button'].size};
      transition: all 200ms ease;
      cursor: pointer;
      border: 1.5px solid ${colors.p40};
      ${(props: ButtonProps) => props.fullWidth && 'width: 100%;'}

      &.${buttonUnstyledClasses.focusVisible} {
        box-shadow: 0 4px 20px 0 ${colors.p40 + '24'}, 0 0 0 5px ${colors.p40 + '57'};
        outline: none;
      }

      ${(props: ButtonProps) => props.variant === 'contained' ?
    `
      background-color: ${colors.p40};
      color: ${colors.white};
      &:hover {
        background-color: ${colors.p30};
      }

      &.${buttonUnstyledClasses.active} {
        background-color: ${colors.p20};
      }

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${colors.n80};
        color: ${colors.n50};
        cursor: not-allowed;
        border-color: transparent;
      }
    `
    :
    `
      background-color: transparent;
      color: ${colors.p40};
      &:hover {
        background-color: ${colors.p40 + '24'};
      }

      &.${buttonUnstyledClasses.active} {
        background-color: ${colors.p40 + '57'};
      }

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${colors.n90 + '24'};
        color: ${colors.n50};
        cursor: not-allowed;
        border: 1.5px solid ${colors.n50};
      }
    `
  }

`

export interface ButtonProps {
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
  thick?: boolean
  fullWidth?: boolean
  variant?: 'contained' | 'outlined'
  className?: string
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <GvButton {...props} variant={props.variant ? props.variant : 'contained'} />
  )
}

export default Button