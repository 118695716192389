import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Grid,
  Select,
  InputLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import { Formik } from "formik";
import axios from "axios";
import UserContext from "src/context/user-context";
import { UPDATE_ORGANIZATION_URL, UPLOAD_ORG_LOGO_URL_V2 } from "src/utils/constants";
import { motion } from "framer-motion";
import { UploadImageDialog } from "src/components/dialogs/UploadImageDialog";
import Avatar from "src/components/Avatar";
import * as yup from "yup";
const useStyles = makeStyles((theme) => ({
  root: {},
  status: {
    color: "green",
  },
  avatarContainer: {
    width: "115px",
    height: "115px",
    borderRadius: "14px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 25px 50px -12px",
    position: "relative",
  },
  editText: {
    position: "absolute",
    color: "white",
    width: "115px",
    height: "115px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    borderRadius: "14px",
    zIndex: "1",
  },
}));

const OrganizationInfo = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    userCode: "",
    name: "",
    description: "",
    website: "",
  });

  // const initialValues = {
  //   name: "",
  //   description: "",
  //   website: ""
  // };
  const validationSchema = yup.object().shape({
    name: yup
      .string("Must be valid name")
      .min(1, "Must atleast be 1 character")
      .required("Organization Name is required"),
    website: yup
      .string("Must be valid name")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    // .required('Please enter valid website')
  });
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const [iconEdit, setIconEdit] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");

  const profileEditVariants = {
    unselected: { opacity: 0 },
    selected: { opacity: 1 },
  };

  const userContext = useContext(UserContext);
  useEffect(() => {
    if (data != null) {
      setValues({
        userCode: userContext.user.get.userCode,
        name: data.organizationEmployerDto.name,
        description: data.organizationEmployerDto.description,
        website: data.organizationEmployerDto.website,
      });
      setLogoUrl(data.organizationEmployerDto.logo + "?t=" + new Date().getMilliseconds(), 1000);
    }
  }, [data]);

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  const handleSubmit = (values, actions) => {
    setDisabled(true);
    axios({
      method: "POST",
      url: UPDATE_ORGANIZATION_URL,
      data: values,
    }).then((response) => {
      // setDisabled(false);
      console.info("IN SUCCESS!!");
      window.location.reload();
      var userObj = JSON.parse(localStorage.getItem("auth"));
      localStorage.setItem("auth", JSON.stringify(userObj));
      userContext.user.set(userObj);
      showStatus("Organization Profile Updated Successfully..");
    });
  };
  const showStatus = (message) => {
    setStatus(message);
    setTimeout(() => {
      setStatus("");
    }, 5000);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        isSubmitting,
        touched,
        values,
      }) => (
        <form className={clsx(classes.root, className)} {...rest}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <div className={classes.avatarContainer}>
                    <div>
                      <motion.div
                        initial="unselected"
                        animate={iconEdit ? "selected" : "unselected"}
                        variants={profileEditVariants}
                        className={classes.editText}
                        onMouseEnter={() => setIconEdit(true)}
                        onMouseLeave={() => setIconEdit(false)}
                        onClick={() => setEditAvatar(true)}
                      >
                        <Tooltip title="Edit Logo" arrow>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M14.078 7.061l2.861 2.862-10.799 10.798-3.584.723.724-3.585 10.798-10.798zm0-2.829l-12.64 12.64-1.438 7.128 7.127-1.438 12.642-12.64-5.691-5.69zm7.105 4.277l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z"
                              fill="#F4F6F8"
                            />
                          </svg>
                        </Tooltip>
                      </motion.div>
                      <UploadImageDialog
                        URL={UPLOAD_ORG_LOGO_URL_V2}
                        userCode={userContext.user.get.userCode}
                        open={editAvatar}
                        setOpen={setEditAvatar}
                        refetch={(res) => {
                          setEditAvatar(false);
                          console.log("after refetch", res);
                          setLogoUrl(res.data.s3PublicUrl);
                          window.location.reload();
                        }}
                      />
                    </div>

                    <Avatar
                      URL={logoUrl}
                      firstName={values.name}
                      height={115}
                      width={115}
                      className={classes.companyLogo}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                label="Name of the Organization"
                margin="normal"
                name="name"
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                label="About the Organization"
                margin="normal"
                name="description"
                onChange={handleChange}
                type="text"
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                label="Website"
                margin="normal"
                name="website"
                onChange={handleChange}
                type="text"
                value={values.website}
                variant="outlined"
                error={Boolean(touched.website && errors.website)}
                helperText={touched.website && errors.website}
              />
            </Grid>

            <Divider />
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" p={2}>
                <Button
                  disabled={disabled}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Box>
            </Grid>
            {status && (
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.status}>
                  {status}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default OrganizationInfo;
