import styled from "@emotion/styled";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Typography from "src/designsystem/Core/Typography";
import { breakpoints } from "src/designsystem/theme";

const Container = styled.div`
    display: grid;
    min-height: 100vh;
    margin: 0px;
    grid-template-columns: 1fr;
    
`
const Hero = styled.div`
    background-image: url(/static/images/circles_bg_1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 30px 0px 30px;
    align-items: center;
`

const Content = styled.div`
    display:grid;
    background: linear-gradient(90deg, rgba(23,141,219,1) 0%, rgba(4,119,195,1) 100%);
    color: white;
    padding: 10px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    min-height: 60vh;
    @media (min-width: ${breakpoints['lg']}) {
      padding: 40px;
    }
`

const AuthLayout: FC = () => {

  return (
    <Container>
      <Hero>
        <img src="/static/logo-full.svg" alt="gigvistas-logo" />
        <Content>
            <Outlet />
        </Content>
      </Hero>
    </Container>
  );
};

export default AuthLayout;
