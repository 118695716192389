import React, { useContext, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Page from "src/components/Page";
//import Notifications from './Notifications';
//import Password from './Password';
import OrganizationInfo from "./OrganizationInfo";
// import VerifyEmail from './VerifyEmail';
import useRequestID from "src/hooks/useRequestID";
import { GET_EMP_ORG_URL } from "src/utils/constants";
import UserContext from "src/context/user-context";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const OrganizationView = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("personal-info");
  const userContext = useContext(UserContext);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { data, loading, error } = useRequestID(
    GET_EMP_ORG_URL,
    userContext.user.get.userCode
  );

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <Typography variant="h5">Organization Profile</Typography>
        <Box mt={3}>
          <Accordion
            expanded={expanded === "personal-info"}
            onChange={handleChange("personal-info")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Organization Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OrganizationInfo data={data} />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Page>
  );
};

export default OrganizationView;
