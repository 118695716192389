import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormLabel, Popover, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const MoreDetails = ({ title, desc, ...rest }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [popOverMessage, setPopOverMessage] = React.useState("");

  const handleAnchorOpen = (event, message) => {
    setAnchorEl(event.target);
    setPopOverMessage(message);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setPopOverMessage("");
  };
  const openPopOver = Boolean(anchorEl);

  return (
    <React.Fragment>
      <FormLabel component="legend">
        {" "}
        <IconButton
          onClick={(e) => {
            handleAnchorOpen(
              e,
              <>
                Title: {title}
                <br />
                Description: {desc}
              </>
            );
          }}
          size="large"
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      </FormLabel>
      <Popover
        id="popover"
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleAnchorClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>{popOverMessage}</Typography>
      </Popover>
    </React.Fragment>
  );
};

MoreDetails.propTypes = {
  className: PropTypes.string,
};

export default MoreDetails;
