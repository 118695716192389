import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../../theme";

import File from "src/derivedComponents/File";

import { Hidden } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Frame from "../../Core/Frame";

import File1 from "src/designsystem/Derivative/FilesCard/FileImages/File1.svg";
import File2 from "src/designsystem/Derivative/FilesCard/FileImages/File2.svg";
import File3 from "src/designsystem/Derivative/FilesCard/FileImages/File3.svg";
import File4 from "src/designsystem/Derivative/FilesCard/FileImages/File4.svg";
import File5 from "src/designsystem/Derivative/FilesCard/FileImages/File5.svg";
import File6 from "src/designsystem/Derivative/FilesCard/FileImages/File6.svg";
import File7 from "src/designsystem/Derivative/FilesCard/FileImages/File7.svg";
import File8 from "src/designsystem/Derivative/FilesCard/FileImages/File8.svg";
import File9 from "src/designsystem/Derivative/FilesCard/FileImages/File9.svg";
import File10 from "src/designsystem/Derivative/FilesCard/FileImages/File10.svg";
import { CandidateFile } from "src/views/candidate/CandidateProfile/state/models";

const GvFileCard = styled.div`
  //background-color: ${colors.white};
  //border: 1px solid ${colors.nv80};
  //border-radius: 8px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;

const GvGigDetails = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  @media (max-width: ${breakpoints["sm"]}) {
    grid-template-columns: 1fr 0.5fr;
  }
`;

const MobileView = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;
interface FileCardProps {
  data: Array<CandidateFile>;
  editable?: boolean;
  deletable?: boolean;
}

const FileCard: FC<FileCardProps> = ({ data, editable, deletable }) => {
  const [toggle, setToggle] = useState(false);
  const matches = useMediaQuery(`(min-width:${breakpoints["lg"]})`);

  // const [showFrame, setShowFrame] = useState(1);

  const toggleFrame = () => {
    console.log(toggle);
    setToggle(!toggle);
  };

  const images = [
    File1,
    File2,
    File3,
    File4,
    File5,
    File6,
    File7,
    File8,
    File9,
    File10,
  ];

  return (
    <GvFileCard>
      <GvGigDetails>
        <Hidden smDown>
          {data.slice(0, data.length > 3 ? 3 : data.length).map((file, i) => {
            return (
              <File
                candidateFileId={file.candidateFileId}
                src={images[i]}
                name={file.title}
                alt={file.fileName}
                deletable={deletable}
                size={matches ? "180px" : "170px"}
              />
            );
          })}
          {data.length >= 4 && (
            <Frame
              hue={toggle ? 85 : 235}
              children={""}
              onClick={() => toggleFrame()}
            />
          )}
          {toggle &&
            data.slice(3, 5).map((file, i) => {
              return (
                <File
                  candidateFileId={file.candidateFileId}
                  src={images[i + 3]}
                  name={file.title}
                  alt={file.fileName}
                  deletable={deletable}
                  size={matches ? "180px" : "170px"}
                />
              );
            })}
        </Hidden>
        <Hidden smUp>
          {data.slice(0, 1).map((file, i) => {
            return (
              <File
                candidateFileId={file.candidateFileId}
                src={images[i]}
                name={file.title}
                alt={file.fileName}
                deletable={deletable}
                size={matches ? "180px" : "170px"}
              />
            );
          })}
          {data.length > 1 && (
            <Frame
              hue={toggle ? 85 : 235}
              children={""}
              onClick={() => toggleFrame()}
            />
          )}
          <MobileView>
            {toggle &&
              data.slice(1, 5).map((file, i) => {
                return (
                  <File
                    candidateFileId={file.candidateFileId}
                    src={images[i + 1]}
                    name={file.title}
                    alt={file.fileName}
                    deletable={deletable}
                    size={matches ? "180px" : "170px"}
                  />
                );
              })}
          </MobileView>
        </Hidden>
      </GvGigDetails>
    </GvFileCard>
  );
};

export default FileCard;
