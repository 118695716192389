import React, { useState, useContext } from "react";
import { Menu, Slide } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    minHeight: "75px",
    padding: "10px",
    borderBottom: "1px solid lightgrey",
    fontFamily: "sans-serif",
    display: "flex",
    width: "400px",
    backgroundColor: "white",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
  menuText: {
    padding: "5px",
  },
  menuImg: {
    flex: "0 0 50px",
  },
  menuTitle: {
    fontWeight: "bold",
    color: "#597A96",
    fontSize: "1rem",
  },
  menuDescription: {
    color: "grey",
    fontSize: "0.9rem",
    margin: "5px 0",
  },
  menuDate: {
    fontSize: "0.8rem",
  },
}));

const ToastNotification = (props) => {
  const classes = useStyles();

  return (
    <Slide direction="left" in={props.in}>
      <div className={classes.menuItem}>
        <img
          className={classes.menuImg}
          src="/static/logo.svg"
          alt="logo"
          height="50px"
          width="50px"
        />
        <div className={classes.menuText}>
          <p className={classes.menuTitle}>{props.content.title}</p>
          <p className={classes.menuDescription}>{props.content.description}</p>
        </div>
      </div>
    </Slide>
  );
};

export default ToastNotification;
