import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  CardHeader,
  Divider,
  CardContent,
  Slider,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import DateFnsUtils from "@date-io/date-fns";
import { useFormik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/lab";
import axios from "axios";
import moment from "moment";
import {
  POST_PROFILE_CERTIFICATE_URL,
  DELETE_PROFILE_CERTIFICATE_URL,
  DegreeType,
} from "src/utils/constants";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "calc(100% - 50px)",
    margin: "25px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 25px",
    alignItems: "center",
  },
}));

const CertificateEditDialog = ({
  open,
  setOpen,
  info,
  user,
  refetch,
  addFlag,
}) => {
  console.log(user);
  const classes = useStyles();
  const [initVal, setInitVal] = useState({});
  const theme = useTheme();
  // const [openDialog,setShowDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (addFlag) {
      setInitVal({
        title: "",
        description: "",
        certifiedDate: "",
        validUpto: "",
      });
    } else {
      setInitVal({
        title: info.title,
        description: info.description,
        certifiedDate: info.certifiedDate,
        validUpto: info.validUpto,
      });
    }
  }, []);

  const deleteCertificate = () => {
    axios
      .delete(
        DELETE_PROFILE_CERTIFICATE_URL +
          info.id +
          "/?userCode=" +
          user.get.userCode
      )
      .then(function (response) {
        console.log(response);
        refetch();
        setOpen(false);
      })
      .catch((err) => console.log(err));
  };
  const validationSchema = yup.object({
    title: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(50, "Must be less than 50 characters")
      .required("Title is required"),
    description: yup
      .string("Must be valid text")
      .min(1, "Must atleast be 1 character")
      .max(1000, "Must be less than 1000 characters")
      .required("Description is required"),
    certifiedDate: yup
      .date()
      .max(new Date(), "Future date not allowed")
      .typeError("Certified Date field can't be set empty")
      .required("Certified Date is required"),
    validUpto: yup
      .date()
      .nullable()
      .when(
        "certifiedDate",
        (certifiedDate, schema) =>
          certifiedDate &&
          schema.min(certifiedDate, "Valid Upto can't be before Certified Date")
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initVal,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (addFlag) {
        const valuesWebService = {
          title: values.title,
          description: values.description,
          // certifiedDate: moment(values.certifiedDate).format("DD-MM-YYYY"),
          // validUpto: moment(values.validUpto).format("DD-MM-YYYY"),
          certifiedDate: values.certifiedDate,
          validUpto: values.validUpto,
        };
        console.log();
        axios
          .post(
            `${POST_PROFILE_CERTIFICATE_URL}/?userCode=${user.get.userCode}`,
            valuesWebService
          )
          // .post(POST_PROFILE_CERTIFICATE_URL, valuesWebService, {
          // params: { userCode: user.get.userCode },
          // })
          .then(function (response) {
            console.log(response);
            refetch();
            setOpen(false);
          })
          .catch((err) => console.log(err));
      } else {
        const valuesWebService = {
          id: info.id,
          title: values.title,
          description: values.description,
          // certifiedDate: moment(values.certifiedDate).format("DD-MM-YYYY"),
          // validUpto: moment(values.validUpto).format("DD-MM-YYYY"),
          certifiedDate: values.certifiedDate,
          validUpto: values.validUpto,
        };
        console.log(valuesWebService);
        axios
          .put(POST_PROFILE_CERTIFICATE_URL, valuesWebService, {
            params: { userCode: user.get.userCode },
          })
          // .put(
          // `${POST_PROFILE_CERTIFICATE_URL}/?userCode=${user.get.userCode}`,
          // { ...valuesWebService }
          // {
          //   params: { userCode: user.get.userCode },
          // }
          // )
          .then(function (response) {
            console.log(response);
            setOpen(false);
            refetch();
          })
          .catch((err) => console.log(err));
      }
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onBackdropClick="false"
      // onClose={(event, reason) => {
      //   if (reason !== "backdropClick") {
      //     setOpen(false);
      //   }
      // }}
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
    >
      <div className={classes.title}>
        {addFlag ? (
          <Typography variant="h6">Add New Certificate</Typography>
        ) : (
          <Typography variant="h6">Edit Certificate</Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="title"
                label="Title*"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item sm={12} md={12} xs={12}>
              <TextField
                id="description"
                label="Description*"
                multiline
                rows={2}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                className={classes.field}
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                clearable
                name="certifiedDate"
                inputVariant="outlined"
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                margin="normal"
                id="certifiedDate"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Certified Date*"
                    className={classes.field}
                    error={
                      formik.touched.certifiedDate &&
                      Boolean(formik.errors.certifiedDate)
                    }
                    helperText={
                      formik.touched.certifiedDate &&
                      formik.errors.certifiedDate
                    }
                  />
                )}
                value={formik.values.certifiedDate}
                onChange={(date) => {
                  if (date === null) {
                    formik.setFieldValue("certifiedDate", "");
                    return;
                  }
                  formik.setFieldValue("certifiedDate", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                error={
                  formik.touched.certifiedDate &&
                  Boolean(formik.errors.certifiedDate)
                }
                helperText={
                  formik.touched.certifiedDate && formik.errors.certifiedDate
                }
              />
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <DatePicker
                disabled={!formik.values.certifiedDate}
                clearable
                name="validUpto"
                inputVariant="outlined"
                inputFormat="MM/yyyy"
                views={["month", "year"]}
                margin="normal"
                id="validUpto"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Valid Upto"
                    className={classes.field}
                    error={
                      formik.touched.validUpto &&
                      Boolean(formik.errors.validUpto)
                    }
                    helperText={
                      formik.touched.validUpto && formik.errors.validUpto
                    }
                  />
                )}
                // minDate={formik.values.validUpto}
                //  disableFuture
                value={formik.values.validUpto}
                onChange={(date) => {
                  if (date === null) {
                    formik.setFieldValue("validUpto", "");
                    return;
                  }
                  formik.setFieldValue("validUpto", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!addFlag && (
            <Button
              style={{ color: "#FF4A4A" }}
              onClick={() => deleteCertificate()}
            >
              Delete
            </Button>
          )}
          <Button color="primary" type="submit">
            {addFlag ? "Add Certification" : "Save changes"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CertificateEditDialog;
